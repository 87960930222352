/*======================= 
    Preloader
=======================*/
.preloader {
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: $white-color;
}

.th-preloader {
  align-items: center;
  cursor: none;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%; 

  .animation-preloader { 
      position: absolute;
      z-index: 100;

      .txt-loading { 
          font-family: $title-font; 
          font-size: 72px;
          line-height: 82px; 
          font-weight: 700;
          text-align: center;
          user-select: none;

          .characters {
              color: rgba(0, 0, 0, 0.2);
              position: relative;

              &:before {
                  animation: characters 4s infinite;
                  color: $theme-color;
                  content: attr(preloader-text);
                  left: 0;
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  transform: rotateY(-90deg);
              }

              &:nth-child(2) {
                  &:before {
                      animation-delay: 0.2s;
                  }
              }

              &:nth-child(3) {
                  &:before {
                      animation-delay: 0.4s;
                  }
              }

              &:nth-child(4) {
                  &:before {
                      animation-delay: 0.6s;
                  }
              }

              &:nth-child(5) {
                  &:before {
                      animation-delay: 0.8s;
                  }
              }

              &:nth-child(6) {
                  &:before {
                      animation-delay: 1s;
                  }
              }
          }
      }
    }
    @include xs {
        .animation-preloader .txt-loading {
            font-size: 30px;
            line-height: 30px;
        }
    }
}

/* Animation of letters loading from the preloader */
@keyframes characters {

  0%,
  75%,
  100% {
      opacity: 0;
      transform: rotateY(-90deg);
  }

  25%,
  50% {
      opacity: 1;
      transform: rotateY(0deg);
  }
}