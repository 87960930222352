@charset "UTF-8";
/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Mixin
    1.2. Function
    1.3. Variable
    1.4. Typography
    1.5. Extend
    1.7. Wordpress Default
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
    
04. Template Style
    4.1. Widget
    4.2. Header
    4.3. Footer
    4.4. Breadcumb
    4.5. Pagination
    4.6. Blog
    4.7. Comments
    4.8. Hero Area
    4.9. Error    
    4.00. Popup Search
    4.00. Popup Side Menu
    4.00. Wocommerce
    4.00. Products
    4.00. Cart
    4.00. Checkout
    4.00. Wishlist
    4.00. Contact
    4.00. About
    4.00. Team
    4.00. Testimonial
    4.00. Counter
    4.00. Blog
    4.00. Brand
    4.00. Simple Sections
    4.00. Why
    4.00. Faq
    4.00. CTA
    4.00. Service
    4.00. Pricing
    4.00. Gallery
    4.00. Skill
    4.00. Process
    4.00. Particle
    4.00. Feature

05. Spacing

*/
/*=================================
    CSS Index End
==================================*/
/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Mixin -------------------*/
@use "sass:math";
/*------------------- 1.2. Function -------------------*/
/*------------------- 1.3. Variable-------------------*/
:root {
  --theme-color: #00337C;
  --theme-color2: #00337C;
  --title-color: #141D38;
  --body-color: #737887;
  --smoke-color: #F5F5F5;
  --smoke-color2: #E2E8FA;
  --smoke-color3: #EFF1F9;
  --black-color: #000000;
  --black-color2: #080E1C;
  --gray-color: #bdbdbd;
  --white-color: #ffffff;
  --light-color: #72849B;
  --yellow-color: #FFB539;
  --success-color: #28a745;
  --error-color: #dc3545;
  --th-border-color: #E0E0E0;
  --title-font: 'Barlow', sans-serif;
  --body-font: 'Roboto', sans-serif;
  --icon-font: "Font Awesome 6 Pro";
  --main-container: 1220px;
  --container-gutters: 24px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 60px;
  --ripple-ani-duration: 5s;
}

/*------------------- 1.5. Typography -------------------*/
html,
body {
  scroll-behavior: auto !important;
}

body {
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 26px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

body.theme-blue {
  --theme-color: #3E66F3;
  background-color: #F8FAFF;
}

body.theme-blue2 {
  --theme-color: #3E66F3;
  background-color: #F3F2EA;
}

body.theme-blue3 {
  --theme-color: #3E66F3;
}

body.bg-dark {
  background-color: #0E0E0E !important;
  --theme-color: #02CE80;
  --theme-color2: #7BD706;
}

body.bg-como {
  --theme-color: #407360;
}

iframe {
  border: none;
  width: 100%;
}

.slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--th-border-color);
}

th {
  font-weight: 700;
  color: var(--title-color);
}

td,
th {
  border: 1px solid var(--th-border-color);
  padding: 9px 12px;
}

a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

a:hover {
  color: var(--title-color);
}

a:active, a:focus, a:hover, a:visited {
  text-decoration: none;
  outline: 0;
}

button {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

img {
  border: none;
  max-width: 100%;
}

ins {
  text-decoration: none;
}

pre {
  font-family: var(--body-font);
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

span.ajax-loader:empty,
p:empty {
  display: none;
}

p {
  font-family: var(--body-font);
  margin: 0 0 18px 0;
  color: var(--body-color);
  line-height: 1.75;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: var(--title-font);
  color: var(--title-color);
  text-transform: none;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 15px 0;
}

.h1,
h1 {
  font-size: 48px;
  line-height: 1.208;
}

.h2,
h2 {
  font-size: 40px;
  line-height: 1.25;
}

.h3,
h3 {
  font-size: 36px;
  line-height: 1.278;
}

.h4,
h4 {
  font-size: 30px;
  line-height: 1.333;
}

.h5,
h5 {
  font-size: 24px;
  line-height: 1.417;
}

.h6,
h6 {
  font-size: 20px;
  line-height: 1.5;
}

/* Large devices */
@media (max-width: 1199px) {
  .h1,
  h1 {
    font-size: 44px;
    line-height: 1.3;
  }
  .h2,
  h2 {
    font-size: 36px;
    line-height: 1.3;
  }
  .h3,
  h3 {
    font-size: 30px;
  }
  .h4,
  h4 {
    font-size: 24px;
  }
  .h5,
  h5 {
    font-size: 20px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 40px;
  }
  .h2,
  h2 {
    font-size: 34px;
    line-height: 1.3;
  }
  .h3,
  h3 {
    font-size: 26px;
  }
  .h4,
  h4 {
    font-size: 22px;
  }
  .h5,
  h5 {
    font-size: 18px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .h1,
  h1 {
    font-size: 34px;
    line-height: 1.35;
  }
  .h2,
  h2 {
    font-size: 28px;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .h1,
  h1 {
    font-size: 32px;
  }
}

/*------------------- 1.6. Extend -------------------*/
/*------------------- 1.7. Wordpress Default -------------------*/
p.has-drop-cap {
  margin-bottom: 20px;
}

.page--item p:last-child .alignright {
  clear: right;
}

.blog-title,
.pagi-title,
.breadcumb-title {
  word-break: break-word;
}

.blocks-gallery-caption,
.wp-block-embed figcaption,
.wp-block-image figcaption {
  color: var(--body-color);
}

.bypostauthor,
.gallery-caption {
  display: block;
}

.page-links,
.clearfix {
  clear: both;
}

.page--item {
  margin-bottom: 30px;
}

.page--item p {
  line-height: 1.8;
}

.content-none-search {
  margin-top: 30px;
}

.wp-block-button.aligncenter {
  text-align: center;
}

.alignleft {
  display: inline;
  float: left;
  margin-bottom: 10px;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-bottom: 10px;
  margin-left: 1.5em;
  margin-right: 1em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.gallery {
  margin-bottom: 1.5em;
  width: 100%;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
  padding: 0 5px;
}

.wp-block-columns {
  margin-bottom: 1em;
}

figure.gallery-item {
  margin-bottom: 10px;
  display: inline-block;
}

figure.wp-block-gallery {
  margin-bottom: 14px;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
  font-size: 12px;
  color: var(--body-color);
  line-height: 1.5;
  padding: .5em 0;
}

.wp-block-cover p:not(.has-text-color),
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: var(--white-color);
}

.wp-block-cover {
  margin-bottom: 15px;
}

.wp-caption-text {
  text-align: center;
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption .wp-caption-text {
  margin: .5em 0;
  font-size: 14px;
}

.wp-block-media-text,
.wp-block-media-text.alignwide,
figure.wp-block-gallery {
  margin-bottom: 30px;
}

.wp-block-media-text.alignwide {
  background-color: var(--smoke-color);
}

.editor-styles-wrapper .has-large-font-size,
.has-large-font-size {
  line-height: 1.4;
}

.wp-block-latest-comments a {
  color: inherit;
}

.wp-block-button {
  margin-bottom: 10px;
}

.wp-block-button:last-child {
  margin-bottom: 0;
}

.wp-block-button .wp-block-button__link {
  color: #fff;
}

.wp-block-button .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
}

.wp-block-button.is-style-outline .wp-block-button__link {
  background-color: transparent;
  border-color: var(--title-color);
  color: var(--title-color);
}

.wp-block-button.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

ol.wp-block-latest-comments li {
  margin: 15px 0;
}

ul.wp-block-latest-posts {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

ul.wp-block-latest-posts a {
  color: inherit;
}

ul.wp-block-latest-posts a:hover {
  color: var(--theme-color);
}

ul.wp-block-latest-posts li {
  margin: 15px 0;
}

.wp-block-search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 30px;
}

.wp-block-search .wp-block-search__input {
  width: 100%;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
}

.wp-block-search .wp-block-search__button {
  margin: 0;
  min-width: 110px;
  border: none;
  color: #fff;
  background-color: var(--theme-color);
}

.wp-block-search .wp-block-search__button.has-icon {
  min-width: 55px;
}

.wp-block-search .wp-block-search__button:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper {
  padding: 0;
  border: none;
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper .wp-block-search__input {
  padding: 0 8px 0 25px;
}

ul.wp-block-rss a {
  color: inherit;
}

.wp-block-group.has-background {
  padding: 15px 15px 1px;
  margin-bottom: 30px;
}

.wp-block-table td,
.wp-block-table th {
  border-color: rgba(0, 0, 0, 0.1);
}

.wp-block-table.is-style-stripes {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.wp-block-table.is-style-stripes {
  border: 0;
  margin-bottom: 30px;
  border-bottom: 0;
}

.wp-block-table.is-style-stripes th, .wp-block-table.is-style-stripes td {
  border-color: var(--th-border-color);
}

.logged-in .will-sticky .sticky-active.active,
.logged-in .preloader .th-btn {
  top: 32px;
}

@media (max-width: 782px) {
  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .th-btn {
    top: 46px;
  }
}

@media (max-width: 600px) {
  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .th-btn {
    top: 0;
  }
}

.post-password-form {
  margin-bottom: 30px;
  margin-top: 20px;
}

.post-password-form p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  gap: 15px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .post-password-form p {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.post-password-form label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
      -ms-flex: auto;
          flex: auto;
  margin-bottom: 0;
  line-height: 1;
  margin-top: 0;
  gap: 15px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .post-password-form label {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.post-password-form input {
  width: 100%;
  border: none;
  height: 55px;
  padding-left: 25px;
  color: var(--body-color);
  border: 1px solid var(--th-border-color);
}

.post-password-form input[type="submit"] {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  width: 140px;
  border: none;
  color: #fff;
  background-color: var(--theme-color);
  text-align: center;
}

.post-password-form input[type="submit"]:hover {
  background-color: var(--title-color);
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
  padding-top: 1em;
}

.page-links > .page-links-title {
  margin-right: 10px;
}

.page-links > span:not(.page-links-title):not(.screen-reader-text),
.page-links > a {
  display: inline-block;
  padding: 5px 13px;
  background-color: var(--white-color);
  color: var(--title-color);
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-right: 10px;
}

.page-links > span:not(.page-links-title):not(.screen-reader-text):hover,
.page-links > a:hover {
  opacity: 0.8;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: transparent;
}

.page-links > span:not(.page-links-title):not(.screen-reader-text).current,
.page-links > a.current {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}

.page-links span.screen-reader-text {
  display: none;
}

.blog-single .wp-block-archives-dropdown {
  margin-bottom: 30px;
}

.blog-single.format-quote, .blog-single.format-link, .blog-single.tag-sticky-2, .blog-single.sticky {
  border-color: transparent;
  position: relative;
}

.blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
  background-color: var(--smoke-color);
  padding: 0;
  border: none;
}

.blog-single.format-quote .blog-content:before, .blog-single.format-link .blog-content:before, .blog-single.tag-sticky-2 .blog-content:before, .blog-single.sticky .blog-content:before {
  display: none;
}

.blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
  content: '\f0c1';
  position: absolute;
  font-family: 'Font Awesome 6 Pro';
  font-size: 5rem;
  opacity: 0.3;
  right: 15px;
  line-height: 1;
  top: 15px;
  color: var(--theme-color);
  z-index: 1;
}

.blog-single.tag-sticky-2::before, .blog-single.sticky::before {
  content: "Featured";
  right: 0;
  top: 0;
  font-size: 18px;
  color: var(--white-color);
  background-color: var(--theme-color);
  font-family: var(--title-font);
  opacity: 1;
  text-transform: capitalize;
  padding: 10px 23px;
  font-weight: 400;
}

.blog-single.format-quote:before {
  content: "\f10e";
  top: 0;
}

.blog-single .blog-content .wp-block-categories-dropdown.wp-block-categories,
.blog-single .blog-content .wp-block-archives-dropdown {
  display: block;
  margin-bottom: 30px;
}

.blog-details .blog-single:before {
  display: none;
}

.blog-details .blog-single .blog-content {
  background-color: transparent;
  overflow: hidden;
}

.blog-details .blog-single.format-chat .blog-meta {
  margin-bottom: 20px;
}

.blog-details .blog-single.format-chat .blog-content > p:nth-child(2n) {
  background: var(--smoke-color);
  padding: 5px 20px;
}

.blog-details .blog-single.tag-sticky-2, .blog-details .blog-single.sticky, .blog-details .blog-single.format-quote, .blog-details .blog-single.format-link {
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.blog-details .blog-single.tag-sticky-2:before, .blog-details .blog-single.sticky:before, .blog-details .blog-single.format-quote:before, .blog-details .blog-single.format-link:before {
  display: none;
}

.th-search {
  background-color: #f3f3f3;
  margin-bottom: 30px;
  border: 1px solid #f3f3f3;
}

.th-search .search-grid-content {
  padding: 30px;
  /* Small devices */
}

@media (max-width: 767px) {
  .th-search .search-grid-content {
    padding: 20px;
  }
}

.th-search .search-grid-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 0;
}

.th-search .search-grid-title a {
  color: inherit;
}

.th-search .search-grid-title a:hover {
  color: var(--theme-color);
}

.th-search .search-grid-meta > * {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}

.th-search .search-grid-meta > *:last-child {
  margin-right: 0;
}

.th-search .search-grid-meta a,
.th-search .search-grid-meta span {
  color: var(--body-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }
  .blog-single.format-quote:before {
    top: 15px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 768px) {
  .wp-block-latest-comments {
    padding-left: 10px;
  }
  .page--content.clearfix + .th-comment-form {
    margin-top: 24px;
  }
}

/*=================================
    02. Reset
==================================*/
/*------------------- 2.1. Container -------------------*/
/* Medium Large devices */
@media (max-width: 1399px) {
  :root {
    --main-container: 1250px;
  }
}

@media (min-width: 1370px) {
  .th-container2 {
    --main-container: 1470px;
  }
  .th-container2 .container {
    --main-container: 1220px;
  }
}

/* Hight Resoulation devices */
@media (min-width: 1922px) {
  .th-container2 {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1300px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
  .container-xxl.px-0,
  .container-xl.px-0,
  .container-lg.px-0,
  .container-md.px-0,
  .container-sm.px-0,
  .container.px-0 {
    max-width: var(--main-container);
  }
  .th-container4 {
    --main-container: 1380px;
  }
  .th-container5 {
    --main-container: 1320px;
  }
}

@media only screen and (min-width: 1300px) {
  .th-container {
    --main-container: 1720px;
  }
}

@media only screen and (max-width: 1600px) {
  .container-fluid.px-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .container-fluid.px-0 .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.th-container3 {
  max-width: 1520px;
}

.round-container {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

/* Large devices */
@media (max-width: 1199px) {
  .round-container {
    border-radius: 0;
  }
}

/*------------------- 2.2. Grid -------------------*/
.slick-track > [class*=col] {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y);
}

.gy-30 {
  --bs-gutter-y: 30px;
}

.gy-40 {
  --bs-gutter-y: 40px;
}

.gy-50 {
  --bs-gutter-y: 50px;
}

.gx-10 {
  --bs-gutter-x: 10px;
}

.gy-80 {
  --bs-gutter-y: 80px;
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1199px) {
  .gy-80 {
    --bs-gutter-y: 60px;
  }
}

@media (max-width: 991px) {
  .gy-80 {
    --bs-gutter-y: 40px;
  }
}

@media (min-width: 1299px) {
  .gx-60 {
    --bs-gutter-x: 60px;
  }
}

@media (min-width: 1399px) {
  .gx-30 {
    --bs-gutter-x: 30px;
  }
  .gx-25 {
    --bs-gutter-x: 25px;
  }
  .gx-40 {
    --bs-gutter-x: 40px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .gy-50 {
    --bs-gutter-y: 40px;
  }
}

/*------------------- 2.3. Input -------------------*/
select,
.form-control,
.form-select,
textarea,
input {
  height: 56px;
  padding: 0 25px 0 25px;
  padding-right: 45px;
  border: 1px solid transparent;
  color: var(--body-color);
  background-color: var(--smoke-color);
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  font-family: var(--body-font);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  border-color: var(--theme-color);
  background-color: var(--smoke-color);
}

select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}

select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}

select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}

select::-webkit-input-placeholder, .form-control::-webkit-input-placeholder, .form-select::-webkit-input-placeholder, textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
  color: var(--body-color);
}

select::-moz-placeholder, .form-control::-moz-placeholder, .form-select::-moz-placeholder, textarea::-moz-placeholder, input::-moz-placeholder {
  color: var(--body-color);
}

select:-ms-input-placeholder, .form-control:-ms-input-placeholder, .form-select:-ms-input-placeholder, textarea:-ms-input-placeholder, input:-ms-input-placeholder {
  color: var(--body-color);
}

select::-ms-input-placeholder, .form-control::-ms-input-placeholder, .form-select::-ms-input-placeholder, textarea::-ms-input-placeholder, input::-ms-input-placeholder {
  color: var(--body-color);
}

select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}

.form-select,
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 26px center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.form-select.style2,
select.style2 {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.87109 1.71094L5.71484 5.62109C5.56901 5.7487 5.41406 5.8125 5.25 5.8125C5.08594 5.8125 4.9401 5.7487 4.8125 5.62109L0.65625 1.71094C0.382812 1.40104 0.373698 1.09115 0.628906 0.78125C0.920573 0.507812 1.23047 0.498698 1.55859 0.753906L5.25 4.25391L8.96875 0.753906C9.27865 0.498698 9.57943 0.498698 9.87109 0.753906C10.1263 1.08203 10.1263 1.40104 9.87109 1.71094Z' fill='%238B929C'/%3E%3C/svg%3E");
}

textarea.form-control,
textarea {
  min-height: 154px;
  padding-top: 16px;
  padding-bottom: 17px;
}

textarea.form-control.style2,
textarea.style2 {
  min-height: 100px;
}

.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}

.form-group > i {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 19px;
  font-size: 16px;
  color: var(--body-color);
}

.form-group > i.fa-envelope {
  padding-top: 1px;
}

.form-group > i.fa-comment {
  margin-top: -2px;
}

.form-group > i.fa-chevron-down {
  width: 17px;
  background-color: var(--smoke-color);
}

.form-group.has-label > i {
  top: 50px;
}

[class*="col-"].form-group > i {
  right: calc((var(--bs-gutter-x) / 2) + 25px);
}

option:checked, option:focus, option:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type="checkbox"]:checked ~ label:before {
  content: "\f00c";
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

input[type="checkbox"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}

input[type="checkbox"] ~ label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0px;
  top: 3.5px;
  background-color: var(--white-color);
  border: 1px solid var(--th-border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}

input[type="checkbox"].style2 ~ label {
  color: #8B929C;
  padding-left: 23px;
  margin-bottom: -0.5em;
}

input[type="checkbox"].style2 ~ label:before {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #8B929C;
  height: 14px;
  width: 14px;
  line-height: 14px;
  border-radius: 3px;
  top: 6px;
}

input[type="checkbox"].style2:checked ~ label:before {
  color: var(--theme-color);
}

input[type="radio"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type="radio"] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}

input[type="radio"] ~ label::before {
  content: "\f111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  padding-left: 0;
  font-size: 0.6em;
  line-height: 19px;
  text-align: center;
  border: 1px solid var(--theme-color);
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type="radio"]:checked ~ label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}

label {
  margin-bottom: 0.5em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 16px;
}

textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}

textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
  outline: 0;
  box-shadow: none;
}

textarea.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right calc(0.375em + 0.8875rem);
}

.row.no-gutters > .form-group {
  margin-bottom: 0;
}

.form-messages {
  display: none;
}

.form-messages.mb-0 * {
  margin-bottom: 0;
}

.form-messages.success {
  color: var(--success-color);
  display: block;
}

.form-messages.error {
  color: var(--error-color);
  display: block;
}

.form-messages pre {
  padding: 0;
  background-color: transparent;
  color: inherit;
}

/*------------------- 2.4. Slider -------------------*/
.swiper-wrapper.row {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.th-slider.has-shadow {
  padding-left: 12px;
  padding-right: 12px;
  margin: -25px -12px;
}

.th-slider.has-shadow .swiper-wrapper {
  padding: 25px 0;
}

.swiper-fade .swiper-slide {
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}

.swiper-fade .swiper-slide-prev {
  opacity: 0;
}

.swiper-pagination-bullets {
  position: relative;
  z-index: 3;
  margin: 50px 0 10px 0;
  line-height: 12px;
  height: 12px;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-horizontal-gap: 8px;
  margin: 5px 7px;
  opacity: 1;
  background-color: var(--th-border-color);
  position: relative;
  cursor: pointer;
}

.swiper-pagination-bullets .swiper-pagination-bullet:first-child {
  margin-left: 0;
}

.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.swiper-pagination-bullets .swiper-pagination-bullet:before {
  content: '';
  position: absolute;
  inset: -5px;
  border: 1px solid var(--theme-color);
  background: var(--theme-color);
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  border-radius: inherit;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}

.slider-area {
  position: relative;
}

.slider-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--white-color);
  border: 1px solid #EBEDF1;
  -webkit-filter: drop-shadow(0px 26px 65px rgba(232, 234, 238, 0.55));
          filter: drop-shadow(0px 26px 65px rgba(232, 234, 238, 0.55));
  color: var(--theme-color);
  position: absolute;
  top: 50%;
  left: var(--pos-x, -120px);
  width: var(--icon-size, 55px);
  height: var(--icon-size, 55px);
  line-height: var(--icon-size, 55px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 55px) / -2);
  z-index: 3;
  border-radius: 50%;
  /* Extra large devices */
}

@media (max-width: 1500px) {
  .slider-arrow {
    display: none !important;
  }
}

.slider-arrow.style2:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: var(--title-color);
}

.slider-arrow.style3 {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}

.slider-arrow.style3:hover {
  background-color: var(--title-color);
  border-color: var(--title-color);
}

.slider-arrow.style4 {
  background-color: #656A74;
  color: var(--white-color);
  border-color: #656A74;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
}

.slider-arrow.style4:hover {
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  border-color: #02CE80;
}

.slider-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}

.slider-arrow.slider-next {
  right: var(--pos-x, -120px);
  left: auto;
}

.slider-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}

.arrow-margin .slider-arrow {
  top: calc(50% - 30px);
}

.arrow-wrap {
  position: relative;
}

.arrow-wrap .slider-arrow {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.4);
      -ms-transform: scale(0.4);
          transform: scale(0.4);
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .arrow-wrap .slider-arrow {
    display: none;
  }
}

.arrow-wrap:hover .slider-arrow {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

/* Extra large devices */
@media (max-width: 1500px) {
  .slider-arrow {
    --arrow-horizontal: -20px;
    --pos-x: -70px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .slider-arrow {
    --arrow-horizontal: 40px;
    --pos-x: -17px;
  }
}

.icon-box .slider-arrow {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.icon-box .slider-arrow:not(:last-child) {
  margin-right: 8px;
}

/* Medium devices */
@media (max-width: 991px) {
  .slider-arrow {
    --icon-size: 40px;
    line-height: 38px;
    margin-right: 40px;
    font-size: 14px;
  }
  .slider-arrow.slider-next {
    margin-right: 0;
    margin-left: 40px;
  }
  .slick-dots {
    margin: 40px 0 0 0;
  }
  .icon-box .slider-arrow {
    margin-right: 0;
  }
}

.slick-3d-active {
  margin-left: -12%;
  margin-right: -12%;
}

.slick-3d-active .slick-list {
  padding-left: 30% !important;
  padding-right: 30% !important;
}

.slick-3d-active .slick-track {
  max-width: 100% !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
          transform: translate3d(0, 0, 0) !important;
  -webkit-perspective: 100px;
          perspective: 100px;
}

.slick-3d-active .slick-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
  width: 100% !important;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, -webkit-transform 1s;
  transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s, -webkit-transform 1s;
}

.slick-3d-active .slick-3d-next,
.slick-3d-active .slick-3d-prev,
.slick-3d-active .slick-3d-next2,
.slick-3d-active .slick-3d-prev2 {
  display: block;
}

.slick-3d-active .slick-current {
  opacity: 1;
  position: relative;
  display: block;
  z-index: 2;
}

.slick-3d-active .slick-3d-next {
  opacity: 1;
  -webkit-transform: translate3d(50%, 0, -21px);
          transform: translate3d(50%, 0, -21px);
  z-index: 1;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.slick-3d-active .slick-3d-next2 {
  opacity: 1;
  -webkit-transform: translate3d(40%, 0, -23px);
          transform: translate3d(40%, 0, -23px);
  z-index: 0;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.slick-3d-active .slick-3d-prev {
  opacity: 1;
  -webkit-transform: translate3d(-50%, 0, -21px);
          transform: translate3d(-50%, 0, -21px);
}

.slick-3d-active .slick-3d-prev .testi-card {
  box-shadow: none;
}

.slick-3d-active .slick-3d-prev2 {
  opacity: 1;
  -webkit-transform: translate3d(-40%, 0, -23px);
          transform: translate3d(-40%, 0, -23px);
}

/*------------------- 2.5. Mobile Menu -------------------*/
.th-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;
}

.th-menu-wrapper .mobile-logo {
  padding-bottom: 30px;
  padding-top: 40px;
  display: block;
  text-align: center;
  background-color: var(--smoke-color2);
}

.th-menu-wrapper .mobile-logo svg {
  max-width: 185px;
}

.th-menu-wrapper .th-menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: -16.5px;
  top: 25px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 35px;
  font-size: 18px;
  z-index: 1;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-radius: 50%;
}

.th-menu-wrapper .th-menu-toggle:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.th-menu-wrapper .th-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: #fff;
  border-right: 3px solid var(--theme-color);
  height: 100%;
  position: relative;
  left: -110%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
  z-index: 1;
}

.th-menu-wrapper.th-body-visible {
  opacity: 1;
  visibility: visible;
}

.th-menu-wrapper.th-body-visible .th-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.th-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;
}

.th-mobile-menu ul {
  margin: 0;
  padding: 0 0;
}

.th-mobile-menu ul li {
  border-bottom: 1px solid #fdedf1;
  list-style-type: none;
}

.th-mobile-menu ul li li:first-child {
  border-top: 1px solid #fdedf1;
}

.th-mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1.4;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--title-color);
  padding-left: 18px;
}

.th-mobile-menu ul li a:before {
  content: '\f105';
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
  top: 12px;
  margin-right: 10px;
  display: inline-block;
}

.th-mobile-menu ul li.th-active > a {
  color: var(--theme-color);
}

.th-mobile-menu ul li.th-active > a:before {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}

.th-mobile-menu ul li ul li {
  padding-left: 20px;
}

.th-mobile-menu ul li ul li:last-child {
  border-bottom: none;
}

.th-mobile-menu ul .th-item-has-children > a .th-mean-expand {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background-color: var(--smoke-color);
  color: var(--title-color);
  box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  border-radius: 50%;
}

.th-mobile-menu ul .th-item-has-children > a .th-mean-expand:before {
  content: '\f067';
  font-family: var(--icon-font);
}

.th-mobile-menu ul .th-item-has-children > a:after {
  content: "\f067";
  font-family: var(--icon-font);
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  border-radius: 50px;
  background-color: var(--smoke-color);
  float: right;
  margin-top: 1px;
}

.th-mobile-menu ul .th-item-has-children.th-active > a .th-mean-expand:before {
  content: '\f068';
}

.th-mobile-menu ul .th-item-has-children.th-active > a:after {
  content: "\f068";
}

.th-mobile-menu > ul {
  padding: 0 40px;
}

.th-mobile-menu > ul > li:last-child {
  border-bottom: none;
}

.th-menu-toggle {
  width: 56px;
  height: 56px;
  padding: 0;
  font-size: 20px;
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  border-radius: 4px;
}

.th-menu-toggle:hover {
  background-color: var(--title-color);
}

.th-menu-toggle.style-text, .th-menu-toggle.style-text-white {
  width: auto;
  height: auto;
  background-color: transparent;
  color: var(--title-color);
  font-size: 20px;
}

.th-menu-toggle.style-text i, .th-menu-toggle.style-text-white i {
  margin-right: 10px;
}

.th-menu-toggle.style-text-white {
  color: var(--white-color);
}

@media (max-width: 400px) {
  .th-menu-wrapper .th-menu-area {
    width: 100%;
    max-width: 270px;
  }
  .th-mobile-menu > ul {
    padding: 0 20px;
  }
}

/*=================================
    03. Utilities
==================================*/
/*------------------- 3.1. Preloader -------------------*/
/*======================= 
    Preloader
=======================*/
.preloader {
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: var(--white-color);
}

.th-preloader {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  /* Extra small devices */
}

.th-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.th-preloader .animation-preloader .txt-loading {
  font-family: var(--title-font);
  font-size: 72px;
  line-height: 82px;
  font-weight: 700;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.th-preloader .animation-preloader .txt-loading .characters {
  color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.th-preloader .animation-preloader .txt-loading .characters:before {
  -webkit-animation: characters 4s infinite;
          animation: characters 4s infinite;
  color: var(--theme-color);
  content: attr(preloader-text);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
}

.th-preloader .animation-preloader .txt-loading .characters:nth-child(2):before {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.th-preloader .animation-preloader .txt-loading .characters:nth-child(3):before {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.th-preloader .animation-preloader .txt-loading .characters:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.th-preloader .animation-preloader .txt-loading .characters:nth-child(5):before {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.th-preloader .animation-preloader .txt-loading .characters:nth-child(6):before {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@media (max-width: 575px) {
  .th-preloader .animation-preloader .txt-loading {
    font-size: 30px;
    line-height: 30px;
  }
}

/* Animation of letters loading from the preloader */
@-webkit-keyframes characters {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
            transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}
@keyframes characters {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
            transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}

/*------------------- 3.2. Buttons -------------------*/
.th-btn {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-family: var(--body-font);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 20.5px 20px;
  min-width: 144px;
  border-radius: 4px;
}

.th-btn:before, .th-btn:after {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--title-color);
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border-radius: 99px;
  -webkit-transform: scaleY(1.1);
      -ms-transform: scaleY(1.1);
          transform: scaleY(1.1);
}

.th-btn:before {
  -webkit-transform: scaleY(0);
      -ms-transform: scaleY(0);
          transform: scaleY(0);
}

.th-btn:after {
  -webkit-transform: scaleY(0);
      -ms-transform: scaleY(0);
          transform: scaleY(0);
}

.th-btn:hover, .th-btn.active {
  color: var(--white-color);
  box-shadow: none;
}

.th-btn:hover::before, .th-btn:hover:after, .th-btn.active::before, .th-btn.active:after {
  border-radius: 0;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.th-btn.style2 {
  background-color: transparent;
  color: var(--white-color);
  border: 1px solid var(--white-color);
  padding: 20px 40px;
  box-shadow: none;
}

.th-btn.style2:hover {
  color: var(--title-color);
  border-color: var(--white-color);
}

.th-btn.style2:hover:before, .th-btn.style2:hover:after {
  background-color: var(--white-color);
}

.th-btn.style3:hover {
  color: var(--title-color);
}

.th-btn.style3:hover:before, .th-btn.style3:hover:after {
  background-color: var(--white-color);
}

.th-btn.style4 {
  background-color: var(--title-color);
  color: var(--white-color);
}

.th-btn.style4:hover {
  color: var(--title-color);
}

.th-btn.style4:hover:before, .th-btn.style4:hover:after {
  background-color: var(--white-color);
}

.th-btn.style5 {
  background-color: var(--white-color);
  color: var(--title-color);
  box-shadow: none;
}

.th-btn.style5:hover {
  color: var(--white-color);
}

.th-btn.style5:hover:before, .th-btn.style5:hover:after {
  background-color: var(--title-color);
}

.th-btn.style6 {
  background-color: var(--white-color);
  color: var(--title-color);
  box-shadow: none;
}

.th-btn.style6:hover {
  color: var(--white-color);
}

.th-btn.style6:hover:before, .th-btn.style6:hover:after {
  background-color: var(--theme-color);
}

.th-btn.style7 {
  background-color: var(--title-color);
  color: var(--white-color);
}

.th-btn.style7:hover:before, .th-btn.style7:hover:after {
  background-color: var(--theme-color);
}

.th-btn.style8 {
  font-size: 16px;
  padding: 19.5px 37px;
  text-transform: capitalize;
  border-radius: 100px;
}

.th-btn.style9 {
  background-color: var(--white-color);
  color: var(--theme-color);
  box-shadow: none;
  border: none;
  padding: 17px 35.6px;
  font-weight: 700;
}

.th-btn.style9:hover {
  color: var(--white-color);
}

.th-btn.style9:hover:before, .th-btn.style9:hover:after {
  background-color: var(--title-color);
}

.th-btn.style10 {
  background-color: var(--white-color);
  border: 1px solid #E3E7F0;
  color: var(--theme-color);
  border-radius: 100px;
  padding: 18px 44px;
}

.th-btn.style10:hover {
  color: var(--white-color);
}

.th-btn.blue-border {
  background: #1C1C25;
  border: 1px solid var(--theme-color);
  border-radius: 100px;
}

.th-btn.blue-border:hover:before, .th-btn.blue-border:hover:after {
  background-color: var(--theme-color);
}

.th-btn.btn-gradient {
  font-size: 16px;
  text-transform: capitalize;
  padding: 18px 20px;
  background-color: var(--theme-color);
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.th-btn.btn-gradient:hover {
  background: -webkit-linear-gradient(top, var(--theme-color2) 0%, var(--theme-color) 100%);
  background: linear-gradient(180deg, var(--theme-color2) 0%, var(--theme-color) 100%);
}

.th-btn.btn-gradient:hover:before, .th-btn.btn-gradient:hover:after {
  display: none;
}

.th-btn .th-gradient-btn {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  text-transform: capitalize;
  text-align: center;
  color: var(--white-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 16px 35px;
  border-radius: 100px;
  border: 1px solid #293EEE;
  -webkit-transition: all 800ms linear 30s;
  transition: all 800ms linear 30s;
}

.th-btn .th-gradient-btn:after {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(left, #2C40F3 0%, rgba(0, 33, 209, 0.4) 100%);
  background: linear-gradient(90deg, #2C40F3 0%, rgba(0, 33, 209, 0.4) 100%);
  -webkit-transition: all 800ms linear 30s;
  transition: all 800ms linear 30s;
  z-index: -1;
}

.th-btn .th-gradient-btn:hover {
  color: var(--white-color);
}

.th-btn .th-gradient-btn:hover:after {
  background: -webkit-linear-gradient(left, rgba(0, 33, 209, 0.4) 0%, #2C40F3 100%);
  background: linear-gradient(90deg, rgba(0, 33, 209, 0.4) 0%, #2C40F3 100%);
}

.th-btn.style-radius {
  border-radius: 100px;
  padding: 18px 40px;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .th-btn.style-radius {
    padding: 18px 25px;
  }
}

.th-btn.btn-fw {
  width: 100%;
}

.th-btn.btn-fw:before, .th-btn.btn-fw:after {
  display: none;
}

.th-btn.btn-fw:hover {
  background-color: var(--title-color);
}

.th-btn.style-border {
  background-color: transparent;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  box-shadow: none;
}

.th-btn.style-border:hover {
  color: var(--white-color);
  border-color: var(--theme-color);
}

.th-btn.style-border:hover:before, .th-btn.style-border:hover:after {
  background-color: var(--theme-color);
}

.th-btn.style-border2 {
  background-color: transparent;
  color: var(--title-color);
  border: 1px solid var(--title-color);
  box-shadow: none;
}

.th-btn.style-border2:hover {
  color: var(--white-color);
  border-color: var(--title-color);
}

.th-btn.style-border2:hover:before, .th-btn.style-border2:hover:after {
  background-color: var(--title-color);
}

.th-btn.th-border {
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.06);
  -webkit-backdrop-filter: blur(12.5px);
          backdrop-filter: blur(12.5px);
  padding: 16px 39.4px;
}

.th-btn.th-border:hover {
  background: var(--title-color);
  border: 1px solid var(--title-color);
}

.icon-btn {
  display: inline-block;
  width: var(--btn-size, 56px);
  height: var(--btn-size, 56px);
  line-height: var(--btn-size, 56px);
  font-size: var(--btn-font-size, 16px);
  background-color: var(--icon-bg, transparent);
  color: var(--title-color);
  text-align: center;
  border-radius: 4px;
  border: 1px solid #232C47;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.icon-btn:hover {
  background-color: var(--white-color);
  color: var(--theme-color);
  border-color: var(--white-color);
}

.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.play-btn > i {
  display: inline-block;
  width: var(--icon-size, 60px);
  height: var(--icon-size, 60px);
  line-height: var(--icon-size, 60px);
  text-align: center;
  background-color: var(--white-color);
  color: var(--theme-color);
  font-size: var(--icon-font-size, 1.1em);
  border-radius: 50%;
  z-index: 1;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.play-btn:after, .play-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white-color);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.play-btn:after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.play-btn:hover:after, .play-btn:hover::before,
.play-btn:hover i {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.play-btn.style2:before, .play-btn.style2:after {
  background-color: transparent;
  border: 1px solid var(--white-color);
}

.play-btn.style3 > i {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 20px;
}

.play-btn.style3:before, .play-btn.style3:after {
  background-color: var(--theme-color);
}

.play-btn.style3:hover > i {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.play-btn.style3:hover:before, .play-btn.style3:hover:after {
  background-color: var(--white-color);
}

.link-btn {
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: -2px;
  text-transform: uppercase;
  color: var(--theme-color);
}

.link-btn i {
  margin-left: 5px;
  font-size: 0.9rem;
}

.link-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--theme-color);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.link-btn:hover {
  color: var(--theme-color);
}

.link-btn:hover::before {
  width: 100%;
}

.line-btn {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 4px;
  margin-bottom: -1px;
  text-transform: uppercase;
  color: var(--theme-color);
}

.line-btn i {
  margin-left: 5px;
  font-size: 0.9rem;
}

.line-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--theme-color);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.line-btn:hover {
  color: var(--title-color);
}

.line-btn:hover::before {
  background-color: var(--title-color);
  width: 45px;
}

.scroll-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 10000;
  opacity: 1;
  visibility: hidden;
  -webkit-transform: translateY(45px);
      -ms-transform: translateY(45px);
          transform: translateY(45px);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.scroll-top:after {
  content: "\f062";
  font-family: var(--icon-font);
  position: absolute;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: var(--theme-color);
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  z-index: 1;
  border: 2px solid var(--theme-color);
  box-shadow: none;
  border-radius: 50%;
}

.scroll-top svg {
  color: var(--theme-color);
  border-radius: 50%;
  background: var(--white-color);
}

.scroll-top svg path {
  fill: none;
}

.scroll-top .progress-circle path {
  stroke: var(--theme-color);
  stroke-width: 20px;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.scroll-top.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

/*------------------- 3.3. Titles -------------------*/
.sec-title {
  margin-bottom: calc(var(--section-title-space) - 10px);
  margin-top: -0.32em;
  text-transform: capitalize;
}

.sec-title.sec-title2 span {
  display: inline-block;
  position: relative;
  color: var(--theme-color);
}

.sec-title.sec-title2 span:after {
  content: "";
  height: 15px;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='215' height='19' viewBox='0 0 215 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 16.9999C37.6667 5.9999 129.8 -9.4001 213 16.9999' stroke='%23407360' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: repeat-x;
  position: absolute;
  bottom: -7px;
  left: 0;
  -webkit-animation: titleFill 3s linear infinite;
          animation: titleFill 3s linear infinite;
}

.sub-title {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color);
  margin-bottom: 30px;
  text-transform: uppercase;
  line-height: 24px;
  margin-top: -0.34em;
}

.sub-title:has(img) {
  margin-top: 0;
}

.sub-title.sub-title2 {
  position: relative;
  font-family: var(--body-font);
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 25px;
}

.sub-title.sub-title2.style1::before {
  margin-left: -4.5rem;
  display: none;
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 575px) {
  .sub-title.sub-title2.style1::before {
    margin-left: -4.5rem;
  }
}

@media (max-width: 375px) {
  .sub-title.sub-title2.style1::before {
    display: none;
  }
}

.sub-title.sub-title2::before, .sub-title.sub-title2:after {
  content: "";
  position: absolute;
  top: 48%;
  height: 2px;
  width: 40px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .sub-title.sub-title2::before, .sub-title.sub-title2:after {
    width: 30px;
  }
}

@media (max-width: 450px) {
  .sub-title.sub-title2::before, .sub-title.sub-title2:after {
    display: none;
  }
}

.sub-title.sub-title2::before {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  margin-left: -3.2rem;
  opacity: 0.5;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .sub-title.sub-title2::before {
    margin-left: -2.6rem;
  }
}

.sub-title.sub-title2::after {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  margin-left: 10px;
  opacity: 0.5;
}

.sub-title.sub-title3 {
  position: relative;
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--theme-color);
  margin-bottom: 25px;
}

.sub-title.sub-title3.style1::before {
  margin-left: -4.5rem;
  display: none;
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 575px) {
  .sub-title.sub-title3.style1::before {
    margin-left: -4.5rem;
  }
}

@media (max-width: 375px) {
  .sub-title.sub-title3.style1::before {
    display: none;
  }
}

.sub-title.sub-title3::before, .sub-title.sub-title3:after {
  content: "";
  position: absolute;
  top: 48%;
  height: 2px;
  width: 40px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .sub-title.sub-title3::before, .sub-title.sub-title3:after {
    width: 30px;
  }
}

@media (max-width: 450px) {
  .sub-title.sub-title3::before, .sub-title.sub-title3:after {
    display: none;
  }
}

.sub-title.sub-title3::before {
  background-color: var(--theme-color);
  margin-left: -3.2rem;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .sub-title.sub-title3::before {
    margin-left: -2.6rem;
  }
}

.sub-title.sub-title3::after {
  background-color: var(--theme-color);
  margin-left: 10px;
}

.sub-title.sub-title4 {
  position: relative;
  display: inline-block;
  padding: 0 20px;
}

.sub-title.sub-title4:before, .sub-title.sub-title4:after {
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='1.14673' width='9' height='9' rx='0.5' fill='white' stroke='%233E66F3'/%3E%3Crect x='3' y='3.64673' width='4' height='4' rx='2' fill='%233E66F3'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: 0px;
  left: 0px;
}

.sub-title.sub-title4:after {
  left: unset;
  right: 0px;
}

.sub-title.sub-title4.style1 {
  padding-right: 0;
}

.sub-title.sub-title4.style1:after {
  display: none;
}

.box-title {
  font-size: 24px;
  line-height: 1.417;
  font-weight: 700;
  margin-top: -0.32em;
}

.box-title a {
  color: inherit;
}

.box-title a:hover {
  color: var(--theme-color);
}

.sec-text {
  max-width: 720px;
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 10px);
  position: relative;
  z-index: 2;
}

.title-area.style2 {
  margin-top: -8px;
}

.title-area .sec-title {
  margin-bottom: 15px;
}

.title-area.mb-0 .sec-title {
  margin-bottom: -0.41em;
}

.title-area .th-btn {
  margin-top: 20px;
  margin-bottom: 10px;
}

hr.title-line {
  margin-top: 0;
  background-color: var(--th-border-color);
  opacity: 1;
}

.sec-btn,
.title-line {
  margin-bottom: var(--section-title-space);
}

.shadow-title {
  font-family: var(--title-font);
  font-size: 74px;
  font-weight: 900;
  line-height: 1;
  background-image: -webkit-linear-gradient(top, rgba(226, 232, 250, 0.7) 0%, rgba(226, 232, 250, 0) 88.54%);
  background-image: linear-gradient(180deg, rgba(226, 232, 250, 0.7) 0%, rgba(226, 232, 250, 0) 88.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: -0.55em 0 -0.45em -0.25em;
}

.shadow-title.color2 {
  background-image: -webkit-linear-gradient(top, #232C47 0%, rgba(20, 29, 56, 0) 91.15%);
  background-image: linear-gradient(180deg, #232C47 0%, rgba(20, 29, 56, 0) 91.15%);
}

.shadow-title.color3 {
  background-image: -webkit-linear-gradient(top, #E0E0E0 0%, rgba(220, 214, 214, 0) 93.75%);
  background-image: linear-gradient(180deg, #E0E0E0 0%, rgba(220, 214, 214, 0) 93.75%);
}

/* Large devices */
@media (max-width: 1199px) {
  .sub-title {
    margin-bottom: 20px;
  }
  .shadow-title {
    font-size: 64px;
  }
  .title-area,
  .sec-title {
    --section-title-space: 60px;
  }
  .title-area.mb-45,
  .sec-title.mb-45 {
    margin-bottom: 36px;
  }
  .title-area.mb-50,
  .sec-title.mb-50 {
    margin-bottom: 40px;
  }
  .sec-btn,
  .title-line {
    --section-title-space: 55px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .shadow-title {
    font-size: 60px;
  }
  .title-area,
  .sec-title {
    --section-title-space: 50px;
  }
  .title-area.mb-45,
  .sec-title.mb-45 {
    margin-bottom: 35px;
  }
  .sec-btn,
  .title-line {
    --section-title-space: 50px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .shadow-title {
    font-size: 52px;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .shadow-title {
    font-size: 40px;
  }
}

/*------------------- 3.4. Common -------------------*/
.shape-mockup-wrap {
  z-index: 2;
  position: relative;
}

.shape-mockup {
  position: absolute;
  z-index: -1;
}

.shape-mockup.z-index-3 {
  z-index: 3;
}

.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}

.z-index-common {
  position: relative;
  z-index: 3;
}

.z-index-3 {
  z-index: 3;
}

.z-index-n1 {
  z-index: -1;
}

.th-radius2 {
  border-radius: 20px;
}

.th-radius3 {
  border-radius: 30px;
}

.th-radius4 {
  border-radius: 100px;
  /* Small devices */
}

@media (max-width: 767px) {
  .th-radius4 {
    border-radius: 50px;
  }
}

.th-radius5 {
  border-radius: 50px;
}

.media-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.badge {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0.25em 0.45em;
  font-size: 0.7em;
  border-radius: 50%;
  top: 8px;
  right: 8px;
  font-weight: 400;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.th-social a {
  display: inline-block;
  width: var(--icon-size, 46px);
  height: var(--icon-size, 46px);
  line-height: var(--icon-size, 46px);
  background-color: var(--white-color);
  color: var(--body-color);
  font-size: 16px;
  text-align: center;
  margin-right: 5px;
  border-radius: 50%;
}

.th-social a:last-child {
  margin-right: 0;
}

.th-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.th-social.style2 a {
  --icon-size: 40px;
  font-size: 14px;
  background-color: transparent;
  color: var(--white-color);
  border: 1px solid;
}

.th-social.style2 a:hover {
  border-color: var(--theme-color);
  color: var(--white-color);
  background-color: var(--theme-color);
}

.th-social.color-theme a {
  color: var(--body-color);
  border-color: var(--theme-color);
}

.slider-shadow .slick-list {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: -30px;
  margin-top: -30px;
}

.btn-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.btn-group.style2 {
  gap: 20px 70px;
  /* Small devices */
}

@media (max-width: 767px) {
  .btn-group.style2 {
    gap: 20px 30px;
  }
}

.th-bg-img {
  position: absolute;
  inset: 0;
}

.th-bg-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*sec shape  start --------------------------*/
.sec-shape {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.sec-shape.style2:after, .sec-shape.style2:before {
  background: #E3E7F0;
}

.sec-shape.style2 .th-btn {
  padding: 20.5px 40px;
}

.sec-shape:after, .sec-shape:before {
  content: "";
  position: absolute;
  top: 50%;
  width: 560px;
  height: 1px;
  background: #656A74;
}

.sec-shape:before {
  margin-left: -37rem;
}

.sec-shape:after {
  margin-left: 2.5rem;
}

/*sec shape  end  --------------------------*/
/*Icon Masking********/
.icon-masking {
  position: relative;
  display: inline-block;
  height: auto;
  width: auto;
}

.icon-masking .mask-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  mix-blend-mode: hue;
  background: var(--theme-color);
  top: 0;
}

.icon-masking2 {
  position: relative;
  display: inline-block;
  height: auto;
  width: auto;
}

.icon-masking2 .mask-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  mix-blend-mode: color;
  background: var(--theme-color);
  top: 0;
}

/*Magnific popup transition********/
.mfp-zoom-in .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0.7);
      -ms-transform: scale(0.7);
          transform: scale(0.7);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.mfp-zoom-in.mfp-ready .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.7;
}

.mfp-zoom-in.mfp-removing .mfp-content {
  -webkit-transform: scale(0.7);
      -ms-transform: scale(0.7);
          transform: scale(0.7);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/*------------------- 3.6. Font -------------------*/
.font-icon {
  font-family: var(--icon-font);
}

.font-title {
  font-family: var(--title-font);
}

.font-body {
  font-family: var(--body-font);
}

.fw-extralight {
  font-weight: 100;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}

.fs-md {
  font-size: 18px;
}

.fs-xs {
  font-size: 14px;
}

/*------------------- 3.7. Background -------------------*/
.bg-theme {
  background-color: var(--theme-color) !important;
}

.bg-smoke {
  background-color: var(--smoke-color) !important;
}

.bg-smoke2 {
  background-color: var(--smoke-color2) !important;
}

.bg-smoke3 {
  background-color: var(--smoke-color3) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-black {
  background-color: var(--black-color) !important;
}

.bg-black2 {
  background-color: var(--black-color2) !important;
}

.bg-title {
  background-color: var(--title-color) !important;
}

.gradient-body {
  background-image: -webkit-linear-gradient(349.38deg, rgba(249, 215, 175, 0.3) 0%, rgba(214, 202, 245, 0.3) 24.03%, rgba(198, 241, 255, 0.3) 45.73%, rgba(248, 242, 222, 0.3) 69.05%, rgba(212, 179, 253, 0.3) 100.44%);
  background-image: linear-gradient(100.62deg, rgba(249, 215, 175, 0.3) 0%, rgba(214, 202, 245, 0.3) 24.03%, rgba(198, 241, 255, 0.3) 45.73%, rgba(248, 242, 222, 0.3) 69.05%, rgba(212, 179, 253, 0.3) 100.44%);
}

.gr-bg1 {
  background-image: -webkit-linear-gradient(10deg, rgba(249, 215, 175, 0.3) 0%, rgba(214, 202, 245, 0.3) 23.81%, rgba(198, 241, 255, 0.3) 45.3%, rgba(248, 242, 222, 0.3) 68.4%, rgba(212, 179, 253, 0.3) 99.5%);
  background-image: linear-gradient(80deg, rgba(249, 215, 175, 0.3) 0%, rgba(214, 202, 245, 0.3) 23.81%, rgba(198, 241, 255, 0.3) 45.3%, rgba(248, 242, 222, 0.3) 68.4%, rgba(212, 179, 253, 0.3) 99.5%);
}

.gr-bg2 {
  background-image: -webkit-linear-gradient(349.38deg, rgba(249, 215, 175, 0.3) 0%, rgba(214, 202, 245, 0.3) 24.03%, rgba(198, 241, 255, 0.3) 45.73%, rgba(248, 242, 222, 0.3) 69.05%, rgba(212, 179, 253, 0.3) 100.44%);
  background-image: linear-gradient(100.62deg, rgba(249, 215, 175, 0.3) 0%, rgba(214, 202, 245, 0.3) 24.03%, rgba(198, 241, 255, 0.3) 45.73%, rgba(248, 242, 222, 0.3) 69.05%, rgba(212, 179, 253, 0.3) 100.44%);
}

.gr-bg3 {
  background-image: -webkit-linear-gradient(339.41deg, rgba(236, 207, 254, 0.4) 0%, rgba(255, 221, 221, 0.5) 46.79%, rgba(247, 255, 229, 0.7) 100%);
  background-image: linear-gradient(110.59deg, rgba(236, 207, 254, 0.4) 0%, rgba(255, 221, 221, 0.5) 46.79%, rgba(247, 255, 229, 0.7) 100%);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.gr-bg4 {
  background-image: -webkit-linear-gradient(344.56deg, rgba(255, 229, 133, 0.4) 0%, rgba(189, 255, 199, 0.4) 48.48%, rgba(223, 109, 223, 0.4) 100%);
  background-image: linear-gradient(105.44deg, rgba(255, 229, 133, 0.4) 0%, rgba(189, 255, 199, 0.4) 48.48%, rgba(223, 109, 223, 0.4) 100%);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}

.gr-bg5 {
  background: -webkit-linear-gradient(top, rgba(237, 240, 244, 0) 0%, rgba(237, 240, 244, 0.85) 100%);
  background: linear-gradient(180deg, rgba(237, 240, 244, 0) 0%, rgba(237, 240, 244, 0.85) 100%);
}

.gr-bg6 {
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
}

.gray-body {
  background-color: #F8FAFF;
}

.smoke-body {
  background-color: #F3F2EA;
}

.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.bg-auto {
  background-size: auto auto;
}

.bg-top-center {
  background-size: auto;
  background-position: top center;
}

.bg-bottom-center {
  background-size: auto;
  background-position: bottom center;
}

.bg-repeat {
  background-size: auto;
  background-repeat: repeat;
}

.bg-bottom-right {
  background-size: auto;
  background-position: bottom right;
}

.bg-top-right {
  background-size: auto;
  background-position: top right;
}

/*------------------- 3.8. Text Color -------------------*/
.text-theme {
  color: var(--theme-color) !important;
}

.text-title {
  color: var(--title-color) !important;
}

.text-body {
  color: var(--body-color) !important;
}

.text-white {
  color: var(--white-color) !important;
}

.text-light {
  color: var(--light-color) !important;
}

.text-yellow {
  color: var(--yellow-color) !important;
}

.text-success {
  color: var(--success-color) !important;
}

.text-error {
  color: var(--error-color) !important;
}

.text-inherit {
  color: inherit;
}

.text-inherit:hover {
  color: var(--theme-color);
}

a.text-theme:hover,
.text-reset:hover {
  text-decoration: underline;
}

/*------------------- 3.9. Overlay -------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

[data-overlay] {
  position: relative;
  z-index: 2;
}

[data-overlay] [class^="col-"],
[data-overlay] [class*="col-"] {
  z-index: 1;
}

[data-overlay]:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

[data-overlay="theme"]:before {
  background-color: var(--theme-color);
}

[data-overlay="title"]:before {
  background-color: var(--title-color);
}

[data-overlay="white"]:before {
  background-color: var(--white-color);
}

[data-overlay="black"]:before {
  background-color: var(--black-color);
}

[data-overlay="overlay1"]:before {
  background-color: #080E1C;
}

[data-opacity="1"]:before {
  opacity: 0.1;
}

[data-opacity="2"]:before {
  opacity: 0.2;
}

[data-opacity="3"]:before {
  opacity: 0.3;
}

[data-opacity="4"]:before {
  opacity: 0.4;
}

[data-opacity="5"]:before {
  opacity: 0.5;
}

[data-opacity="6"]:before {
  opacity: 0.6;
}

[data-opacity="7"]:before {
  opacity: 0.7;
}

[data-opacity="8"]:before {
  opacity: 0.8;
}

[data-opacity="9"]:before {
  opacity: 0.9;
}

[data-opacity="10"]:before {
  opacity: 1;
}

/*------------------- 3.10. Animation -------------------*/
.ripple-animation, .play-btn:after, .play-btn:before, .testi-box_quote:after, .testi-box_quote:before, .testi-grid_quote:after, .testi-grid_quote:before, .faq-img4 .faq-shape:after, .faq-img4 .faq-shape:before, .service-grid_icon:after, .service-grid_icon:before, .process-card_number:after, .process-card_number:before,
.process-card .pulse:after,
.process-card .pulse:before {
  -webkit-animation-duration: var(--ripple-ani-duration);
          animation-duration: var(--ripple-ani-duration);
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: ripple;
          animation-name: ripple;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
    opacity: 0;
  }
}

@-webkit-keyframes ripple2 {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 1;
  }
  50% {
    opacity: 0.40;
  }
  100% {
    -webkit-transform: scale(1.9);
            transform: scale(1.9);
    opacity: 0;
  }
}

@keyframes ripple2 {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 1;
  }
  50% {
    opacity: 0.40;
  }
  100% {
    -webkit-transform: scale(1.9);
            transform: scale(1.9);
    opacity: 0;
  }
}

.fancy-animation {
  -webkit-animation: morph 8s ease-in-out infinite;
          animation: morph 8s ease-in-out infinite;
}

@-webkit-keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.movingX {
  -webkit-animation: movingX 8s linear infinite;
          animation: movingX 8s linear infinite;
}

@-webkit-keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes movingX {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.moving {
  -webkit-animation: moving 8s linear infinite;
          animation: moving 8s linear infinite;
}

@-webkit-keyframes moving {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes moving {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.jump {
  -webkit-animation: jumpAni 7s linear infinite;
          animation: jumpAni 7s linear infinite;
}

@-webkit-keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes jumpAni {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.jump-reverse {
  -webkit-animation: jumpReverseAni 7s linear infinite;
          animation: jumpReverseAni 7s linear infinite;
}

@-webkit-keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes jumpReverseAni {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.spin {
  -webkit-animation: spin 15s linear infinite;
          animation: spin 15s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes titleFill {
  0% {
    width: 0;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes titleFill {
  0% {
    width: 0;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

.swing {
  -webkit-animation: swing 10s infinite ease-in-out;
          animation: swing 10s infinite ease-in-out;
}

@-webkit-keyframes swing {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
}

@keyframes swing {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
}

.dance {
  -webkit-animation: dance 2s alternate infinite;
          animation: dance 2s alternate infinite;
}

@-webkit-keyframes dance {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes dance {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.dance2 {
  -webkit-animation: dance2 4s alternate infinite;
          animation: dance2 4s alternate infinite;
}

@-webkit-keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
            transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
            transform: translate3d(0, -15px, 15px);
  }
}

@keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
            transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
            transform: translate3d(0, -15px, 15px);
  }
}

.bg-color-ani,
.color-animate {
  -webkit-animation: bgColor 6s linear infinite;
          animation: bgColor 6s linear infinite;
}

@-webkit-keyframes bgColor {
  0% {
    background-color: #F2BA4C;
  }
  25% {
    background-color: #81F24C;
  }
  50% {
    background-color: #41F27D;
  }
  75% {
    background-color: #0500FF;
  }
  100% {
    background-color: #F2BA4C;
  }
}

@keyframes bgColor {
  0% {
    background-color: #F2BA4C;
  }
  25% {
    background-color: #81F24C;
  }
  50% {
    background-color: #41F27D;
  }
  75% {
    background-color: #0500FF;
  }
  100% {
    background-color: #F2BA4C;
  }
}

@-webkit-keyframes footerLine {
  0% {
    left: 40px;
  }
  50% {
    left: 0;
  }
  100% {
    left: 40px;
  }
}

@keyframes footerLine {
  0% {
    left: 40px;
  }
  50% {
    left: 0;
  }
  100% {
    left: 40px;
  }
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.fadein,
.scalein,
.slidetopleft,
.slidebottomright,
.slideinleft,
.slideinright,
.slideindown,
.slideinup,
.rollinleft,
.rollinright {
  opacity: 0;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-duration: 1.3s;
          animation-duration: 1.3s;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation-name: var(--animation-name);
          animation-name: var(--animation-name);
}

.th-ani {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.th-ani:hover {
  -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
          transform: translateY(-5px);
}

.swiper-slide-active .fadein {
  --animation-name: fadein;
}

.swiper-slide-active .scalein {
  --animation-name: scalein;
}

.swiper-slide-active .slidetopleft {
  --animation-name: slidetopleft;
}

.swiper-slide-active .slidebottomright {
  --animation-name: slidebottomright;
}

.swiper-slide-active .slideinleft {
  --animation-name: slideinleft;
}

.swiper-slide-active .slideinright {
  --animation-name: slideinright;
}

.swiper-slide-active .slideinup {
  --animation-name: slideinup;
}

.swiper-slide-active .slideindown {
  --animation-name: slideindown;
}

.swiper-slide-active .rollinleft {
  --animation-name: rollinleft;
}

.swiper-slide-active .rollinright {
  --animation-name: rollinright;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideinup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
            transform: translateX(calc(-100% - var(--gap)));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
            transform: translateX(calc(-100% - var(--gap)));
  }
}

@-webkit-keyframes slideinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(180px);
            transform: translateX(180px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideinright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(180px);
            transform: translateX(180px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideindown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes slideinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slidebottomright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(120px) translateY(120px);
            transform: translateX(120px) translateY(120px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
}

@keyframes slidebottomright {
  0% {
    opacity: 0;
    -webkit-transform: translateX(120px) translateY(120px);
            transform: translateX(120px) translateY(120px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
}

@-webkit-keyframes slidetopleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) translateY(-100px);
            transform: translateX(-100px) translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
}

@keyframes slidetopleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) translateY(-100px);
            transform: translateX(-100px) translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.dance {
  -webkit-animation: dance 2s alternate infinite;
          animation: dance 2s alternate infinite;
}

@keyframes dance {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.dance2 {
  -webkit-animation: dance2 4s alternate infinite;
          animation: dance2 4s alternate infinite;
}

@keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
            transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
            transform: translate3d(0, -15px, 15px);
  }
}

/*=================================
    04. Template Style
==================================*/
/*------------------- 4.1. Widget  -------------------*/
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
  list-style: none;
  padding: 0;
  margin: 0 0 0 0;
}

.widget_nav_menu > ul,
.widget_nav_menu .menu,
.widget_meta > ul,
.widget_meta .menu,
.widget_pages > ul,
.widget_pages .menu,
.widget_archive > ul,
.widget_archive .menu,
.widget_categories > ul,
.widget_categories .menu {
  margin: 0 0 -10px 0;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
  display: block;
  background-color: var(--white-color);
  margin: 0 0 10px 0;
  padding: 16px 45px 19px 25px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.313;
  color: var(--body-color);
  border-radius: 4px;
}

.widget_nav_menu a::before,
.widget_meta a::before,
.widget_pages a::before,
.widget_archive a::before,
.widget_categories a::before {
  content: "\f061";
  position: absolute;
  right: 25px;
  top: 17px;
  font-family: var(--icon-font);
  font-weight: 600;
  color: var(--theme-color);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
}

.widget_nav_menu li:last-child a,
.widget_meta li:last-child a,
.widget_pages li:last-child a,
.widget_archive li:last-child a,
.widget_categories li:last-child a {
  border-bottom: none;
}

.widget_nav_menu li > span,
.widget_meta li > span,
.widget_pages li > span,
.widget_archive li > span,
.widget_categories li > span {
  color: var(--white-color);
  background-color: #272A34;
  font-weight: 600;
  text-align: center;
  position: absolute;
  right: 24px;
  top: 15px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  pointer-events: none;
  min-width: 20px;
  text-align: right;
}

.widget_nav_menu a:hover,
.widget_meta a:hover,
.widget_pages a:hover,
.widget_archive a:hover,
.widget_categories a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.widget_nav_menu a:hover:before,
.widget_meta a:hover:before,
.widget_pages a:hover:before,
.widget_archive a:hover:before,
.widget_categories a:hover:before {
  color: var(--white-color);
}

.widget_nav_menu a:hover ~ span,
.widget_meta a:hover ~ span,
.widget_pages a:hover ~ span,
.widget_archive a:hover ~ span,
.widget_categories a:hover ~ span {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.widget_nav_menu .children,
.widget_meta .children,
.widget_pages .children,
.widget_archive .children,
.widget_categories .children {
  margin-left: 10px;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
  padding-right: 20px;
}

.widget_nav_menu .sub-menu {
  margin-left: 10px;
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.wp-block-archives a:not(:hover) {
  color: inherit;
}

.th-blog ul.wp-block-archives li {
  margin: 5px 0;
}

.widget {
  padding: var(--widget-padding-y, 40px) var(--widget-padding-x, 40px);
  background-color: var(--smoke-color);
  margin-bottom: 40px;
  position: relative;
  border-radius: 5px;
}

.widget[data-overlay]:before {
  z-index: -1;
}

.widget .th-social a {
  box-shadow: 0px 2px 14px rgba(4, 6, 66, 0.1);
}

.widget_title {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  font-family: var(--title-font);
  line-height: 1em;
  margin: -0.12em 0 28px 0;
}

.widget .search-form {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.widget .search-form input {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-radius: 4px 0 0 4px;
  padding-right: 25px;
}

.widget .search-form button {
  border: none;
  font-size: 16px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  padding: 0;
  width: 56px;
  text-align: center;
  border-radius: 0 4px 4px 0;
}

.widget .search-form button:hover {
  background-color: var(--title-color);
}

.wp-block-tag-cloud a,
.tagcloud a {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--body-font);
  text-transform: capitalize;
  line-height: 1;
  padding: 13px 19px;
  margin-right: 5px;
  margin-bottom: 10px;
  color: var(--body-color);
  background-color: var(--white-color);
  border-radius: 4px;
}

.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: var(--theme-color);
  color: var(--white-color) !important;
  border-color: var(--theme-color);
}

.wp-block-tag-cloud,
.tagcloud {
  margin-right: -10px;
  margin-bottom: -10px;
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 320px;
}

.sidebar-gallery .gallery-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--white-color);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(-50%, 20px);
      -ms-transform: translate(-50%, 20px);
          transform: translate(-50%, 20px);
}

.sidebar-gallery .gallery-btn:hover {
  color: var(--theme-color);
}

.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}

.sidebar-gallery .gallery-thumb:before {
  content: '';
  height: calc(100% - 14px);
  width: calc(100% - 14px);
  background-color: var(--title-color);
  opacity: 0.8;
  position: absolute;
  top: 7px;
  left: 7px;
  border: 1px solid var(--white-color);
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  border-radius: inherit;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.sidebar-gallery .gallery-thumb img {
  width: 100%;
}

.sidebar-gallery .gallery-thumb:hover:before {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}

.sidebar-gallery .gallery-thumb:hover .gallery-btn {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.recent-post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

.recent-post:last-child {
  margin-bottom: 0;
}

.recent-post .media-img {
  margin-right: 15px;
  width: 80px;
  overflow: hidden;
  border-radius: 5px;
}

.recent-post .media-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.recent-post .post-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0 0 8px 0;
  font-family: var(--title-font);
  text-transform: capitalize;
}

.recent-post .recent-post-meta a {
  text-transform: capitalize;
  font-size: 14px;
  color: var(--body-color);
  font-family: var(--body-font);
}

.recent-post .recent-post-meta a i {
  margin-right: 6px;
  color: var(--theme-color);
}

.recent-post .recent-post-meta a:hover {
  color: var(--theme-color);
}

.recent-post:hover .media-img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.sidebar-area {
  margin-bottom: -10px;
}

.sidebar-area ul.wp-block-latest-posts {
  margin-bottom: 0;
}

.sidebar-area ul.wp-block-latest-posts li:last-child {
  margin-bottom: 0;
}

.sidebar-area select,
.sidebar-area input {
  background-color: var(--white-color);
}

.sidebar-area .widget .wp-block-search {
  margin-bottom: 0;
}

.sidebar-area .wp-block-group__inner-container h2 {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 20px;
  margin-top: -0.07em;
}

.sidebar-area ol.wp-block-latest-comments {
  padding: 0;
  margin: 0;
}

.sidebar-area ol.wp-block-latest-comments li {
  line-height: 1.5;
  margin: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}

.sidebar-area ol.wp-block-latest-comments li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.download-widget-wrap .th-btn {
  width: 100%;
}

.download-widget-wrap .th-btn:first-child {
  margin-bottom: 20px;
}

.download-widget-wrap .th-btn.style2 {
  color: var(--body-color);
}

.download-widget-wrap .th-btn.style2:before, .download-widget-wrap .th-btn.style2:after {
  background-color: var(--theme-color);
}

.download-widget-wrap .th-btn.style2:hover {
  color: var(--white-color);
  border-color: var(--theme-color);
}

.widget_banner {
  background-color: var(--title-color);
  position: relative;
}

.widget_banner .shape1 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.widget_banner .widget_title {
  color: var(--white-color);
}

.widget_banner:has(.widget-map) {
  padding: 0;
  line-height: 0px;
}

.widget-map iframe {
  min-height: 280px;
}

.widget-banner {
  height: 360px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

@media (max-width: 360px) {
  .widget-banner {
    height: 280px;
  }
}

.widget-banner .text {
  display: block;
  margin-top: -0.4em;
  margin-bottom: 15px;
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 500;
}

.widget-banner .title {
  color: var(--white-color);
  margin-bottom: 35px;
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .widget-banner .title {
    font-size: 34px;
  }
}

.project-info-list .contact-feature {
  padding: 17px;
}

.project-info-list .contact-feature:not(:last-of-type) {
  margin-bottom: 10px;
}

.price_slider_wrapper {
  margin-top: -0.5em;
}

.price_slider_wrapper .price_label span {
  display: inline-block;
  color: var(--body-color);
}

.price_slider_wrapper .ui-slider {
  height: 6px;
  position: relative;
  width: 100%;
  background-color: var(--theme-color);
  border: none;
  margin-top: 15px;
  margin-bottom: 25px;
  cursor: pointer;
  border-radius: 0;
}

.price_slider_wrapper .ui-slider-range {
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  display: block;
  background-color: var(--title-color);
}

.price_slider_wrapper .ui-slider-handle {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  cursor: pointer;
  position: absolute;
  margin-top: -5px;
  z-index: 2;
  background-color: var(--white-color);
  border: 3px solid var(--title-color);
  -webkit-transform: translateX(-1px);
      -ms-transform: translateX(-1px);
          transform: translateX(-1px);
}

.price_slider_wrapper .ui-slider-handle:focus {
  outline: none;
  box-shadow: 1.5px 2.598px 10px 0px rgba(0, 0, 0, 0.15);
}

.price_slider_wrapper .ui-slider-handle:last-child {
  -webkit-transform: translateX(-9px);
      -ms-transform: translateX(-9px);
          transform: translateX(-9px);
}

.price_slider_wrapper button,
.price_slider_wrapper .button {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-weight: 500;
  line-height: 1.6;
  text-transform: capitalize;
  text-align: center;
  border: none;
  display: inline-block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  padding: 7px 20px;
  min-width: 100px;
  font-size: 14px;
  border-radius: 5px;
  -webkit-transition: 0.4s ease-in;
  transition: 0.4s ease-in;
}

.price_slider_wrapper button:hover,
.price_slider_wrapper .button:hover {
  background-color: var(--title-color);
}

.widget_shopping_cart .widget_title {
  margin-bottom: 30px;
  border-bottom: none;
}

.widget_shopping_cart ul {
  margin: 0;
  padding: 0;
}

.widget_shopping_cart ul li {
  list-style-type: none;
}

.widget_shopping_cart .mini_cart_item {
  position: relative;
  padding: 30px 30px 30px 90px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  text-align: left;
}

.widget_shopping_cart .mini_cart_item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.widget_shopping_cart .cart_list a:not(.remove) {
  display: block;
  color: var(--body-color);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--title-font);
  font-weight: 600;
  color: var(--title-color);
}

.widget_shopping_cart .cart_list a:not(.remove):hover {
  color: var(--theme-color);
}

.widget_shopping_cart .cart_list a.remove {
  position: absolute;
  top: 50%;
  left: 95%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--body-color);
}

.widget_shopping_cart .cart_list a.remove:hover {
  color: var(--theme-color);
}

.widget_shopping_cart .cart_list img {
  width: 75px;
  height: 75px;
  position: absolute;
  left: 0;
  top: 18px;
}

.widget_shopping_cart .quantity {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  vertical-align: top;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
}

.widget_shopping_cart .total {
  margin-top: 20px;
  font-size: 18px;
  color: var(--title-color);
  font-family: var(--body-font);
}

.widget_shopping_cart .total strong {
  font-family: var(--title-font);
}

.widget_shopping_cart .amount {
  padding-left: 5px;
}

.sidebar-area .widget_shopping_cart .th-btn {
  padding: 8px 22px;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .sidebar-shop .widget {
    padding: 30px;
  }
}

@media (min-width: 992px) {
  .sidebar-shop .widget {
    margin-bottom: 24px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .widget {
    --widget-padding-y: 30px;
    --widget-padding-x: 30px;
  }
  .widget_title {
    font-size: 22px;
    margin: -0.12em 0 24px 0;
  }
  .author-widget-wrap .name {
    font-size: 22px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .sidebar-area {
    padding-top: 30px;
  }
  .widget {
    --widget-padding-y: 40px;
    --widget-padding-x: 40px;
  }
  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .widget {
    padding: 35px 20px;
  }
}

@media (max-width: 330px) {
  .recent-post .post-title {
    font-size: 14px;
    line-height: 24px;
  }
  .recent-post .recent-post-meta a {
    font-size: 12px;
  }
}

.footer-widget {
  margin-bottom: 50px;
}

.footer-widget,
.footer-widget .widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
  box-shadow: none;
}

.footer-widget .form-group > i {
  color: var(--theme-color);
  top: 18px;
}

.footer-widget .sidebar-gallery {
  max-width: 287px;
}

.footer-widget .widget_title {
  max-width: 270px;
  color: var(--white-color);
  font-weight: 600;
  text-transform: uppercase;
  margin: -0.12em 0 35px 0;
  padding: 0 0 17px 0;
}

.footer-widget .widget_title:before {
  content: '';
  height: 2px;
  width: 100px;
  background-color: var(--theme-color);
  position: absolute;
  left: 0;
  bottom: 0;
}

.footer-widget .widget_title:after {
  content: '';
  height: 10px;
  width: 10px;
  background-color: var(--white-color);
  position: absolute;
  bottom: -4px;
  left: 96px;
  border-radius: 99px;
  border: 2px solid var(--theme-color);
}

.footer-widget .widget_title.style2 {
  color: var(--white-color);
}

.footer-widget .widget_title.style2:before, .footer-widget .widget_title.style2:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 61px;
  height: 2px;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.footer-widget .widget_title.style2:after {
  width: 16px;
  border: 2px solid var(--black-color);
  height: 10px;
  background: transparent;
  bottom: -3px;
  left: 40px;
  -webkit-animation: footerLine 7s linear infinite;
          animation: footerLine 7s linear infinite;
}

.footer-widget.widget_meta ul, .footer-widget.widget_pages ul, .footer-widget.widget_archive ul, .footer-widget.widget_categories ul, .footer-widget.widget_nav_menu ul {
  margin-top: -5px;
}

.footer-widget.widget_meta .menu,
.footer-widget.widget_meta > ul, .footer-widget.widget_pages .menu,
.footer-widget.widget_pages > ul, .footer-widget.widget_archive .menu,
.footer-widget.widget_archive > ul, .footer-widget.widget_categories .menu,
.footer-widget.widget_categories > ul, .footer-widget.widget_nav_menu .menu,
.footer-widget.widget_nav_menu > ul {
  margin-bottom: -5px;
}

.footer-widget.widget_meta a, .footer-widget.widget_pages a, .footer-widget.widget_archive a, .footer-widget.widget_categories a, .footer-widget.widget_nav_menu a {
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 0 20px;
  margin-bottom: 21px;
  font-family: var(--body-font);
  display: block;
  max-width: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding-right: 0;
  background-color: transparent;
  border-bottom: none;
  position: relative;
}

.footer-widget.widget_meta a:before, .footer-widget.widget_pages a:before, .footer-widget.widget_archive a:before, .footer-widget.widget_categories a:before, .footer-widget.widget_nav_menu a:before {
  content: "\f054";
  font-weight: 600;
  left: 0;
  top: 2px;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  font-size: 0.9em;
  background-color: transparent;
  border: none;
  color: inherit;
}

.footer-widget.widget_meta a:hover, .footer-widget.widget_pages a:hover, .footer-widget.widget_archive a:hover, .footer-widget.widget_categories a:hover, .footer-widget.widget_nav_menu a:hover {
  background-color: transparent;
  color: var(--theme-color);
}

.footer-widget.widget_meta a:hover:before, .footer-widget.widget_pages a:hover:before, .footer-widget.widget_archive a:hover:before, .footer-widget.widget_categories a:hover:before, .footer-widget.widget_nav_menu a:hover:before {
  color: var(--theme-color);
}

.footer-widget.widget_meta li > span, .footer-widget.widget_pages li > span, .footer-widget.widget_archive li > span, .footer-widget.widget_categories li > span, .footer-widget.widget_nav_menu li > span {
  width: auto;
  height: auto;
  position: relative;
  background-color: transparent;
  color: var(--body-color);
  line-height: 1;
}

.footer-widget.widget_meta li:last-child a, .footer-widget.widget_pages li:last-child a, .footer-widget.widget_archive li:last-child a, .footer-widget.widget_categories li:last-child a, .footer-widget.widget_nav_menu li:last-child a {
  margin-bottom: 0;
}

.footer-widget .recent-post {
  max-width: 315px;
  margin-top: -0.3em;
  margin-bottom: 28px;
}

.footer-widget .recent-post .post-title {
  color: var(--white-color);
  font-weight: 500;
}

.footer-widget .recent-post:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.footer-widget .recent-post .recent-post-meta a {
  font-weight: 400;
  line-height: 1.2;
}

.footer-widget .recent-post .recent-post-meta i {
  color: var(--theme-color);
}

.footer-widget .recent-post .recent-post-meta a:hover i {
  color: var(--theme-color);
}

.th-widget-about .about-logo {
  margin-bottom: 35px;
  margin-top: -8px;
}

.th-widget-about .about-text {
  margin-bottom: 25px;
  margin-top: -0.5em;
}

.footer-text {
  margin-top: -0.46em;
  margin-bottom: 25px;
}

.social-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.social-box .social-title {
  font-size: 20px;
  color: var(--white-color);
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 0;
}

.icon-group a {
  color: var(--white-color);
  font-size: 18px;
  margin-right: 17px;
}

.icon-group a:last-child {
  margin-right: 0;
}

.newsletter-widget {
  max-width: 290px;
}

.newsletter-widget .form-control {
  background-color: var(--white-color) !important;
  border: 1px solid var(--th-border-color);
}

.newsletter-form {
  position: relative;
  max-width: 465px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.newsletter-form .th-btn {
  min-width: 145px;
  padding: 21px 20px;
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-widget.widget_meta a, .footer-widget.widget_pages a, .footer-widget.widget_archive a, .footer-widget.widget_categories a, .footer-widget.widget_nav_menu a {
    margin-bottom: 16px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .footer-widget .widget_title {
    margin-bottom: 35px;
  }
  .th-widget-about .about-text {
    margin-bottom: 20px;
  }
  .social-box.mb-30 {
    margin-bottom: 25px;
  }
}

/*------------------- 4.2. Header  -------------------*/
.th-header {
  position: relative;
  z-index: 41;
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.sticky-wrapper {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.sticky-wrapper.sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-animation: stickyAni 0.4s ease-in-out;
          animation: stickyAni 0.4s ease-in-out;
}

@-webkit-keyframes stickyAni {
  0% {
    -webkit-transform: translate3d(0, -40px, 0) scaleY(0.8);
            transform: translate3d(0, -40px, 0) scaleY(0.8);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scaleY(1);
            transform: translate3d(0, 0, 0) scaleY(1);
    opacity: 1;
  }
}

@keyframes stickyAni {
  0% {
    -webkit-transform: translate3d(0, -40px, 0) scaleY(0.8);
            transform: translate3d(0, -40px, 0) scaleY(0.8);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scaleY(1);
            transform: translate3d(0, 0, 0) scaleY(1);
    opacity: 1;
  }
}

.main-menu a {
  display: block;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  color: var(--title-color);
  font-family: var(--title-font);
  text-transform: uppercase;
}

.main-menu a:hover {
  color: var(--theme-color);
}

.main-menu > ul > li {
  margin: 0 14px;
}

.main-menu > ul > li > a {
  padding: 47px 0;
}

.main-menu > ul > li > a:hover {
  color: var(--theme-color);
}

.main-menu ul {
  margin: 0;
  padding: 0;
}

.main-menu ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}

.main-menu ul li.menu-item-has-children > a:after {
  content: "\f078";
  position: relative;
  font-family: var(--icon-font);
  margin-left: 4px;
  font-weight: 600;
  top: 0;
  font-size: 1em;
}

.main-menu ul li:last-child {
  margin-right: 0 !important;
}

.main-menu ul li:first-child {
  margin-left: 0 !important;
}

.main-menu ul li:hover > ul.sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
          transform: scaleY(1);
  z-index: 9;
}

.main-menu ul li:hover ul.mega-menu {
  visibility: visible;
  opacity: 1;
  z-index: 9;
  -webkit-transform: scaleY(1) translateX(-50%);
      -ms-transform: scaleY(1) translateX(-50%);
          transform: scaleY(1) translateX(-50%);
}

.main-menu ul.sub-menu,
.main-menu ul.mega-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  visibility: hidden;
  min-width: 190px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 7px;
  left: -14px;
  opacity: 0;
  z-index: -1;
  border: 0;
  box-shadow: 0px 4px 15px rgba(1, 15, 28, 0.06);
  border-radius: 0;
  -webkit-transform: scaleY(0);
      -ms-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top center;
      -ms-transform-origin: top center;
          transform-origin: top center;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.main-menu ul.sub-menu a,
.main-menu ul.mega-menu a {
  font-size: 16px;
  line-height: 30px;
}

.main-menu ul.sub-menu {
  padding: 18px 20px 18px 18px;
  left: -27px;
}

.main-menu ul.sub-menu li {
  display: block;
  margin: 0 0;
  padding: 0px 9px;
}

.main-menu ul.sub-menu li.menu-item-has-children > a:after {
  content: "\f105";
  float: right;
  top: 1px;
}

.main-menu ul.sub-menu li a {
  position: relative;
  padding-left: 23px;
  text-transform: capitalize;
}

.main-menu ul.sub-menu li a:before {
  content: "\f649";
  position: absolute;
  top: 8px;
  left: 0;
  font-family: var(--icon-font);
  width: 11px;
  height: 11px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1;
  color: var(--theme-color);
  font-weight: 500;
}

.main-menu ul.sub-menu li ul.sub-menu {
  left: 100%;
  right: auto;
  top: 0;
  margin: 0 0;
  margin-left: 20px;
}

.main-menu ul.sub-menu li ul.sub-menu li ul {
  left: 100%;
  right: auto;
}

.main-menu .mega-menu-wrap {
  position: static;
}

.main-menu ul.mega-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: left;
  width: 100%;
  max-width: var(--main-container);
  padding: 20px 15px 23px 15px;
  left: 50%;
  -webkit-transform: scaleY(0) translateX(-50%);
      -ms-transform: scaleY(0) translateX(-50%);
          transform: scaleY(0) translateX(-50%);
}

.main-menu ul.mega-menu li {
  display: block;
  width: 100%;
  padding: 0 15px;
}

.main-menu ul.mega-menu li li {
  padding: 2px 0;
}

.main-menu ul.mega-menu li a {
  display: inline-block;
  text-transform: capitalize;
}

.main-menu ul.mega-menu > li > a {
  display: block;
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 700;
  color: var(--title-color);
  border-color: var(--theme-color);
}

.main-menu ul.mega-menu > li > a::after, .main-menu ul.mega-menu > li > a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: var(--theme-color);
}

.main-menu ul.mega-menu > li > a::after {
  width: calc(100% - 20px);
  left: 20px;
}

.main-menu ul.mega-menu > li > a:hover {
  padding-left: 0;
}

.main-menu.style2 > ul > li {
  margin: 0 6px;
}

.main-menu.style2 > ul > li > a {
  padding: 2px 16px;
  border-radius: 100px;
  background-color: transparent;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.main-menu.style2 > ul > li > a:hover {
  background-color: rgba(64, 102, 242, 0.1);
}

.main-menu.style2 ul.sub-menu {
  padding: 18px 20px 18px 18px;
  left: -27px;
  margin-top: 15px;
}

.header-button {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.header-button .icon-btn {
  position: relative;
}

.header-button .icon-btn:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.header-button .icon-btn:hover .badge {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.header-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header-links li {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 400;
}

.header-links li:not(:last-child) {
  margin: 0 26px 0 0;
}

.header-links li > i {
  margin-right: 6px;
}

.header-links li,
.header-links span,
.header-links p,
.header-links a {
  color: var(--body-color);
}

.header-links a:hover {
  color: var(--theme-color);
}

.header-links i {
  color: var(--body-color);
}

.header-links b,
.header-links strong {
  font-weight: 600;
}

.header-social .social-title {
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  margin: 0 15px 0 0;
  color: var(--body-color);
}

.header-social a {
  font-size: 16px;
  display: inline-block;
  color: var(--body-color);
  margin: 0 15px 0 0;
}

.header-social a:last-child {
  margin-right: 0;
}

.header-social a:hover {
  color: var(--theme-color);
}

.header-logo {
  padding-top: 15px;
  padding-bottom: 15px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .header-logo {
    max-width: 150px;
  }
  .header-logo .icon-masking .mask-icon {
    -webkit-mask-size: cover;
            mask-size: cover;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .main-menu > ul > li > a {
    padding: 37px 0;
  }
}

/* Header 1 ---------------------------------- */
.header-layout1 {
  position: relative;
}

.header-layout1 .header-top {
  padding: 17px 0;
  background-color: var(--theme-color);
  --body-color: #fff;
}

.header-layout1 .header-top a:hover {
  color: var(--title-color);
}

.header-layout1 .menu-area {
  position: relative;
  z-index: 2;
  background-color: #080E1C;
}

.header-layout1 .main-menu {
  margin-left: 60px;
}

.header-layout1 .main-menu > ul > li > a:not(:hover) {
  color: var(--white-color);
}

.header-layout1 .menu-area {
  position: relative;
}

.header-layout1 .header-logo {
  position: relative;
  z-index: 2;
}

.header-layout1 .header-button .icon-btn {
  color: var(--white-color);
}

.header-layout1 .logo-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 32.5%;
  height: 100%;
}

.header-layout1 .logo-bg:before, .header-layout1 .logo-bg:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--theme-color);
  -webkit-clip-path: polygon(0 0, calc(100% - 80px) 0%, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, calc(100% - 80px) 0%, 100% 100%, 0% 100%);
  z-index: -2;
}

.header-layout1 .logo-bg:after {
  background-image: url("../img/theme-img/logo_bg.svg");
  background-color: var(--white-color);
  background-repeat: repeat;
  width: calc(100% - 20px);
  -webkit-clip-path: polygon(0 0, calc(100% - 60px) 0%, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, calc(100% - 60px) 0%, 100% 100%, 0% 100%);
  z-index: -1;
}

/* Hight Resoulation devices */
@media (min-width: 1922px) {
  .header-layout1 .logo-bg {
    width: 37%;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {
  .header-layout1 .logo-bg {
    width: 27%;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .header-layout1 .header-button .th-btn {
    display: none;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .header-layout1 .logo-bg:before {
    -webkit-clip-path: polygon(0 0, calc(100% - 50px) 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, calc(100% - 50px) 0%, 100% 100%, 0% 100%);
  }
  .header-layout1 .logo-bg:after {
    -webkit-clip-path: polygon(0 0, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
  }
  .header-layout1 .main-menu {
    margin-left: 30px;
  }
  .header-layout1 .header-top {
    padding: 12px 0;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .header-layout1 .logo-bg {
    width: 56%;
  }
  .header-layout1 .header-logo img {
    max-height: 80px;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .header-layout1 .logo-bg {
    width: 60%;
  }
}

@media (max-width: 320px) {
  .header-layout1 .logo-bg {
    width: 64%;
  }
}

/* Header 2 ---------------------------------- */
.header-layout2 .icon-btn {
  background-color: var(--white-color);
  color: var(--title-color);
  border: 1px solid #ddd;
  border-radius: 50%;
  line-height: 54px;
}

.header-layout2 .icon-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.header-layout2 .main-menu > ul > li > a {
  color: var(--title-color);
  font-weight: 600;
}

.header-layout2 .main-menu > ul > li > a:hover {
  color: var(--theme-color);
}

.header-layout2 ul.sub-menu {
  background-color: #E1EAFF;
}

.header-layout2 .header-top {
  padding: 17px 0;
  background-color: var(--theme-color);
  --body-color: #fff;
}

.header-layout2 .header-top a:hover {
  color: var(--title-color);
}

.header-layout2 .menu-area {
 /* background-color: #E1EAFF;*/
}

/* Large devices */
@media (max-width: 1199px) {
  .header-layout2 .header-button .th-btn {
    display: none;
  }
  .header-layout2 .header-top {
    padding: 12px 0;
  }
}

/* Header 3 ---------------------------------- */
.header-layout3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  --main-container: 1700px;
}

.header-layout3 .header-button .icon-btn:not(:hover) {
  border-color: #bdbdbd;
}

.header-layout3 .main-menu > ul > li > a {
  font-weight: 600;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .header-layout3 .header-button .th-btn {
    display: none;
  }
}

/* Header 4 ---------------------------------- */
.header-layout4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header-layout4 .sticky-wrapper.sticky .menu-area {
  background-color: var(--title-color);
}

.header-layout4 .menu-area {
  background: rgba(0, 0, 0, 0.29);
  -webkit-backdrop-filter: blur(7.5px);
          backdrop-filter: blur(7.5px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.36);
  padding: 15px 0;
}

.header-layout4 .main-menu > ul > li > a {
  color: var(--white-color) !important;
  font-size: 16px;
  font-weight: 600;
}

.header-layout4 .main-menu > ul > li > a:after {
  color: var(--white-color) !important;
}

.header-layout4 .main-menu > ul > li > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white-color) !important;
}

.header-layout4 .main-menu > ul > li > a:hover:after {
  color: var(--white-color) !important;
}

.header-layout4 .main-menu ul li.menu-item-has-children > a:after {
  content: "\2b";
  font-size: 12px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}

.header-layout4 .main-menu ul li.menu-item-has-children > a:hover:after {
  content: "\f068";
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.header-layout4 .header-button {
  /* Large devices */
}

.header-layout4 .header-button .th-btn {
  padding: 17px 42.5px;
  font-weight: 700;
}

@media (max-width: 1199px) {
  .header-layout4 .header-button .th-btn {
    display: none;
  }
}

/* Header 5 ---------------------------------- */
.header-layout5 {
  position: relative;
}

.header-layout5 .social-links .social-title {
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 0 0;
  letter-spacing: -0.32px;
  font-family: var(--title-font);
  color: var(--title-color);
}

@media (max-width: 1599px) {
  .header-layout5 .social-links .social-title {
    display: none;
  }
}

.header-layout5 .social-links a {
  font-size: 14px;
  display: inline-block;
  color: var(--title-color);
  margin: 0 15px 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header-layout5 .social-links a:last-child {
  margin-right: 0 !important;
}

.header-layout5 .social-links a:hover {
  color: var(--theme-color);
}

.header-layout5 .social-links a:hover i {
  -webkit-animation: slideTop 0.5s;
          animation: slideTop 0.5s;
}

.header-layout5 .header-top {
  background-color: transparent;
  border-bottom: 1px solid #D9D9D9;
  padding: 12px 0;
}

.header-layout5 .header-top .header-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header-layout5 .header-top .header-links li {
  display: inline-block;
  position: relative;
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
}

.header-layout5 .header-top .header-links li:not(:last-child) {
  margin: 0 40px 0 0;
}

.header-layout5 .header-top .header-links li:not(:last-child):after {
  content: '';
  height: 15px;
  width: 1px;
  background-color: #CAD4F1;
  position: absolute;
  top: 5px;
  right: -20px;
}

.header-layout5 .header-top .header-links li > i {
  color: var(--theme-color);
  margin-right: 8px;
}

.header-layout5 .main-menu ul li.menu-item-has-children > a:after {
  content: "\2b";
  font-size: 12px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}

.header-layout5 .main-menu ul li.menu-item-has-children > a:hover:after {
  content: "\f068";
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.header-layout5 .menu-area {
  padding: 18px 0;
}

.header-layout5 .main-menu {
  margin-right: 150px;
  /* Medium Large devices */
}

@media (max-width: 1399px) {
  .header-layout5 .main-menu {
    margin-right: 0;
  }
}

.header-layout5 .header-button .th-btn {
  padding: 18px 38px;
}

/* Header 6 ---------------------------------- */
.header-layout6 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.header-layout6 .menu-area {
  padding: 17px 0;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .header-layout6 .menu-area {
    padding: 10px 0;
  }
}

/* Header layout 7 start  ---------------------------------- */
.header-layout7 .sticky-wrapper.sticky .menu-area {
  background-color: var(--title-color);
}

.header-layout7 .menu-area {
  background: rgba(0, 0, 0, 0.29);
  -webkit-backdrop-filter: blur(7.5px);
          backdrop-filter: blur(7.5px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.36);
  padding: 15px 0;
}

.header-layout7 .main-menu.style2 > ul > li {
  margin: 0 4px;
}

.header-layout7 .main-menu > ul > li > a {
  color: var(--white-color) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
}

.header-layout7 .main-menu > ul > li > a:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  border-radius: 100px;
  opacity: 0;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.header-layout7 .main-menu > ul > li > a:after {
  color: var(--white-color) !important;
}

.header-layout7 .main-menu > ul > li > a:hover {
  color: var(--theme-color) !important;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.header-layout7 .main-menu > ul > li > a:hover:before {
  opacity: 0.1;
}

.header-layout7 .main-menu > ul > li > a:hover:after {
  color: var(--theme-color) !important;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.header-layout7 .main-menu ul li.menu-item-has-children > a:after {
  content: "\2b";
  font-size: 12px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}

.header-layout7 .main-menu ul li.menu-item-has-children > a:hover:after {
  content: "\f068";
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.header-layout7 .header-button {
  /* Large devices */
}

.header-layout7 .header-button .th-btn {
  padding: 17px 40px;
}

@media (max-width: 1199px) {
  .header-layout7 .header-button .th-btn {
    display: none;
  }
}

@media (min-width: 1400px) {
  .header-layout7 .th-container {
    --main-container: 1720px;
  }
}

/* header layout 7 end -----------------------------*/
/* header-layout8 start ------------------- */
.header-layout8 .sticky-wrapper.sticky .menu-area {
  padding: 15px 0;
}

.header-layout8 .menu-area {
  padding: 40px 0;
  /* Small devices */
}

@media (max-width: 767px) {
  .header-layout8 .menu-area {
    padding: 25px 0;
  }
}

.header-layout8 .main-menu a {
  display: block;
  position: relative;
  font-size: 16px;
  text-transform: capitalize;
}

.header-layout8 .main-menu.style2 > ul > li {
  margin: 0 0px;
}

.header-layout8 .th-btn {
  border-radius: 100px;
  padding: 18px 42px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .header-layout8 .th-btn {
    display: none;
  }
}

/* header-layout8 end ------------------- */
/* header-layout9 start ------------------- */
.header-layout9.header-absolute {
  top: 120px;
}

.header-layout9 .sticky-wrapper.sticky .menu-area {
  background-color: var(--title-color);
}

.header-layout9 .menu-area {
  max-width: 1500px;
  display: block;
  margin: auto;
  background: rgba(22, 24, 29, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 50px;
  padding: 16px 45px;
}

.header-layout9 .main-menu.style2 > ul > li {
  margin: 0 4px;
}

.header-layout9 .main-menu > ul > li > a {
  color: var(--white-color) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
}

.header-layout9 .main-menu > ul > li > a:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  border-radius: 100px;
  opacity: 0;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.header-layout9 .main-menu > ul > li > a:after {
  color: var(--white-color) !important;
}

.header-layout9 .main-menu > ul > li > a:hover {
  color: var(--theme-color) !important;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.header-layout9 .main-menu > ul > li > a:hover:before {
  opacity: 0.1;
}

.header-layout9 .main-menu > ul > li > a:hover:after {
  color: var(--theme-color) !important;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.header-layout9 .main-menu ul li.menu-item-has-children > a:after {
  content: "\2b";
  font-size: 12px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}

.header-layout9 .main-menu ul li.menu-item-has-children > a:hover:after {
  content: "\f068";
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.header-layout9 .header-button {
  /* Large devices */
}

.header-layout9 .header-button .th-btn {
  padding: 17px 40px;
}

@media (max-width: 1199px) {
  .header-layout9 .header-button .th-btn {
    display: none;
  }
}

/* header-layout9 end ------------------- */
/* header-layout10 start ------------------- */
.header-layout10 {
  position: relative;
}

.header-layout10 .social-links .social-title {
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 0 0;
  letter-spacing: -0.32px;
  font-family: var(--title-font);
  color: var(--white-color);
}

@media (max-width: 1599px) {
  .header-layout10 .social-links .social-title {
    display: none;
  }
}

.header-layout10 .social-links a {
  font-size: 14px;
  display: inline-block;
  color: var(--white-color);
  margin: 0 15px 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header-layout10 .social-links a:last-child {
  margin-right: 0 !important;
}

.header-layout10 .social-links a:hover {
  color: var(--title-color);
}

.header-layout10 .social-links a:hover i {
  -webkit-animation: slideTop 0.5s;
          animation: slideTop 0.5s;
}

.header-layout10 .header-top {
  background-color: var(--theme-color);
  padding: 12px 0;
  --body-color: #fff;
}

.header-layout10 .header-top .header-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header-layout10 .header-top .header-links li {
  font-family: var(--title-font);
}

.header-layout10 .header-top .header-links li:not(:last-child) {
  margin: 0 40px 0 0;
}

.header-layout10 .header-top .header-links li:not(:last-child):after {
  content: '';
  height: 15px;
  width: 1px;
  background-color: #CAD4F1;
  position: absolute;
  top: 5px;
  right: -20px;
}

.header-layout10 .header-top .header-links li a:hover {
  color: var(--title-color);
}

.header-layout10 .header-top .header-links li > i {
  margin-right: 8px;
}

.header-layout10 .main-menu ul li.menu-item-has-children > a:after {
  content: "\2b";
  font-size: 12px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}

.header-layout10 .main-menu ul li.menu-item-has-children > a:hover:after {
  content: "\f068";
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.header-layout10 .menu-area {
  padding: 18px 0;
}

.header-layout10 .main-menu a {
  font-size: 16px;
  text-transform: capitalize;
}

.header-layout10 .main-menu.style2 > ul > li {
  margin: 0 2px;
}

.header-layout10 .header-button .th-btn {
  padding: 18px 38px;
}

/* header-layout10 end ------------------- */
/* header layout 11 start ----------------*/
.header-layout11 .sticky-wrapper.sticky {
  background-color: var(--title-color);
}

.header-layout11 .menu-area {
  padding: 15px 0;
}

.header-layout11 .main-menu > ul > li > a {
  color: var(--white-color);
  text-transform: capitalize;
}

.header-layout11 .main-menu > ul > li > a:hover {
  background: rgba(62, 101, 243, 0.1);
  color: var(--theme-color);
}

.header-layout11 .main-menu.style2 > ul > li {
  margin: 0 0px;
}

/* header layout 11 end ----------------*/
/* header layout 13 start ----------------*/
.header-layout12 .main-menu.style2 > ul > li > a {
  text-transform: capitalize;
  font-size: 16px;
}

.header-layout12 .menu-area {
  background-color: #EFF1F9;
  padding: 16.5px 0;
}

.header-layout12 .th-btn {
  text-transform: capitalize;
  font-weight: 400;
}

/* header layout 13 end ----------------*/
/* header layout 14 end ----------------*/
.header-layout13 .menu-area {
  padding: 16.5px 0;
}

/* header layout 14 end ----------------*/
/* header layout 15 start ----------------*/
.header-layout15 .header-top {
  padding: 17px 0;
  background-color: var(--theme-color);
  --body-color: #fff;
}

.header-layout15 .header-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header-layout15 .header-links li {
  font-family: var(--title-font);
}

.header-layout15 .header-links li:not(:last-child) {
  margin: 0 40px 0 0;
}

.header-layout15 .header-links li:not(:last-child):after {
  content: '';
  height: 15px;
  width: 1px;
  background-color: #CAD4F1;
  position: absolute;
  top: 5px;
  right: -20px;
}

.header-layout15 .header-links li a:hover {
  color: var(--title-color);
}

.header-layout15 .header-links li > i {
  margin-right: 8px;
}

.header-layout15 .social-links .social-title {
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 0 0;
  letter-spacing: -0.32px;
  font-family: var(--title-font);
  color: var(--white-color);
}

@media (max-width: 1599px) {
  .header-layout15 .social-links .social-title {
    display: none;
  }
}

.header-layout15 .social-links a {
  font-size: 14px;
  display: inline-block;
  color: var(--white-color);
  margin: 0 15px 0 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header-layout15 .social-links a:last-child {
  margin-right: 0 !important;
}

.header-layout15 .social-links a:hover {
  color: var(--title-color);
}

.header-layout15 .social-links a:hover i {
  -webkit-animation: slideTop 0.5s;
          animation: slideTop 0.5s;
}

.header-layout15 .menu-area {
  background-color: #EFF1F9;
  padding: 16.5px 0;
}

.header-layout15 .th-btn {
  text-transform: capitalize;
  font-weight: 400;
}

/* header layout 16 start -------------------*/
.header-layout16 .menu-area {
  padding: 16px 0;
}

.header-layout16 .main-menu > ul > li > a {
  font-size: 16px;
  text-transform: capitalize;
}

/* header layout 16 end -------------------*/
/* header layout 17 start -------------------*/
.header-layout17 {
  background-color: #EFF1F9;
}

.header-layout17 .menu-area {
  padding: 16px 0;
  border-bottom: 1px solid rgba(101, 106, 116, 0.2);
}

.header-layout17 .main-menu > ul > li > a {
  font-size: 16px;
  text-transform: capitalize;
}

/* header layout 17 end -------------------*/
/*------------------- 4.3. Footer  -------------------*/
.footer-wrapper {
  --border-color: rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 2;
}

.footer-wrapper .th-social a {
  background-color: transparent;
  color: var(--white-color);
  border: 1px solid var(--white-color);
}

.footer-wrapper .th-social a:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.widget-area {
  padding-top: var(--section-space);
  padding-bottom: 66px;
}

.footer-menu {
  margin: 0;
  padding: 0;
  /* Medium devices */
}

@media (max-width: 991px) {
  .footer-menu {
    text-align: center;
  }
}

.footer-menu li {
  display: inline-block;
  margin-right: 30px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-menu li {
    margin-right: 5px;
  }
}

.footer-menu li a {
  position: relative;
  font-weight: 400;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 24px;
  color: var(--white-color);
  text-transform: capitalize;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-menu li a {
    font-size: 14px;
  }
}

.footer-menu li a:hover {
  color: var(--theme-color);
}

.footer-links ul {
  padding: 0;
  margin: 0;
}

.footer-links li {
  font-family: var(--body-font);
  display: inline-block;
  padding-right: 15px;
  margin-right: 10px;
  position: relative;
}

.footer-links li:after {
  content: "";
  height: 20px;
  width: 1px;
  background-color: var(--body-color);
  position: absolute;
  top: 50%;
  right: 0;
  margin: -10px 0;
}

.footer-links li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.footer-links li:last-child:after {
  display: none;
}

.footer-links a {
  font-family: inherit;
  color: var(--body-color);
}

.footer-links a:hover {
  color: var(--theme-color);
}

.footer-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 170px;
  margin-top: -0.4rem;
  margin-bottom: 0;
}

.footer-info:not(:last-child) {
  margin-top: 0;
  margin-bottom: 10px;
}

.footer-info_title {
  font-size: 18px;
  color: var(--white-color);
  display: block;
  margin-bottom: 7px;
}

.footer-info_icon {
  width: 26px;
  height: 26px;
  min-width: 26px;
  line-height: 26px;
  font-size: 14px;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  border-radius: 5px;
  gap: 8px;
  margin-right: 10px;
}

.footer-info_text {
  display: block;
  color: var(--body-color);
  margin-top: -0.45em;
  margin-bottom: -0.45em;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

.footer-info_link {
  display: inline-block;
  color: var(--body-color);
}

.footer-info_link:hover {
  color: var(--theme-color);
}

.copyright-wrap {
  padding: 22px 0;
  background-size: 100% auto;
}

.copyright-text {
  margin: 0;
}

.copyright-text a {
  color: var(--theme-color);
}

.copyright-text a:hover {
  color: var(--white-color);
}

.th-widget-contact {
  max-width: 265px;
}

.footer-contact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.footer-contact-wrap {
  display: grid;
  grid-template-columns: auto auto auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer-contact_icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  min-width: 70px;
  font-size: 30px;
  background-color: var(--white-color);
  color: var(--theme-color);
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.footer-contact_icon:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--title-color);
  border-radius: inherit;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: -1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.footer-contact_icon:hover:before {
  top: 0;
  left: 0;
}

.footer-contact_text {
  display: block;
  color: var(--body-color);
  margin-bottom: 5px;
}

.footer-contact_link {
  display: inline-block;
  color: var(--body-color);
  font-size: 20px;
  font-weight: 600;
}

.footer-contact_link:hover {
  color: var(--title-color);
}

.info-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 21px;
}

.info-box:last-child {
  margin-bottom: -5px;
}

.info-box_icon {
  width: 30px;
  height: 30px;
  min-width: 30px;
  line-height: 29px;
  font-size: 14px;
  background-color: transparent;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  border-radius: 999px;
  text-align: center;
  margin-right: 10px;
}

.info-box_text {
  display: block;
  color: var(--body-color);
  margin-top: -0.45em;
  margin-bottom: 0;
}

.info-box_link {
  display: inline-block;
  color: var(--body-color);
}

.info-box_link:hover {
  color: var(--theme-color);
}

.footer-newsletter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 25px;
  padding-left: 60px;
}

.footer-newsletter .newsletter-title {
  max-width: 425px;
  margin-bottom: 0;
}

.footer-layout1 {
  background-color: #080e1c;
  --body-color: #fff;
  overflow: hidden;
}

.footer-layout1 .footer-top {
  background-color: var(--theme-color);
  position: relative;
  z-index: 2;
}

.footer-layout1 .footer-logo {
  padding: 45px 45px 45px 0;
}

.footer-layout1 .shape-left,
.footer-layout1 .shape-right {
  position: absolute;
  z-index: -1;
}

.footer-layout1 .shape-left {
  top: 0;
  left: 0;
}

.footer-layout1 .shape-right {
  bottom: 65px;
  right: 0;
}

.footer-layout1 .logo-bg {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--white-color);
  height: 101%;
  width: 33%;
  z-index: -1;
  -webkit-clip-path: polygon(100% 0, calc(100% - 60px) 50%, 100% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, calc(100% - 60px) 50%, 100% 100%, 0 100%, 0 0);
}

.footer-layout1 .logo-bg:before {
  content: "";
  height: 100%;
  width: calc(100% - 10px);
  background-color: var(--title-color);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon(100% 0, calc(100% - 60px) 50%, 100% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, calc(100% - 60px) 50%, 100% 100%, 0 100%, 0 0);
}

/* Hight Resoulation devices */
@media (min-width: 1922px) {
  .footer-layout1 .logo-bg {
    width: 37%;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {
  .footer-layout1 .logo-bg {
    width: 27%;
  }
  .footer-layout1 .shape-right {
    bottom: -20px;
    right: -70px;
  }
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .footer-layout1 .shape-left {
    max-width: 65%;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-layout1 .logo-bg {
    width: 100%;
    height: 170px;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 40px), 0 100%, 0 0);
            clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 40px), 0 100%, 0 0);
  }
  .footer-layout1 .logo-bg:before {
    width: 100%;
    height: calc(100% - 10px);
    -webkit-clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 40px), 0 100%, 0 0);
            clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 40px), 0 100%, 0 0);
  }
  .footer-layout1 .footer-logo {
    padding: 40px 15px 60px 15px;
    text-align: center;
  }
  .footer-contact-wrap {
    padding: 40px 0 30px 0;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .footer-wrapper .widget-area {
    padding-top: var(--section-space-mobile);
    padding-bottom: 30px;
  }
  .footer-contact-wrap {
    grid-template-columns: auto auto;
    gap: 30px;
  }
  .copyright-text {
    text-align: center;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .copyright-wrap {
    background-size: cover;
  }
  .footer-contact {
    margin-left: 10px;
  }
  .footer-contact-wrap {
    grid-template-columns: auto;
  }
}

/* footer 2 ---------------------------------- */
.footer-layout2 {
  background-color: var(--smoke-color2);
}

.footer-layout2.bg-transparent {
  background-color: transparent;
  border-top: 1px solid #d9e0f3;
}

.footer-layout2 .footer-widget .widget_title,
.footer-layout2 .footer-widget .recent-post .post-title {
  color: var(--title-color);
  font-weight: 700;
}

.footer-layout2 .copyright-wrap {
  --body-color: #fff;
  background-color: var(--theme-color);
}

.footer-layout2 .copyright-wrap a {
  color: var(--white-color);
}

.footer-layout2 .copyright-wrap a:hover {
  color: var(--title-color);
}

.footer-layout2 .th-social a {
  color: var(--body-color);
  box-shadow: none;
  border: 1px solid var(--body-color);
}

.footer-layout2 .th-social a:hover {
  color: var(--white-color);
}

.footer-widget .contact-feature {
  padding: 0 !important;
  background-color: transparent;
  box-shadow: none;
  gap: 15px;
}

.footer-widget .contact-feature:not(:last-child) {
  margin-bottom: 28px;
}

.footer-widget .contact-feature .icon-btn {
  --btn-size: 44px;
  font-size: 16px;
}

.footer-widget .contact-feature_label {
  margin-top: -0.4em;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.5;
}

.footer-widget .contact-feature_link {
  font-size: 18px;
  margin-bottom: -0.4em;
  display: block;
}

.newsletter-widget .newsletter-form {
  gap: 0;
}

.newsletter-widget .form-group {
  margin-bottom: 0;
}

.newsletter-widget input {
  border-radius: 5px 0 0 5px;
}

.newsletter-widget .icon-btn {
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 0 5px 5px 0;
  min-width: 56px;
  padding: 0;
}

.newsletter-widget .icon-btn:hover {
  background-color: var(--title-color);
}

.newsletter-widget .footer-text {
  margin-bottom: 30px;
}

/* footer 3 ---------------------------------- */
.footer-layout3 {
  background-color: var(--black-color2);
  background-size: cover;
  --body-color: #fff;
}

.footer-layout3 .widget-area {
  padding-top: 100px;
  padding-bottom: 60px;
}

.footer-layout3 .th-btn {
  box-shadow: none;
}

.footer-layout3 .footer-top {
  padding-top: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background-color: transparent;
}

.footer-layout3 .copyright-wrap {
  --body-color: #fff;
  background-color: var(--theme-color);
}

.footer-layout3 .copyright-wrap a {
  color: var(--white-color);
}

.footer-layout3 .copyright-wrap a:hover {
  color: var(--title-color);
}

.newsletter-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-left: 2px solid var(--theme-color);
  padding-left: 25px;
}

.newsletter-wrap .newsletter-title {
  margin-top: -0.3em;
  margin-bottom: 5px;
  max-width: 400px;
  color: var(--white-color);
}

.newsletter-wrap .newsletter-text {
  margin-bottom: -0.5em;
  max-width: 400px;
}

.newsletter-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 465px;
  gap: 15px;
}

.newsletter-form .form-group {
  margin-bottom: 0;
  width: 100%;
}

.newsletter-form .form-group > i {
  color: var(--theme-color);
}

.newsletter-form input {
  background-color: transparent;
  border: 1px solid #323232;
  width: 100%;
}

.newsletter-form input:focus {
  background-color: transparent;
  color: var(--body-color);
}

.newsletter-form .th-btn {
  min-width: 150px;
}

.newsletter-form .newsletter-btn {
  display: inline-block;
  border: none;
  background-color: var(--theme-color);
  color: var(--title-color);
  width: 55px;
  height: 55px;
  line-height: 55px;
  min-width: 55px;
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-top .footer-logo {
    text-align: center;
    margin: 0 auto 35px auto;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .footer-layout3 .widget-area {
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .footer-layout3 .footer-top {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .footer-wrapper .newsletter-form {
    max-width: 400px;
  }
  .newsletter-wrap .newsletter-title {
    font-size: 24px;
  }
  .newsletter-wrap .newsletter-text {
    font-size: 14px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .newsletter-wrap {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-left: 0;
    border-left: none;
    gap: 20px;
  }
  .newsletter-wrap .newsletter-title {
    text-align: center;
  }
  .footer-wrapper .newsletter-form {
    max-width: 550px;
    gap: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .footer-wrapper .newsletter-form {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

/* footer 5 ---------------------------------- */
.footer-layout5 {
  background-color: #F7F8FB;
}

.footer-layout5 .footer-newsletter {
  padding-left: 0;
}

.footer-layout5 .footer-newsletter .newsletter-title {
  color: var(--title-color);
  max-width: 500px;
}

.footer-layout5 .footer-newsletter .newsletter-form {
  position: relative;
  overflow: hidden;
  max-width: 560px;
}

.footer-layout5 .footer-newsletter .newsletter-form i {
  position: absolute;
  top: 22px;
  left: 30px;
  color: var(--theme-color);
}

.footer-layout5 .footer-newsletter .newsletter-form .form-control {
  border-radius: 100px;
  min-height: 60px;
  padding-left: 55px;
  padding-right: 200px;
  background-color: var(--white-color);
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-layout5 .footer-newsletter .newsletter-form .form-control {
    min-width: 100%;
  }
}

.footer-layout5 .footer-newsletter .newsletter-form .form-control:hover {
  border: 1px solid var(--theme-color);
}

.footer-layout5 .footer-newsletter .newsletter-form .th-btn {
  min-width: 188px;
  padding: 22px 40px;
  text-transform: capitalize;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 100px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-layout5 .footer-newsletter .newsletter-form .th-btn {
    max-width: 100%;
    padding: 16px 20px;
    position: relative;
    margin-top: 10px;
    display: block;
    width: 100%;
  }
}

.footer-layout5 .footer-top {
  padding: 105px 0 75px 0;
  border-bottom: 1px solid #E4E7EE;
  /* Medium devices */
}

@media (max-width: 991px) {
  .footer-layout5 .footer-top {
    padding: 80px 0 75px 0;
  }
}

.footer-layout5 .widget-area {
  padding-top: 80px;
  padding-bottom: 30px;
}

.footer-layout5 .widget_title {
  font-weight: 600;
  color: var(--title-color);
}

.footer-layout5 .footer-widget .recent-post .post-title {
  color: var(--title-color);
}

.footer-layout5 .th-social a {
  --icon-size: 40px;
}

.footer-layout5 .th-social a:not(:hover) {
  background: var(--white-color);
  color: var(--title-color);
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.06);
}

.footer-layout5 .copyright-wrap {
  padding: 18px 0;
  background-size: 100% auto;
  background-color: transparent;
  border-top: 1px solid rgba(121, 129, 150, 0.35);
}

.footer-layout5 .copyright-wrap .copyright-text {
  color: var(--title-color);
}

.footer-layout5 .copyright-wrap .copyright-text a {
  color: var(--theme-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-newsletter {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer-layout5 .footer-newsletter .newsletter-title {
    margin-top: -0.3em;
  }
}

/* footer 6 ---------------------------------- */
.footer-layout6 {
  background-color: #F7F8FB;
}

.footer-layout6 .newsletter-widget {
  position: relative;
  overflow: hidden;
  max-width: 350px;
}

.footer-layout6 .newsletter-widget i {
  position: absolute;
  top: 22px;
  left: 30px;
  color: var(--theme-color);
}

.footer-layout6 .newsletter-widget .form-control {
  border-radius: 100px;
  min-height: 60px;
  padding-left: 55px;
  padding-right: 130px;
  background-color: var(--white-color);
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-layout6 .newsletter-widget .form-control {
    min-width: 100%;
  }
}

.footer-layout6 .newsletter-widget .form-control:hover {
  border: 1px solid var(--theme-color);
}

.footer-layout6 .newsletter-widget .th-btn {
  padding: 23px 30px;
  text-transform: capitalize;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 100px;
  min-width: auto;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-layout6 .newsletter-widget .th-btn {
    max-width: 100%;
    padding: 16px 20px;
    position: relative;
    margin-top: 10px;
    display: block;
    width: 100%;
  }
}

.footer-layout6 .widget-area {
  padding-top: 120px;
  padding-bottom: 60px;
}

.footer-layout6 .widget_title {
  font-weight: 600;
  color: var(--title-color);
  text-transform: capitalize;
}

.footer-layout6 .footer-widget .recent-post .post-title {
  color: var(--title-color);
}

.footer-layout6 .th-social a {
  --icon-size: 40px;
}

.footer-layout6 .th-social a:not(:hover) {
  background: var(--white-color);
  color: var(--title-color);
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.06);
}

.footer-layout6 .copyright-wrap {
  padding: 29.5px 0;
  background-size: 100% auto;
  background-color: transparent;
  border-top: 1px solid rgba(121, 129, 150, 0.35);
}

.footer-layout6 .copyright-wrap .copyright-text {
  color: var(--title-color);
}

.footer-layout6 .copyright-wrap .copyright-text a {
  color: var(--theme-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-newsletter {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer-layout6 .footer-newsletter .newsletter-title {
    margin-top: -0.3em;
  }
}

/* footer 7 ---------------------------------- */
.footer-layout7 {
  background-color: #EFF1F9;
}

.footer-layout7 .footer-newsletter {
  padding-left: 0;
}

.footer-layout7 .footer-newsletter .newsletter-title {
  color: var(--title-color);
  max-width: 500px;
}

.footer-layout7 .footer-newsletter .newsletter-form {
  position: relative;
  overflow: hidden;
  max-width: 560px;
}

.footer-layout7 .footer-newsletter .newsletter-form i {
  position: absolute;
  top: 22px;
  left: 30px;
  color: var(--theme-color);
}

.footer-layout7 .footer-newsletter .newsletter-form .form-control {
  border-radius: 100px;
  min-height: 60px;
  padding-left: 55px;
  padding-right: 200px;
  background-color: var(--white-color);
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-layout7 .footer-newsletter .newsletter-form .form-control {
    min-width: 100%;
  }
}

.footer-layout7 .footer-newsletter .newsletter-form .form-control:hover {
  border: 1px solid var(--theme-color);
}

.footer-layout7 .footer-newsletter .newsletter-form .th-btn {
  min-width: 188px;
  padding: 22px 40px;
  text-transform: capitalize;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 100px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-layout7 .footer-newsletter .newsletter-form .th-btn {
    max-width: 100%;
    padding: 16px 20px;
    position: relative;
    margin-top: 10px;
    display: block;
    width: 100%;
  }
}

.footer-layout7 .footer-top {
  padding: 105px 0 75px 0;
  border-bottom: 1px solid #D0D3DA;
}

.footer-layout7 .widget-area {
  padding-top: 80px;
  padding-bottom: 30px;
}

.footer-layout7 .widget_title {
  font-weight: 600;
  text-transform: capitalize;
  color: var(--title-color);
}

.footer-layout7 .footer-widget .recent-post .post-title {
  color: var(--title-color);
}

.footer-layout7 .th-social a {
  --icon-size: 40px;
}

.footer-layout7 .th-social a:not(:hover) {
  background: var(--white-color);
  color: var(--title-color);
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.06);
}

.footer-layout7 .copyright-wrap {
  padding: 35.5px 0;
  background-size: 100% auto;
  background-color: transparent;
  border-top: 1px solid rgba(121, 129, 150, 0.35);
}

.footer-layout7 .copyright-wrap .copyright-text {
  color: var(--title-color);
}

.footer-layout7 .copyright-wrap .copyright-text a {
  color: var(--theme-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-newsletter {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer-layout7 .footer-newsletter .newsletter-title {
    margin-top: -0.3em;
  }
}

/* footer 8  start ---------------------------------- */
.footer-layout8 {
  --body-color: #8D929C;
}

.footer-layout8 .footer-widget.style2 {
  max-width: 561px;
  background-color: #16181D;
  padding: 40px;
  border-radius: 20px;
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .footer-layout8 .footer-widget.style2 {
    max-width: 100%;
  }
}

@media (max-width: 375px) {
  .footer-layout8 .footer-widget.style2 {
    padding: 30px;
  }
}

.footer-layout8 .footer-widget.style2 .widget_title {
  color: var(--white-color);
}

.footer-layout8 .footer-widget.style2 .widget_title:after {
  border: 2px solid #16181D;
}

.footer-layout8 .newsletter-widget {
  position: relative;
  overflow: hidden;
  max-width: 100%;
}

.footer-layout8 .newsletter-widget .newsletter-form {
  max-width: 481px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .footer-layout8 .newsletter-widget .newsletter-form {
    max-width: 100%;
  }
}

.footer-layout8 .newsletter-widget .newsletter-form.style2 i {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footer-layout8 .newsletter-widget .footer-text {
  margin-bottom: 20px;
}

.footer-layout8 .newsletter-widget i {
  position: absolute;
  top: 18px;
  left: 30px;
  font-weight: 400;
  font-size: 18px;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footer-layout8 .newsletter-widget .form-control {
  border-radius: 100px;
  min-height: 56px;
  padding-left: 55px;
  padding-right: 130px;
  background-color: var(--black-color) !important;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-layout8 .newsletter-widget .form-control {
    min-width: 100%;
    padding-right: 30px;
  }
}

.footer-layout8 .newsletter-widget .form-control:hover {
  border: 1px solid var(--theme-color);
}

.footer-layout8 .newsletter-widget .th-btn {
  padding: 20px 30px;
  text-transform: capitalize;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 100px;
  min-width: auto;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .footer-layout8 .newsletter-widget .th-btn {
    max-width: 100%;
    padding: 16px 20px;
    position: relative;
    margin-top: 10px;
    display: block;
    width: 100%;
  }
}

.footer-layout8 .widget-area {
  padding-top: 120px;
  padding-bottom: 60px;
}

.footer-layout8 .widget_title {
  font-weight: 600;
  color: var(--title-color);
  font-size: 20px;
  text-transform: none;
}

.footer-layout8 .footer-widget .recent-post .post-title {
  color: var(--title-color);
}

.footer-layout8 .th-social a {
  --icon-size: 40px;
  background-color: #292E38;
  border-color: #292E38;
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.06);
}

.footer-layout8 .th-social a:hover {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  color: #292E38;
  border-color: var(--theme-color);
}

.footer-layout8 .copyright-wrap {
  background-color: #16181D;
}

.footer-layout8 .copyright-wrap .copyright-text {
  color: var(--white-color);
}

.footer-layout8 .copyright-wrap .copyright-text a {
  font-family: var(--body-font);
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* footer 8  end ---------------------------------- */
/* footer 9  start ---------------------------------- */
.footer-layout9 .th-social a {
  --icon-size: 40px;
  background-color: #292E38;
  border-color: #292E38;
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.06);
}

.footer-layout9 .th-social a:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.footer-layout9 .copyright-wrap .copyright-text a {
  background: transparent;
  color: var(--theme-color);
  -webkit-text-fill-color: var(--theme-color);
}

/* footer 9  end ---------------------------------- */
/*footer 11 start -----------------------------------*/
.footer-layout11 {
  background-color: var(--black-color);
  --body-color: #A1A6B0;
}

.footer-layout11 .footer-top {
  border-bottom: 1px solid #2C3248;
}

.footer-layout11 .footer-newsletter .newsletter-title {
  color: var(--white-color);
}

.footer-layout11 .footer-newsletter .newsletter-form .form-control {
  background-color: #16181D;
}

.footer-layout11 .footer-newsletter .newsletter-form .th-btn {
  padding: 23px 40px;
}

.footer-layout11 .footer-newsletter .newsletter-form .th-btn:hover {
  color: var(--title-color);
}

.footer-layout11 .footer-newsletter .newsletter-form .th-btn:hover:before, .footer-layout11 .footer-newsletter .newsletter-form .th-btn:hover:after {
  background-color: var(--white-color);
}

.footer-layout11 .footer-widget .widget_title {
  text-transform: none;
}

.footer-layout11 .footer-widget.widget_nav_menu a {
  margin-bottom: 14px;
}

.footer-layout11 .copyright-wrap {
  background-color: #16181D;
  border-top: none;
}

.footer-layout11 .copyright-wrap .copyright-text {
  color: var(--white-color);
}

.footer-layout11 .th-widget-about.style2 {
  max-width: 290px;
}

.footer-layout11 .th-social a {
  border: none;
}

.footer-layout11 .th-social a:not(:hover) {
  background: #1C1C25;
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
  color: var(--white-color);
}

/* footer 11  end ---------------------------------- */
/* footer 12 start ---------------------------------*/
.footer-layout12 {
  background-color: #000C2C;
  border-radius: 30px 30px 0 0;
  --body-color: #797E88;
}

.footer-layout12 .footer-top {
  border-bottom: 1px solid #2C3248;
}

.footer-layout12 .footer-newsletter .newsletter-title {
  color: var(--white-color);
}

.footer-layout12 .footer-newsletter .newsletter-form .th-btn {
  padding: 23px 40px;
}

.footer-layout12 .footer-newsletter .newsletter-form .th-btn:hover {
  color: var(--white-color);
}

.footer-layout12 .footer-newsletter .newsletter-form .th-btn:hover:before, .footer-layout12 .footer-newsletter .newsletter-form .th-btn:hover:after {
  background-color: var(--title-color);
}

.footer-layout12 .footer-widget .widget_title {
  text-transform: none;
}

.footer-layout12 .footer-widget.widget_nav_menu a {
  margin-bottom: 14px;
}

.footer-layout12 .copyright-wrap {
  background-color: transparent;
}

.footer-layout12 .copyright-wrap .copyright-text {
  color: var(--white-color);
}

.footer-layout12 .th-widget-about.style2 {
  max-width: 290px;
}

.footer-layout12 .th-social a {
  border: none;
}

.footer-layout12 .th-social a:not(:hover) {
  background: var(--white-color);
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
  color: var(--title-color);
}

/* footer 12 end ---------------------------------*/
/* footer 12 start ---------------------------------*/
.footer-layout13 {
  background-color: #000;
}

/* footer 13 end ---------------------------------*/
/* footer 14 start ---------------------------------*/
.footer-layout14 {
  position: relative;
  z-index: 2;
}

.footer-layout14:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(6, 7, 7, 0.93);
  border-radius: 30px 30px 0 0;
  z-index: -1;
}

.footer-layout14 .footer-top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-layout14 .footer-widget .widget_title.style2:after {
  border: 2px solid #060707ed;
}

/* footer 14 end ---------------------------------*/
/* footer 15 start ---------------------------------*/
.footer-layout15 .footer-widget .widget_title.style2:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 61px;
  height: 2px;
  background: var(--theme-color);
}

.footer-layout15 .newsletter-widget i {
  background: var(--theme-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footer-layout15 .copyright-wrap .copyright-text a {
  background: var(--theme-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footer-layout15 .th-social a:hover {
  background: var(--theme-color);
}

/* footer 14 end ---------------------------------*/
.footer-layout16 {
  background-color: #16181D;
}

.footer-layout16 .footer-widget .widget_title.style2:after {
  border: 2px solid #16181D;
}

/*------------------- 4.4. Breadcumb  -------------------*/
.breadcumb-menu {
  max-width: 100%;
  padding: 0;
  margin: 30px 0 -0.5em 0;
  list-style-type: none;
  position: relative;
}

.breadcumb-menu li {
  display: inline-block;
  margin-right: 3px;
  padding-right: 5px;
  list-style: none;
  position: relative;
}

.breadcumb-menu li:after {
  content: "\f324";
  position: relative;
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
  color: var(--white-color);
  font-family: var(--icon-font);
}

.breadcumb-menu li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.breadcumb-menu li:last-child:after {
  display: none;
}

.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
  white-space: normal;
  color: inherit;
  word-break: break-word;
  font-weight: 400;
  font-size: 18px;
  color: var(--white-color);
}

.breadcumb-title {
  color: var(--white-color);
  margin: -0.22em 0 -0.14em 0;
  line-height: 1.1;
}

.breadcumb-wrapper {
  background-color: var(--title-color);
  padding: 160px 0;
  overflow: hidden;
  text-align: center;
}

/* Large devices */
@media (max-width: 1199px) {
  .breadcumb-wrapper {
    padding: 140px 0;
  }
  .breadcumb-menu {
    margin: 20px 0 -0.5em 0;
  }
  .breadcumb-menu li,
  .breadcumb-menu a,
  .breadcumb-menu span {
    font-size: 16px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .breadcumb-wrapper {
    padding: 120px 0;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .breadcumb-wrapper {
    padding: 100px 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .breadcumb-title {
    font-size: 34px;
  }
}

/*------------------- 4.5. Pagination  -------------------*/
.th-pagination {
  margin-bottom: 30px;
}

.th-pagination ul {
  margin: 0;
  padding: 0;
}

.th-pagination li {
  display: inline-block;
  margin: 0 3px;
  list-style-type: none;
}

.th-pagination li:last-child {
  margin-right: 0;
}

.th-pagination li:first-child {
  margin-left: 0;
}

.th-pagination span,
.th-pagination a {
  display: inline-block;
  text-align: center;
  position: relative;
  border: none;
  color: var(--theme-color);
  background-color: var(--smoke-color2);
  width: 56px;
  height: 56px;
  line-height: 56px;
  z-index: 1;
  font-size: 18px;
  font-weight: 500;
  border-radius: 4px;
}

.th-pagination span.active, .th-pagination span:hover,
.th-pagination a.active,
.th-pagination a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
}

/* Small devices */
@media (max-width: 767px) {
  .th-pagination span,
  .th-pagination a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

/*------------------- 4.6. Blog  -------------------*/
/* Blockquote ---------------------*/
blockquote,
.wp-block-quote {
  display: block;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  font-family: var(--body-font);
  color: var(--title-color);
  background-color: var(--quote-bg, #f5f5f5);
  padding: 50px 40px 38px 165px;
  margin: 35px 0;
  line-height: 1.667;
  border: none !important;
  border-radius: 5px;
}

blockquote p,
blockquote pre,
.wp-block-quote p,
.wp-block-quote pre {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  width: 100%;
  position: relative;
  z-index: 3;
  margin-top: -0.5em;
  margin-bottom: 0;
}

blockquote p a,
blockquote pre a,
.wp-block-quote p a,
.wp-block-quote pre a {
  color: inherit;
}

blockquote:before,
.wp-block-quote:before {
  content: "\f10e";
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 60px;
  font-weight: 900;
  line-height: 1;
  color: var(--white-color);
  background-color: var(--theme-color);
  width: 125px;
  height: 100%;
  text-align: center;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

blockquote cite,
.wp-block-quote cite {
  display: inline-block;
  font-size: 18px !important;
  font-family: var(--title-font);
  position: relative;
  padding-left: 45px;
  line-height: 1;
  font-weight: 600;
  margin-top: 17px;
  font-style: normal;
  color: var(--theme-color);
  white-space: nowrap;
}

blockquote cite:before,
.wp-block-quote cite:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 7px;
  width: 30px;
  height: 2px;
  border-top: 2px solid var(--theme-color);
}

blockquote.style-left-icon, blockquote.is-large:not(.is-style-plain), blockquote.is-style-large:not(.is-style-plain), blockquote.has-text-align-right,
.wp-block-quote.style-left-icon,
.wp-block-quote.is-large:not(.is-style-plain),
.wp-block-quote.is-style-large:not(.is-style-plain),
.wp-block-quote.has-text-align-right {
  padding: 50px 40px 38px 165px;
}

blockquote.style-left-icon,
.wp-block-quote.style-left-icon {
  font-size: 18px;
  color: var(--body-color);
  font-weight: 400;
  line-height: 1.556;
  background-color: var(--smoke-color);
  padding-left: 160px;
}

blockquote.style-left-icon cite,
.wp-block-quote.style-left-icon cite {
  color: var(--title-color);
}

blockquote.style-left-icon cite:before,
.wp-block-quote.style-left-icon cite:before {
  background-color: var(--title-color);
  top: 8px;
}

blockquote.is-large p, blockquote.is-style-large p,
.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
  margin-bottom: -0.4em;
}

blockquote.is-large cite:before, blockquote.is-style-large cite:before,
.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before {
  top: unset;
  bottom: 10px;
}

blockquote.has-text-align-right:before,
.wp-block-quote.has-text-align-right:before {
  content: "\f10d";
  right: unset;
  left: 0;
}

.wp-block-pullquote {
  color: var(--body-color);
  padding: 0;
}

blockquote.has-very-dark-gray-color {
  color: var(--body-color) !important;
}

.wp-block-column blockquote,
.wp-block-column .wp-block-quote {
  padding: 100px 15px 30px 15px;
}

.wp-block-column blockquote:before,
.wp-block-column .wp-block-quote:before {
  width: 100%;
  height: 60px;
  font-size: 30px;
}

.wp-block-column blockquote.style-left-icon, .wp-block-column blockquote.is-large:not(.is-style-plain), .wp-block-column blockquote.is-style-large:not(.is-style-plain), .wp-block-column blockquote.has-text-align-right,
.wp-block-column .wp-block-quote.style-left-icon,
.wp-block-column .wp-block-quote.is-large:not(.is-style-plain),
.wp-block-column .wp-block-quote.is-style-large:not(.is-style-plain),
.wp-block-column .wp-block-quote.has-text-align-right {
  padding: 100px 15px 30px 15px;
}

/* Large devices */
@media (max-width: 1199px) {
  blockquote,
  .wp-block-quote {
    font-size: 16px;
    padding: 40px 20px 30px 120px;
  }
  blockquote:before,
  .wp-block-quote:before {
    width: 100px;
    font-size: 52px;
  }
  blockquote cite,
  .wp-block-quote cite {
    margin-top: 23px;
  }
  blockquote.style-left-icon, blockquote.is-large:not(.is-style-plain), blockquote.is-style-large:not(.is-style-plain), blockquote.has-text-align-right,
  .wp-block-quote.style-left-icon,
  .wp-block-quote.is-large:not(.is-style-plain),
  .wp-block-quote.is-style-large:not(.is-style-plain),
  .wp-block-quote.has-text-align-right {
    padding: 40px 20px 30px 120px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  blockquote,
  .wp-block-quote {
    padding: 100px 20px 30px 20px;
  }
  blockquote:before,
  .wp-block-quote:before {
    height: 60px;
    width: 100%;
    font-size: 2.5rem;
  }
  blockquote.style-left-icon, blockquote.is-large:not(.is-style-plain), blockquote.is-style-large:not(.is-style-plain), blockquote.has-text-align-right,
  .wp-block-quote.style-left-icon,
  .wp-block-quote.is-large:not(.is-style-plain),
  .wp-block-quote.is-style-large:not(.is-style-plain),
  .wp-block-quote.has-text-align-right {
    padding: 100px 20px 30px 20px;
  }
  .wp-block-pullquote.is-style-solid-color blockquote {
    max-width: 90%;
  }
}

.blog-meta {
  display: block;
}

.blog-meta span,
.blog-meta a {
  display: inline-block;
  font-size: 14px;
  color: var(--body-color);
  font-family: var(--body-font);
  position: relative;
  margin-right: 20px;
}

.blog-meta span i,
.blog-meta a i {
  margin-right: 6px;
  color: var(--theme-color);
}

.blog-meta span:last-child,
.blog-meta a:last-child {
  margin-right: 0;
}

.blog-meta .author img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 6px;
}

.blog-meta a:hover {
  color: var(--theme-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .blog-meta span,
  .blog-meta a {
    margin-right: 6px;
    padding-right: 15px;
  }
}

.blog-audio img,
.blog-img img,
.blog-video img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.blog-title a {
  color: inherit;
}

.blog-title a:hover {
  color: var(--theme-color);
}

.th-blog {
  margin-bottom: 30px;
}

.blog-inner-title {
  margin-top: -0.25em;
  margin-bottom: 25px;
}

.blog-inner-title i {
  color: var(--theme-color);
  margin-right: 4px;
}

@media (min-width: 1300px) {
  .page-single,
  .blog-single,
  .as-comments-wrap,
  .as-comment-form {
    margin-right: 16px;
  }
}

.blog-single {
  position: relative;
  margin-bottom: var(--blog-space-y, 40px);
  box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
  border-radius: 5px;
  overflow: hidden;
}

.blog-single .blog-title {
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 1.4;
  font-weight: 600;
}

.blog-single .blog-text {
  margin-bottom: 27px;
}

.blog-single .social-links {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
}

.blog-single .social-links li {
  display: inline-block;
  margin-right: 3px;
}

.blog-single .social-links li:last-child {
  margin-right: 0;
}

.blog-single .social-links a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--smoke-color);
  font-size: 14px;
  color: var(--title-color);
  text-align: center;
  border-radius: 5px;
}

.blog-single .social-links a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.blog-single .wp-block-tag-cloud a,
.blog-single .tagcloud a {
  background-color: var(--smoke-color);
  color: var(--title-color);
}

.blog-single .wp-block-tag-cloud a:hover,
.blog-single .tagcloud a:hover {
  background-color: var(--theme-color);
}

.blog-single .blog-meta {
  margin: -0.35em 0 10px 0;
}

.blog-single .blog-content {
  margin: 0 0 0 0;
  padding: 40px;
  position: relative;
}

.blog-single .blog-audio {
  line-height: 1;
}

.blog-single .blog-audio,
.blog-single .blog-img,
.blog-single .blog-video {
  position: relative;
  overflow: hidden;
  background-color: var(--smoke-color);
}

.blog-single .blog-img .slick-arrow {
  --pos-x: 30px;
  --icon-size: 45px;
  border: none;
  background-color: var(--white-color);
  color: var(--theme-color);
  border-radius: 5px;
  box-shadow: none;
}

.blog-single .blog-img .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.blog-single .blog-img .play-btn {
  --icon-size: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2);
}

.blog-single .line-btn {
  display: block;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-bottom: -1px;
}

.blog-single:hover .blog-img .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.share-links-title {
  font-size: 20px;
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: 700;
  margin: 0 15px 0 0;
  display: inline-block;
}

.share-links {
  margin: 0 var(--blog-space-x, 40px) var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  border-top: 1px solid var(--th-border-color);
  padding: 30px 0 0 0;
}

.share-links > .row {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  --bs-gutter-y: 20px;
}

.share-links .wp-block-tag-cloud,
.share-links .tagcloud {
  display: inline-block;
}

.blog-details .blog-single {
  background-color: transparent;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .blog-single .blog-content {
    padding: 40px 20px;
  }
  .blog-single .blog-title {
    font-size: 28px;
  }
  .share-links {
    --blog-space-x: 20px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .blog-single .blog-content {
    padding: 40px;
  }
  .blog-details .blog-single {
    --blog-space-x: 20px;
    --blog-space-y: 40px;
  }
  .share-links {
    --blog-space-x: 40px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .share-links {
    --blog-space-x: 20px;
  }
  .blog-details .blog-single {
    --blog-space-x: 20px;
    --blog-space-y: 20px;
  }
  .blog-single .blog-content {
    padding: 30px 20px;
  }
  .blog-single .blog-title {
    font-size: 24px;
    line-height: 1.3;
  }
  .blog-single .blog-text {
    margin-bottom: 22px;
  }
  .blog-single .blog-bottom {
    padding-top: 15px;
  }
  .blog-single .share-links-title {
    font-size: 18px;
    display: block;
    margin: 0 0 10px 0;
  }
}

/*------------------- 4.7. Comments  -------------------*/
.th-comment-form {
  margin-top: var(--blog-space-y, 40px);
  margin-bottom: 30px;
  padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  position: relative;
}

.th-comment-form .row {
  --bs-gutter-x: 20px;
}

.th-comment-form .blog-inner-title {
  margin-bottom: 0px;
}

.th-comment-form .form-title {
  margin-top: -0.35em;
}

.th-comment-form .form-title a#cancel-comment-reply-link {
  font-size: 0.7em;
  text-decoration: underline;
}

.th-comment-form .form-text {
  margin-bottom: 25px;
}

.th-comment-form .form-group > i {
  color: var(--theme-color);
}

.blog-comment-area {
  margin-bottom: 25px;
}

.th-comment-form,
.th-comments-wrap {
  padding: 40px;
  box-shadow: 0px 6px 30px rgba(7, 36, 95, 0.07);
}

.th-comments-wrap {
  --border-color: #E2E8FA;
  margin-top: var(--blog-space-y, 40px);
  margin-bottom: 30px;
}

.th-comments-wrap .description p:last-child {
  margin-bottom: -0.5em;
}

.th-comments-wrap .comment-respond {
  margin: 30px 0;
}

.th-comments-wrap pre {
  background: #ededed;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.th-comments-wrap li {
  margin: 0;
}

.th-comments-wrap .th-post-comment {
  padding: 0;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
  border-bottom: 1px solid var(--th-border-color);
}

.th-comments-wrap .th-post-comment ol,
.th-comments-wrap .th-post-comment ul,
.th-comments-wrap .th-post-comment dl {
  margin-bottom: 1rem;
}

.th-comments-wrap .th-post-comment ol ol,
.th-comments-wrap .th-post-comment ol ul,
.th-comments-wrap .th-post-comment ul ol,
.th-comments-wrap .th-post-comment ul ul {
  margin-bottom: 0;
}

.th-comments-wrap ul.comment-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: -30px;
}

.th-comments-wrap ul.comment-list ul ul,
.th-comments-wrap ul.comment-list ul ol,
.th-comments-wrap ul.comment-list ol ul,
.th-comments-wrap ul.comment-list ol ol {
  margin-bottom: 0;
}

.th-comments-wrap .comment-avater {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 5px;
}

.th-comments-wrap .comment-avater img {
  width: 100%;
}

.th-comments-wrap .comment-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-top: -6px;
  position: relative;
}

.th-comments-wrap .commented-on {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 2px;
  font-weight: 400;
  color: var(--body-color);
}

.th-comments-wrap .commented-on i {
  margin-right: 7px;
  font-size: 0.9rem;
}

.th-comments-wrap .name {
  margin-bottom: 12px;
  font-size: 20px;
}

.th-comments-wrap .comment-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.th-comments-wrap .text {
  margin-bottom: 10px;
}

.th-comments-wrap .children {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 80px;
}

.th-comments-wrap .reply_and_edit {
  margin-top: 12px;
  margin-bottom: -0.46em;
}

.th-comments-wrap .reply_and_edit a {
  margin-right: 10px;
}

.th-comments-wrap .reply_and_edit a:last-child {
  margin-right: 0;
}

.th-comments-wrap .reply-btn {
  font-weight: 600;
  font-size: 16px;
  color: var(--theme-color);
  display: inline-block;
}

.th-comments-wrap .reply-btn i {
  margin-right: 7px;
}

.th-comments-wrap .reply-btn:hover {
  color: var(--title-color);
}

.th-comments-wrap .star-rating {
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;
  top: 5px;
  right: 0;
  width: 80px;
}

ul.comment-list .th-comment-item:last-child > .th-post-comment {
  border-bottom: none;
  padding-bottom: 0;
}

ul.comment-list .th-comment-item:first-child > .th-post-comment {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--th-border-color);
}

.th-comments-wrap.th-comment-form {
  margin: 0;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .th-comment-form,
  .th-comments-wrap {
    padding: 40px 20px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .th-comments-wrap .children {
    margin-left: 40px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .th-comment-form,
  .th-comments-wrap {
    padding: 40px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .th-comment-form,
  .th-comments-wrap {
    padding: 40px 20px;
  }
  .th-comments-wrap .th-post-comment {
    display: block;
  }
  .th-comments-wrap .star-rating {
    position: relative;
    top: 0;
    right: 0;
  }
  .th-comments-wrap .comment-top {
    display: block;
  }
  .th-comments-wrap .comment-avater {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .th-comments-wrap .children {
    margin-left: 40px;
  }
  .th-comments-wrap .children {
    margin-left: 30px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .th-comment-form {
    --blog-space-x: 20px;
  }
}

/*------------------- 4.8. Hero Area  -------------------*/
/* Hero Global ---------------------------------- */
.th-hero-wrapper {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.th-hero-wrapper .slider-arrow {
  --pos-x: 100px;
  background-color: var(--white-color);
  box-shadow: none;
  color: var(--theme-color);
  border-color: var(--white-color);
}

.th-hero-wrapper .slider-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}

.th-hero-wrapper .slick-dots {
  position: absolute;
  top: 50%;
  left: 80px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* Extra large devices */
@media (max-width: 1500px) {
  .th-hero-wrapper .slider-arrow {
    --pos-x: 40px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .th-hero-wrapper .slider-arrow {
    left: auto;
    top: calc(50% - 35px);
    right: var(--pos-x, -120px);
    margin: 0;
  }
  .th-hero-wrapper .slider-arrow.slider-next {
    top: calc(50% + 35px);
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .th-hero-wrapper .slider-arrow {
    left: auto;
    top: calc(50% - 30px);
    right: var(--pos-x, -120px);
    margin: 0;
  }
  .th-hero-wrapper .slider-arrow.slider-next {
    top: calc(50% + 30px);
  }
}

/* Small devices */
@media (max-width: 767px) {
  .th-hero-wrapper .slider-arrow {
    display: none;
  }
}

.th-hero-bg {
  position: absolute;
  inset: 0;
}

.th-hero-bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Hero 1 ---------------------------------- */
.hero-subtitle {
  font-family: var(--title-font);
  color: var(--theme-color);
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 17px;
  text-transform: uppercase;
  margin-top: -0.24em;
}

.hero-title {
  font-size: 74px;
  font-weight: bold;
  line-height: 1.135;
  margin-bottom: 0;
}

.hero-title:last-of-type {
  margin-bottom: 21px;
}

.hero-text {
  margin-bottom: 43px;
}

.hero-1 {
  overflow: hidden;
}

.hero-1 .hero-shape1,
.hero-1 .hero-shape2,
.hero-1 .hero-shape3 {
  position: absolute;
  z-index: 2;
}

.hero-1 .hero-shape1,
.hero-1 .hero-shape2 {
  top: 10%;
  right: 0;
  -webkit-animation: jumpAni 7s linear infinite;
          animation: jumpAni 7s linear infinite;
}

.hero-1 .hero-shape2 {
  -webkit-animation: jumpReverseAni 7s linear infinite;
          animation: jumpReverseAni 7s linear infinite;
}

.hero-1 .hero-shape3 {
  bottom: -80px;
  left: -60px;
  -webkit-animation: jumpAni 7s linear infinite;
          animation: jumpAni 7s linear infinite;
}

.hero-1 .hero-img {
  position: absolute;
  top: 20px;
  right: 10%;
  z-index: 3;
}

.hero-1 .hero-img:before {
  content: '';
  width: 500px;
  height: 500px;
  background-color: #F2BA4C;
  opacity: 0.3;
  -webkit-filter: blur(300px);
          filter: blur(300px);
  border-radius: 50%;
  position: absolute;
  right: -40px;
  top: -180px;
  z-index: -1;
  -webkit-animation: bgColor 8s ease-in-out infinite;
          animation: bgColor 8s ease-in-out infinite;
}

.hero-style1 {
  position: relative;
  z-index: 6;
  padding: 130px 0 150px 0;
  max-width: 710px;
}

/* Hight Resoulation devices */
@media (min-width: 1922px) {
  .hero-1 .hero-img {
    right: 18%;
  }
}

@media (max-width: 1700px) {
  .hero-1 .hero-img {
    right: 1%;
  }
}

@media (max-width: 1400px) {
  .hero-1 .hero-img {
    top: 20px;
  }
  .hero-style1 {
    padding: 180px 0;
  }
  .hero-title {
    font-size: 68px;
  }
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .hero-1 .hero-img {
    max-width: 485px;
  }
  .hero-style1 {
    padding: 150px 0;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .hero-title {
    font-size: 60px;
  }
  .hero-1 .hero-img {
    right: -10%;
  }
  .hero-1 .hero-shape1,
  .hero-1 .hero-shape2 {
    max-width: 600px;
  }
  .hero-1 .hero-shape3 {
    max-width: 600px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .hero-title {
    font-size: 54px;
    line-height: 1.2;
  }
  .hero-style1 {
    padding: 110px 0;
    text-align: center;
  }
  .hero-style1 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .hero-style1 .hero-text {
    margin-left: auto;
    margin-right: auto;
  }
  .hero-1 .hero-img {
    position: relative;
    margin-top: 30px;
    margin-bottom: -60px;
    text-align: center;
    right: 0;
    top: 0;
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .hero-subtitle {
    font-size: 18px;
  }
  .hero-title {
    font-size: 48px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .hero-subtitle {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .hero-title {
    font-size: 38px;
    line-height: 1.24;
  }
  .hero-title:last-of-type {
    margin-bottom: 16px;
  }
  .hero-text {
    margin-bottom: 28px;
  }
}

@media (max-width: 390px) {
  .hero-title {
    font-size: 34px;
    line-height: 1.3;
  }
}

@media (max-width: 330px) {
  .hero-title {
    font-size: 30px;
  }
}

/* Hero 2 ---------------------------------- */
.hero-2 {
  overflow-x: hidden;
  /* Small devices */
}

.hero-2 .th-hero-bg {
  z-index: -1;
}

.hero-2 .th-hero-bg:before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-linear-gradient(left, var(--theme-color), transparent);
  background: linear-gradient(to right, var(--theme-color), transparent);
  z-index: 2;
  opacity: 0.9;
}

.hero-2 .hero-shape1,
.hero-2 .hero-shape2,
.hero-2 .hero-shape3 {
  position: absolute;
  right: 0;
  z-index: 1;
}

.hero-2 .hero-shape1 {
  bottom: 0;
  height: 430px;
  width: 215px;
  background: var(--theme-color);
  -webkit-transform: skewX(-45deg);
      -ms-transform: skewX(-45deg);
          transform: skewX(-45deg);
  opacity: 0.65;
  /* Small devices */
}

@media (max-width: 767px) {
  .hero-2 .hero-shape1 {
    display: none;
  }
}

.hero-2 .hero-shape2,
.hero-2 .hero-shape3 {
  top: 0;
}

.hero-2 .ripple-shape {
  position: absolute;
  top: -50px;
  left: -50px;
  z-index: 1;
  height: 100px;
  width: 100px;
}

@media (max-width: 767px) {
  .hero-2 .th-hero-bg:before {
    background: -webkit-linear-gradient(left, var(--theme-color), var(--theme-color));
    background: linear-gradient(to right, var(--theme-color), var(--theme-color));
    opacity: 0.6;
  }
}

.ripple-1,
.ripple-2,
.ripple-3,
.ripple-4,
.ripple-5,
.ripple-6 {
  height: 100px;
  width: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  -webkit-animation: heroripple 8s linear infinite;
          animation: heroripple 8s linear infinite;
}

.ripple-1 {
  -webkit-animation-delay: 0;
          animation-delay: 0;
}

.ripple-2 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.ripple-3 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.ripple-4 {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.ripple-4 {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.ripple-5 {
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
}

.ripple-6 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@-webkit-keyframes heroripple {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(4);
            transform: scale(4);
  }
}

@keyframes heroripple {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(4);
            transform: scale(4);
  }
}

.hero-style2 {
  position: relative;
  z-index: 6;
  margin: 240px 0;
  max-width: 620px;
}

.hero-style2 .hero-text {
  color: var(--white-color);
}

.hero-style2 .hero-title {
  color: var(--white-color);
}

.hero-style2 .hero-subtitle {
  color: var(--white-color);
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .hero-style2 {
    margin: 200px 0;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .hero-style2 {
    margin: 150px 0;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .hero-2 .hero-shape1 {
    max-width: 180px;
  }
  .hero-2 .hero-shape2 {
    max-width: 110px;
  }
  .hero-2 .hero-shape3 {
    max-width: 150px;
  }
  .hero-style2 {
    margin: 130px 0;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .hero-style2 {
    margin: 100px 0;
    text-align: center;
  }
  .hero-style2 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

/* Hero 3 start---------------------------------- */
.hero-social {
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
}

.hero-social a {
  display: inline-block;
  color: var(--title-color);
  font-family: var(--title-font);
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
}

.hero-social a:not(:last-child):after {
  content: '';
  height: 6px;
  width: 6px;
  display: inline-block;
  background-color: var(--theme-color);
  position: relative;
  margin: 35px 0 30px 0;
  position: relative;
  top: -2px;
}

.hero-social a:hover {
  color: var(--theme-color);
}

.hero-3 {
  background-color: #F7F8FD;
  overflow-x: hidden;
}

.hero-3 .hero-img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.hero-3 .hero-img:after {
  content: '';
  position: absolute;
  height: 611px;
  width: 611px;
  background: var(--theme-color);
  border-radius: 50%;
  bottom: -65px;
  left: -65px;
  z-index: -1;
}

.hero-3 .hero-img-phone {
  display: none;
}

.hero-3 .shape-blur {
  position: absolute;
  bottom: 80px;
  left: 100px;
  width: 534px;
  height: 534px;
  background: var(--theme-color);
  opacity: 0.5;
  -webkit-filter: blur(250px);
          filter: blur(250px);
  z-index: -1;
}

.hero-3 .hero-shape1 {
  position: absolute;
  top: 15%;
  left: 4%;
  -webkit-animation: jumpAni 7s linear infinite;
          animation: jumpAni 7s linear infinite;
}

.hero-3 .hero-social {
  position: absolute;
  bottom: 150px;
  left: 50px;
  z-index: 3;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.hero-style3 {
  padding: 340px 0 245px 0;
  max-width: 620px;
  position: relative;
  z-index: 5;
}

@media (max-width: 1700px) {
  .hero-3 .hero-img {
    right: -7%;
  }
}

@media (max-width: 1500px) {
  .hero-3 .hero-img {
    right: -12%;
  }
  .hero-3 .hero-social {
    left: 20px;
  }
}

@media (max-width: 1400px) {
  .hero-3 .hero-img {
    right: -14%;
  }
  .hero-3 .hero-social {
    display: none;
  }
  .hero-3 .hero-img:after {
    height: 561px;
    width: 561px;
    bottom: -45px;
    left: -45px;
  }
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .hero-3 .hero-img {
    right: -27%;
  }
  .hero-style3 {
    padding: 300px 0 205px 0;
    max-width: 620px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .hero-3 .hero-img {
    right: -13%;
    max-width: 550px;
  }
  .hero-3 .hero-img:after {
    height: 411px;
    width: 411px;
    bottom: -45px;
    left: -45px;
  }
  .hero-3 .hero-shape1 {
    max-width: 500px;
    top: unset;
    left: unset;
    bottom: 0;
    right: 0;
  }
  .hero-style3 {
    padding: 210px 0 145px 0;
  }
  .hero-style3 .hero-text {
    max-width: 500px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .hero-3 .hero-img {
    display: none;
  }
  .hero-3 .hero-img-phone {
    display: block;
    text-align: center;
    margin: 100px 15px 40px 15px;
    position: relative;
  }
  .hero-style3 {
    padding: 0px 0 100px 0;
    margin: 0 auto;
    text-align: center;
  }
  .hero-style3 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: left;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .hero-3 .hero-shape1 {
    display: none;
  }
}

/* Hero 3 end---------------------------------- */
/* Hero 4 start ---------------------------------- */
.hero-4 {
  background-color: #F0F4FF;
}

.hero-4 .hero-img {
  position: absolute;
  top: 50px;
  right: 7%;
  z-index: 2;
}

.hero-4 .body-particle {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-4 .triangle-1,
.hero-4 .triangle-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 82%;
  background-color: #E1E7F9;
  opacity: 0.3;
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
          clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  z-index: -1;
}

.hero-4 .triangle-2 {
  width: calc(70% - 140px);
  height: calc(82% - 90px);
  background-color: #E0E6F8;
  opacity: 0.5;
}

.hero-4 .hero-shape2,
.hero-4 .hero-shape3 {
  position: absolute;
  right: 0;
  top: 0;
}

.hero-style4 {
  position: relative;
  z-index: 6;
  margin: 219px 0 219px 0;
  max-width: 620px;
}

.hero-style4 .ripple-shape {
  position: absolute;
  top: -50px;
  left: -50px;
  z-index: 1;
  height: 100px;
  width: 100px;
}

.hero-style4 .ripple-1,
.hero-style4 .ripple-2,
.hero-style4 .ripple-3,
.hero-style4 .ripple-4,
.hero-style4 .ripple-5,
.hero-style4 .ripple-6 {
  border-color: rgba(20, 29, 56, 0.3);
}

/* Hight Resoulation devices */
@media (min-width: 1922px) {
  .hero-4 .hero-img {
    right: 18%;
  }
}

@media (max-width: 1700px) {
  .hero-4 .hero-img {
    right: 1%;
  }
}

@media (max-width: 1400px) {
  .hero-4 .hero-img {
    top: 20px;
  }
  .hero-style4 {
    margin: 180px 0;
  }
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .hero-4 .hero-img {
    max-width: 520px;
    top: 80px;
  }
  .hero-style4 {
    margin: 150px 0;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .hero-4 .hero-img {
    right: -5%;
  }
  .hero-style4 {
    max-width: 500px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .hero-style4 {
    margin: 110px auto;
    text-align: center;
  }
  .hero-style4 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: left;
  }
  .hero-style4 .hero-text {
    margin-left: auto;
    margin-right: auto;
  }
  .hero-4 .hero-img {
    position: relative;
    margin-top: 50px;
    margin-bottom: -70px;
    text-align: center;
    right: 0;
    top: 0;
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .hero-subtitle {
    font-size: 18px;
  }
  .hero-title {
    font-size: 48px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .hero-subtitle {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .hero-title {
    font-size: 38px;
    line-height: 1.24;
  }
  .hero-title:last-of-type {
    margin-bottom: 16px;
  }
  .hero-text {
    margin-bottom: 28px;
  }
}

/* Hero 4 end ---------------------------------- */
/* Hero 5  start---------------------------------- */
.hero-5 {
  /* Extra small devices */
}

.hero-5 .hero-inner {
  background: -webkit-linear-gradient(left, #6D8EFF 9.29%, rgba(62, 102, 243, 0) 66.11%), var(--theme-color);
  background: linear-gradient(90deg, #6D8EFF 9.29%, rgba(62, 102, 243, 0) 66.11%), var(--theme-color);
  -webkit-clip-path: path("M1920 0H0V634H0.327779V798.568C-0.10435 798.269 -0.114115 798.27 0.327779 798.605V798.568C0.532816 798.71 0.83295 798.919 1.22505 799.193C18.1153 810.983 205.659 941.897 313.899 915.501C389.861 896.976 437.729 855.824 481.717 818.007C530.331 776.214 574.207 738.494 646.031 739.895C718.95 741.318 761.644 779.396 814.255 826.319C856.921 864.372 906.109 908.242 983.229 943.098C1226.21 1052.92 1364.04 951.411 1415.94 902.969C1424.5 894.981 1432.69 887.305 1440.54 879.94C1605.3 725.417 1623.91 707.962 1887.01 815.029C1898.49 819.704 1909.48 823.995 1920 827.922V634V633V0Z");
          clip-path: path("M1920 0H0V634H0.327779V798.568C-0.10435 798.269 -0.114115 798.27 0.327779 798.605V798.568C0.532816 798.71 0.83295 798.919 1.22505 799.193C18.1153 810.983 205.659 941.897 313.899 915.501C389.861 896.976 437.729 855.824 481.717 818.007C530.331 776.214 574.207 738.494 646.031 739.895C718.95 741.318 761.644 779.396 814.255 826.319C856.921 864.372 906.109 908.242 983.229 943.098C1226.21 1052.92 1364.04 951.411 1415.94 902.969C1424.5 894.981 1432.69 887.305 1440.54 879.94C1605.3 725.417 1623.91 707.962 1887.01 815.029C1898.49 819.704 1909.48 823.995 1920 827.922V634V633V0Z");
}

@media (min-width: 1930px) {
  .hero-5 .hero-inner {
    -webkit-clip-path: path("M1920 0H0V634H0.327779V798.568C-0.10435 798.269 -0.114115 798.27 0.327779 798.605V798.568C0.532816 798.71 0.83295 798.919 1.22505 799.193C18.1153 810.983 205.659 941.897 313.899 915.501C389.861 896.976 437.729 855.824 481.717 818.007C530.331 776.214 574.207 738.494 646.031 739.895C718.95 741.318 761.644 779.396 814.255 826.319C856.921 864.372 906.109 908.242 983.229 943.098C1226.21 1052.92 1364.04 951.411 1415.94 902.969C1424.5 894.981 1432.69 887.305 1440.54 879.94C1605.3 725.417 1623.91 707.962 1887.01 815.029C1898.49 819.704 1909.48 823.995 7520 827.922V634V633V0Z");
            clip-path: path("M1920 0H0V634H0.327779V798.568C-0.10435 798.269 -0.114115 798.27 0.327779 798.605V798.568C0.532816 798.71 0.83295 798.919 1.22505 799.193C18.1153 810.983 205.659 941.897 313.899 915.501C389.861 896.976 437.729 855.824 481.717 818.007C530.331 776.214 574.207 738.494 646.031 739.895C718.95 741.318 761.644 779.396 814.255 826.319C856.921 864.372 906.109 908.242 983.229 943.098C1226.21 1052.92 1364.04 951.411 1415.94 902.969C1424.5 894.981 1432.69 887.305 1440.54 879.94C1605.3 725.417 1623.91 707.962 1887.01 815.029C1898.49 819.704 1909.48 823.995 7520 827.922V634V633V0Z");
  }
}

.hero-5 .th-hero-bg {
  right: 0;
  bottom: 0;
}

.hero-5 .th-hero-bg .hero-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  mix-blend-mode: soft-light;
}

.hero-5 .th-hero-bg img {
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
}

.hero-5 .hero-shape-2 {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

@media (max-width: 575px) {
  .hero-5 .hero-inner {
    -webkit-clip-path: none;
            clip-path: none;
  }
}

.hero-style5 {
  position: relative;
  max-width: 650px;
  padding: 275px 0 348px 0;
  z-index: 9;
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .hero-style5 {
    padding: 275px 0 295px 0;
  }
}

@media (max-width: 767px) {
  .hero-style5 {
    padding: 275px 0 315px 0;
  }
}

@media (max-width: 575px) {
  .hero-style5 {
    padding: 240px 0 140px 0;
  }
}

.hero-style5 .sub-title {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  display: block;
  letter-spacing: -0.4px;
  margin-bottom: 20px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .hero-style5 .sub-title {
    font-size: 16px;
  }
}

.hero-style5 .hero-title {
  font-size: 64px;
  line-height: 74px;
  letter-spacing: -1.28px;
  margin-bottom: 25px;
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 991px) {
  .hero-style5 .hero-title {
    font-size: 54px;
    line-height: 64px;
  }
}

@media (max-width: 767px) {
  .hero-style5 .hero-title {
    font-size: 44px;
    line-height: 54px;
  }
}

.hero-style5 .hero-text {
  font-size: 18px;
}

.hero-style5 .th-btn {
  padding: 19.5px 48.7px;
}

/* Hero 5 end ---------------------------------- */
/* Hero 6 start---------------------------------- */
.hero-6 {
  position: relative;
  overflow: hidden;
}

.hero-6 .th-hero-bg {
  z-index: 2;
  overflow: hidden;
}

.hero-6 .th-hero-bg img {
  position: relative;
  z-index: 8;
  background-position: bottom center;
}

.hero-6 .slider-arrow {
  --icon-size: 70px;
  background-color: var(--white-color);
  color: var(--theme-color);
  border: none;
  left: var(--pos-x, 120px);
  top: 44.5%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.4);
      -ms-transform: scale(0.4);
          transform: scale(0.4);
}

@media (max-width: 1399px) {
  .hero-6 .slider-arrow {
    display: none;
  }
}

.hero-6 .slider-arrow.slider-next {
  right: var(--pos-x, 120px);
  left: auto;
}

.hero-6 .slider-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.hero-6:hover .slider-arrow {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.hero-6 .hero-big {
  top: unset;
  bottom: 23.5%;
  -webkit-transform: translate(-50%, 50%);
      -ms-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  position: absolute;
  left: 50%;
  z-index: -1;
  opacity: 0.7;
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 767px) {
  .hero-6 .hero-big {
    bottom: 21%;
  }
}

@media (max-width: 575px) {
  .hero-6 .hero-big {
    bottom: 12%;
  }
}

.hero-6 .hero-big_text {
  font-size: 230px;
  font-weight: 900;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.24);
  -webkit-text-fill-color: rgba(255, 255, 255, 0.05);
  -webkit-text-stroke-width: 1.2px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.24);
  /* Large devices */
  /* Small devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .hero-6 .hero-big_text {
    font-size: 150px;
  }
}

@media (max-width: 767px) {
  .hero-6 .hero-big_text {
    font-size: 120px;
  }
}

@media (max-width: 575px) {
  .hero-6 .hero-big_text {
    font-size: 80px;
  }
}

@media (max-width: 375px) {
  .hero-6 .hero-big_text {
    font-size: 70px;
  }
}

@media (max-width: 320px) {
  .hero-6 .hero-big_text {
    font-size: 60px;
    -webkit-text-stroke-width: 1px;
    font-weight: 500;
    letter-spacing: 2px;
  }
}

.hero-style6 {
  position: relative;
  z-index: 9;
  max-width: 950px;
  padding: 260px 0 350px 0;
  display: block;
  margin: auto;
  text-align: center;
}

.hero-style6 .sub-title {
  font-size: 16px;
  letter-spacing: 1.6px;
  color: var(--white-color);
  margin-bottom: 17px;
  display: block;
}

.hero-style6 .hero-title {
  color: var(--white-color);
  font-weight: 800;
  line-height: 82px;
  margin-bottom: 55px;
}

.hero-style6 .th-btn {
  padding: 20px 39.8px;
}

.hero-style6 .btn-group .play-btn > i {
  --icon-size: 55px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: var(--white-color);
  border: 1px solid var(--white-color);
  color: var(--theme-color);
  font-size: var(--icon-font-size, 1.2em);
}

.hero-style6 .btn-group .play-btn:before, .hero-style6 .btn-group .play-btn:after {
  background-color: var(--white-color);
}

.hero-style6 .btn-group .play-btn:hover i {
  background: var(--theme-color);
  border: 1px solid var(--theme-color);
  color: var(--white-color);
}

.hero-style6 .btn-group .play-btn:hover:before, .hero-style6 .btn-group .play-btn:hover:after {
  background-color: var(--theme-color);
}

.hero-style6 .call-btn .btn-title {
  color: var(--white-color);
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .hero-style6 {
    padding: 180px 0 350px 0;
  }
  .hero-style6 .hero-title {
    font-size: 74px;
    line-height: 84px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .hero-6 .hero-img {
    position: static;
    max-width: 100%;
    text-align: center;
    margin: 0 auto 50px auto;
  }
  .hero-6 .hero-shape2 {
    display: none;
  }
  .hero-style6 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-style6 .hero-title {
    font-size: 60px;
    line-height: 1.2;
  }
  .hero-style6 .title1,
  .hero-style6 .title2 {
    margin-left: auto;
    margin-right: auto;
  }
  .hero-style6 .title-img {
    max-width: 40px;
    right: 5%;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .hero-style6 {
    padding: 150px 0 230px 0;
  }
  .hero-style6 .hero-title {
    font-size: 40px;
    line-height: 50px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .hero-style6 {
    padding: 150px 0;
  }
  .hero-style6 .hero-title {
    font-size: 42px;
    line-height: 1.24;
    margin-bottom: 35px;
  }
}

@media (max-width: 390px) {
  .hero-style6 {
    padding: 130px 0;
  }
  .hero-style6 .hero-title {
    font-size: 36px;
    line-height: 1.3;
  }
}

@media (max-width: 330px) {
  .hero-style6 .hero-title {
    font-size: 32px;
  }
}

/* Hero 6 end---------------------------------- */
/* Hero 7 start ---------------------------------- */
.hero-7 {
  position: relative;
  padding: 202px 0 120px;
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1199px) {
  .hero-7 {
    padding: 170px 0 120px;
  }
}

@media (max-width: 991px) {
  .hero-7 {
    padding: 170px 0 80px;
  }
}

.hero-7 .th-hero-bg {
  z-index: -1;
  inset: 0 0 628px;
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .hero-7 .th-hero-bg {
    inset: 0 0 380px;
  }
}

@media (max-width: 767px) {
  .hero-7 .th-hero-bg {
    inset: 0 0 320px;
  }
}

@media (max-width: 575px) {
  .hero-7 .th-hero-bg {
    inset: 0 0 200px;
  }
}

.hero-7 .th-hero-thumb {
  margin-top: 140px;
  box-shadow: 0px 20px 100px -12px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  /* Medium Large devices */
  /* Extra small devices */
}

.hero-7 .th-hero-thumb img {
  width: 100%;
  border-radius: 30px;
}

@media (max-width: 1399px) {
  .hero-7 .th-hero-thumb {
    margin-top: 100px;
  }
}

@media (max-width: 575px) {
  .hero-7 .th-hero-thumb {
    margin-top: 80px;
    border-radius: 10px;
  }
  .hero-7 .th-hero-thumb img {
    border-radius: 10px;
  }
}

.hero-style7 .hero-text {
  max-width: 570px;
  margin: auto;
}

.hero-style7 .btn-group {
  gap: 20px 30px;
}

.hero-style7 .btn-group .th-btn {
  min-width: 200px;
}

/* Hero 7 end ---------------------------------- */
/* Hero 8 start ---------------------------------- */
.hero-8 {
  position: relative;
  padding: 140px 0 40px;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1299px) {
  .hero-8 {
    padding: 160px 0 100px;
  }
}

@media (max-width: 1199px) {
  .hero-8 {
    padding: 200px 0 40px;
  }
}

@media (max-width: 991px) {
  .hero-8 {
    padding: 170px 0 40px;
  }
}

.hero-8 .th-hero-bg {
  z-index: -1;
  inset: 0;
}

.hero-8 .th-hero-thumb {
  margin-right: -100px;
  /* Extra large devices */
}

.hero-8 .th-hero-thumb img {
  width: 100%;
}

@media (max-width: 1500px) {
  .hero-8 .th-hero-thumb {
    margin-right: 0;
  }
}

.hero-style8 {
  /* Large devices */
  /* Small devices */
}

.hero-style8 .sub-title {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 15px;
}

.hero-style8 .sub-title:after {
  content: '';
  position: relative;
  width: 60px;
  height: 2px;
  background: var(--theme-color);
  display: inline-block;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style8 .sub-title:after {
    display: none;
  }
}

.hero-style8 .hero-text {
  margin-bottom: 31px;
}

.hero-style8 .btn-group {
  gap: 20px 30px;
}

.hero-style8 .btn-group .th-btn {
  padding: 14.5px 40px;
  min-width: 200px;
}

@media (max-width: 1199px) {
  .hero-style8 {
    text-align: center;
    margin-bottom: 80px;
  }
  .hero-style8 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (max-width: 767px) {
  .hero-style8 {
    margin-bottom: 40px;
  }
}

/* Hero 8 end ---------------------------------- */
/* hero 9 start-------------------------------- */
.hero-9 {
  margin: 0 100px;
  border-radius: 50px;
  /* Extra large devices */
}

@media (max-width: 1699px) {
  .hero-9 {
    margin: 0 50px;
  }
}

@media (max-width: 1500px) {
  .hero-9 {
    margin: 0;
  }
}

.hero-9 .th-hero-img {
  text-align: right;
}

.hero-style9 {
  padding: 241px 0;
  max-width: 670px;
  /* Medium Large devices */
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .hero-style9 {
    padding: 210px 0;
  }
}

@media (max-width: 1199px) {
  .hero-style9 {
    text-align: center;
    max-width: 100%;
    padding: 140px 0 80px 0;
  }
}

@media (max-width: 767px) {
  .hero-style9 {
    padding: 120px 0 80px 0;
  }
}

.hero-style9 .hero-title {
  font-weight: 700;
  font-size: 84px;
  line-height: 94px;
  text-transform: capitalize;
  color: var(--white-color);
  margin-top: -1.3rem;
  margin-bottom: 18px;
  /* Medium Large devices */
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .hero-style9 .hero-title {
    font-size: 74px;
    line-height: 84px;
  }
}

@media (max-width: 991px) {
  .hero-style9 .hero-title {
    font-size: 64px;
    line-height: 74px;
  }
}

@media (max-width: 767px) {
  .hero-style9 .hero-title {
    font-size: 54px;
    line-height: 64px;
  }
}

@media (max-width: 575px) {
  .hero-style9 .hero-title {
    font-size: 44px;
    line-height: 54px;
  }
}

@media (max-width: 375px) {
  .hero-style9 .hero-title {
    font-size: 34px;
    line-height: 44px;
  }
}

.hero-style9 .hero-text {
  color: var(--white-color);
  max-width: 495px;
  margin-bottom: 30px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style9 .hero-text {
    display: block;
    margin: auto auto 30px auto;
  }
}

.hero-style9 .btn-group {
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style9 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

/* hero 9 end-------------------------------- */
/* hero 10 start -------------------------------- */
.hero-10 .th-hero-img {
  position: relative;
  margin-right: -10%;
  z-index: 2;
  text-align: center;
}

.hero-10 .th-hero-img .hero-line1 {
  position: absolute;
  bottom: 8%;
  right: -2%;
  background-image: -webkit-linear-gradient(left, #02CE80 30.66%, #7BD706 45.85%, #02CE80 62.34%, #7BD706 75.62%, #02CE80 93.74%);
  background-image: linear-gradient(left, #02CE80 30.66%, #7BD706 45.85%, #02CE80 62.34%, #7BD706 75.62%, #02CE80 93.74%);
  background-size: 200% 100%;
  -webkit-animation: lineBg 5s linear infinite;
  animation: lineBg 5s linear infinite;
  z-index: 2;
  opacity: 0.3;
  /* Large devices */
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .hero-10 .th-hero-img .hero-line1 {
    right: unset;
    left: 20%;
  }
}

@media (max-width: 991px) {
  .hero-10 .th-hero-img .hero-line1 {
    left: 10%;
  }
}

@media (max-width: 767px) {
  .hero-10 .th-hero-img .hero-line1 {
    left: 0;
  }
}

@media (max-width: 375px) {
  .hero-10 .th-hero-img .hero-line1 {
    bottom: 0;
  }
}

.hero-10 .th-hero-img .hero-line1 {
  min-height: 618px;
  min-width: 618px;
  border-radius: 50%;
  max-width: 100%;
  z-index: -1;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .hero-10 .th-hero-img .hero-line1 {
    min-width: 500px;
    min-height: 500px;
  }
}

.hero-10 .th-hero-img .hero-line2 {
  position: absolute;
  bottom: 8%;
  right: 2%;
  min-width: 548px;
  min-height: 548px;
  border-radius: 50%;
  max-width: 100%;
  background: #F3F2EA;
  z-index: -1;
  /* Large devices */
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .hero-10 .th-hero-img .hero-line2 {
    right: unset;
    left: 24%;
  }
}

@media (max-width: 991px) {
  .hero-10 .th-hero-img .hero-line2 {
    left: 15%;
  }
}

@media (max-width: 767px) {
  .hero-10 .th-hero-img .hero-line2 {
    left: 7%;
  }
}

@media (max-width: 575px) {
  .hero-10 .th-hero-img .hero-line2 {
    min-width: 450px;
    min-height: 450px;
  }
}

@media (max-width: 375px) {
  .hero-10 .th-hero-img .hero-line2 {
    bottom: 0;
  }
}

.hero-10 .hero10-shape {
  position: absolute;
  bottom: 0;
  right: -25%;
  z-index: 4;
  min-width: 1150px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-10 .hero10-shape {
    right: 0;
  }
}

.hero-style10 {
  position: relative;
  padding: 155px 0 206px 0;
  z-index: 8;
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1199px) {
  .hero-style10 {
    text-align: center;
    padding: 120px 0 80px 0;
  }
}

@media (max-width: 991px) {
  .hero-style10 {
    padding: 100px 0 80px 0;
  }
}

.hero-style10 .hero-title {
  font-size: 84px;
  line-height: 94px;
  margin-top: -1.3rem;
  letter-spacing: 0.5px;
  display: block;
  /* Medium Large devices */
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .hero-style10 .hero-title {
    font-size: 74px;
    line-height: 84px;
  }
}

@media (max-width: 991px) {
  .hero-style10 .hero-title {
    font-size: 64px;
    line-height: 74px;
    margin-top: -1.0rem;
  }
}

@media (max-width: 767px) {
  .hero-style10 .hero-title {
    font-size: 54px;
    line-height: 64px;
  }
}

@media (max-width: 575px) {
  .hero-style10 .hero-title {
    font-size: 44px;
    line-height: 54px;
    margin-top: -0.8rem;
  }
}

@media (max-width: 375px) {
  .hero-style10 .hero-title {
    font-size: 30px;
    line-height: 40px;
    margin-top: -0.6rem;
  }
}

.hero-style10 .hero-title span {
  display: block;
}

.hero-style10 .hero-text {
  max-width: 585px;
  margin-bottom: 30px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style10 .hero-text {
    display: block;
    margin: auto auto 30px auto;
  }
}

.hero-style10 .btn-group {
  gap: 24px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style10 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.hero-style10 .th-btn.style5 {
  color: var(--theme-color);
  padding: 19.5px 41.6px;
}

@-webkit-keyframes lineBg {
  0% {
    background-position: 0% 0%;
  }
  20% {
    background-position: 50% 0%;
  }
  40% {
    background-position: 50% 50%;
  }
  60% {
    background-position: -100% 100%;
  }
  80% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes lineBg {
  0% {
    background-position: 0% 0%;
  }
  20% {
    background-position: 50% 0%;
  }
  40% {
    background-position: 50% 50%;
  }
  60% {
    background-position: -100% 100%;
  }
  80% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* hero 10 end -------------------------------- */
/* hero 9 start-------------------------------- */
.hero-11 {
  margin: 60px 60px 0 60px;
  border-radius: 50px;
  /* Extra large devices */
}

@media (max-width: 1699px) {
  .hero-11 {
    margin: 0 50px;
  }
}

@media (max-width: 1500px) {
  .hero-11 {
    margin: 0;
  }
}

.hero-11 .th-hero-img {
  text-align: right;
}

.hero-style11 {
  padding: 401px 0 241px 0;
  max-width: 657px;
  /* Medium Large devices */
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .hero-style11 {
    padding: 210px 0;
  }
}

@media (max-width: 1199px) {
  .hero-style11 {
    text-align: center;
    max-width: 100%;
    padding: 140px 0 80px 0;
  }
}

@media (max-width: 767px) {
  .hero-style11 {
    padding: 120px 0 80px 0;
  }
}

.hero-style11 .hero-meta {
  padding: 13.6px 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(28.85px);
          backdrop-filter: blur(28.85px);
  border-radius: 6px;
  display: inline-block;
  margin-bottom: 22px;
}

.hero-style11 .sub-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  text-transform: uppercase;
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: -0.6rem;
}

.hero-style11 .hero-title {
  font-weight: 700;
  font-size: 72px;
  line-height: 82px;
  color: var(--white-color);
  letter-spacing: -0.02em;
  margin-bottom: 18px;
  /* Medium Large devices */
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .hero-style11 .hero-title {
    font-size: 74px;
    line-height: 84px;
  }
}

@media (max-width: 991px) {
  .hero-style11 .hero-title {
    font-size: 64px;
    line-height: 74px;
  }
}

@media (max-width: 767px) {
  .hero-style11 .hero-title {
    font-size: 54px;
    line-height: 64px;
  }
}

@media (max-width: 575px) {
  .hero-style11 .hero-title {
    font-size: 44px;
    line-height: 54px;
  }
}

@media (max-width: 375px) {
  .hero-style11 .hero-title {
    font-size: 34px;
    line-height: 44px;
  }
}

.hero-style11 .hero-text {
  color: var(--white-color);
  max-width: 495px;
  margin-bottom: 30px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style11 .hero-text {
    display: block;
    margin: auto auto 30px auto;
  }
}

.hero-style11 .btn-group {
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style11 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

/* hero 11 end-------------------------------- */
/* hero 12 start -------------------------------- */
.hero-12 {
  background-position: left center;
}

.hero-12 .th-hero-img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  /* Medium devices */
}

@media (max-width: 991px) {
  .hero-12 .th-hero-img {
    display: none;
  }
}

.hero-style12 {
  padding: 189px 0 369px 0;
  max-width: 630px;
  text-align: left;
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 1399px) {
  .hero-style12 {
    max-width: 600px;
  }
}

@media (max-width: 991px) {
  .hero-style12 {
    padding: 150px 0 320px 0;
  }
}

@media (max-width: 767px) {
  .hero-style12 {
    padding: 120px 0 80px 0;
  }
}

@media (max-width: 575px) {
  .hero-style12 {
    text-align: center;
  }
  .hero-style12 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.hero-style12 .hero-title {
  color: var(--white-color);
  margin-top: -1.1rem;
  margin-bottom: 37px;
}

.hero-style12 .th-btn {
  padding: 18px 47px;
}

.hero-watch-area {
  position: relative;
  background-color: var(--theme-color);
  border-radius: 100px;
  padding: 30px 40px;
  z-index: 2;
  margin-top: -13%;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .hero-watch-area {
    margin-top: -15%;
  }
}

@media (max-width: 1199px) {
  .hero-watch-area {
    margin-top: -20%;
  }
}

@media (max-width: 991px) {
  .hero-watch-area {
    margin-top: -25%;
  }
}

@media (max-width: 767px) {
  .hero-watch-area {
    margin: 0 0 40px 0;
  }
}

.hero-watch-area .btn-group:has(.call-btn) {
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1199px) {
  .hero-watch-area .btn-group:has(.call-btn) {
    gap: 25px;
  }
}

@media (max-width: 767px) {
  .hero-watch-area .btn-group:has(.call-btn) {
    gap: 20px;
  }
}

.hero-watch-area .hero-btn {
  max-width: 580px;
  gap: 30px;
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .hero-watch-area .hero-btn {
    gap: 20px;
    max-width: 350px;
  }
}

@media (max-width: 575px) {
  .hero-watch-area .hero-btn {
    display: block;
    text-align: center;
  }
}

.hero-watch-area .hero-btn .play-btn > i {
  --icon-size: 80px;
  background-color: transparent;
  color: var(--white-color);
  border: 1px solid var(--white-color);
  /* Extra small devices */
}

@media (max-width: 575px) {
  .hero-watch-area .hero-btn .play-btn > i {
    margin-bottom: 20px;
  }
}

.hero-watch-area .hero-btn .btn-title {
  color: var(--white-color);
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .hero-watch-area .hero-btn .btn-title {
    font-size: 20px;
    line-height: 30px;
  }
}

.hero-watch-area .th-btn {
  color: var(--theme-color);
  padding: 21px 55px;
  font-size: 16px;
}

/* hero 12 end -------------------------------- */
/* hero 13 start ------------------------------*/
.hero-13 .th-hero-img {
  border-radius: 30px;
  margin-top: 55px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-13 .th-hero-img {
    margin-top: 0;
  }
}

.hero-13 .th-hero-img img {
  border-radius: 30px;
}

.hero-style13 {
  padding: 272px 0 323px 0;
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .hero-style13 {
    padding: 200px 0 40px 0;
    max-width: 738px;
  }
}

@media (max-width: 575px) {
  .hero-style13 {
    padding: 150px 0 40px 0;
  }
}

.hero-style13 .sub-title {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
}

@media (max-width: 480px) {
  .hero-style13 .sub-title {
    font-size: 16px;
  }
}

.hero-style13 .hero-title {
  color: var(--white-color);
  font-size: 84px;
  line-height: 94px;
  margin-bottom: 0px;
  /* Medium Large devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .hero-style13 .hero-title {
    font-size: 70px;
    line-height: 80px;
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .hero-style13 .hero-title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 480px) {
  .hero-style13 .hero-title {
    font-size: 30px;
    line-height: 40px;
  }
}

.hero-style13 .hero-title .square-text {
  position: relative;
  display: inline-block;
  font-weight: 400;
  line-height: 60px;
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding: 8px 33px 14px 33px;
  z-index: 2;
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 767px) {
  .hero-style13 .hero-title .square-text {
    line-height: 40px;
  }
}

@media (max-width: 480px) {
  .hero-style13 .hero-title .square-text {
    line-height: 30px;
  }
}

@media (max-width: 575px) {
  .hero-style13 .hero-title .square-text {
    line-height: 30px;
  }
}

.hero-style13 .hero-title .square-text:before {
  content: "";
  position: absolute;
  inset: 0;
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  z-index: -1;
}

.hero-style13 .hero-title .square-text .dot1,
.hero-style13 .hero-title .square-text .dot2 {
  position: absolute;
  top: -10px;
  height: calc(100% + 20px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.hero-style13 .hero-title .square-text .dot1:before, .hero-style13 .hero-title .square-text .dot1:after,
.hero-style13 .hero-title .square-text .dot2:before,
.hero-style13 .hero-title .square-text .dot2:after {
  content: '';
  width: 14px;
  height: 14px;
  display: inline-block;
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
}

.hero-style13 .hero-title .square-text .dot1 {
  left: -9px;
}

.hero-style13 .hero-title .square-text .dot2 {
  right: -9px;
}

.hero-style13 .hero-wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .hero-style13 .hero-wrapp {
    display: block;
  }
}

.hero-style13 .cilent-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: end;
      -ms-flex-pack: end;
          justify-content: end;
  -webkit-box-align: end;
  -webkit-align-items: end;
      -ms-flex-align: end;
          align-items: end;
  gap: 10px;
}

.hero-style13 .cilent-box img {
  margin-bottom: 10px;
}

.hero-style13 .cilent-box .title {
  color: var(--white-color);
  display: block;
  margin-bottom: 5px;
}

.hero-style13 .cilent-box .about_review i {
  color: #FF9900;
  font-size: 14px;
  margin-right: 5px;
}

.hero-style13 .cilent-box .line-btn {
  font-size: 14px;
  color: var(--white-color);
  text-transform: capitalize;
  font-weight: 400;
  padding-bottom: 0;
}

.hero-style13 .cilent-box .line-btn i {
  font-size: 14px;
}

.hero-style13 .cilent-box .line-btn:before {
  display: none;
}

.hero-style13 .about-wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .hero-style13 .about-wrapp {
    display: block;
  }
}

.hero-style13 .btn-group:has(.call-btn) {
  gap: 24px;
}

.hero-style13 .call-btn .play-btn:before, .hero-style13 .call-btn .play-btn:after {
  background-color: var(--white-color);
}

.hero-style13 .call-btn .play-btn > i {
  --icon-size: 55px;
  background-color: var(--white-color);
  color: var(--theme-color);
}

.hero-style13 .call-btn .play-btn > i:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.hero-style13 .call-btn .btn-title {
  font-size: 16px;
  color: var(--white-color);
}

.hero-style13 .th-btn {
  font-size: 16px;
  padding: 20.5px 47px;
  border-radius: 100px;
}

.brand-slider7 {
  position: absolute;
  bottom: -1px;
  left: 0;
  background-color: #EFF1F9;
  padding: 60px 36px 45px 36px;
  max-width: 1008px;
  margin-left: 0;
  -webkit-clip-path: polygon(0 0, 95% 0%, 100% 40%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%);
          clip-path: polygon(0 0, 95% 0%, 100% 40%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%);
  /* Medium devices */
}

@media (max-width: 991px) {
  .brand-slider7 {
    max-width: 100%;
  }
}

/* hero 13 end ------------------------------*/
/* hero 14 start -------------------------------*/
.hero-14 {
  position: relative;
  padding: 245px 0;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1299px) {
  .hero-14 {
    padding: 180px 0;
  }
}

@media (max-width: 1199px) {
  .hero-14 {
    padding: 120px 0;
  }
}

.hero-14 .th-hero-bg {
  background-position: top center;
  z-index: -1;
  inset: 0;
}

.hero-14 .th-hero-image {
  position: relative;
}

.hero-14 .th-hero-thumb {
  position: relative;
  margin: 0 -155px 0 80px;
  z-index: 2;
  /* Large devices */
  /* Extra large devices */
}

@media (max-width: 1799px) {
  .hero-14 .th-hero-thumb {
    margin: 0 -100px 0 80px;
  }
}

@media (max-width: 1699px) {
  .hero-14 .th-hero-thumb {
    margin: 0 -50px 0 80px;
  }
}

@media (max-width: 1199px) {
  .hero-14 .th-hero-thumb {
    margin: 0;
  }
}

.hero-14 .th-hero-thumb:before {
  content: "";
  position: absolute;
  inset: 0;
  top: -19px;
  right: -19px;
  background: #B4C4FF;
  border-radius: 20px;
  z-index: -1;
}

.hero-14 .th-hero-thumb img {
  width: 100%;
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 20px;
}

@media (max-width: 1500px) {
  .hero-14 .th-hero-thumb {
    margin-right: 0;
  }
}

.hero-14 .th-hero-thumb2 {
  position: absolute;
  left: -10px;
  bottom: -60px;
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 6px;
  z-index: 3;
}

.hero-14 .hero-shape {
  position: absolute;
  top: -22%;
  left: 0;
  z-index: -1;
}

.hero-style14 {
  /* Large devices */
}

.hero-style14 .sub-title {
  margin-bottom: 15px;
}

.hero-style14 .hero-title {
  font-style: 72px;
  margin-bottom: 20px;
}

.hero-style14 .hero-text {
  max-width: 782px;
  margin-bottom: 35px;
}

.hero-style14 .btn-group {
  gap: 30px;
}

.hero-style14 .btn-group .th-btn {
  font-size: 16px;
  font-weight: 400;
}

.hero-style14 .btn-group .btn-title {
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 1199px) {
  .hero-style14 {
    text-align: center;
    margin-bottom: 80px;
  }
  .hero-style14 .hero-text {
    display: block;
    margin: auto auto 35px auto;
  }
  .hero-style14 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

/* hero 14 end -------------------------------*/
/* hero 15 start -------------------------------*/
.hero-15 .th-hero15-img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1599px) {
  .hero-15 .th-hero15-img {
    max-width: 950px;
    height: 100%;
  }
}

@media (max-width: 1299px) {
  .hero-15 .th-hero15-img {
    max-width: 850px;
    right: -10%;
  }
}

@media (max-width: 1199px) {
  .hero-15 .th-hero15-img {
    position: relative;
    max-width: 100%;
    right: -15%;
  }
}

@media (max-width: 991px) {
  .hero-15 .th-hero15-img {
    right: 0;
  }
}

.hero-15 .th-hero15-img .hero15-img {
  height: 100%;
  -webkit-mask-size: 100%;
          mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
}

.hero-15 .th-hero15-img .hero15-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-15 .hero15-shape {
  content: "";
  position: absolute;
  inset: 0;
  left: -1%;
  background-color: #9FB3F9;
  -webkit-mask-size: 100%;
          mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  z-index: -1;
}

.hero-style15 {
  position: relative;
  padding: 206px 0 305px 0;
  max-width: 777px;
  z-index: 3;
  /* Large devices */
}

@media (max-width: 1599px) {
  .hero-style15 {
    padding: 170px 0 270px 0;
  }
}

@media (max-width: 1199px) {
  .hero-style15 {
    padding: 120px 0 80px 0;
  }
}

.hero-style15 .sub-title {
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.hero-style15 .hero-title {
  font-size: 72px;
  line-height: 82px;
  /* Medium Large devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 1399px) {
  .hero-style15 .hero-title {
    font-size: 62px;
    line-height: 72px;
  }
}

@media (max-width: 1299px) {
  .hero-style15 .hero-title {
    font-size: 52px;
    line-height: 62px;
  }
}

@media (max-width: 575px) {
  .hero-style15 .hero-title {
    font-size: 42px;
    line-height: 52px;
  }
}

@media (max-width: 375px) {
  .hero-style15 .hero-title {
    font-size: 30px;
    line-height: 40px;
  }
}

.hero-style15 .hero-text {
  max-width: 770px;
  margin-bottom: 40px;
  /* Extra large devices */
}

@media (max-width: 1500px) {
  .hero-style15 .hero-text {
    max-width: 700px;
  }
}

/* hero 15 end -------------------------------*/
/* hero 16 start -------------------------*/
.hero-16 {
  border-radius: 0 0 50px 50px;
  margin-bottom: -50px;
}

.hero-16 .th-hero-image .th-hero-thumb {
  text-align: right;
  margin-right: -50px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-16 .th-hero-image .th-hero-thumb {
    margin: 0;
  }
}

.hero-style16 {
  padding: 205px 0;
  max-width: 621px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style16 {
    padding: 120px 0 40px 0;
  }
}

.hero-style16 .sub-title {
  margin-bottom: 5px;
}

.hero-style16 .hero-title {
  font-size: 72px;
  line-height: 82px;
  margin-bottom: 30px;
  /* Medium Large devices */
  /* Large devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .hero-style16 .hero-title {
    font-size: 62px;
    line-height: 72px;
  }
}

@media (max-width: 1199px) {
  .hero-style16 .hero-title {
    font-size: 52px;
    line-height: 62px;
  }
}

@media (max-width: 575px) {
  .hero-style16 .hero-title {
    font-size: 42px;
    line-height: 52px;
  }
}

@media (max-width: 375px) {
  .hero-style16 .hero-title {
    font-size: 32px;
    line-height: 42px;
  }
}

.hero-style16 .th-btn {
  padding: 18.5px 40px;
}

.hero-style16 .btn-group {
  gap: 30px;
}

.hero-style16 .hero-counter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.hero-style16 .hero-counter_wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #E3E7F0;
  margin-top: 42px;
  padding-top: 40px;
  /* Extra small devices */
}

@media (max-width: 480px) {
  .hero-style16 .hero-counter_wrapp {
    display: block;
  }
}

@media (max-width: 375px) {
  .hero-style16 .hero-counter_wrapp {
    margin-top: 0;
    padding-top: 20px;
  }
}

.hero-style16 .hero-counter:not(:last-child) {
  border-right: 1px solid #E3E7F0;
  margin-right: 91px;
  padding-right: 91px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .hero-style16 .hero-counter:not(:last-child) {
    margin-right: 45px;
    padding-right: 45px;
  }
}

.hero-style16 .hero-counter:not(:last-child) .hero-counter_text {
  text-transform: uppercase;
}

.hero-style16 .hero-counter .counter-title {
  color: var(--theme-color);
}

.hero-style16 .hero-counter_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--title-color);
}

/* hero 9 start-------------------------------- */
.hero-17 {
  margin: 0 100px;
  border-radius: 30px;
  /* Extra large devices */
}

@media (max-width: 1699px) {
  .hero-17 {
    margin: 0 50px;
  }
}

@media (max-width: 1500px) {
  .hero-17 {
    margin: 0;
  }
}

.hero-17 .th-hero17-wrapp {
  position: relative;
  text-align: center;
  z-index: 2;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-17 .th-hero17-wrapp {
    padding-bottom: 120px;
  }
}

.hero-17 .th-hero17-wrapp .hero17-shape1 {
  position: absolute;
  left: 5%;
  bottom: 80px;
  width: 295px;
  height: 468px;
  background: #FFBE11;
  border-radius: 30px;
  background-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  z-index: -1;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-17 .th-hero17-wrapp .hero17-shape1 {
    left: 18%;
  }
}

.hero-17 .th-hero17-wrapp .hero17-shape2 {
  position: absolute;
  right: -4%;
  bottom: 60px;
  width: 403px;
  height: 640px;
  background: #407360;
  border-radius: 30px;
  border-radius: 30px;
  background-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  z-index: -2;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-17 .th-hero17-wrapp .hero17-shape2 {
    right: 10%;
  }
}

.hero-17 .th-hero17-wrapp .hero17-shape3 {
  position: absolute;
  top: 35%;
  left: -16px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-17 .th-hero17-wrapp .hero17-shape3 {
    left: 10%;
  }
}

@media (max-width: 480px) {
  .hero-17 .th-hero17-wrapp .hero17-shape3 {
    display: none;
  }
}

.hero-17 .th-hero17-wrapp .hero-client-box {
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 30px 30px 24px 30px;
  display: inline-block;
  text-align: left;
  position: absolute;
  left: -10%;
  bottom: 20%;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-17 .th-hero17-wrapp .hero-client-box {
    left: 10%;
  }
}

.hero-17 .th-hero17-wrapp .hero-client-box .cilent-box_counter {
  line-height: 40px;
  margin-top: -0.6rem;
  margin-bottom: -0.3rem;
}

.hero-17 .th-hero17-wrapp .hero-client-box .cilent-box_title {
  display: block;
  margin-bottom: 15px;
}

.hero-17 .th-hero17-wrapp .hero-client-box .client-thumb-group .thumb img {
  height: 40px;
  border: 2px solid var(--white-color);
}

.hero-17 .th-hero17-wrapp .hero-client-box .client-thumb-group .thumb:not(:first-child) {
  margin-left: -12px;
}

.hero-17 .th-hero17-wrapp .hero-client-box .client-thumb-group .thumb.icon {
  position: relative;
  background: rgba(62, 101, 243, 0.9);
  font-size: 16px;
  color: var(--white-color);
  border: 2px solid var(--white-color);
  height: 40px;
  width: 40px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  margin-left: -42px;
  z-index: 2;
}

.hero-17 .th-hero17-wrapp .hero-profile {
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 15px 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  text-align: left;
  max-width: 288px;
  position: absolute;
  left: 28.6%;
  bottom: -1px;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .hero-17 .th-hero17-wrapp .hero-profile {
    left: 23.6%;
  }
}

@media (max-width: 1199px) {
  .hero-17 .th-hero17-wrapp .hero-profile {
    bottom: 80px;
    left: 37%;
  }
}

@media (max-width: 991px) {
  .hero-17 .th-hero17-wrapp .hero-profile {
    left: 32%;
  }
}

@media (max-width: 767px) {
  .hero-17 .th-hero17-wrapp .hero-profile {
    left: 27%;
  }
}

@media (max-width: 480px) {
  .hero-17 .th-hero17-wrapp .hero-profile {
    left: 19%;
  }
}

@media (max-width: 375px) {
  .hero-17 .th-hero17-wrapp .hero-profile {
    gap: 10px;
    padding: 15px;
  }
}

.hero-17 .th-hero17-wrapp .hero-profile .avater {
  position: relative;
  min-width: 50px;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .hero-17 .th-hero17-wrapp .hero-profile .avater {
    min-width: 40px;
  }
}

.hero-17 .th-hero17-wrapp .hero-profile .avater:before {
  content: '';
  position: absolute;
  right: 2px;
  top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  border: 1px solid #FFFFFF;
}

.hero-17 .th-hero17-wrapp .hero-profile .avater img {
  border-radius: 50%;
}

.hero-17 .th-hero17-wrapp .hero-profile .box-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.hero-17 .th-hero-img img {
  -webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 0, 100% 80%, 100% 100%, 27% 100%, 0 89%, 0 0);
          clip-path: polygon(20% 0%, 80% 0%, 100% 0, 100% 80%, 100% 100%, 27% 100%, 0 89%, 0 0);
  margin-top: 80px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-17 .th-hero-img img {
    margin-top: 0;
  }
}

.hero-style17 {
  padding: 212px 0;
  max-width: 747px;
  /* Medium Large devices */
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .hero-style17 {
    padding: 210px 0;
  }
}

@media (max-width: 1199px) {
  .hero-style17 {
    text-align: center;
    max-width: 100%;
    padding: 140px 0 80px 0;
  }
}

@media (max-width: 767px) {
  .hero-style17 {
    padding: 120px 0 80px 0;
  }
}

.hero-style17 .sub-title {
  font-family: var(--title-font);
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  background-color: var(--white-color);
  box-shadow: 0px 6px 30px 6px rgba(62, 102, 243, 0.05);
  border-radius: 30px;
  display: inline-block;
  padding: 3px 17px;
}

.hero-style17 .hero-title {
  font-weight: 700;
  font-size: 74px;
  line-height: 84px;
  color: var(--title-color);
  text-transform: capitalize;
  margin-top: -1.3rem;
  margin-bottom: 18px;
  /* Medium Large devices */
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
  /* Extra small devices */
}

.hero-style17 .hero-title span {
  display: inline-block;
  position: relative;
  color: var(--theme-color);
}

.hero-style17 .hero-title span:after {
  content: "";
  height: 15px;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='215' height='19' viewBox='0 0 215 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 16.9999C37.6667 5.9999 129.8 -9.4001 213 16.9999' stroke='%23407360' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: repeat-x;
  position: absolute;
  bottom: -7px;
  left: 0;
  -webkit-animation: titleFill 3s linear infinite;
          animation: titleFill 3s linear infinite;
}

@media (max-width: 1299px) {
  .hero-style17 .hero-title {
    font-size: 70px;
    line-height: 80px;
  }
}

@media (max-width: 991px) {
  .hero-style17 .hero-title {
    font-size: 64px;
    line-height: 74px;
  }
}

@media (max-width: 767px) {
  .hero-style17 .hero-title {
    font-size: 54px;
    line-height: 64px;
  }
}

@media (max-width: 575px) {
  .hero-style17 .hero-title {
    font-size: 44px;
    line-height: 54px;
  }
}

@media (max-width: 375px) {
  .hero-style17 .hero-title {
    font-size: 34px;
    line-height: 44px;
  }
}

.hero-style17 .hero-text {
  font-family: var(--title-font);
  font-size: 20px;
  font-weight: 500;
  color: var(--body-color);
  max-width: 597px;
  margin-bottom: 30px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style17 .hero-text {
    display: block;
    margin: auto auto 30px auto;
  }
}

.hero-style17 .btn-group {
  /* Large devices */
}

@media (max-width: 1199px) {
  .hero-style17 .btn-group {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

/* hero 17 end-------------------------------- */
/* hero 18 start -------------------------------- */
.hero-18 {
  position: relative;
  background-color: #EFF1F9;
}

.hero-18:before, .hero-18:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 808px;
  height: 917px;
  z-index: 1;
  background: #B7C7FF;
  mix-blend-mode: normal;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 1;
}

.hero-18:before {
  left: 0%;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
      -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  -webkit-clip-path: polygon(100% 1%, 100% 100%, 40% 50%);
          clip-path: polygon(100% 1%, 100% 100%, 40% 50%);
}

.hero-18:after {
  right: 0%;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
      -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  -webkit-clip-path: polygon(0% 0%, 60% 50%, 0% 100%);
          clip-path: polygon(0% 0%, 60% 50%, 0% 100%);
  z-index: -1;
}

.hero-18 .ripple-shape {
  position: absolute;
  width: 1544px;
  left: 9%;
  bottom: 14%;
  z-index: -1;
}

.hero-18 .th-hero-img {
  position: relative;
  display: block;
  margin: auto;
  text-align: center;
  border-radius: 30px;
  margin-bottom: 80px;
  z-index: 3;
  max-width: 1380px;
}

.hero-18 .th-hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.hero-18 .th-hero-img .about-client-box {
  position: absolute;
  left: 0;
  bottom: 140px;
  min-width: 366px;
  background: #EFF1F9;
  padding: 20px 30px;
  border-radius: 0px 100px 100px 0px;
  margin-bottom: 0;
  /* Large devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .hero-18 .th-hero-img .about-client-box {
    bottom: 30px;
  }
}

@media (max-width: 767px) {
  .hero-18 .th-hero-img .about-client-box {
    position: relative;
    bottom: 0;
  }
}

@media (max-width: 375px) {
  .hero-18 .th-hero-img .about-client-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
  }
}

.hero-18 .social-links {
  position: absolute;
  z-index: 2;
  right: 0%;
  top: 60%;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media (max-width: 1699px) {
  .hero-18 .social-links {
    display: none;
  }
}

.hero-18 .social-links .social-title {
  color: var(--theme-color);
  font-size: 16px;
  margin-right: 10px;
}

.hero-18 .social-links a {
  display: inline-block;
  color: var(--theme-color);
  font-size: 18px;
  margin-right: 10px;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hero-18 .social-links a:hover {
  color: var(--title-color);
}

.hero-18 .swiper-pagination-bullets {
  position: relative;
  z-index: 3;
  text-align: center;
  margin: 25px 0 0px 0;
  line-height: 10px;
  height: 3px;
  /* Extra large devices */
}

@media (max-width: 1500px) {
  .hero-18 .swiper-pagination-bullets {
    display: none !important;
  }
}

.hero-18 .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  --swiper-pagination-bullet-size: 24px;
  --swiper-pagination-bullet-horizontal-gap: 12px;
  margin: 5px 7px;
  height: 2px;
  opacity: 1;
  border-radius: 0;
  background-color: var(--white-color);
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}

.hero-18 .swiper-pagination-bullets .swiper-pagination-bullet:before {
  display: none;
}

.hero-18 .swiper-pagination-bullets .swiper-pagination-bullet:hover, .hero-18 .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--theme-color);
}

.hero-18 .swiper-pagination-bullets .swiper-pagination-bullet:first-child {
  margin-left: 0;
}

.hero-18 .swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.hero-18 .slider-pagination {
  position: absolute;
  z-index: 2;
  left: 4%;
  top: 62%;
  width: auto;
  margin: 0;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}

.hero-18 .slider-pagination .swiper-pagination-bullets .swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  background: var(--white-color);
  height: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hero-style18 {
  text-align: center;
  max-width: 1030px;
  display: block;
  margin: auto;
  padding: 60px 0 80px 0;
}

.hero-style18 .hero-title {
  font-size: 72px;
  /* Large devices */
  /* Medium devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .hero-style18 .hero-title {
    font-size: 62px;
  }
}

@media (max-width: 991px) {
  .hero-style18 .hero-title {
    font-size: 46px;
  }
}

@media (max-width: 575px) {
  .hero-style18 .hero-title {
    font-size: 36px;
  }
}

@media (max-width: 375px) {
  .hero-style18 .hero-title {
    font-size: 28px;
  }
}

/* hero 18 end-------------------------------- */
/*------------------- 4.9. Error  -------------------*/
.error-content {
  text-align: center;
}

.error-img {
  text-align: center;
  margin-bottom: 45px;
}

.error-title {
  margin-bottom: 20px;
}

.error-text {
  margin-bottom: 35px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

/* Medium devices */
@media (max-width: 991px) {
  .error-title {
    margin-bottom: 15px;
  }
  .error-img {
    margin-bottom: 30px;
  }
  .error-text {
    margin-bottom: 25px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .error-title {
    margin-bottom: 10px;
  }
}

/*------------------- 4.00. Popup Search  -------------------*/
.popup-search-box {
  position: fixed;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.95);
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.popup-search-box button.searchClose {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 40px;
  right: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--theme-color);
  background-color: transparent;
  font-size: 22px;
  border-radius: 50%;
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  color: var(--theme-color);
}

.popup-search-box button.searchClose:hover {
  color: var(--body-color);
  background-color: #fff;
  border-color: transparent;
  border-color: transparent;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}

.popup-search-box form {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding-bottom: 40px;
  cursor: auto;
  width: 100%;
  max-width: 700px;
  -webkit-transform: translate(-50%, -50%) scale(0);
      -ms-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  -webkit-transition: -webkit-transform ease 0.4s;
  transition: -webkit-transform ease 0.4s;
  transition: transform ease 0.4s;
  transition: transform ease 0.4s, -webkit-transform ease 0.4s;
  /* Large devices */
}

@media (max-width: 1199px) {
  .popup-search-box form {
    max-width: 600px;
  }
}

.popup-search-box form input {
  font-size: 18px;
  height: 70px;
  width: 100%;
  border: 2px solid var(--theme-color);
  background-color: transparent;
  padding-left: 30px;
  color: #fff;
  border-radius: 50px;
}

.popup-search-box form input::-moz-placeholder {
  color: #fff;
}

.popup-search-box form input::-webkit-input-placeholder {
  color: #fff;
}

.popup-search-box form input:-ms-input-placeholder {
  color: #fff;
}

.popup-search-box form input::-ms-input-placeholder {
  color: #fff;
}

.popup-search-box form input::placeholder {
  color: #fff;
}

.popup-search-box form button {
  position: absolute;
  top: 0px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  right: 12px;
  color: var(--white-color);
  cursor: pointer;
  width: 70px;
  height: 70px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-transform: scale(1.001);
      -ms-transform: scale(1.001);
          transform: scale(1.001);
}

.popup-search-box form button:hover {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.popup-search-box.show {
  opacity: 1;
  visibility: visible;
  width: 100.1%;
  height: 100%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  border-radius: 0;
}

.popup-search-box.show form {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
  -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

/*------------------- 4.00. Popup Side Menu  -------------------*/
.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}

.sidemenu-wrapper .th-social a {
  background-color: transparent;
  border: 1px solid;
}

.sidemenu-wrapper .th-social a:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.sidemenu-wrapper .closeButton {
  display: inline-block;
  border: 2px solid;
  width: 50px;
  height: 50px;
  line-height: 48px;
  font-size: 24px;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--white-color);
  border-radius: 50%;
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.sidemenu-wrapper .closeButton:hover {
  color: var(--theme-color);
  border-color: var(--theme-color);
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}

.sidemenu-wrapper .sidemenu-content {
  background-color: var(--white-color);
  width: 450px;
  margin-left: auto;
  padding: 80px 30px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
  -webkit-transition: right ease 1s;
  transition: right ease 1s;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .sidemenu-wrapper .sidemenu-content {
    width: 320px;
  }
}

.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
}

.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #F5F5F5;
}

.sidemenu-wrapper .widget {
  padding: 0;
  border: none;
  background-color: transparent;
}

.sidemenu-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
}

.sidemenu-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}

/*------------------- 4.00. Wocommerce  -------------------*/
.woocommerce-message,
.woocommerce-info {
  position: relative;
  padding: 11px 20px 11px 50px;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  border-radius: 5px;
}

.woocommerce-message a,
.woocommerce-info a {
  color: var(--white-color);
  text-decoration: underline;
}

.woocommerce-message a:hover,
.woocommerce-info a:hover {
  color: var(--title-color);
}

.woocommerce-message:before,
.woocommerce-info:before {
  content: '\f06a';
  font-family: var(--icon-font);
  font-weight: 400;
  margin-right: 10px;
  font-size: 18px;
  position: absolute;
  left: 20px;
  top: 11px;
}

.woocommerce-notices-wrapper .woocommerce-message {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.woocommerce-notices-wrapper .woocommerce-message:before {
  content: '\f14a';
  font-weight: 300;
}

.woocommerce-form-login-toggle .woocommerce-info {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.woocommerce-form-login-toggle .woocommerce-info a {
  color: inherit;
}

.woocommerce-form-login-toggle .woocommerce-info a:hover {
  color: var(--title-color);
}

.woocommerce-form-register,
.woocommerce-form-coupon,
.woocommerce-form-login {
  padding: 35px 40px 35px 40px;
  background-color: var(--white-color);
  box-shadow: 0px 6px 30px rgba(1, 15, 28, 0.1);
  margin-bottom: 0;
  border-radius: 10px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .woocommerce-form-register,
  .woocommerce-form-coupon,
  .woocommerce-form-login {
    padding: 40px 20px;
  }
}

.woocommerce-form-register .form-group,
.woocommerce-form-coupon .form-group,
.woocommerce-form-login .form-group {
  margin-bottom: 20px;
}

.woocommerce-form-register .form-group:last-child,
.woocommerce-form-coupon .form-group:last-child,
.woocommerce-form-login .form-group:last-child {
  margin-bottom: 0;
}

.woocommerce-form-login {
  margin-bottom: 30px;
}

.woocommerce-error {
  background-color: var(--error-color);
  color: #fff;
  list-style: none;
  padding: 10px 26px;
  margin: 0 0 30px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}

nav.woocommerce-MyAccount-navigation li {
  border: 1px solid #ddd;
  margin: 0;
  border-top: none;
}

nav.woocommerce-MyAccount-navigation li:first-child {
  border-top: 1px solid #ddd;
}

nav.woocommerce-MyAccount-navigation li a {
  color: var(--title-color);
  font-weight: 700;
  padding: 7px 17px;
  display: block;
}

nav.woocommerce-MyAccount-navigation li.is-active a,
nav.woocommerce-MyAccount-navigation li a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.woocommerce-MyAccount-content h3 {
  margin-top: -0.3em;
}

.woocommerce-MyAccount-content .btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  padding: 10px 25px;
  font-weight: 700;
}

.woocommerce-MyAccount-content .btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

table.variations,
.woocommerce-grouped-product-list-item {
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
}

table.variations td,
.woocommerce-grouped-product-list-item td {
  border: none;
  vertical-align: middle;
  padding: 0 5px;
}

table.variations td:first-child,
.woocommerce-grouped-product-list-item td:first-child {
  padding: 0;
}

table.variations label,
.woocommerce-grouped-product-list-item label {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}

table.variations label a,
.woocommerce-grouped-product-list-item label a {
  color: var(--title-color);
}

table.variations label a:hover,
.woocommerce-grouped-product-list-item label a:hover {
  color: var(--theme-color);
}

table.variations .label,
.woocommerce-grouped-product-list-item .label {
  border: none;
}

table.variations__label,
.woocommerce-grouped-product-list-item__label {
  border: none !important;
  font-weight: 600;
}

table.variations__price,
.woocommerce-grouped-product-list-item__price {
  border: none !important;
}

table.variations__price .price,
table.variations__price .amount,
.woocommerce-grouped-product-list-item__price .price,
.woocommerce-grouped-product-list-item__price .amount {
  font-size: 18px !important;
}

table.variations del,
.woocommerce-grouped-product-list-item del {
  margin-left: 12px;
}

.woocommerce-product-attributes th,
.woocommerce-product-attributes td {
  border: 1px solid var(--border-color);
}

.woocommerce-product-attributes th p:last-child,
.woocommerce-product-attributes td p:last-child {
  margin-bottom: 0;
}

.woocommerce-grouped-product-list.group_table {
  border-collapse: collapse;
  margin-bottom: 15px;
}

.woocommerce-grouped-product-list.group_table .woocommerce-Price-amount.amount {
  font-size: 16px;
  color: var(--title-color);
}

.woocommerce-grouped-product-list.group_table label {
  margin: 0 0 0 10px;
  margin: 0 0 0 10px;
  font-family: var(--title-font);
  font-size: 18px;
}

.woocommerce-grouped-product-list.group_table .qty-input {
  border-color: #e3e6e9;
}

.woocommerce-grouped-product-list.group_table tr {
  border-bottom: 1px solid #e3e6e9;
}

.woocommerce-grouped-product-list.group_table tr:last-child {
  border-bottom: none;
}

.woocommerce-grouped-product-list.group_table td {
  padding: 30px 5px;
}

table.variations {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}

table.variations td {
  padding: 0;
}

table.variations td.label {
  padding-right: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

table.variations select {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  margin: 0;
  padding-right: 54px;
  padding-left: 20px;
  height: 50px;
}

table.variations .reset_variations {
  margin-left: 16px;
  display: inline-block;
  position: absolute;
  left: 100%;
  bottom: 25px;
}

.woosq-product .product .woocommerce-grouped-product-list-item__quantity,
.woosq-product .product .woocommerce-grouped-product-list-item__label,
.woosq-product .product .woocommerce-grouped-product-list-item__price {
  width: auto !important;
}

.woocommerce-variation.single_variation {
  margin-bottom: 30px;
}

.woocommerce-variation.single_variation .price {
  color: var(--title-color);
  font-weight: 700;
}

.wooscp-table-items td.woocommerce-product-attributes-item__value {
  padding-left: 15px !important;
}

.wooscp-table-items a.added_to_cart.wc-forward {
  margin-left: 15px;
  text-decoration: underline;
}

.tinvwl_added_to_wishlist.tinv-modal.tinv-modal-open {
  z-index: 1111;
}

table.woocommerce-product-attributes {
  margin-bottom: 30px;
}

#woosq-popup .product_meta {
  margin-top: 20px;
}

#woosq-popup .product_title {
  font-size: 24px;
  margin-bottom: 5px;
}

#woosq-popup .single-product .product .actions {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

#woosq-popup .single-product .product .actions > div {
  height: auto;
  overflow: visible;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

#woosq-popup .single-product .product .actions > div .quantity.style2.woocommerce-grouped-product-list-item__quantity {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.login-tab {
  margin-bottom: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.login-tab button.nav-link {
  background-color: var(--smoke-color);
  color: var(--title-color);
  padding: 11px 39px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 15px ​15px 0;
}

.login-tab button.nav-link.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.star-rating {
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 1.2em;
  line-height: 1.2em;
  display: block;
  font-family: var(--icon-font);
  font-weight: 700;
  font-size: 14px;
}

.star-rating:before {
  content: "\e28b\e28b\e28b\e28b\e28b";
  color: #e1e1e1;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  letter-spacing: 3px;
}

.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}

.star-rating span:before {
  content: "\e28b\e28b\e28b\e28b\e28b";
  top: 0;
  position: absolute;
  left: 0;
  color: var(--theme-color);
  letter-spacing: 3px;
}

.rating-select label {
  margin: 0;
  margin-right: 10px;
}

.rating-select p.stars {
  margin-bottom: 0;
  line-height: 1;
}

.rating-select p.stars a {
  position: relative;
  height: 14px;
  width: 18px;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
}

.rating-select p.stars a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 14px;
  line-height: 1;
  font-family: var(--icon-font);
  content: "\e28b";
  font-weight: 400;
  text-indent: 0;
  color: var(--yellow-color);
}

.rating-select p.stars a:hover ~ a::before {
  content: "\e28b";
  font-weight: 400;
}

.rating-select p.stars:hover a::before {
  content: "\e28b";
  font-weight: 700;
}

.rating-select p.stars.selected a.active::before {
  content: "\e28b";
  font-weight: 700;
}

.rating-select p.stars.selected a.active ~ a::before {
  content: "\e28b";
  font-weight: 400;
}

.rating-select p.stars.selected a:not(.active)::before {
  content: "\e28b";
  font-weight: 700;
}

/* Small devices */
@media (max-width: 767px) {
  .woocommerce-message,
  .woocommerce-info {
    font-size: 14px;
    line-height: 22px;
    padding: 10px 15px 10px 37px;
  }
  .woocommerce-message:before,
  .woocommerce-info:before {
    font-size: 16px;
    top: 10px;
    left: 15px;
  }
}

/*------------------- 4.00. Products  -------------------*/
.th-product {
  text-align: center;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.th-product .product-title {
  font-size: 20px;
  margin: 10px 0 10px 0;
}

.th-product .product-title a {
  color: inherit;
}

.th-product .product-title a:hover {
  color: var(--theme-color);
}

.th-product .price {
  display: block;
  color: var(--body-color);
  font-weight: 500;
  margin-bottom: -0.4em;
  font-family: var(--body-font);
}

.th-product .price del {
  margin-left: 10px;
  color: #a9a9a9;
}

.th-product .product-img {
  --space: 20px;
  background-color: var(--smoke-color);
  overflow: hidden;
  position: relative;
  margin: 0 0 25px 0;
  text-align: center;
  background-color: var(--smoke-color);
  z-index: 2;
  border-radius: 5px;
}

.th-product .product-img:before {
  --space: 20px;
  content: '';
  height: calc(100% - var(--space)*2);
  width: calc(100% - var(--space)*2);
  border-radius: inherit;
  position: absolute;
  top: var(--space);
  left: var(--space);
  background-color: var(--title-color);
  z-index: 1;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.th-product .product-img img {
  width: 100%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.th-product .product-img .tag {
  font-size: 14px;
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0 12px;
  min-width: 60px;
  z-index: 3;
  border-radius: 3px;
  line-height: 24px;
}

.th-product .star-rating {
  margin: 0 auto 5px auto;
  width: 93px;
}

.th-product .actions {
  height: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 3;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.th-product .actions .icon-btn {
  --btn-size: 40px;
  font-size: 14px;
  border-color: var(--white-color);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  color: var(--white-color);
}

.th-product .actions .icon-btn:hover {
  color: var(--title-color);
  border-color: var(--white-color);
}

.th-product .actions > * {
  margin: 0 var(--icon-gap-x, 5px);
}

.th-product .actions > * > a {
  margin: 0;
}

.th-product .icon-btn {
  -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.th-product .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.th-product .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
}

.th-product .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.th-product .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
  position: relative;
  top: 0;
  left: 0;
  line-height: inherit;
  margin: 0;
  font-size: 24px;
}

.th-product .tinv-wishlist a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: transparent;
  color: var(--white-color);
  border-radius: 4px;
}

.th-product .tinv-wishlist a:hover {
  background-color: var(--white-color);
  color: var(--title-color);
}

.th-product .add_to_cart_button.added {
  display: none;
}

.th-product .added_to_cart {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--white-color);
  color: var(--title-color);
  font-size: 0;
  text-align: center;
  border-radius: 4px;
}

.th-product .added_to_cart:after {
  content: "\f07a";
  position: relative;
  font-family: var(--icon-font);
  font-size: 16px;
  font-weight: 700;
}

.th-product .added_to_cart:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.th-product .action-btn {
  background-color: var(--white-color);
  font-size: 14px;
  font-family: var(--title-font);
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  padding: 13px 25px;
}

.th-product:hover .product-img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.th-product:hover .product-img:before {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
  visibility: visible;
  opacity: 0.7;
}

.th-product:hover .actions {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.th-product:hover .icon-btn {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.th-product.list-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  height: 100%;
}

.th-product.list-view .product-img {
  width: 100%;
  max-width: 200px;
  margin: 0;
}

.th-product.list-view .star-rating {
  margin: 0 auto 10px 0;
  width: 93px;
}

.th-product.list-view .product-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: 1px solid var(--th-border-color);
  border-left: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0 25px;
}

.th-product.list-view .actions {
  --btn-size: 35px;
  --btn-font-size: 13px;
  --icon-gap-x: 2px;
}

.th-product.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.th-product.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.th-product.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.th-product.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
  font-size: 20px;
}

.th-product.list-view .tinv-wishlist a {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.th-product.list-view .added_to_cart {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.th-product.list-view .added_to_cart:after {
  font-size: 16px;
}

.th-product.list-view .action-btn {
  padding: 8px 15px;
}

.th-product.list-view .tag {
  top: 8px;
  right: 8px;
  padding: 0px 15px;
}

.th-product.list-view .product-title {
  font-size: 18px;
  margin: 0 0 5px 0;
}

.th-product.list-view .product-price {
  font-size: 14px;
}

#productCarousel .slick-arrow {
  top: 37.5%;
}

.mfp-content {
  margin: 1.5rem auto;
}

.mfp-content .product-details-img {
  padding-top: 15px;
}

.mfp-content .product-about {
  padding-top: 20px;
  padding-bottom: 20px;
}

.mfp-content .container {
  position: relative;
}

.mfp-content .product-big-img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.woosq-popup {
  max-height: 600px;
  max-width: 1220px;
}

.woosq-popup .product_meta > span > a:after,
.woosq-popup .product_meta > span > span:after {
  display: none;
}

.woosq-product > .product > div {
  max-height: 600px;
  min-height: 460px;
  height: auto;
}

.th-sort-bar {
  padding: 10px;
  margin: 0 0 24px 0;
  background-color: var(--smoke-color);
  border-radius: 5px;
}

.th-sort-bar .row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 15px;
}

.th-sort-bar select {
  height: 50px;
  border: 1px solid var(--th-border-color);
  background-color: var(--white-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 250px;
  font-size: 16px;
  margin: 0;
  color: var(--body-color);
}

.th-sort-bar .woocommerce-result-count {
  padding-left: 15px;
  margin-bottom: 0;
  color: var(--body-color);
}

.th-sort-bar .nav a {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border: 1px solid var(--th-border-color);
  background-color: var(--white-color);
  text-align: center;
  position: relative;
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--body-color);
  margin: 0 0 0 10px;
}

.th-sort-bar .nav a.active, .th-sort-bar .nav a:hover {
  color: var(--theme-color);
}

.product-thumb-area {
  position: relative;
  margin-right: 10px;
}

.product-thumb-area .product-thumb-tab {
  position: absolute;
  top: 0;
  left: 0;
}

.product-thumb-tab {
  --thumb: 120px;
  --gap: 10px;
  max-width: var(--thumb);
  margin-left: calc(0px - var(--thumb)/2);
  position: relative;
  z-index: 3;
  display: grid;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  height: 100%;
}

.product-thumb-tab .tab-btn {
  background-color: var(--white-color);
  cursor: pointer;
  height: var(--thumb);
  width: var(--thumb);
  padding: var(--gap);
  border-radius: 10px;
}

.product-thumb-tab .tab-btn img {
  max-width: 100%;
  border-radius: inherit;
}

.product-thumb-tab .tab-btn:not(:last-of-type) {
  margin-bottom: 20px;
}

.product-thumb-tab .indicator {
  position: absolute;
  top: calc(var(--pos-y) + var(--gap));
  left: calc(var(--pos-x) + var(--gap));
  width: calc(var(--width-set) - var(--gap)*2);
  height: calc(var(--height-set) - var(--gap)*2);
  border: 2px solid var(--theme-color);
  border-radius: 10px;
  pointer-events: none;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.product-big-img {
  background-color: var(--smoke-color2);
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
}

.product-big-img .img {
  width: 100%;
}

.product-big-img .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quantity {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.quantity > .screen-reader-text {
  display: inline-block;
  font-weight: 600;
  color: var(--title-color);
  font-family: var(--title-font);
  margin: 0;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  margin-right: 10px;
}

.quantity .qty-btn,
.quantity .qty-input {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: none;
  border-right: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  text-align: center;
  color: var(--body-color);
  font-size: 18px;
  font-weight: 600;
}

.quantity .qty-btn:last-child,
.quantity .qty-input:last-child {
  border-right: none;
}

.quantity .qty-btn {
  font-size: 16px;
}

.product_meta {
  font-weight: 700;
  font-size: 16px;
  font-family: var(--body-font);
  margin: 35px 0 0 0;
}

.product_meta > span {
  display: block;
  margin-bottom: 5px;
  color: var(--title-color);
  font-weight: bold;
}

.product_meta > span:last-child {
  margin-bottom: 0;
}

.product_meta > span a {
  color: inherit;
}

.product_meta > span a:hover {
  color: var(--theme-color);
}

.product_meta > span > a,
.product_meta > span > span {
  position: relative;
  color: var(--body-color);
  font-weight: 400;
}

.product_meta > span > a:after,
.product_meta > span > span:after {
  content: ",";
  margin-right: 5px;
}

.product_meta > span > a:last-child:after,
.product_meta > span > span:last-child:after {
  display: none;
}

.product_meta > span > a:first-child,
.product_meta > span > span:first-child {
  margin-left: 7px;
}

.product-tab-style1 {
  border-bottom: 1px solid var(--smoke-color2);
  margin: 95px auto 40px auto;
  padding-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 24px;
}

.woocommerce-Reviews .th-post-comment .text {
  margin-bottom: -0.5em;
}

.woocommerce-Reviews .th-comments-wrap {
  padding: 0;
  box-shadow: none;
  margin-right: 0;
}

.woocommerce-Reviews .th-comment-form {
  padding: 60px;
  background-color: var(--smoke-color);
  box-shadow: none;
  border-radius: 10px;
  margin-right: 0;
}

.woocommerce-Reviews .th-comment-form input,
.woocommerce-Reviews .th-comment-form .form-control {
  background-color: var(--white-color);
}

.woocommerce-Reviews .th-comment-form .blog-inner-title {
  margin-bottom: 10px;
}

.woocommerce-Reviews .comment-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 24px;
  margin-bottom: 40px;
}

.woocommerce-Reviews .th-post-comment {
  border: 1px solid var(--th-border-color) !important;
  padding: 40px !important;
  box-shadow: 0px 4px 30px rgba(1, 15, 28, 0.06);
  margin-bottom: 24px;
  border-radius: 8px;
}

.woocommerce-Reviews .th-post-comment .name {
  margin-bottom: 3px;
}

.woocommerce-Reviews .th-post-comment .commented-on {
  margin-bottom: 12px;
}

/* Large devices */
@media (max-width: 1199px) {
  .woocommerce-Reviews .th-post-comment {
    padding: 30px !important;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .woocommerce-Reviews .comment-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .woocommerce-Reviews .th-comment-form {
    padding: 40px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .woocommerce-Reviews .th-comment-form {
    padding: 40px 20px;
  }
}

#productTabContent {
  margin-bottom: -10px;
}

#additional_information {
  margin-bottom: 40px;
}

.product-inner-list > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.product-inner-list li {
  position: relative;
  padding-left: 15px;
}

.product-inner-list li:before {
  content: "-";
  position: absolute;
  left: 0;
}

.share-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--title-color);
  margin-bottom: 8px;
}

.share-title i {
  color: var(--theme-color);
}

.summary-content .product-title,
.product-about .product-title {
  margin: 0 0 12px 0;
}

.summary-content > .price,
.product-about > .price {
  font-family: var(--title-font);
  font-size: 20px;
  font-weight: 800;
  color: var(--title-color);
  display: inline-block;
  margin-bottom: 14px;
}

.summary-content > .price del,
.product-about > .price del {
  color: var(--gray-color);
  font-weight: 500;
  margin-left: 15px;
}

.summary-content .product-rating,
.product-about .product-rating {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  top: 2px;
  font-size: 16px;
  line-height: 20px;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  margin-bottom: 14px;
}

.summary-content .product-rating .star-rating,
.product-about .product-rating .star-rating {
  width: 80px;
  font-size: 12px;
  margin-right: 8px;
}

.summary-content .woocommerce-review-link,
.product-about .woocommerce-review-link {
  color: var(--body-color);
}

.summary-content .checklist,
.product-about .checklist {
  margin: 30px 0 40px 0;
}

.summary-content .actions,
.product-about .actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 15px;
  margin: 33px 0 27px 0;
}

.summary-content .actions .th-btn .btn-icon,
.product-about .actions .th-btn .btn-icon {
  padding: 10.5px 15px 10.5px 15px;
}

.summary-content .actions .icon-btn,
.product-about .actions .icon-btn {
  border-color: var(--th-border-color);
}

.summary-content .actions .icon-btn:hover,
.product-about .actions .icon-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}

.summary-content .share,
.product-about .share {
  margin-top: 25px;
}

.summary-content .th-social a,
.product-about .th-social a {
  --icon-size: 40px;
  line-height: 38px;
  font-size: 14px;
  border: 1px solid var(--th-border-color);
  border-radius: 0;
}

.summary-content .th-social a:hover,
.product-about .th-social a:hover {
  border-color: var(--theme-color);
}

.summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt,
.product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  border-radius: 4px;
}

.summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before,
.product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
  position: relative;
  top: 0;
  left: 0;
  line-height: inherit;
  margin: 0;
  font-size: 24px;
}

.summary-content .tinv-wishlist a,
.product-about .tinv-wishlist a {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 0;
}

.summary-content .tinv-wishlist a:hover,
.product-about .tinv-wishlist a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.summary-content .quantity,
.product-about .quantity {
  position: relative;
}

.summary-content .quantity:after,
.product-about .quantity:after {
  content: '';
  height: 100%;
  width: 1px;
  background-color: var(--th-border-color);
  position: absolute;
  top: 0;
  left: 55px;
}

.summary-content .quantity .qty-input,
.product-about .quantity .qty-input {
  width: 112px;
  height: 56px;
  border: 1px solid var(--th-border-color);
  background-color: transparent;
  color: var(--title-color);
  padding: 0 21px;
  text-align: left;
  font-weight: 500;
  border-radius: 5px;
}

.summary-content .quantity .qty-btn,
.product-about .quantity .qty-btn {
  color: var(--title-color);
  background-color: transparent;
  position: absolute;
  right: 21px;
  height: auto;
  width: auto;
  border: none;
}

.summary-content .quantity .quantity-minus,
.product-about .quantity .quantity-minus {
  bottom: 8px;
}

.summary-content .quantity .quantity-plus,
.product-about .quantity .quantity-plus {
  top: 8px;
}

.woocommerce-Reviews .th-post-comment {
  border-radius: 20px;
}

.woocommerce-Reviews .th-post-comment .text {
  margin-bottom: -0.5em;
}

.woocommerce-Reviews .th-comments-wrap {
  padding: 0;
  margin-right: 0 !important;
  box-shadow: none;
  margin: 0;
}

.woocommerce-Reviews .th-comment-form {
  padding: 60px;
  background-color: var(--smoke-color);
  box-shadow: none;
  margin-right: 0 !important;
  margin-top: 28px;
  margin-bottom: 40px;
}

.woocommerce-Reviews .th-comment-form input,
.woocommerce-Reviews .th-comment-form .form-control {
  background-color: var(--white-color);
}

.woocommerce-Reviews .th-comment-form .blog-inner-title {
  margin-bottom: 10px;
}

.woocommerce-Reviews .comment-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 0 24px;
  margin: -12px !important;
  margin-bottom: 28px !important;
}

.woocommerce-Reviews .comment-list li {
  width: 50%;
  /* Medium devices */
}

@media (max-width: 991px) {
  .woocommerce-Reviews .comment-list li {
    width: 100%;
  }
}

.woocommerce-Reviews .th-post-comment {
  border: 0 !important;
  padding: 40px !important;
  margin: 12px;
  background: var(--smoke-color);
  border-radius: 0px;
  box-shadow: none;
}

.woocommerce-Reviews .th-post-comment .name {
  margin-bottom: 3px;
}

.woocommerce-Reviews .th-post-comment .commented-on {
  margin-bottom: 12px;
}

/* Large devices */
@media (max-width: 1199px) {
  .woocommerce-Reviews .th-post-comment {
    padding: 30px !important;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .woocommerce-Reviews .comment-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .woocommerce-Reviews .th-comment-form {
    padding: 40px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .woocommerce-Reviews .th-comment-form {
    padding: 40px 20px;
  }
}

#description {
  margin-bottom: 30px;
}

.product-details .th-comments-wrap {
  margin-top: 0;
}

.product-details .border-title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.product-details .border-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 80px;
  background-color: var(--theme-color);
}

.product-inner-title {
  font-size: 32px;
  border-bottom: 1px solid var(--th-border-color);
  padding: 0 0 7px 0;
  margin: 0 0 30px 0;
}

.related-product-wrapper {
  padding-top: 115px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .th-product.list-view .product-img {
    max-width: 150px;
  }
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .product-thumb-tab {
    --thumb: 100px;
    margin-left: -40px;
  }
  .product-thumb-area {
    margin-right: 0;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .th-sort-bar select {
    min-width: auto;
  }
  .product-tab-style1 {
    margin: 55px 0 40px 0;
  }
  .summary-content,
  .product-about {
    padding-left: 0;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .product-big-img {
    margin-bottom: 40px;
  }
  .product-thumb-tab {
    margin-left: -10px;
  }
  .th-product-box.list-view .product-img {
    max-width: 150px;
  }
  .th-sort-bar .row {
    --bs-gutter-x: 20px;
  }
  .th-sort-bar .nav a:last-child {
    margin-right: 0;
    padding-right: 0;
  }
  .th-sort-bar .nav a:last-child:before {
    display: none;
  }
  .woosq-product > .product .thumbnails {
    max-height: 400px;
    min-height: 200px;
    padding: 10px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .th-sort-bar {
    text-align: center;
    padding: 10px 15px 15px 15px;
  }
  .th-sort-bar .nav {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .th-sort-bar select {
    margin: 0 auto;
  }
  .th-sort-bar .woocommerce-result-count {
    padding-left: 0;
  }
  .th-product-box.list-view .product-img {
    max-width: 130px;
  }
  .th-product-box.list-view .actions {
    --btn-size: 30px;
    --btn-font-size: 10px;
    --icon-gap-x: 2px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .product-about .actions {
    gap: 15px 15px;
  }
  .product-thumb-tab {
    --thumb: 74px;
    --gap: 6px;
  }
  .product-thumb-tab .tab-btn {
    border-radius: 6px;
  }
  .product-thumb-tab .tab-btn:not(:last-of-type) {
    margin-bottom: 10px;
  }
  .product-thumb-tab .indicator {
    border-radius: 6px;
  }
  .product-grid {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .th-product.list-view .product-img {
    max-width: 130px;
  }
}

button.tinvwl_button_close.th-btn .ftinvwl.ftinvwl-times,
button.tinvwl_button_view.th-btn .ftinvwl.ftinvwl-heart-o {
  top: -3px;
}

/*------------------- 4.00. Cart  -------------------*/
.woocommerce-cart-form {
  text-align: center;
}

.cart_table {
  border: 1px solid #eaf0f2;
  margin-bottom: 45px;
}

.cart_table thead {
  background-color: #ecf0f1;
}

.cart_table thead th {
  border: none !important;
}

.cart_table td:before,
.cart_table th {
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 800;
  border: none;
  padding: 27px 15px;
}

.cart_table td:before {
  content: attr(data-title);
  position: absolute;
  left: 15px;
  top: 50%;
  vertical-align: top;
  padding: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  display: none;
}

.cart_table td {
  border: none;
  border-bottom: 1px solid #f3f3f3;
  color: #8b8b8b;
  padding: 20px 10px;
  position: relative;
  vertical-align: middle;
}

.cart_table .product-quantity {
  color: var(--title-color);
}

.cart_table .product-quantity input {
  position: relative;
  top: -2px;
}

.cart_table .cart-productname {
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
}

.cart_table .cart-productimage {
  display: inline-block;
  border: 2px solid var(--smoke-color);
}

.cart_table .remove {
  color: var(--theme-color);
  font-size: 18px;
}

.cart_table .quantity {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.cart_table .qty-btn {
  border: 2px solid var(--smoke-color) !important;
  background-color: transparent;
  color: #b8c6d0;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 16px;
  border-radius: 4px;
}

.cart_table .qty-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.cart_table .qty-input {
  vertical-align: middle;
  border: 2px solid var(--smoke-color);
  width: 70px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: var(--title-color);
  font-weight: 700;
  margin: 0 10px;
  border-radius: 4px;
  padding: 0;
  /* Firefox */
}

.cart_table .qty-input::-moz-placeholder {
  color: var(--title-color);
}

.cart_table .qty-input::-webkit-input-placeholder {
  color: var(--title-color);
}

.cart_table .qty-input:-ms-input-placeholder {
  color: var(--title-color);
}

.cart_table .qty-input::-ms-input-placeholder {
  color: var(--title-color);
}

.cart_table .qty-input::placeholder {
  color: var(--title-color);
}

.cart_table .qty-input::-webkit-outer-spin-button, .cart_table .qty-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart_table .qty-input[type="number"] {
  -moz-appearance: textfield;
}

.cart_table .actions {
  text-align: right;
  vertical-align: middle;
}

.cart_table .actions > .th-btn {
  font-size: 16px;
  padding: 20px 28px;
  margin-right: 15px;
}

.cart_table .actions > .th-btn:last-child {
  margin-right: 0;
}

.cart_table .th-cart-coupon {
  float: left;
  margin: 0;
  width: 455px;
  max-width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.cart_table .th-cart-coupon input {
  width: calc(100% - 200px);
  margin-right: 10px;
}

.cart_table .th-cart-coupon .th-btn {
  font-size: 16px;
  padding: 20px 25px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.cart_totals {
  border: 1px solid #ecf0f1;
}

.cart_totals th,
.cart_totals td {
  vertical-align: top;
  padding: 20px 20px;
  border: none;
  border-bottom: 1px solid #ecf0f1;
  font-size: 14px;
  color: var(--title-color);
  width: 55%;
}

.cart_totals th:first-child,
.cart_totals td:first-child {
  width: 45%;
  background-color: #f9fbfb;
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}

.cart_totals .shipping-calculator-button {
  display: inline-block;
  border-bottom: 1px solid;
  color: var(--title-color);
  font-weight: 700;
}

.cart_totals .shipping-calculator-button:hover {
  color: var(--theme-color);
}

.cart_totals .woocommerce-shipping-destination {
  margin-bottom: 10px;
}

.cart_totals .woocommerce-shipping-methods {
  margin-bottom: 0;
}

.cart_totals .shipping-calculator-form {
  display: none;
}

.cart_totals .shipping-calculator-form p:first-child {
  margin-top: 20px;
}

.cart_totals .shipping-calculator-form p:last-child {
  margin-bottom: 0;
}

.cart_totals .shipping-calculator-form .th-btn {
  padding: 5px 30px;
}

.cart_totals .amount {
  font-weight: 700;
}

.cart_totals .order-total .amount {
  color: var(--theme-color);
}

/* Medium devices */
@media (max-width: 991px) {
  .cart_table th {
    padding: 23px 8px;
    font-size: 14px;
  }
  .cart_table .cart-productname {
    font-size: 14px;
  }
  .cart_table .th-cart-coupon {
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .cart_table .actions {
    text-align: center;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 20px;
    border: none;
  }
  .cart_table thead {
    display: none;
  }
  .cart_table td {
    padding: 15px;
    display: block;
    width: 100%;
    padding-left: 25%;
    text-align: right;
    border: 1px solid #f3f3f3;
    border-bottom: none;
  }
  .cart_table td::before {
    display: block;
  }
  .cart_table td:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
  .cart_table td.actions {
    padding-left: 15px;
    text-align: center;
  }
  .cart_table td.actions > .th-btn {
    margin-top: 10px;
    margin-right: 0;
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  .cart_table td.actions > .th-btn:last-child {
    margin-right: auto;
  }
  .cart_table .th-cart-coupon {
    width: 100%;
    text-align: center;
    float: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: block;
    padding-bottom: 10px;
  }
  .cart_table .th-cart-coupon input {
    width: 100%;
    margin-bottom: 10px;
  }
  .cart_totals th,
  .cart_totals td {
    padding: 15px 10px;
  }
  .cart_totals th:first-child,
  .cart_totals td:first-child {
    width: 17%;
    line-height: 1.4;
  }
}

/*------------------- 4.00. Checkout  -------------------*/
.woocommerce-checkout .form-group,
.woocommerce-checkout .form-row {
  margin-bottom: 0;
}

.woocommerce-checkout .form-select,
.woocommerce-checkout .select2-container,
.woocommerce-checkout .form-control {
  margin-bottom: 0;
}

.woocommerce-checkout .select2-container--open .select2-dropdown--below {
  margin-top: -35px;
}

.woocommerce-checkout .select2-container--open .select2-dropdown--above {
  position: relative;
  bottom: -30px;
}

.woocommerce-checkout .select2-dropdown {
  border: 1px solid #e3e6e9;
  border-top: none;
}

.woocommerce-checkout .select2-container--default .select2-selection--single {
  border-radius: 0;
}

.woocommerce-checkout .select2-container--default .select2-selection--single .select2-selection__rendered,
.woocommerce-checkout .select2-container--default .select2-selection--single .form-control:focus {
  color: var(--body-color);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #eee;
  padding: 0;
}

.woocommerce-form-login select,
.woocommerce-form-login .form-select,
.woocommerce-form-login .form-control,
.woocommerce-form-login .select2,
.woocommerce-form-login .select2-container,
.woocommerce-form-coupon select,
.woocommerce-form-coupon .form-select,
.woocommerce-form-coupon .form-control,
.woocommerce-form-coupon .select2,
.woocommerce-form-coupon .select2-container,
.woocommerce-checkout select,
.woocommerce-checkout .form-select,
.woocommerce-checkout .form-control,
.woocommerce-checkout .select2,
.woocommerce-checkout .select2-container {
  margin-bottom: var(--bs-gutter-x);
}

#ship-to-different-address {
  margin-top: 15px;
}

.select2-container--default .select2-selection--single {
  height: 60px;
  border: 1px solid #e3e6e9;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 60px;
  padding-left: 30px;
  padding-right: 25px;
}

.woocommerce-billing-fields .form-row {
  margin-bottom: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:before {
  content: "\f107";
  font-family: var(--icon-font);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin: 0;
  border: none;
  top: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 60px;
  line-height: 60px;
  margin-right: 30px;
}

span.select2-selection.select2-selection--single:focus {
  outline: none;
}

.shipping-calculator-form .form-select,
.shipping-calculator-form .form-control {
  height: 40px;
  padding-left: 15px;
  font-size: 16px;
  background-position: right 13px center;
}

.shipping-calculator-form .th-btn {
  font-size: 14px;
  padding: 0 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 40px;
}

.checkout-ordertable th,
.checkout-ordertable td {
  border: 1px solid #ededed;
  text-align: right;
  padding: 5px 20px;
  vertical-align: top;
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
}

.checkout-ordertable th {
  font-weight: 800;
  text-align: left;
}

.checkout-ordertable ul {
  margin: 0;
  padding: 0;
}

.checkout-ordertable .order-total .amount {
  color: var(--theme-color);
}

.checkout-ordertable input[type="hidden"] ~ label {
  color: var(--theme-color);
}

.woocommerce-checkout .form-group input:not(:last-child) {
  margin-bottom: var(--bs-gutter-x);
}

.woocommerce-checkout-payment {
  text-align: left;
}

.woocommerce-checkout-payment ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.woocommerce-checkout-payment ul li {
  padding-top: 12px;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 16px;
}

.woocommerce-checkout-payment ul input[type="radio"] ~ label {
  margin-bottom: 17px;
  color: var(--body-color);
}

.woocommerce-checkout-payment ul input[type="radio"] ~ label img {
  margin-bottom: -2px;
  margin-left: 10px;
}

.woocommerce-checkout-payment .place-order {
  padding-top: 30px;
}

.woocommerce-checkout-payment .payment_box {
  color: #a1b1bc;
  background-color: #ecf0f1;
  border: 1px solid #d8d8d8;
  border-bottom: none;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  display: none;
}

.woocommerce-checkout-payment .payment_box p {
  margin: 0;
}

.th-checkout-wrapper form.woocommerce-form {
  margin-bottom: 25px;
}

/* Small devices */
@media (max-width: 767px) {
  tfoot.checkout-ordertable th {
    display: none;
  }
  .woocommerce-checkout-payment ul input[type="radio"] ~ label img {
    max-width: 150px;
  }
  .checkout-ordertable th,
  .checkout-ordertable td {
    padding: 5px 20px 5px 60px;
  }
}

/*------------------- 4.00. Wishlist  -------------------*/
.tinv-wishlist input[type=checkbox] {
  display: inline-block;
  opacity: 1;
  visibility: visible;
  vertical-align: middle;
  width: auto;
  height: auto;
}

.tinv-wishlist .tinv-header {
  margin-top: -0.8rem;
}

.tinv-wishlist .cart-empty {
  padding: 12px 25px;
  background-color: #eee;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}

.tinv-wishlist p.return-to-shop .button {
  display: inline-block;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 10px 25px;
  margin-top: 10px;
  font-weight: 700;
}

.tinv-wishlist p.return-to-shop .button:Hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.tinv-wishlist table {
  border: none;
}

.tinv-wishlist table th {
  color: var(--title-color);
}

.tinv-wishlist table td, .tinv-wishlist table th {
  padding: 15.3px 10px;
  border-bottom: 1px solid var(--th-border-color);
  text-align: center;
}

.tinv-wishlist table thead {
  background-color: var(--smoke-color);
}

.tinv-wishlist .product-cb,
.tinv-wishlist .product-remove {
  width: 40px;
  text-align: center;
}

.tinv-wishlist .product-thumbnail {
  width: 110px;
}

.tinv-wishlist .stock.in-stock {
  margin-bottom: 0;
}

.tinv-wishlist ins {
  text-decoration: none;
}

.tinv-wishlist .product-remove button {
  border: none;
  height: 22px;
  width: 22px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  border-radius: 0;
  padding-top: 0;
}

.tinv-wishlist .product-remove button i {
  line-height: 22px;
  font-size: 16px;
}

.tinv-wishlist .tinvwl-mobile {
  display: none;
}

.tinv-wishlist .social-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 295px;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.tinv-wishlist .social-buttons ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 6px;
}

.tinv-wishlist table.tinvwl-table-manage-list {
  font-size: 16px;
}

.tinv-wishlist .product-stock .stock {
  display: block;
}

.tinv-wishlist .product-stock span {
  display: inline;
}

.tinv-wishlist .product-stock i {
  margin-right: 5px;
}

.tinv-wishlist .tinv-modal .icon_big_times {
  margin-bottom: 5px;
  color: var(--theme-color);
}

.tinv-wishlist button.button {
  border: none;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--theme-color);
  color: #fff;
  padding: 1px 15px;
  min-width: 140px;
}

.tinv-wishlist button.button.mask-btn {
  padding: 0;
}

.tinv-wishlist button.button .btn-text-mask {
  padding: 0.5px 21px;
}

.tinv-wishlist button.button:hover {
  background-color: var(--title-color);
  color: #fff;
}

.tinv-wishlist button.button i {
  font-size: 14px !important;
  margin-right: 3px !important;
}

.tinv-wishlist th,
.tinv-wishlist td.product-name {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--title-font);
}

.tinv-wishlist td.product-name a {
  color: var(--body-color);
}

.tinv-wishlist td.product-name a:hover {
  color: var(--theme-color);
}

.tinv-wishlist td.product-price del {
  margin-left: 8px;
  font-size: 0.9em;
}

.tinv-wishlist .social-buttons > span {
  font-weight: 700;
  margin-right: 10px;
  font-family: var(--title-font);
  color: var(--title-color);
}

.tinv-wishlist .social-buttons li {
  display: inline-block;
  margin-right: 0;
}

.tinv-wishlist .social-buttons li a.social {
  background-color: var(--theme-color);
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  margin-left: 3px;
}

.tinv-wishlist .social-buttons li a.social:first-child {
  margin-left: 0;
}

.tinv-wishlist .social-buttons li a.social i {
  line-height: inherit;
}

.tinv-wishlist .social-buttons li a.social:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

/* Medium devices */
@media (max-width: 991px) {
  .tinvwl-full {
    display: none;
  }
  .tinv-wishlist .tinvwl-mobile {
    display: block;
  }
  .tinvwl-txt {
    display: none !important;
  }
  .product-stock {
    width: 40px;
    text-align: center;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .tinv-wishlist table {
    table-layout: fixed;
    border-bottom: 1px solid var(--th-border-color);
  }
  .tinv-wishlist table.tinvwl-table-manage-list tbody td.product-remove, .tinv-wishlist table.tinvwl-table-manage-list thead th:not(.product-name) {
    display: none;
  }
  .tinv-wishlist table td, .tinv-wishlist table th {
    border: 1px solid var(--th-border-color);
  }
  .tinv-wishlist table.tinvwl-table-manage-list tbody td {
    display: block;
    width: 100% !important;
    text-align: center;
  }
  .product-name {
    text-align: center;
  }
  .tinv-wishlist table td, .tinv-wishlist table th {
    border-bottom: none;
  }
  .tinv-wishlist table tfoot {
    border-bottom: 1px solid var(--th-border-color);
  }
  .tinv-wishlist .social-buttons {
    max-width: 100%;
    margin-left: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .tinv-wishlist .social-buttons ul {
    margin-left: unset;
    margin-top: 5px;
  }
  .tinvwl-txt {
    display: inline-block !important;
  }
}

/*------------------- 4.00. Contact  -------------------*/
/* Contact 1 ---------------------------------- */
.appoitment-form select,
.appoitment-form .form-control,
.appoitment-form .form-select,
.appoitment-form textarea,
.appoitment-form input {
  background-color: var(--white-color);
  border: 1px solid var(--th-border-color);
}

.appointment-form2 {
  margin-left: 75px;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1299px) {
  .appointment-form2 {
    margin-left: 40px;
  }
}

@media (max-width: 1199px) {
  .appointment-form2 {
    margin-left: 0;
  }
}

.appointment-form2 select,
.appointment-form2 .form-control,
.appointment-form2 .form-select,
.appointment-form2 textarea,
.appointment-form2 input {
  background-color: var(--white-color);
  border: none;
  color: var(--body-color);
  font-weight: 400;
  border-radius: 100px;
}

.appointment-form2 select::-moz-placeholder,
.appointment-form2 .form-control::-moz-placeholder,
.appointment-form2 .form-select::-moz-placeholder,
.appointment-form2 textarea::-moz-placeholder,
.appointment-form2 input::-moz-placeholder {
  color: var(--body-color);
}

.appointment-form2 select::-webkit-input-placeholder,
.appointment-form2 .form-control::-webkit-input-placeholder,
.appointment-form2 .form-select::-webkit-input-placeholder,
.appointment-form2 textarea::-webkit-input-placeholder,
.appointment-form2 input::-webkit-input-placeholder {
  color: var(--body-color);
}

.appointment-form2 select:-ms-input-placeholder,
.appointment-form2 .form-control:-ms-input-placeholder,
.appointment-form2 .form-select:-ms-input-placeholder,
.appointment-form2 textarea:-ms-input-placeholder,
.appointment-form2 input:-ms-input-placeholder {
  color: var(--body-color);
}

.appointment-form2 select::-webkit-input-placeholder, .appointment-form2 .form-control::-webkit-input-placeholder, .appointment-form2 .form-select::-webkit-input-placeholder, .appointment-form2 textarea::-webkit-input-placeholder, .appointment-form2 input::-webkit-input-placeholder {
  color: var(--body-color);
}

.appointment-form2 select::-moz-placeholder, .appointment-form2 .form-control::-moz-placeholder, .appointment-form2 .form-select::-moz-placeholder, .appointment-form2 textarea::-moz-placeholder, .appointment-form2 input::-moz-placeholder {
  color: var(--body-color);
}

.appointment-form2 select:-ms-input-placeholder, .appointment-form2 .form-control:-ms-input-placeholder, .appointment-form2 .form-select:-ms-input-placeholder, .appointment-form2 textarea:-ms-input-placeholder, .appointment-form2 input:-ms-input-placeholder {
  color: var(--body-color);
}

.appointment-form2 select::-ms-input-placeholder, .appointment-form2 .form-control::-ms-input-placeholder, .appointment-form2 .form-select::-ms-input-placeholder, .appointment-form2 textarea::-ms-input-placeholder, .appointment-form2 input::-ms-input-placeholder {
  color: var(--body-color);
}

.appointment-form2 select::placeholder,
.appointment-form2 .form-control::placeholder,
.appointment-form2 .form-select::placeholder,
.appointment-form2 textarea::placeholder,
.appointment-form2 input::placeholder {
  color: var(--body-color);
}

.appointment-form2 textarea.form-control,
.appointment-form2 textarea {
  min-height: 150px;
  border-radius: 20px;
}

.appointment-form2 .th-btn {
  padding: 20px 51px;
  margin-top: 10px;
}

.appointment-form2 .row {
  --bs-gutter-x: 11px;
}

.contact-feature {
  padding: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  background: var(--white-color);
  box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
  border-radius: 5px;
}

.contact-feature-wrap {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
  border-top: 1px solid var(--th-border-color);
  padding-top: 40px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.contact-feature .icon-btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 24px;
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
  border-radius: 5px;
  border: none;
}

.contact-feature_label {
  margin-bottom: 0;
}

.contact-feature_link {
  font-size: 20px;
  font-weight: 600;
  color: var(--title-color);
}

.contact-feature_link:hover {
  color: var(--theme-color);
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .contact-feature {
    padding: 20px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .contact-feature {
    gap: 15px;
    padding: 15px;
  }
  .contact-feature-wrap {
    gap: 15px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .contact-feature {
    padding: 22px;
  }
  .contact-feature-wrap {
    grid-template-columns: auto;
  }
}

/* Quote Form faq page ----------------------- */
.quote-form {
  background: var(--white-color);
  box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
  border-radius: 5px;
  padding: 50px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .quote-form {
    padding: 40px 20px;
  }
}

.quote-form select,
.quote-form .form-control,
.quote-form .form-select,
.quote-form textarea,
.quote-form input {
  background-color: var(--smoke-color2);
}

.quote-form .form-group > i {
  background-color: var(--smoke-color2);
  width: 18px;
  text-align: right;
}

/* Contact Page ---------------------------------- */
.contact-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 25px;
  box-shadow: 0px 4px 30px rgba(8, 14, 28, 0.08);
  border-radius: 5px;
  background-color: var(--white-color);
  padding: 40px;
}

.contact-info .box-title {
  margin-bottom: 10px;
}

.contact-info_icon {
  display: inline-block;
  width: 80px;
  min-width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  font-size: 40px;
  position: relative;
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
  border-radius: 5px;
}

.contact-info_text {
  min-width: 180px;
  display: block;
  margin-bottom: -0.5em;
  font-family: var(--body-font);
}

.contact-info_text span,
.contact-info_text a {
  display: block;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.contact-info_text a {
  color: var(--body-color);
}

.contact-info_text a:hover {
  color: var(--theme-color);
}

.contact-form select,
.contact-form .form-control,
.contact-form .form-select,
.contact-form textarea,
.contact-form input {
  background-color: var(--white-color);
}

.contact-form .form-group > i {
  background-color: var(--white-color);
  color: var(--theme-color);
  width: 18px;
  text-align: right;
}

.map-sec {
  line-height: 0px;
}

.map-sec iframe {
  -webkit-filter: grayscale(100);
          filter: grayscale(100);
  height: 600px;
  /* Medium Large devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .map-sec iframe {
    height: 450px;
  }
}

@media (max-width: 575px) {
  .map-sec iframe {
    height: 300px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .contact-info {
    padding: 30px;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .contact-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

/* Appointment Area 3 ---------------------------------- */
.appointment-area-wrapp {
  background-color: var(--white-color);
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin-left: 50px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .appointment-area-wrapp {
    margin: 0;
  }
}

.appointment-area-wrapp .title {
  border-bottom: 1px solid #E4E6EA;
  padding: 40px 50px 20px 50px;
  margin-bottom: 0;
}

.appointment-form3 {
  position: relative;
  padding: 35px 50px 50px;
  border-radius: 30px;
  background-color: var(--white-color);
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 767px) {
  .appointment-form3 {
    padding: 40px;
  }
}

@media (max-width: 575px) {
  .appointment-form3 {
    padding: 30px;
  }
}

.appointment-form3 .form-group label {
  color: var(--body-color);
  font-size: 14px;
}

.appointment-form3 select,
.appointment-form3 .form-control,
.appointment-form3 .form-select,
.appointment-form3 textarea,
.appointment-form3 input {
  border: 1px solid #E4E6EA;
  background-color: var(--white-color);
  height: 50px;
  border-radius: 5px;
}

.appointment-form3 textarea.form-control,
.appointment-form3 textarea {
  min-height: 120px;
}

.appointment-form3 .th-btn {
  border-radius: 5px;
}

/* contact 5 start -------------------*/
.contact-area5 {
  position: relative;
  max-width: 1760px;
  border-radius: 30px;
  margin: auto auto 120px auto;
  overflow: hidden;
  /* Medium devices */
}

@media (max-width: 991px) {
  .contact-area5 {
    margin-bottom: 80px;
  }
}

.sec-text.style3 {
  /* Large devices */
}

@media (max-width: 1199px) {
  .sec-text.style3 {
    display: block;
    margin: auto;
  }
}

.contact-btn {
  /* Large devices */
}

.contact-btn .th-btn {
  text-transform: capitalize;
  font-size: 16px;
  padding: 20px 50px;
}

@media (max-width: 1199px) {
  .contact-btn {
    text-align: center;
  }
}

.contact-shape {
  position: absolute;
  right: 0;
  top: 0;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1299px) {
  .contact-shape {
    right: -10%;
    height: 100%;
  }
  .contact-shape img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1199px) {
  .contact-shape {
    display: none;
  }
}

/* contact 6 */
.contact-feature-area {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 15px;
  padding-top: 54px;
  /* Medium Large devices */
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .contact-feature-area {
    gap: 15px;
  }
}

@media (max-width: 1199px) {
  .contact-feature-area {
    gap: 40px;
  }
}

@media (max-width: 767px) {
  .contact-feature-area {
    display: block;
  }
}

.contact-feature-area .feature-wrapper.style3 {
  margin-bottom: 0;
}

.contact-feature-area .feature-wrapper.style3 .header-info_link {
  /* Extra small devices */
}

@media (max-width: 375px) {
  .contact-feature-area .feature-wrapper.style3 .header-info_link {
    font-size: 20px;
  }
}

.contact-feature-area .th-social {
  /* Small devices */
}

.contact-feature-area .th-social .social-title {
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #656A74;
  margin-bottom: 15px;
}

.contact-feature-area .th-social a {
  --icon-size: 35px;
  color: var(--theme-color);
}

@media (max-width: 767px) {
  .contact-feature-area .th-social {
    margin-top: 20px;
  }
}

.contact-text {
  font-family: var(--body-font);
  font-size: 18px;
  color: #C7C7C7;
}

/* contact area start ----------------------*/
.contact-form2 {
  background-color: var(--white-color);
  border-radius: 20px;
  padding: 50px;
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 575px) {
  .contact-form2 {
    padding: 40px;
  }
}

@media (max-width: 375px) {
  .contact-form2 {
    padding: 30px;
  }
}

.contact-form2 select,
.contact-form2 .form-control,
.contact-form2 .form-select,
.contact-form2 textarea,
.contact-form2 input {
  height: 56px;
  border: 1px solid #E4E6EA;
  color: var(--body-color);
  background-color: transparent;
  border-radius: 5px;
  font-size: 14px;
}

.contact-form2 textarea.form-control,
.contact-form2 textarea {
  min-height: 136px;
}

.contact-form2 .form-group:last-child {
  margin: 0;
}

.contact-form2 .form-group i {
  color: var(--theme-color);
}

.contact-form2 .form-group label {
  color: #A1A6B0;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 0;
}

.contact-form2 .form-btn {
  margin-top: 34px;
}

.contact-item {
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}

.contact-item:not(:last-child) {
  margin-bottom: 24px;
}

.contact-item_icon {
  font-size: 30px;
  line-height: 30px;
  color: var(--theme-color);
}

.contact-item_label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #A1A6B0;
  margin-bottom: 0;
}

.contact-item_link, .contact-item_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: block;
  margin-bottom: -0.3rem;
}

.contact-item_link:not(:hover), .contact-item_text:not(:hover) {
  color: var(--title-color);
}

/* contact area start ----------------------*/
/*------------------- 4.00. About  -------------------*/
/* About 1 ---------------------------------- */
.img-box1 {
  position: relative;
  z-index: 2;
  margin-right: 18px;
  margin-left: 50px;
}

.img-box1 .img1 img {
  width: 100%;
  max-width: 650px;
}

.img-box1 .shape1 {
  position: absolute;
  bottom: 0px;
  right: 80px;
  z-index: -1;
}

.img-box1 .shape1 img {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.img-box1 .year-counter {
  position: absolute;
  bottom: 30px;
  right: 0;
}

.year-counter {
  height: 184px;
  width: 184px;
  background-color: var(--theme-color);
  border: 12px solid var(--white-color);
  box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.1);
  border-radius: 50%;
  text-align: center;
  padding: 25px 0;
}

.year-counter_number {
  color: var(--white-color);
  font-size: 64px;
  margin-bottom: 0px;
  line-height: 1.1;
}

.year-counter_text {
  color: var(--white-color);
  margin-bottom: 0;
  font-weight: 400;
}

.about-feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.about-feature-wrap {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  padding-bottom: 35px;
  border-bottom: 1px solid var(--th-border-color);
  margin-bottom: 40px;
}

.about-feature_title {
  font-size: 20px;
  margin-bottom: 7px;
}

.about-feature_text {
  margin-bottom: 0;
}

.about-feature.style2 {
  gap: 30px;
  -webkit-box-align: start;
  -webkit-align-items: start;
      -ms-flex-align: start;
          align-items: start;
}

.about-feature.style2:not(:last-child) {
  margin-bottom: 37px;
}

.about-feature.style2 .about-feature_icon {
  height: 64px;
  width: 64px;
  line-height: 64px;
  display: inline-block;
  background: var(--theme-color);
  border-radius: 16px;
  text-align: center;
  color: var(--white-color);
  font-size: 28px;
}

.about-feature.style2 .about-feature_icon i {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.about-feature.style2 .about-feature_title {
  font-size: 24px;
  font-weight: 600;
  margin-top: -0.2em;
  margin-bottom: 13px;
}

.about-feature.style2 .about-feature_text {
  margin-bottom: -0.4em;
}

.about-feature.style2:hover .about-feature_icon i {
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
}

.about-feature.style3 {
  gap: 20px;
  max-width: 390px;
}

.about-feature.style3 .about-feature_icon {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  background: #EFF1F9;
  border-radius: 16px;
  -webkit-align-self: start;
      -ms-flex-item-align: start;
          align-self: start;
}

.about-feature.style3 .about-feature_title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.about-feature.style3:not(:last-child) {
  margin-bottom: 40px;
}

.call-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.call-btn .play-btn > i {
  --icon-size: 56px;
  background-color: var(--theme-color);
  color: var(--white-color);
}

.call-btn .play-btn:before, .call-btn .play-btn:after {
  background-color: var(--theme-color);
}

.call-btn .play-btn:hover > i {
  background-color: var(--title-color);
}

.call-btn .play-btn:hover:before, .call-btn .play-btn:hover:after {
  background-color: var(--title-color);
}

.call-btn .btn-text {
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.call-btn .btn-title {
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
}

.call-btn a:hover {
  color: var(--theme-color);
}

.btn-group:has(.call-btn) {
  gap: 50px;
}

/* Small devices */
@media (max-width: 767px) {
  .img-box1 {
    margin-right: 0;
    margin-left: 0;
  }
  .img-box1 .year-counter {
    bottom: 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .btn-group:has(.call-btn) {
    gap: 30px;
  }
  .about-feature-wrap {
    grid-template-columns: auto;
    padding-bottom: 25px;
    margin-bottom: 35px;
  }
}

/* About 2 ---------------------------------- */
@media (min-width: 1300px) {
  .img-box3 {
    margin-right: 56px;
  }
}

.img-box3 {
  position: relative;
  z-index: 2;
  line-height: 1px;
}

.img-box3 .img1 {
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 15px;
}

.img-box3 .img2 {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--white-color);
  padding: 10px 0 0 10px;
  border-radius: 10px;
  -webkit-animation: jumpAni 7s linear infinite;
          animation: jumpAni 7s linear infinite;
}

.img-box3 .img2 img {
  border-radius: inherit;
}

.img-box3 .img3 {
  position: absolute;
  left: -90px;
  top: 80px;
  -webkit-animation: jumpReverseAni 7s linear infinite;
          animation: jumpReverseAni 7s linear infinite;
}

.img-box3 .img3 img {
  border-radius: 10px;
}

.img-box3 .shape1 {
  position: absolute;
  top: 80px;
  left: -195px;
  z-index: -1;
  -webkit-animation: movingX 8s linear infinite;
          animation: movingX 8s linear infinite;
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .img-box3 .img3 {
    left: -40px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .img-box3 {
    margin-left: 150px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .img-box3 {
    margin-left: 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .img-box3 .img3 {
    left: -28px;
    max-width: 35%;
  }
  .img-box3 .img2 {
    max-width: 70%;
  }
  .img-box3 .shape1 {
    max-width: 50%;
    left: -30px;
    top: 40%;
  }
}

/* About 3 ---------------------------------- */
.achivement-tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--title-color);
  position: relative;
}

.achivement-tab button {
  color: var(--white-color);
  border: none;
  padding: 0 5px;
  line-height: 56px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-box-flex: 50%;
  -webkit-flex: 50%;
      -ms-flex: 50%;
          flex: 50%;
  position: relative;
  z-index: 2;
  background-color: transparent;
}

.achivement-tab button:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: var(--theme-color) transparent transparent transparent;
  z-index: 1;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.achivement-tab button.active {
  background: var(--theme-color);
}

.achivement-tab button.active:after {
  opacity: 1;
}

.achivement-tab .indicator {
  position: absolute;
  top: var(--pos-y);
  left: var(--pos-x);
  height: var(--height-set);
  width: var(--width-set);
  background-color: var(--theme-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.achivement-tab .indicator:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: var(--theme-color) transparent transparent transparent;
  z-index: 1;
}

.achivement-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 100%;
  margin: 30px;
}

.achivement-box-area {
  background-color: var(--white-color);
  box-shadow: 0px 4px 50px rgba(8, 14, 28, 0.1);
}

.achivement-box_img {
  border-radius: 5px;
  overflow: hidden;
  min-width: 218px;
}

.achivement-box_img img {
  width: 100%;
}

.achivement-box .box-title {
  margin-bottom: 12px;
}

.achivement-box_text {
  font-size: 14px;
  margin-bottom: 14px;
}

@media (min-width: 1300px) {
  .img-box4 {
    margin-left: -90px;
    margin-right: 56px;
  }
}

.img-box4 {
  position: relative;
  text-align: center;
}

.img-box4 img {
  width: 100%;
  max-width: 750px;
}

.img-box4 .img-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.img-box4 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* Small devices */
@media (max-width: 767px) {
  .achivement-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .achivement-box_img {
    min-width: 100%;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .achivement-tab button {
    font-size: 14px;
    text-transform: capitalize;
  }
}

/* About Feature ---------------------------------- */
.about-sec-v4 {
  padding-top: 67px;
}

.img-box6 {
  position: relative;
}

.img-box6 .shape1 {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-animation: jumpReverseAni 7s linear infinite;
          animation: jumpReverseAni 7s linear infinite;
}

.img-box6 .shape2 {
  position: absolute;
  top: 0;
  left: -40px;
  -webkit-animation: moving 8s linear infinite;
          animation: moving 8s linear infinite;
}

.color-animate {
  width: 100%;
  max-width: 500px;
  aspect-ratio: 16 / 16;
  background-color: #F2BA4C;
  opacity: 0.45;
  -webkit-filter: blur(300px);
          filter: blur(300px);
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  -webkit-animation: bgColor 8s ease-in-out infinite;
          animation: bgColor 8s ease-in-out infinite;
}

/* Medium devices */
@media (max-width: 991px) {
  .img-box6 {
    text-align: center;
  }
  .img-box6 .shape2 {
    left: 0;
  }
  .list-center {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
  .list-center.two-column {
    max-width: 500px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .img-box6 .shape1,
  .img-box6 .shape2 {
    max-width: 130px;
  }
}

/* About 4 ---------------------------------- */
.about-sec4 {
  position: relative;
  min-height: 770px;
  border-radius: 30px;
  z-index: 2;
  background-attachment: fixed;
  /* Medium devices */
}

@media (max-width: 991px) {
  .about-sec4 {
    height: 472px;
  }
}

.about-sec4 .about-shape4 {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 512px;
  border-radius: 30px;
  background-color: var(--theme-color);
  z-index: -1;
  overflow: hidden;
}

/* About 5 ---------------------------------- */
.about-area5 {
  border: 1px solid #E3E7F0;
  padding: 80px;
  border-radius: 30px;
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 991px) {
  .about-area5 {
    padding: 80px 40px;
  }
}

@media (max-width: 767px) {
  .about-area5 {
    padding: 80px 20px;
  }
}

.th-experience {
  position: absolute;
  bottom: 80px;
  left: 0px;
  z-index: 2;
  padding: 30px 30px 30px 40px;
  background: var(--theme-color);
  border-radius: 25px 0px 0px 25px;
  width: 340px;
  color: var(--white-color);
  /* Medium Large devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .th-experience {
    left: -30px;
  }
}

@media (max-width: 375px) {
  .th-experience {
    bottom: 0;
    padding: 20px;
  }
}

.th-experience_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.th-experience .experience-year {
  font-size: 54px;
  font-weight: 700;
  letter-spacing: -1.08px;
  color: var(--white-color);
  margin-bottom: 0px;
}

.th-experience .experience-text {
  font-family: var(--title-font);
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--white-color);
  margin-bottom: 0;
}

.th-experience.style2 {
  position: relative;
  width: 120px;
  bottom: 0;
  background-color: var(--white-color);
  border-radius: 15px;
  padding: 25px 18px;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 13px 25px 0px rgba(0, 0, 0, 0.05);
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .th-experience.style2 {
    left: 0;
  }
}

.th-experience.style2 .th-experience_content {
  display: block;
  text-align: center;
}

.th-experience.style2 .experience-year {
  font-size: 36px;
  color: var(--theme-color);
  margin-bottom: 0px;
}

.th-experience.style2 .experience-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--title-color);
  margin-bottom: 0;
}

.img-box6 {
  position: relative;
  z-index: 2;
  text-align: right;
}

.img-box6 .img1 {
  position: relative;
  text-align: right;
  z-index: 2;
  border-radius: 30px;
  /* Extra small devices */
  /* Large devices */
}

@media (max-width: 575px) {
  .img-box6 .img1 {
    text-align: center;
  }
}

.img-box6 .img1 img {
  border-radius: 30px;
}

@media (max-width: 1199px) {
  .img-box6 .img1 {
    margin-top: 50px;
  }
}

.img-box6 .th-experience {
  text-align: left;
}

.down-option-area {
  position: relative;
  border-radius: 30px;
  border: 1px solid #E3E7F0;
  background: #F8FAFF;
  padding: 0 30px;
  margin-top: 30px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .down-option-area {
    padding: 30px;
  }
}

.down-option-area_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 991px) {
  .down-option-area_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 30px;
  }
}

@media (max-width: 767px) {
  .down-option-area_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.down-option-area_wrapper .about-counter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 17px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 0;
  margin-right: 50px;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .down-option-area_wrapper .about-counter {
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .down-option-area_wrapper .about-counter {
    display: block;
  }
}

@media (max-width: 991px) {
  .down-option-area_wrapper .about-counter {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .down-option-area_wrapper .about-counter {
    margin: 20px 0;
  }
}

.down-option-area_wrapper .about-counter:before {
  content: "";
  position: absolute;
  top: -2px;
  width: 1px;
  height: 124px;
  right: 25%;
  background: #E2E4EA;
  -webkit-transform: matrix(-0.97, -0.26, -0.26, 0.97, 0, 0);
      -ms-transform: matrix(-0.97, -0.26, -0.26, 0.97, 0, 0);
          transform: matrix(-0.97, -0.26, -0.26, 0.97, 0, 0);
  /* Large devices */
}

@media (max-width: 1199px) {
  .down-option-area_wrapper .about-counter:before {
    display: none;
  }
}

.down-option-area_wrapper .about-counter .cilent-box_title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: block;
  color: var(--title-color);
}

.down-option-area_wrapper .about_review {
  display: block;
  margin-bottom: 3px;
}

.down-option-area_wrapper .about_review i {
  color: #FFBE11;
  font-size: 14px;
  margin-right: 3px;
}

.down-option-area_wrapper .cilent-box_counter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.down-option-area_wrapper .cilent-box .counter-number {
  font-size: 14px;
  font-weight: 700;
}

.about-client-box {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 0;
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .about-client-box {
    padding: 0;
  }
}

@media (max-width: 375px) {
  .about-client-box {
    display: block;
  }
}

.about-client-box:before {
  content: "";
  position: absolute;
  right: -35%;
  top: -2px;
  width: 1px;
  height: 124px;
  -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
          transform: rotate(-15deg);
  background: #E2E4EA;
  /* Large devices */
}

@media (max-width: 1199px) {
  .about-client-box:before {
    display: none;
  }
}

.about-client-box .cilent-box_title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--title-color);
}

.client-thumb-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .client-thumb-group {
    margin-bottom: 20px;
  }
}

.client-thumb-group .thumb {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
}

.client-thumb-group .thumb:not(:first-child) {
  margin-left: -30px;
}

.client-thumb-group .thumb img {
  border-radius: 50%;
  height: 60px;
}

/* About 8 ---------------------------------- */
.img-box10 .img1 {
  position: relative;
  display: inline-block;
}

.img-box10 .img1 .img2 {
  position: absolute;
  right: 0;
  bottom: 100px;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .img-box10 .img1 .img2 img {
    max-width: 200px;
  }
}

.about-sec-8 {
  max-width: 1720px;
  margin: auto;
}

.about-text {
  color: #8E939D;
}

/* About 9 ---------------------------------- */
.img-box11 {
  text-align: end;
  position: relative;
  display: inline-block;
  padding: 0 80px 0 174px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box11 {
    padding: 0 40px 0 70px;
  }
}

.img-box11 .img2 {
  bottom: 100px;
  left: 0;
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 20px;
  display: inline-block;
  position: absolute;
}

.img-box11:after {
  content: '';
  position: absolute;
  inset: 299px 0 54px 75px;
  border-radius: 30px;
  background: var(--theme-color);
  z-index: -1;
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box11:after {
    inset: 299px 0 54px 25px;
  }
}

.img-box12 {
  text-align: end;
  position: relative;
  display: inline-block;
  padding: 0 250px 0 80px;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1299px) {
  .img-box12 {
    padding: 0 170px 0 80px;
  }
}

@media (max-width: 1199px) {
  .img-box12 {
    padding: 0 70px 0 40px;
  }
}

.img-box12 .img2 {
  bottom: 100px;
  right: 0;
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 20px;
  display: inline-block;
  position: absolute;
}

.img-box12:after {
  content: '';
  position: absolute;
  inset: 299px 75px 54px 0;
  border-radius: 30px;
  background: var(--theme-color);
  z-index: -1;
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box12:after {
    inset: 299px 20px 54px 0;
  }
}

/*about 10 ------------------------ */
.img-box13 {
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box13 {
    margin-top: 50px;
  }
}

.img-box13 .img1 {
  border-radius: 30px;
  margin-right: 75px;
  height: 607px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box13 .img1 {
    height: 100%;
    margin: 0;
  }
}

.img-box13 .img1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.about-counter-wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}

@media (max-width: 480px) {
  .about-counter-wrapp {
    display: block;
  }
}

.about-counter-wrapp .img1 {
  border-radius: 20px;
}

@media (max-width: 480px) {
  .about-counter-wrapp .img1 {
    margin-bottom: 30px;
  }
}

.about-counter-wrapp .img1 img {
  width: 100%;
  border-radius: 20px;
}

.counter-card8 {
  position: relative;
  width: 210px;
  height: 165px;
  padding: 50px 20px;
  border-radius: 0px 20px 20px 80px;
  text-align: center;
  z-index: 2;
}

@media (max-width: 480px) {
  .counter-card8 {
    width: 100%;
  }
}

.counter-card8:not(:last-child) {
  margin-bottom: 10px;
  border-radius: 80px 20px 20px 0px;
}

.counter-card8:not(:last-child):before {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.counter-card8:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: -webkit-linear-gradient(top, var(--theme-color2) 0%, var(--theme-color) 100%);
  background: linear-gradient(180deg, var(--theme-color2) 0%, var(--theme-color) 100%);
  opacity: 0.5;
  z-index: -1;
}

.counter-card8 .box-number {
  font-weight: 700;
  font-size: 44px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: var(--white-color);
  margin-top: -0.5rem;
  margin-bottom: 10px;
}

.counter-card8 .counter-text {
  color: var(--white-color);
}

/* About 9 style2 ------------------------------- */
.about-area3 {
  position: relative;
  background: #F8FAFF;
  border: 1px solid #E3E7F0;
  border-radius: 30px;
  padding: 80px;
  max-width: 1380px;
  display: block;
  margin: auto;
  z-index: 3;
  /* Large devices */
}

@media (max-width: 1199px) {
  .about-area3 {
    padding: 80px 20px;
  }
}

.img-box9 {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box9 {
    margin-top: 50px;
  }
}

@media (max-width: 440px) {
  .img-box9 {
    display: block;
  }
}

.img-box9 .img1 {
  margin-left: 45px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box9 .img1 {
    margin: 0;
  }
}

@media (max-width: 440px) {
  .img-box9 .img1 {
    display: none;
  }
}

.img-box9 .img3 {
  position: absolute;
  right: 0;
  bottom: 0;
}

.img-box9 .img3 img {
  width: 100%;
  height: 100%;
}

@media (max-width: 440px) {
  .img-box9 .img3 {
    display: none;
  }
}

.img-box9 img {
  width: 100%;
  text-align: right;
  border-radius: 30px;
}

.img-box9 .th-experience {
  bottom: 0px;
  left: -38px;
  border-radius: 25px 0px 25px 25px;
  width: 340px;
}

@media (max-width: 440px) {
  .img-box9 .th-experience {
    position: relative;
    margin-top: 20px;
    width: 100%;
    left: 0;
  }
}

/* About 13 start  --------------------- */
.img-box14 {
  position: relative;
  z-index: 2;
  line-height: 1px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box14 {
    margin-bottom: 50px;
  }
}

.img-box14 .img1 {
  display: inline-block;
  border-radius: 30px;
  overflow: hidden;
}

.img-box14 .img2 {
  position: absolute;
  bottom: -6%;
  right: 10%;
  background-color: var(--white-color);
  padding: 10px 0 0 10px;
  -webkit-filter: drop-shadow(-10px 10px 15px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(-10px 10px 15px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
  -webkit-animation: jumpAni 7s linear infinite;
          animation: jumpAni 7s linear infinite;
}

.img-box14 .img2 img {
  border-radius: inherit;
}

.img-box14 .img3 {
  position: absolute;
  left: -90px;
  top: 80px;
  -webkit-filter: drop-shadow(-10px 10px 50px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(-10px 10px 50px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
  -webkit-animation: jumpReverseAni 7s linear infinite;
          animation: jumpReverseAni 7s linear infinite;
}

@media (max-width: 1399px) {
  .img-box14 .img3 {
    left: -10px;
  }
}

.img-box14 .img3 img {
  border-radius: 10px;
}

.img-box14 .shape1 {
  position: absolute;
  top: -15px;
  right: -47px;
  z-index: -1;
  /* Medium devices */
}

@media (max-width: 991px) {
  .img-box14 .shape1 {
    right: 0;
  }
}

.img-box14 .shape2 {
  position: absolute;
  position: absolute;
  top: -7%;
  right: 25%;
  -webkit-animation: spin 8s linear infinite;
          animation: spin 8s linear infinite;
}

/* Extra small devices */
@media (max-width: 575px) {
  .img-box14 .img3 {
    left: -28px;
    max-width: 35%;
  }
  .img-box14 .img2 {
    max-width: 70%;
  }
  .img-box14 .shape1 {
    max-width: 50%;
    left: -30px;
    top: 40%;
  }
}

/* About 13 end  --------------------- */
/* About 14 start ----------------------*/
.about-sec-12 {
  max-width: 1760px;
  margin: auto;
  background-color: #EFF1F9;
}

.img-box15 {
  position: relative;
  z-index: 2;
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .img-box15 {
    margin-bottom: 120px;
  }
}

@media (max-width: 575px) {
  .img-box15 {
    margin-bottom: 80px;
  }
}

.img-box15 .img1 img {
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 10px;
}

.img-box15 .img2 {
  position: absolute;
  bottom: -10%;
  right: 18%;
  /* Small devices */
}

@media (max-width: 767px) {
  .img-box15 .img2 {
    display: none;
  }
}

.img-box15 .img3 {
  position: absolute;
  top: 20%;
  right: 18%;
  /* Small devices */
}

@media (max-width: 767px) {
  .img-box15 .img3 {
    display: none;
  }
}

.img-box15 .about-shape15 {
  position: absolute;
  top: 30px;
  right: 9%;
  width: 571px;
  height: 428px;
  border: 4px solid var(--theme-color);
  box-shadow: 5px 6px 15px 3px rgba(0, 31, 96, 0.1);
  border-radius: 10px;
  z-index: -1;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .img-box15 .about-shape15 {
    display: none;
  }
}

@media (max-width: 450px) {
  .two-column.list-center.style2 {
    max-width: 100%;
  }
}

/* About 14 end ----------------------*/
/* About 15 start -------------------------*/
.img-box16 {
  position: relative;
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box16 {
    margin-bottom: 20px;
  }
}

.img-box16 img {
  border-radius: 20px;
}

.img-box16 .img2 {
  position: absolute;
  bottom: -10%;
  right: 60px;
  background-color: var(--white-color);
  padding: 5px;
  border-radius: 20px;
  -webkit-animation: jumpAni 7s linear infinite;
          animation: jumpAni 7s linear infinite;
  /* Large devices */
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 1199px) {
  .img-box16 .img2 {
    right: 20%;
  }
}

@media (max-width: 991px) {
  .img-box16 .img2 {
    right: 10%;
  }
}

@media (max-width: 767px) {
  .img-box16 .img2 {
    right: -5%;
  }
}

@media (max-width: 480px) {
  .img-box16 .img2 {
    position: relative;
  }
}

.img-box16 .img2 img {
  width: 100%;
  object-fit: cover;
}

.img-box16 .img2:before {
  content: "";
  position: absolute;
  inset: 5px;
  background: #3E66F3;
  mix-blend-mode: multiply;
  opacity: 0.92;
  border-radius: 20px;
}

.img-box16 .img2 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.about-feature-wrap.style4 {
  gap: 18px;
}

.about-feature.style4 {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.year-counter.style2 {
  position: absolute;
  background-color: var(--white-color);
  border: 1px solid #E3E7F0;
  box-shadow: none;
  border-radius: 10px;
  padding: 30px 20px;
  width: 230px;
  height: 106px;
  top: 40px;
  right: 60px;
  bottom: unset;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .year-counter.style2 {
    right: 20px;
  }
}

@media (max-width: 1199px) {
  .year-counter.style2 {
    right: 20%;
  }
}

@media (max-width: 991px) {
  .year-counter.style2 {
    right: 10%;
  }
}

@media (max-width: 767px) {
  .year-counter.style2 {
    right: -5%;
  }
}

@media (max-width: 480px) {
  .year-counter.style2 {
    position: relative;
    top: 20px;
    right: 0;
  }
}

.year-counter.style2 .year-counter_number {
  color: var(--theme-color);
  font-size: 32px;
  margin-bottom: 5px;
  line-height: 32px;
  margin-top: -0.4rem;
}

.year-counter.style2 .year-counter_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1C1C25;
}

.btn-group.style3 {
  gap: 30px;
}

.call-btn i {
  width: 51px;
  height: 51px;
  line-height: 51px;
  text-align: center;
  border-radius: 50%;
  background: #CCD6FF;
  color: var(--theme-color);
  font-size: 24px;
}

.call-btn .btn-text {
  font-size: 16px;
}

.call-btn .btn-title {
  font-size: 16px;
}

/* about area 15 end --------------------------*/
/* about area 17 start --------------------------*/
.img-box17 {
  position: relative;
  /* Large devices */
}

.img-box17 .img1 {
  position: relative;
  z-index: 2;
}

.img-box17 .img1:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--theme-color);
  border-radius: 20px;
  z-index: -1;
}

.img-box17 .img1 img {
  width: 100%;
  object-fit: cover;
  margin: 5px 0 0 5px;
  border-radius: 20px 13px 20px 20px;
}

@media (max-width: 1199px) {
  .img-box17 {
    margin-bottom: 50px;
  }
}

.about-feature-wrap.style5 {
  gap: 76px;
  margin-bottom: 0;
  padding: 0;
  border: none;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .about-feature-wrap.style5 {
    display: block;
  }
}

.about-feature-wrap.style5 .btn-group {
  border-top: 1px solid #DBDBDB;
  margin-top: 30px;
  padding-top: 50px;
}

.about-feature.style5 {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.about-feature.style5:not(:last-child) {
  margin-bottom: 20px;
}

.about-feature.style5 .about-feature_title {
  font-size: 24px;
  font-weight: 500;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .about-feature.style5 .about-feature_title {
    font-size: 20px;
  }
}

.about-img {
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .about-img {
    margin-top: 40px;
  }
}

.about-img img {
  box-shadow: 6px 6px 44.2px 10px rgba(0, 31, 96, 0.15);
  border-radius: 6px;
}

/* about area 17 end --------------------------*/
/* about area 18 start ------------------*/
.img-box18 {
  margin-top: 30px;
}

.img-box18 .img1 {
  position: relative;
  border-radius: 30px;
}

.img-box18 .img1:before {
  content: "";
  position: absolute;
  width: 769px;
  height: 404px;
  left: -30px;
  top: -30px;
  background: #EFF1F9;
  border-radius: 30px;
  /* Extra large devices */
}

@media (max-width: 1500px) {
  .img-box18 .img1:before {
    width: 100%;
    height: 100%;
  }
}

.img-box18 .img1 img {
  -webkit-filter: drop-shadow(5px 10px 30px rgba(0, 31, 96, 0.05));
          filter: drop-shadow(5px 10px 30px rgba(0, 31, 96, 0.05));
  border-radius: 20px;
}

.about17-counter-wrapp {
  background: #EFF1F9;
  border-radius: 30px;
}

.counter-card9 {
  padding: 40px;
}

.counter-card9-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 2px solid var(--white-color);
  /* Extra small devices */
}

@media (max-width: 575px) {
  .counter-card9-wrap {
    display: block;
  }
}

.counter-card9:first-child {
  padding: 40px 40px 0 40px;
}

.counter-card9:not(:last-child) {
  border-right: 2px solid var(--white-color);
}

.counter-card9 .box-number {
  font-size: 54px;
  margin-top: -0.6rem;
  margin-bottom: 0;
}

.counter-card9 .counter-title {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--body-color);
}

.counter-card9 .counter-text {
  margin-bottom: -0.4rem;
}

.about17-client-wrapp {
  padding: 0 40px;
}

.about17-client-wrapp .cilent-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  /* Small devices */
}

@media (max-width: 767px) {
  .about17-client-wrapp .cilent-box {
    display: block;
  }
}

.about17-client-wrapp .about-content {
  padding: 40px 0 0 0;
}

.about17-client-wrapp .box-title {
  margin-bottom: 0;
}

.about17-client-wrapp .title {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}

.about17-client-wrapp .about-wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
}

.about17-client-wrapp .about_review i {
  color: #FF9900;
}

.about17-client-wrapp .line-btn {
  text-transform: none;
}

.about17-client-wrapp .line-btn:before {
  display: none;
}

.about17-client-wrapp .about17-wrapp {
  position: relative;
}

.about17-client-wrapp .about17-wrapp .about17-img {
  margin: 15px 0;
  height: 168px;
  /* Small devices */
}

@media (max-width: 767px) {
  .about17-client-wrapp .about17-wrapp .about17-img {
    height: 100%;
  }
}

.about17-client-wrapp .about17-wrapp .about17-img img {
  border-radius: 10px;
}

.about17-text {
  position: relative;
  z-index: 2;
  margin-top: 70px;
  height: 150px;
}

.about17-text span {
  background: #407360;
  border-radius: 30px;
  display: inline-block;
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: var(--white-color);
  padding: 12px 30px;
}

.about17-text span:nth-child(1) {
  position: absolute;
  left: 0;
  -webkit-transform: rotate(-35deg);
      -ms-transform: rotate(-35deg);
          transform: rotate(-35deg);
}

.about17-text span:nth-child(2) {
  position: absolute;
  left: 10%;
  bottom: 22px;
  background: #5C3AE2;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}

.about17-text span:nth-child(3) {
  position: absolute;
  top: 13%;
  left: 26%;
  background: #F9B317;
  -webkit-transform: rotate(20deg);
      -ms-transform: rotate(20deg);
          transform: rotate(20deg);
  z-index: -1;
}

.about17-text span:nth-child(4) {
  position: absolute;
  left: 41%;
  bottom: 20%;
  background: #02CE80;
  -webkit-transform: rotate(-30deg);
      -ms-transform: rotate(-30deg);
          transform: rotate(-30deg);
  z-index: -2;
}

/* about area 18 end ------------------*/
/* about area 19 start ------------------*/
.about16-counter-wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #EFF1F9;
  padding: 26px 30px;
  border-radius: 10px;
  margin-bottom: 50px;
  /* Small devices */
}

@media (max-width: 767px) {
  .about16-counter-wrapp {
    display: block;
  }
}

.about16-year-counter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  max-width: 230px;
  /* Small devices */
}

@media (max-width: 767px) {
  .about16-year-counter:first-child {
    margin-bottom: 20px;
  }
}

.about16-year-counter_number {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: var(--theme-color);
  margin-bottom: 0;
}

.about16-year-counter_text {
  font-size: 14px;
  font-family: var(--body-font);
  font-weight: 600;
  color: var(--title-color);
  display: block;
  margin-bottom: 0;
}

.img-box19 {
  position: relative;
  text-align: center;
  /* Large devices */
}

@media (max-width: 1199px) {
  .img-box19 {
    margin-top: 50px;
  }
}

.img-box19 .img1 {
  position: relative;
  width: 100%;
  border-radius: 20px;
}

.img-box19 .img1 img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.img-box19 .img1:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background: -webkit-linear-gradient(bottom, rgba(28, 28, 37, 0.4), rgba(28, 28, 37, 0.4));
  background: linear-gradient(0deg, rgba(28, 28, 37, 0.4), rgba(28, 28, 37, 0.4));
}

.img-box19 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* about area 19 end ------------------*/
/*------------------- 4.00. Team  -------------------*/
/* Team global ---------------------------------- */
.th-team {
  position: relative;
}

.th-team .team-img {
  position: relative;
  overflow: hidden;
}

.th-team .team-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.th-team .team-desig {
  font-size: 14px;
  display: block;
  margin-bottom: -0.5em;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  color: var(--theme-color);
}

.th-team .team-social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  gap: 20px;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: 4;
}

.th-team .team-social a {
  color: var(--white-color);
}

.th-team .team-social a:hover {
  color: var(--theme-color);
}

.th-team .th-social a {
  --icon-size: 36px;
  background-color: var(--white-color);
  color: var(--theme-color);
}

.th-team .th-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.th-team .box-title {
  margin-bottom: 3px;
}

.th-team:hover .team-img img {
  -webkit-transform: scale(1.08);
      -ms-transform: scale(1.08);
          transform: scale(1.08);
}

.th-team:hover .team-social {
  visibility: visible;
  opacity: 1;
}

/* Team Card ---------------------------------- */
.team-card {
  text-align: center;
}

.team-card .team-content {
  box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
  border-radius: 10px;
  background-color: var(--white-color);
  padding: 26px 10px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: relative;
  z-index: 2;
  max-width: 248px;
  margin: -50px auto 0 auto;
}

.team-card .team-img {
  border-radius: 10px;
}

.team-card .team-social {
  position: absolute;
  top: unset;
  bottom: 100%;
  left: 18px;
  height: 0px;
  padding-top: 15px;
  max-width: calc(100% - 36px);
  background-color: var(--white-color);
  -webkit-clip-path: path("M105.5 0C58.2259 0 17.7021 28.902 0.634674 70H210.365C193.298 28.902 152.774 0 105.5 0Z");
          clip-path: path("M105.5 0C58.2259 0 17.7021 28.902 0.634674 70H210.365C193.298 28.902 152.774 0 105.5 0Z");
  z-index: -1;
}

.team-card .team-social a {
  color: var(--title-color);
}

.team-card:hover .team-content {
  background-color: var(--theme-color);
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
}

.team-card:hover .box-title {
  color: var(--white-color);
}

.team-card:hover .box-title a:hover {
  color: var(--white-color);
}

.team-card:hover .team-desig {
  color: var(--white-color);
}

.team-card:hover .team-social {
  height: 70px;
}

.team-sec {
  position: relative;
  background-color: var(--smoke-color);
  background-size: auto;
  background-position: bottom left;
  overflow: hidden;
}

/* Extra small devices */
@media (max-width: 575px) {
  .team-card {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Team Box ---------------------------------- */
.team-box {
  position: relative;
  background-color: var(--white-color);
  border-radius: 10px;
  text-align: center;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.team-box .team-img {
  border-radius: 10px 10px 0 0;
}

.team-box .th-social {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.team-box .th-social a {
  --icon-size: 36px;
  color: var(--theme-color);
  background-color: var(--white-color);
  border: none;
}

.team-box .th-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.team-box .th-social a:nth-child(2) {
  margin-right: 30px;
}

.team-box .th-social a:nth-child(3) {
  margin-left: 30px;
}

.team-box .team-content {
  padding: 26px 10px;
  position: relative;
  z-index: 2;
}

.team-box .team-social {
  display: block;
  top: unset;
  bottom: 25px;
  text-align: center;
  width: 100%;
  visibility: visible;
  opacity: 1;
}

.team-box .team-social .play-btn {
  cursor: pointer;
}

.team-box .team-social .play-btn > i {
  --icon-size: 36px;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 20px;
}

.team-box .team-social .play-btn:before, .team-box .team-social .play-btn:after {
  background-color: var(--theme-color);
}

.team-box .team-social .play-btn:hover > i {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.team-box .team-social:hover .th-social {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}

.team-box:hover {
  background-color: var(--theme-color);
}

.team-box:hover .box-title {
  color: var(--white-color);
}

.team-box:hover .box-title a:hover {
  color: var(--white-color);
}

.team-box:hover .team-desig {
  color: var(--white-color);
}

/* Extra small devices */
@media (max-width: 575px) {
  .team-box {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Team Grid ---------------------------------- */
.team-grid {
  position: relative;
  z-index: 2;
  background-color: var(--white-color);
  box-shadow: 0px 4px 15px rgba(8, 14, 28, 0.1);
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.team-grid .team-img {
  border-radius: 10px;
  margin-bottom: 25px;
}

.team-grid .th-social {
  position: absolute;
  bottom: 42px;
  right: 0;
  max-width: 36px;
  opacity: 0;
  -webkit-transform: scaleY(0);
      -ms-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom;
      -ms-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.team-grid .th-social a {
  margin-bottom: 6px;
}

.team-grid .team-social {
  display: block;
  top: unset;
  bottom: 82px;
  right: 48px;
  left: unset;
  visibility: visible;
  opacity: 1;
  width: 36px;
}

.team-grid .team-social .play-btn {
  cursor: pointer;
}

.team-grid .team-social .play-btn > i {
  --icon-size: 36px;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 20px;
}

.team-grid .team-social .play-btn:before, .team-grid .team-social .play-btn:after {
  background-color: var(--theme-color);
}

.team-grid .team-social .play-btn:hover > i {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.team-grid .team-social:hover .th-social {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}

.team-grid .team-desig {
  margin-bottom: 0;
}

.team-grid:hover {
  background-color: var(--theme-color);
}

.team-grid:hover .box-title {
  color: var(--white-color);
}

.team-grid:hover .box-title a:hover {
  color: var(--white-color);
}

.team-grid:hover .team-desig {
  color: var(--white-color);
}

.team-grid:hover .play-btn > i {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.team-grid:hover .play-btn:before, .team-grid:hover .play-btn:after {
  background-color: var(--white-color);
}

/* Team Featured ---------------------------------- */
.team-featured {
  padding-left: 36px;
}

.team-featured-img {
  border-radius: 10px;
  overflow: hidden;
}

.team-featured-img img {
  width: 100%;
}

.team-featured .team-title {
  margin-bottom: 5px;
  margin-top: -0.32em;
}

.team-featured .team-desig {
  color: var(--theme-color);
  margin-bottom: 20px;
}

.team-featured .team-bio {
  margin-bottom: 23px;
}

.team-contact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  padding: 15px;
  background-color: var(--smoke-color);
  border-radius: 5px;
}

.team-contact-wrap {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 30px 0;
  margin-bottom: 30px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--smoke-color2);
}

.team-contact .icon-btn {
  --btn-size: 50px;
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}

.team-contact_label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.team-contact_link {
  color: var(--body-color);
  font-family: var(--body-font);
  font-size: 14px;
  display: inline-block;
}

.team-contact a:hover {
  color: var(--theme-color);
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .team-featured {
    padding-left: 0;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .team-contact-wrap {
    grid-template-columns: auto auto;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .team-contact-wrap {
    grid-template-columns: auto;
  }
}

/* Team Details ---------------------------------- */
.about-card {
  background-color: var(--white-color);
  box-shadow: 0px 0px 50px rgba(8, 14, 28, 0.1);
  border-radius: 10px;
  padding: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 40px;
}

.about-card_img {
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-flex: 490px;
  -webkit-flex: 490px;
      -ms-flex: 490px;
          flex: 490px;
}

.about-card_img img {
  width: 100%;
  border-radius: inherit;
}

.about-card_box {
  -webkit-box-flex: 610px;
  -webkit-flex: 610px;
      -ms-flex: 610px;
          flex: 610px;
}

.about-card_title {
  margin-top: -0.32em;
  margin-bottom: 7px;
}

.about-card_desig {
  color: var(--theme-color);
  margin-bottom: 0;
}

.about-card_top {
  margin-bottom: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.about-card_text {
  margin-bottom: 30px;
}

.team-info-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.team-info-wrap .contact-feature {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
}

.border-title {
  border-bottom: 1px solid var(--smoke-color2);
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.experience-box {
  padding: 30px 30px 30px 65px;
  border: 1px solid #F1F1F1;
  overflow: hidden;
  position: relative;
  margin-bottom: -1px;
  margin-right: -1px;
}

.experience-box-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 0px 13px 21px rgba(0, 0, 0, 0.03);
}

.experience-box_num {
  font-size: 12px;
  width: 60px;
  height: 60px;
  line-height: 65px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: -13px;
  left: -13px;
}

.experience-box_num::first-letter {
  font-size: 18px;
  font-weight: 600;
}

.experience-box_title {
  font-family: var(--title-font);
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: -0.2em;
}

.experience-box_text {
  font-size: 14px;
  margin-bottom: -0.2em;
}

/* Large devices */
@media (max-width: 1199px) {
  .border-title {
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
  .about-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .about-card_img {
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
  .about-card_img img {
    width: 100%;
  }
  .about-card_box {
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .experience-box {
    padding: 30px 30px 30px 50px;
  }
  .about-card {
    padding: 20px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .experience-box {
    padding: 30px 30px 30px 65px;
  }
  .experience-box-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
  .team-info-wrap {
    grid-template-columns: auto;
  }
}

/* Team Card ---------------------------------- */
.team-card2 {
  position: relative;
  text-align: center;
  --space: 74px;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1299px) {
  .team-card2 {
    --space: 50px;
  }
}

@media (max-width: 1199px) {
  .team-card2 {
    --space: 30px;
  }
}

.team-card2 .team-img {
  position: relative;
  border-radius: 15px;
  margin-bottom: 15px;
}

.team-card2 .team-img img {
  border-radius: 15px;
  -webkit-transition: 1.3s all ease;
  transition: 1.3s all ease;
}

.team-card2 .team-desig {
  color: var(--body-color);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: -0.3rem;
}

.team-card2 .box-title {
  margin-bottom: 6px;
  margin-bottom: -0.3rem;
}

.team-card2 .box-content {
  background-color: var(--white-color);
  border-radius: 15px;
  padding: 28px;
}

.team-card2 .social-links {
  position: absolute;
  left: var(--space);
  bottom: -30px;
  width: auto;
  z-index: 3;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: var(--white-color);
  padding: 9px 25px;
  border-radius: 100px;
}

.team-card2 .social-links a {
  color: var(--body-color);
  margin: 0px 18px 0px 0px;
}

.team-card2 .social-links a:hover {
  color: var(--theme-color);
}

.team-card2:hover .team-img img {
  -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
          transform: scale(1.3);
}

.team-card2:hover .team-img:before {
  opacity: 1;
  visibility: visible;
}

.team-card2:hover .social-links {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

/* Team Card start---------------------------------- */
.team-area6 {
  background-color: #EFF1F9;
  overflow: hidden;
}

.team-card3 {
  position: relative;
  text-align: center;
  background-color: var(--white-color);
  border-radius: 30px;
  padding: 20px;
  --space: 74px;
  /* Medium Large devices */
  /* Large devices */
}

.team-card3.style2 {
  box-shadow: 1px 5px 10px 5px transparent;
}

.team-card3.style2:hover {
  box-shadow: 1px 5px 10px 5px rgba(0, 31, 96, 0.04);
}

@media (max-width: 1299px) {
  .team-card3 {
    --space: 50px;
  }
}

@media (max-width: 1199px) {
  .team-card3 {
    --space: 30px;
  }
}

.team-card3 .team-img {
  position: relative;
  border-radius: 15px;
}

.team-card3 .team-img img {
  border-radius: 15px;
  -webkit-transition: 1.3s all ease;
  transition: 1.3s all ease;
}

.team-card3 .team-desig {
  color: var(--body-color);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: -0.3rem;
}

.team-card3 .box-title {
  margin-bottom: 6px;
  margin-bottom: -0.3rem;
}

.team-card3 .box-content {
  background-color: var(--white-color);
  border-radius: 15px;
  padding: 0px 30px 10px 30px;
}

.team-card3 .social-links {
  position: relative;
  max-width: 179px;
  display: block;
  margin: -20px auto 0px auto;
  z-index: 3;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  background-color: #EFF1F9;
  padding: 9.5px 20px;
  border-radius: 100px;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
}

.team-card3 .social-links a {
  color: var(--body-color);
  margin: 0px 14px 0px 0px;
}

.team-card3 .social-links a:hover {
  color: var(--theme-color);
}

.team-card3:hover .team-img img {
  -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
          transform: scale(1.3);
}

.team-card3:hover .team-img:before {
  opacity: 1;
  visibility: visible;
}

.team-card3:hover .social-links {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
  margin: -20px auto 30px auto;
}

/* Team Card end ---------------------------------- */
/* Team box2 end ---------------------------------- */
.team-box2 {
  position: relative;
  overflow: hidden;
  border-radius: 100px 100px 200px 200px;
}

.team-box2:before {
  content: "";
  position: absolute;
  left: 0;
  top: 140px;
  width: 100%;
  height: 100%;
  background: #3E66F3;
  border-radius: 100px 100px 200px 200px;
  z-index: -1;
}

.team-box2 .team-img-wrap {
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.team-box2 .team-img {
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  background: #EFF1F9;
  border-radius: 200px;
  z-index: 2;
}

.team-box2 .team-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(28, 28, 37, 0.8);
  border-radius: 200px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.team-box2 .team-img img {
  width: 100%;
  border-radius: 20px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.team-box2 .th-social {
  position: absolute;
  bottom: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 3;
}

.team-box2 .th-social a {
  opacity: 0;
  -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.team-box2 .th-social a:nth-child(1) {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.team-box2 .th-social a:nth-child(2) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

.team-box2 .th-social a:nth-child(3) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.team-box2 .th-social a:nth-child(4) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}

.team-box2 .box-title {
  color: var(--white-color);
  font-weight: 500;
  margin-bottom: 0px;
}

.team-box2 .box-title a {
  color: inherit;
}

.team-box2 .team-desig {
  color: var(--white-color);
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: -0.3em;
}

.team-box2 .team-content {
  padding: 20px 30px 30px 30px;
  text-align: center;
}

.team-box2:hover .team-img-wrap {
  border-color: var(--theme-color);
}

.team-box2:hover .team-img:before {
  opacity: 1;
  visibility: visible;
}

.team-box2:hover .team-img img {
  -webkit-transform: scale(1.08);
      -ms-transform: scale(1.08);
          transform: scale(1.08);
}

.team-box2:hover .th-social a {
  opacity: 1;
  -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
          transform: translateY(0px);
}

/* Team box2 end ---------------------------------- */
/* Team box3 start -------------------------------*/
.team-box3 {
  position: relative;
  background-color: var(--theme-color);
  border-radius: 30px;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  overflow: hidden;
}

.team-box3 .team-img {
  border-radius: 30px;
}

.team-box3 .team-content {
  padding: 30px;
}

.team-box3 .box-title {
  color: var(--white-color);
  font-weight: 600;
  margin-bottom: 0;
}

.team-box3 .team-desig {
  color: var(--white-color);
}

.team-box3 .th-social {
  border-top: 1px solid rgba(255, 255, 255, 0.21);
  margin-top: 18px;
  padding-top: 18px;
  -webkit-transform: translateY(50px);
      -ms-transform: translateY(50px);
          transform: translateY(50px);
  height: 0;
  opacity: 0;
  margin-bottom: -2.2em;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.team-box3 .th-social a:hover {
  background-color: var(--title-color);
}

.team-box3:hover .th-social {
  opacity: 1;
  height: 100%;
  margin-bottom: 0;
  -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
          transform: translateY(0px);
}

.slider-arrow.style2 {
  display: block !important;
  /* Large devices */
}

@media (max-width: 1199px) {
  .slider-arrow.style2 {
    display: none !important;
  }
}

/* Team box3 end -------------------------------*/
/* Team box4 Start ---------------------------------- */
.team-box4 {
  position: relative;
  overflow: hidden;
  background-color: #EFF1F9;
  border-radius: 20px;
}

.team-box4 .team-img-wrap {
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.team-box4 .team-img {
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  background: #EFF1F9;
  border-radius: 20px;
  z-index: 2;
}

.team-box4 .team-img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(28, 28, 37, 0.8);
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.team-box4 .team-img img {
  width: 100%;
  border-radius: 20px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.team-box4 .th-social {
  position: absolute;
  bottom: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 3;
}

.team-box4 .th-social a {
  opacity: 0;
  -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.team-box4 .th-social a:nth-child(1) {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.team-box4 .th-social a:nth-child(2) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

.team-box4 .th-social a:nth-child(3) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.team-box4 .th-social a:nth-child(4) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}

.team-box4 .box-title {
  margin-bottom: 0px;
}

.team-box4 .box-title a {
  color: inherit;
}

.team-box4 .team-desig {
  color: var(--body-color);
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: -0.3em;
}

.team-box4 .team-content {
  padding: 20px 30px 30px 30px;
  text-align: center;
}

.team-box4:hover .team-img-wrap {
  border-color: var(--theme-color);
}

.team-box4:hover .team-img:before {
  opacity: 1;
  visibility: visible;
}

.team-box4:hover .team-img img {
  -webkit-transform: scale(1.08);
      -ms-transform: scale(1.08);
          transform: scale(1.08);
}

.team-box4:hover .th-social a {
  opacity: 1;
  -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
          transform: translateY(0px);
}

/* Team box4 end ---------------------------------- */
/*------------------- 4.00. Testimonial  -------------------*/
/* Testimonial Card ---------------------------------- */
.testi-card {
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 40px;
  position: relative;
}

.testi-card-area {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 40px;
  margin-left: auto;
  margin-right: auto;
}

.testi-card-slide {
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 40px;
  position: relative;
}

.testi-card-tab {
  position: relative;
  z-index: 2;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 5px 0;
  width: 56px;
}

.testi-card-tab:after {
  content: '';
  height: 100%;
  width: 3px;
  position: absolute;
  top: 0;
  left: 26.5px;
  background-color: var(--theme-color);
  z-index: -1;
}

.testi-card-tab .tab-btn {
  cursor: pointer;
  width: 56px;
  border-radius: 99px;
}

.testi-card-tab .tab-btn img {
  border-radius: inherit;
}

.testi-card-tab .indicator {
  position: absolute;
  top: calc(var(--pos-y) - 5px);
  left: calc(var(--pos-x) - 5px);
  width: calc(var(--width-set) + 10px);
  height: calc(var(--height-set) + 10px);
  border: 3px solid var(--theme-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: 50%;
}

.testi-card_quote {
  position: absolute;
  right: 40px;
  bottom: 40px;
}

.testi-card_profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.testi-card_avater {
  border-radius: 99px;
  overflow: hidden;
}

.testi-card_name {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}

.testi-card_desig {
  margin-bottom: 0;
  display: block;
  color: var(--theme-color);
}

.testi-card_text {
  margin-bottom: 22px;
}

.testi-card_review {
  color: var(--theme-color);
  font-size: 14px;
  margin-top: -0.4em;
  margin-bottom: 17px;
}

.testi-card_review i {
  margin-right: 3px;
}

/* Large devices */
@media (max-width: 1199px) {
  .testi-card-area {
    max-width: 700px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .testi-card-area {
    display: block;
  }
  .testi-card-tab {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    max-width: 300px;
    margin: 30px auto 0 auto;
  }
  .testi-card-tab:after {
    height: 3px;
    top: 26.5px;
    left: 0;
    width: 100%;
  }
  .testi-card-tab .tab-btn {
    -webkit-box-flex: 56px;
    -webkit-flex: 56px;
        -ms-flex: 56px;
            flex: 56px;
    width: auto;
    max-width: 56px;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .testi-card {
    padding: 40px 20px;
  }
  .testi-card_name {
    font-size: 20px;
  }
  .testi-card_avater {
    max-width: 60px;
  }
}

/* Testimonial Box ---------------------------------- */
.testi-box {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--white-color);
  box-shadow: 0px 6px 15px rgba(8, 14, 28, 0.06);
  border-radius: 10px;
  gap: 30px;
  padding: 40px;
}

.testi-box_img {
  min-width: 180px;
  text-align: center;
}

.testi-box_img > img {
  border-radius: 10px;
  width: 100%;
}

.testi-box_quote {
  height: 56px;
  width: 56px;
  line-height: 52px;
  background-color: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 2;
  margin: -28px auto 0 auto;
}

.testi-box_quote img {
  position: relative;
  z-index: 1;
}

.testi-box_quote:after, .testi-box_quote:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--theme-color);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.testi-box_quote:after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.testi-box_avater {
  margin-bottom: 12px;
}

.testi-box_avater img {
  border-radius: 999px;
}

.testi-box .box-title {
  margin-bottom: 4px;
}

.testi-box_desig {
  font-size: 14px;
  display: block;
  margin-bottom: -0.5em;
}

.testi-box_text {
  margin-top: -0.45em;
  padding-bottom: 23px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--smoke-color2);
}

.testi-box_review {
  color: var(--theme-color);
  font-size: 14px;
  position: absolute;
  bottom: 55px;
  right: 40px;
}

.testi-box_review i {
  margin-right: 3px;
}

/* Large devices */
@media (max-width: 1199px) {
  .testi-box {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }
  .testi-box_review {
    bottom: 75px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .testi-box {
    padding: 20px;
    gap: 20px;
  }
  .testi-box_img {
    min-width: 140px;
  }
  .testi-box_review {
    bottom: 30px;
  }
  .testi-box_text {
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .testi-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 60px;
    text-align: center;
    padding-bottom: 30px;
  }
  .testi-box_img {
    min-width: 120px;
    max-width: 120px;
    margin-top: -60px;
  }
  .testi-box_img > img {
    border-radius: 50%;
  }
  .testi-box_review {
    position: static;
    margin-bottom: 10px;
    margin-top: -0.4em;
  }
}

/* Testimonial Grid ---------------------------------- */
.testi-grid {
  position: relative;
  background-color: var(--white-color);
  box-shadow: 0px 6px 15px rgba(8, 14, 28, 0.06);
  border-radius: 10px;
  padding: 120px 40px 40px 40px;
  margin-top: 50px;
}

.testi-grid_img {
  background-color: var(--black-color2);
  width: 120px;
  text-align: center;
  border-radius: 10px;
  border: 10px solid var(--black-color2);
  position: absolute;
  top: -50px;
  left: 40px;
}

.testi-grid_img > img {
  border-radius: 10px;
  width: 100%;
}

.testi-grid_quote {
  height: 36px;
  width: 36px;
  line-height: 36px;
  background-color: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  position: absolute;
  bottom: -18px;
  left: calc(50% - 18px);
  z-index: 2;
  margin: -28px auto 0 auto;
}

.testi-grid_quote img {
  position: relative;
  z-index: 1;
}

.testi-grid_quote:after, .testi-grid_quote:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--theme-color);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.testi-grid_quote:after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.testi-grid .box-title {
  margin-bottom: 4px;
}

.testi-grid_desig {
  font-size: 14px;
  display: block;
  margin-bottom: -0.5em;
}

.testi-grid_text {
  margin-top: -0.45em;
  margin-bottom: 25px;
}

.testi-grid_review {
  color: var(--theme-color);
  font-size: 14px;
  position: absolute;
  top: 35px;
  right: 40px;
}

.testi-grid_review i {
  margin-right: 3px;
}

/* Testimonial 1 ---------------------------------- */
.testi-block-area {
  background-color: var(--white-color);
  padding: 100px 95px 100px 100px;
  max-width: 660px;
  border-radius: 20px;
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
}

.testi-block-quote {
  position: absolute;
  bottom: 70px;
  right: 100px;
  -webkit-animation: jumpAni 7s linear infinite;
          animation: jumpAni 7s linear infinite;
}

.testi-block-slide .slick-dots {
  text-align: left;
}

.testi-block_profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.testi-block_avater {
  border-radius: 99px;
  overflow: hidden;
}

.testi-block .box-title {
  margin-top: 0;
  margin-bottom: 5px;
}

.testi-block_desig {
  margin-bottom: 0;
  display: block;
  color: var(--theme-color);
}

.testi-block_text {
  font-size: 20px;
  margin-top: -0.5em;
  margin-bottom: 35px;
}

@media (min-width: 1500px) {
  .testi-block-area {
    margin-left: -100px;
  }
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .testi-block-area {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 50px;
  }
  .testi-block-quote {
    right: 50px;
    bottom: 30px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .testi-block_text {
    font-size: 18px;
    margin-top: -0.45em;
    margin-bottom: 30px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .testi-block-area {
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 50px 20px;
    border-radius: 10px;
  }
  .testi-block-slide .slick-dots {
    margin-top: 25px;
  }
  .testi-block-quote {
    right: 10px;
  }
  .testi-block_text {
    font-size: 16px;
    margin-bottom: 25px;
  }
  .testi-block .box-title {
    font-size: 22px;
  }
  .testi-block_profile {
    gap: 15px;
  }
  .testi-block_avater {
    max-width: 70px;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .testi-block-quote {
    display: none;
  }
}

/* Testimonial Box 5 ---------------------------------- */
.testi-box5 {
  position: relative;
  padding: 40px;
  background-color: var(--white-color);
  border-radius: 15px;
  box-shadow: 0px 14px 16px 0px rgba(0, 0, 0, 0.04);
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .testi-box5 {
    padding: 30px;
  }
}

.testi-box5_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  margin-bottom: 0px;
  /* Medium Large devices */
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .testi-box5_wrapper {
    gap: 15px;
  }
}

@media (max-width: 991px) {
  .testi-box5_wrapper {
    display: block;
  }
}

@media (max-width: 375px) {
  .testi-box5_wrapper {
    display: block;
  }
}

.testi-box5_author {
  margin-bottom: 15px;
}

.testi-box5_image {
  margin-bottom: 20px;
}

.testi-box5 .testi-box5_img {
  min-width: 60px;
}

.testi-box5 .testi-box5_img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.testi-box5 .testi-box5_name {
  margin-bottom: 0px;
}

.testi-box5 .testi-box5_text {
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 30px;
  margin-bottom: 40px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .testi-box5 .testi-box5_text {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.testi-box5 .testi-box5_desig {
  font-size: 14px;
  color: var(--theme-color);
  /* Extra small devices */
}

@media (max-width: 375px) {
  .testi-box5 .testi-box5_desig {
    margin-bottom: 40px;
  }
}

.testi-box5 .testi-box5_info {
  margin-bottom: -0.3rem;
}

.testi-box5 .testi-quote {
  /* Medium devices */
}

@media (max-width: 991px) {
  .testi-box5 .testi-quote {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}

.testi-box5 .box-title {
  font-size: 20px;
  letter-spacing: -0.4px;
  font-weight: 500;
  margin-bottom: -0.3rem;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .testi-box5 .box-title {
    font-size: 20px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .testi-box5_quote {
    display: none;
  }
}

/* Testimonial 7 ---------------------------------- */
.testi-grid2 {
  text-align: center;
}

.testi-grid2-area {
  position: relative;
  background-color: var(--white-color);
  padding: 80px 120px;
  border-radius: 20px;
  border: 1px solid #D8DFED;
  text-align: center;
  z-index: 2;
  /* Extra large devices */
  /* Medium Large devices */
  /* Medium devices */
  /* Extra small devices */
}

.testi-grid2-area.style2 {
  background-color: transparent;
  border: 1px solid #C5C5C5;
}

@media (max-width: 1500px) {
  .testi-grid2-area {
    padding: 80px;
  }
}

@media (max-width: 1299px) {
  .testi-grid2-area {
    padding: 80px 60px;
  }
}

@media (max-width: 991px) {
  .testi-grid2-area {
    padding: 80px 30px;
  }
}

@media (max-width: 575px) {
  .testi-grid2-area {
    padding: 80px 20px;
  }
}

.testi-grid2-area .testi-indicator {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 160px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-grid2-area .testi-indicator {
    position: initial;
    margin-bottom: 30px;
  }
}

.testi-grid2-area .slider-arrow {
  top: 66%;
  left: 0;
  border: 1px solid var(--theme-color);
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  /* Extra large devices */
  /* Small devices */
}

.testi-grid2-area .slider-arrow.slider-next {
  right: 0;
  left: auto;
}

@media (max-width: 1500px) {
  .testi-grid2-area .slider-arrow {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .testi-grid2-area .slider-arrow {
    display: none;
  }
}

.testi-grid2-area .testi-line {
  position: absolute;
  left: 120px;
  z-index: -1;
  bottom: 196px;
  width: 80%;
  height: 1px;
  background-color: var(--theme-color);
  /* Medium Large devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .testi-grid2-area .testi-line {
    left: 60px;
    width: 85%;
  }
}

@media (max-width: 767px) {
  .testi-grid2-area .testi-line {
    display: none;
  }
}

.testi-grid2-thumb {
  position: relative;
  max-width: 410px;
  padding: 7px;
  z-index: 2;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-grid2-thumb {
    max-width: 100%;
  }
}

.testi-grid2-thumb .box-img {
  background-color: var(--white-color);
  border: 1px solid var(--theme-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 50px;
  text-align: center;
  padding: 5px;
  overflow: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-grid2-thumb .box-img {
    margin: auto;
  }
}

@media (max-width: 375px) {
  .testi-grid2-thumb .box-img {
    width: 50px;
    height: 50px;
    line-height: 30px;
    padding: 8px;
  }
}

.testi-grid2-thumb .swiper-slide-thumb-active {
  opacity: 1;
}

.testi-grid2-thumb .swiper-slide-thumb-active .box-img {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
  border-width: 3px;
  line-height: 40px;
  border-color: var(--theme-color);
  /* Extra small devices */
}

@media (max-width: 375px) {
  .testi-grid2-thumb .swiper-slide-thumb-active .box-img {
    line-height: 25px;
  }
}

.testi-card2 {
  text-align: center;
  position: relative;
}

.testi-card2_profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 3;
  gap: 20px;
}

.testi-card2_avater {
  width: 60px;
  min-width: 60px;
  border-radius: 50%;
}

.testi-card2_avater img {
  border-radius: inherit;
}

.testi-card2_text {
  font-size: 22px;
  color: var(--title-color);
  letter-spacing: -0.22px;
  margin-bottom: 140px;
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .testi-card2_text {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .testi-card2_text {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.testi-card2_desig {
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
}

.testi-card2 .box-title {
  font-weight: 700;
  letter-spacing: -0.48px;
  margin-bottom: -0.2rem;
}

/* testimonial box start ------------------------*/
.testiSlider8 .swiper-slide {
  width: calc(678px) !important;
  /* Small devices */
}

@media (max-width: 767px) {
  .testiSlider8 .swiper-slide {
    width: 100% !important;
  }
}

.testi-box6 {
  background: #2D2F34;
  border-radius: 20px;
  padding: 40px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-box6 {
    padding: 30px;
  }
}

.testi-box6_image {
  margin-bottom: 35px;
}

.testi-box6_text {
  font-size: 18px;
  line-height: 28px;
  color: #8D929C;
  max-width: 598px;
  margin-bottom: 35px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .testi-box6_text {
    max-width: 100%;
  }
}

.testi-box6_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 400px) {
  .testi-box6_wrapper {
    display: block;
  }
}

.testi-box6_profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

@media (max-width: 400px) {
  .testi-box6_profile {
    margin-bottom: 20px;
  }
}

.testi-box6_author {
  min-width: 60px;
}

.testi-box6_author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.testi-box6 .box-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--white-color);
  margin-bottom: 0;
}

.testi-box6_desig {
  font-weight: 500;
  font-size: 14px;
  font-family: var(--body-font);
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* testimonial box end ------------------------*/
/* testimonoal 9 start-----------------------------*/
.testi-grid3 {
  text-align: center;
}

.testi-grid3-area {
  position: relative;
  background-color: var(--white-color);
  padding: 80px 120px;
  border-radius: 20px;
  border: 1px solid #D8DFED;
  text-align: center;
  z-index: 2;
  /* Extra large devices */
  /* Medium Large devices */
  /* Medium devices */
  /* Extra small devices */
}

.testi-grid3-area.style2 {
  background-color: transparent;
  border: 1px solid #C5C5C5;
}

@media (max-width: 1500px) {
  .testi-grid3-area {
    padding: 80px;
  }
}

@media (max-width: 1299px) {
  .testi-grid3-area {
    padding: 80px 60px;
  }
}

@media (max-width: 991px) {
  .testi-grid3-area {
    padding: 80px 30px;
  }
}

@media (max-width: 575px) {
  .testi-grid3-area {
    padding: 80px 20px;
  }
}

.testi-grid3-area .testi-indicator {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 160px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-grid3-area .testi-indicator {
    position: initial;
    margin-bottom: 30px;
  }
}

.testi-grid3-area .slider-arrow {
  top: 66%;
  left: 0;
  border: 1px solid var(--theme-color);
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  /* Extra large devices */
  /* Small devices */
}

.testi-grid3-area .slider-arrow.slider-next {
  right: 0;
  left: auto;
}

@media (max-width: 1500px) {
  .testi-grid3-area .slider-arrow {
    display: block;
  }
}

@media (max-width: 767px) {
  .testi-grid3-area .slider-arrow {
    display: none;
  }
}

.testi-grid3-area .testi-line {
  position: absolute;
  left: 120px;
  z-index: -1;
  bottom: 196px;
  width: 80%;
  height: 1px;
  background-color: var(--theme-color);
  /* Medium Large devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .testi-grid3-area .testi-line {
    left: 60px;
    width: 85%;
  }
}

@media (max-width: 767px) {
  .testi-grid3-area .testi-line {
    display: none;
  }
}

.testi-grid3-thumb {
  position: relative;
  max-width: 410px;
  padding: 7px;
  z-index: 2;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-grid3-thumb {
    max-width: 100%;
  }
}

.testi-grid3-thumb .box-img {
  background-color: var(--white-color);
  border: 1px solid var(--theme-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 50px;
  text-align: center;
  padding: 5px;
  overflow: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-grid3-thumb .box-img {
    margin: auto;
  }
}

@media (max-width: 375px) {
  .testi-grid3-thumb .box-img {
    width: 50px;
    height: 50px;
    line-height: 30px;
    padding: 8px;
  }
}

.testi-grid3-thumb .swiper-slide-thumb-active {
  opacity: 1;
}

.testi-grid3-thumb .swiper-slide-thumb-active .box-img {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
  border-width: 3px;
  line-height: 40px;
  border-color: var(--theme-color);
  /* Extra small devices */
}

@media (max-width: 375px) {
  .testi-grid3-thumb .swiper-slide-thumb-active .box-img {
    line-height: 25px;
  }
}

/* testimonoal 9 end -----------------------------*/
/* testimonial 10 start ------------------------------*/
.testi-box7 {
  background-color: var(--white-color);
  border: 1px solid #E3E7F0;
  border-radius: 10px;
  padding: 60px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .testi-box7 {
    padding: 40px;
  }
}

@media (max-width: 575px) {
  .testi-box7 {
    padding: 30px;
  }
}

.testi-box7_text {
  font-size: 20px;
  color: #656A74;
  margin-top: -0.5rem;
  border-bottom: 1px solid #E3E7F0;
  margin-bottom: 40px;
  padding-bottom: 30px;
}

.testi-box7_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 430px) {
  .testi-box7_wrapper {
    display: block;
  }
}

.testi-box7_profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

@media (max-width: 430px) {
  .testi-box7_profile {
    margin-bottom: 20px;
  }
}

.testi-box7_author {
  min-width: 60px;
}

.testi-box7_author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.testi-box7 .box-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .testi-box7 .box-title {
    font-size: 18px;
    line-height: 28px;
  }
}

.testi-box7_desig {
  font-weight: 400;
  font-size: 14px;
  font-family: var(--body-font);
  color: var(--theme-color);
}

.testi-box7_review i {
  color: #F9B317;
}

.testi-box7:hover {
  border: 1px solid transparent;
  box-shadow: 7px 10px 15px 6px rgba(0, 31, 96, 0.07);
}

.testi-grid4-area {
  position: relative;
  margin-bottom: 125px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-grid4-area {
    margin-bottom: 0;
  }
}

.testi-grid4-area .testi-indicator {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -100px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-grid4-area .testi-indicator {
    position: initial;
    margin-top: 30px;
  }
}

.testi-grid4-area .slider-arrow {
  top: 114%;
  left: 20%;
  border: 1px solid var(--theme-color);
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  /* Large devices */
  /* Extra large devices */
  /* Medium Large devices */
  /* Small devices */
}

@media (max-width: 1199px) {
  .testi-grid4-area .slider-arrow {
    top: 116%;
  }
}

.testi-grid4-area .slider-arrow.slider-next {
  right: 20%;
  left: auto;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .testi-grid4-area .slider-arrow.slider-next {
    right: 10%;
  }
}

@media (max-width: 1500px) {
  .testi-grid4-area .slider-arrow {
    display: block;
  }
}

@media (max-width: 1299px) {
  .testi-grid4-area .slider-arrow {
    left: 10%;
  }
}

@media (max-width: 767px) {
  .testi-grid4-area .slider-arrow {
    display: none;
  }
}

.testi-grid4-area .testi-line {
  position: absolute;
  left: 22%;
  z-index: -1;
  bottom: -65px;
  width: 56%;
  height: 1px;
  background-color: var(--theme-color);
  /* Medium Large devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .testi-grid4-area .testi-line {
    left: 14%;
    width: 75%;
  }
}

@media (max-width: 767px) {
  .testi-grid4-area .testi-line {
    display: none;
  }
}

/* testimonial 9 start ----------------------- */
.testimonial-area9 {
  padding: 220px 0;
  -webkit-clip-path: path("M0 0L270 100.815H1920V825L1650 724.185H0V0Z");
          clip-path: path("M0 0L270 100.815H1920V825L1650 724.185H0V0Z");
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
}

@media (min-width: 1930px) {
  .testimonial-area9 {
    -webkit-clip-path: path("M0 0L468.703 100.815H3333V825L2864.3 724.185H0V0Z");
            clip-path: path("M0 0L468.703 100.815H3333V825L2864.3 724.185H0V0Z");
  }
}

@media (max-width: 1299px) {
  .testimonial-area9 {
    padding-bottom: 200px;
  }
}

@media (max-width: 1199px) {
  .testimonial-area9 {
    padding-bottom: 240px;
  }
}

@media (max-width: 991px) {
  .testimonial-area9 {
    padding: 80px 0;
    -webkit-clip-path: none;
            clip-path: none;
  }
}

.testi-tabs {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 50px;
  background-color: var(--white-color);
  border-radius: 20px;
  margin-bottom: 40px;
  border: none;
  overflow: hidden;
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1199px) {
  .testi-tabs {
    gap: 8px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .testi-tabs {
    display: block;
  }
}

.testi-tabs .nav-item.show .nav-link,
.testi-tabs .nav-link.active {
  color: var(--title-color);
  background-color: transparent;
  border-color: none;
}

.testi-tabs .nav-item.show .nav-link:before,
.testi-tabs .nav-link.active:before {
  content: "";
  position: absolute;
  inset: 0;
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  opacity: 0.1;
}

.testi-tabs .nav-link.active .box-img:before {
  opacity: 1;
  visibility: visible;
}

.testi-tabs .nav-link {
  position: relative;
  padding: 10px 59px;
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  gap: 26px;
  font-family: var(--title-font);
  background-color: transparent;
  font-weight: 600;
  font-size: 24px;
  color: var(--title-color);
  margin-bottom: 0px;
  /* Medium Large devices */
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .testi-tabs .nav-link {
    padding: 10px 30px;
  }
}

@media (max-width: 991px) {
  .testi-tabs .nav-link {
    gap: 10px;
    padding: 10px 20px;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .testi-tabs .nav-link {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.testi-tabs .nav-link .box-img {
  position: relative;
  background-color: var(--white-color);
  border: 3px solid var(--theme-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 50px;
  text-align: center;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.testi-tabs .nav-link .box-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background-color: var(--white-color);
  border: 3px solid var(--theme-color);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.testi-tabs .nav-link .desig {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--body-color);
  display: block;
  margin-top: 5px;
}

.testi-box5.style2 .testi-box5_wrapper {
  margin-bottom: 30px;
}

.testi-box5.style2 .testi-box5_image {
  margin-bottom: 0;
}

.testi-box5.style2 .testi-box5_text {
  border: none;
  margin-bottom: -0.4rem;
  padding-bottom: 0;
}

/* testimonial 9 end ----------------------- */
/* restimonial 10 start */
.testi-area10 {
  background-color: #EFF1F9;
  border-radius: 20px 20px 0 0;
  max-width: 1760px;
  margin: auto;
  margin-bottom: -30px;
  padding-bottom: 150px !important;
  /* Medium devices */
}

@media (max-width: 991px) {
  .testi-area10 {
    padding-bottom: 110px !important;
  }
}

.testi-grid2-area.style3 {
  background-color: transparent;
  border: none;
  padding: 0;
  max-width: 1080px;
  margin: auto;
}

.testi-grid2-area.style3 .testi-indicator {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 80px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testi-grid2-area.style3 .testi-indicator {
    position: initial;
    margin-bottom: 30px;
  }
}

.testi-grid2-area.style3 .testi-line {
  left: 50px;
  bottom: 112px;
  width: 92%;
}

/* testi-card3 start ------------------------------------ */
.testi-area11 {
  position: relative;
  z-index: 2;
  border-radius: 30px;
  overflow: hidden;
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1199px) {
  .testi-area11 {
    padding: 120px 0;
  }
}

@media (max-width: 991px) {
  .testi-area11 {
    padding: 80px 0;
  }
}

.shape-mockup.testi-shape {
  min-width: 973px;
  -webkit-clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  border-radius: 30px 0 0 30px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .shape-mockup.testi-shape {
    display: none;
  }
}

.shape-mockup.testi-shape img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px 0 0 30px;
}

.testiSlide8 {
  position: relative;
}

.testiSlide8 .icon-box {
  position: absolute;
  bottom: 10px;
  right: 0;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .testiSlide8 .icon-box {
    display: none !important;
  }
}

.testiSlide8 .slider-pagination {
  position: absolute;
  bottom: 0;
  left: 45%;
  /* Small devices */
}

@media (max-width: 767px) {
  .testiSlide8 .slider-pagination {
    display: none !important;
  }
}

.testiSlide8 .swiper-pagination-bullet-active {
  background: var(--white-color);
}

.testiSlide8 .swiper-pagination-bullets .swiper-pagination-bullet:before {
  border: 1px solid var(--white-color);
  background: var(--white-color);
}

.testi-image11 {
  min-width: 758px;
  margin-left: -100px;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1299px) {
  .testi-image11 {
    min-width: 100%;
  }
}

@media (max-width: 1199px) {
  .testi-image11 {
    display: none;
  }
}

.testi-card3_text {
  color: var(--white-color);
  margin-bottom: 50px;
}

.testi-card3_profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}

.testi-card3 .box-title {
  color: var(--white-color);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.testi-card3_desig {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
}

.testi-card3 .testi-quote {
  position: absolute;
  top: 0;
  right: 0;
}

/* testi-card3 end ------------------------------------ */
/* testi area 14 start ----------------------------*/
.testi-area14 {
  background-color: #EFF1F9;
}

.testi-box3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--white-color);
  box-shadow: 0px 6px 30px rgba(213, 222, 255, 0.16);
  border-radius: 30px;
  gap: 30px;
  padding: 50px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .testi-box3 {
    padding: 40px;
    gap: 20px;
  }
}

.testi-box3_img {
  min-width: 223px;
  text-align: center;
}

.testi-box3_img > img {
  border-radius: 15px;
  width: 100%;
}

.testi-box3_avater img {
  border-radius: 999px;
}

.testi-box3 .box-title {
  margin-bottom: 2px;
}

.testi-box3_desig {
  font-size: 14px;
  display: block;
  margin-bottom: -0.5em;
}

.testi-box3_text {
  line-height: 26px;
  margin-bottom: 28px;
}

.testi-box3_review {
  color: #F9B317;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.testi-box3_review i {
  margin-right: 3px;
}

/* Large devices */
@media (max-width: 1199px) {
  .testi-box3 {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }
  .testi-box3_review {
    bottom: 75px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .testi-box3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    padding: 30px;
  }
  .testi-box3_img {
    min-width: 120px;
    max-width: 120px;
  }
}

/* testi 12 start ---------------------*/
.testi-area12 {
  position: relative;
  border-radius: 30px;
  z-index: 2;
}

.testi-area12:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 30px;
  background: rgba(28, 28, 37, 0.9);
  z-index: -1;
}

.testi-grid3-area.style4 {
  background-color: transparent;
  border: none;
  padding: 0;
}

.testi-grid3-area.style4 .testi-line {
  left: 50px;
  bottom: 114px;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.2);
  /* Medium devices */
}

@media (max-width: 991px) {
  .testi-grid3-area.style4 .testi-line {
    display: none !important;
  }
}

.testi-grid3-area.style4 .slider-arrow {
  /* Extra large devices */
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1500px) {
  .testi-grid3-area.style4 .slider-arrow {
    display: block !important;
  }
}

@media (max-width: 1199px) {
  .testi-grid3-area.style4 .slider-arrow {
    top: 59%;
  }
}

@media (max-width: 991px) {
  .testi-grid3-area.style4 .slider-arrow {
    display: none !important;
  }
}

.testi-grid3-area .testi-grid2-thumb .swiper-wrapper {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.testi-grid3-area .testi-indicator {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 80px;
}

.testi-card4 {
  text-align: center;
  position: relative;
}

.testi-card4_text {
  font-size: 22px;
  color: var(--white-color);
  letter-spacing: -0.22px;
  margin-bottom: 140px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .testi-card4_text {
    font-size: 18px;
  }
}

.testi-card4_profile {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.testi-card4 .box-title {
  color: var(--white-color);
  font-size: 24px;
  margin-bottom: 0;
}

.testi-card4_desig {
  font-size: 16px;
  color: var(--body-color);
}

.testi-image {
  margin-left: -20%;
  /* Large devices */
}

@media (max-width: 1699px) {
  .testi-image {
    margin-left: 0;
  }
}

@media (max-width: 1199px) {
  .testi-image {
    text-align: center;
  }
}

.testi-image img {
  border-radius: 20px;
}

/*------------------- 4.00. Counter  -------------------*/
/* Counter 1 ---------------------------------- */
.counter-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}

.counter-card_icon {
  width: 112px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  position: relative;
  padding-left: 15px;
  margin-bottom: 2px;
  z-index: 2;
}

.counter-card_icon:before, .counter-card_icon:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--black-color2);
  -webkit-clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
          clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
  z-index: -2;
}

.counter-card_icon:after {
  background-color: var(--white-color);
  top: 2px;
  left: 10px;
  z-index: -1;
}

.counter-card_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.counter-card_number {
  font-size: 40px;
  color: var(--white-color);
  font-weight: 900;
  margin-bottom: 8px;
  margin-top: -0.3em;
}

.counter-card_text {
  font-weight: 500;
  color: var(--white-color);
  display: block;
  margin-bottom: -0.5em;
}

.counter-card .icon {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.counter-card:hover .counter-card_icon img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.counter-card:hover .icon {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .counter-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .counter-card_number {
    margin-bottom: 4px;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .counter-card_number {
    font-size: 36px;
  }
  .counter-card_text {
    font-size: 14px;
  }
}

.counter-card-wrap {
  background-color: var(--black-color2);
  border-radius: 10px;
  padding: 65px 40px;
  /* Extra small devices */
}

.counter-card-wrap .counter-card_icon:before {
  background-color: var(--theme-color);
  opacity: 0.6;
}

.counter-card-wrap .counter-card_number {
  font-weight: 700;
}

@media (max-width: 575px) {
  .counter-card-wrap {
    padding: 65px 10px;
  }
}

/* Counter 4 ---------------------------------- */
.counter-area-4 {
  padding-top: 80px;
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .counter-area-4 {
    padding-top: 0;
  }
}

@media (max-width: 575px) {
  .counter-area-4 {
    padding-top: 80px;
  }
}

.counter-card4 {
  /* Large devices */
}

.counter-card4_icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--white-color);
  border: 1px solid #EBEDF1;
  -webkit-filter: drop-shadow(2.5px 2.5px 15px rgba(232, 234, 238, 0.55));
          filter: drop-shadow(2.5px 2.5px 15px rgba(232, 234, 238, 0.55));
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  margin-bottom: 35px;
  /* Small devices */
}

@media (max-width: 767px) {
  .counter-card4_icon {
    display: block;
    margin: auto auto 35px auto;
  }
}

.counter-card4_icon:before {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  border-radius: 50%;
  background-color: var(--theme-color);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transition: all 0.5s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  transition: all 0.5s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.counter-card4_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.counter-card4-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 40px;
}

.counter-card4-wrap .divider {
  background: #D4DAE7;
  width: 1px;
  height: 245px;
  /* Medium devices */
}

.counter-card4-wrap .divider:last-of-type {
  display: none;
}

@media (max-width: 991px) {
  .counter-card4-wrap .divider {
    display: none;
  }
}

.counter-card4-wrap.style2 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #5770FF;
  /* Large devices */
}

@media (max-width: 1199px) {
  .counter-card4-wrap.style2:nth-child(1), .counter-card4-wrap.style2:nth-child(2) {
    border-bottom: 1px solid #5770FF;
  }
  .counter-card4-wrap.style2:nth-child(2) {
    border-right: 0;
  }
  .counter-card4-wrap.style2:nth-child(3) {
    border-bottom: 1px solid #5770FF;
  }
}

.counter-card4-wrap.style2:last-child {
  border-right: 0;
}

.counter-card4-wrap.style2 .divider {
  background: #5770FF;
}

.counter-card4 .box-number {
  font-size: 40px;
  color: var(--title-color);
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: -0.2em;
}

.counter-card4 .counter-title {
  margin-bottom: 10px;
  font-size: 20px;
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 991px) {
  .counter-card4 .counter-title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .counter-card4 .counter-title {
    font-size: 20px;
  }
}

.counter-card4 .counter-text {
  /* Medium devices */
}

@media (max-width: 991px) {
  .counter-card4 .counter-text {
    font-size: 16px;
  }
}

.counter-card4 .media-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 312px;
}

.counter-card4:hover .counter-card4_icon:before {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}

.counter-card4:hover .counter-card4_icon img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.counter-card4.style2 {
  padding: 70px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  /* Small devices */
}

@media (max-width: 767px) {
  .counter-card4.style2 {
    padding: 35px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.title-area-wrapper {
  position: absolute;
  bottom: 0;
  max-width: 600px;
  padding: 120px 0 120px 120px;
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 991px) {
  .title-area-wrapper {
    padding: 80px 0 80px 0px;
    max-width: 400px;
  }
}

@media (max-width: 767px) {
  .title-area-wrapper {
    padding: 0px 0 180px 0px;
  }
}

.title-area-wrapper .title-area .sec-title {
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 767px) {
  .title-area-wrapper .title-area .sec-title {
    font-size: 28px;
  }
}

@media (max-width: 375px) {
  .title-area-wrapper .title-area .sec-title {
    font-size: 25px;
  }
}

.title-area-wrapper .sub-title {
  font-weight: 600;
}

@media (max-width: 600px) {
  .title-area-wrapper {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .title-area-wrapper {
    padding-bottom: 180px;
  }
}

.title-area-wrapper .th-btn {
  padding: 17px 46.5px;
}

/* Medium devices */
@media (max-width: 991px) {
  .counter-area-4 {
    margin-top: 60px;
  }
  .counter-card4 .box-number {
    font-size: 46px;
  }
  .counter-card4 .box-text {
    font-size: 18px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .counter-card4 {
    -webkit-box-flex: 50%;
    -webkit-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .counter-card4-wrap {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    position: relative;
    display: block;
  }
  .counter-card4-wrap .divider {
    display: none;
  }
  .counter-card4:nth-child(1), .counter-card4:nth-child(3) {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .counter-card4 .media-body {
    max-width: 100%;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .counter-area-4 {
    margin-top: 0px;
  }
}

/* Counter 5 ---------------------------------- */
.counter-area-5 {
  border-bottom: 1px solid #D4D4D4;
  padding: 80px 0;
}

.counter-card5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /* Medium devices */
}

.counter-card5 .box-number {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: -0.25em;
  margin-top: -0.25em;
}

.counter-card5 .counter-text {
  max-width: 180px;
  margin-top: -0.25rem;
}

@media (max-width: 991px) {
  .counter-card5 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .counter-card5 .box-number {
    font-size: 44px;
  }
  .counter-card5 .media-body {
    -webkit-box-flex: 0;
    -webkit-flex: none;
        -ms-flex: none;
            flex: none;
  }
}

/* Counter 6 ---------------------------------- */
.counter-area-6 {
  padding: 80px 0;
}

.counter-card6 .box-number {
  color: var(--white-color);
  font-size: 64px;
  font-weight: 700;
  margin-bottom: -2px;
  margin-top: -0.3em;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .counter-card6 .box-number {
    font-size: 40px;
  }
}

.counter-card6 .counter-text {
  color: var(--white-color);
  max-width: 215px;
}

/* counter 7 start -------------------------*/
.counter-area-7 {
  background-color: #F3F2EA;
  border: 4px solid var(--white-color);
}

.counter-card7 {
  position: relative;
  padding: 120px 30px;
  border: 12px solid transparent;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  margin: -20px 0;
  /* Medium Large devices */
  /* Medium devices */
}

@media (max-width: 1399px) {
  .counter-card7 {
    padding: 120px 20px;
  }
}

@media (max-width: 1299px) {
  .counter-card7 {
    padding: 120px 10px;
    margin: -10px 0;
  }
}

@media (max-width: 991px) {
  .counter-card7 {
    padding: 80px 10px;
  }
}

.counter-card7 .box-number {
  font-weight: 700;
  font-size: 84px;
  line-height: 84px;
  text-align: center;
  color: #1C1C25;
}

.counter-card7 .counter-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #656A74;
  display: block;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .counter-card7 .counter-text {
    font-size: 18px;
    line-height: 28px;
  }
}

.counter-card7 .counter-text span {
  display: block;
}

.counter-card7 .divider {
  position: absolute;
  left: 50%;
  top: 5px;
  background-color: var(--white-color);
  width: 5px;
  height: 118px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  /* Medium Large devices */
  /* Medium devices */
}

@media (max-width: 1299px) {
  .counter-card7 .divider {
    top: -2px;
  }
}

@media (max-width: 991px) {
  .counter-card7 .divider {
    height: 96px;
  }
}

.counter-card7:hover {
  background-color: var(--theme-color);
  border: 12px solid var(--white-color);
}

.counter-card7:hover .box-number {
  color: var(--white-color);
}

.counter-card7:hover .counter-text {
  color: var(--white-color);
}

.counter-card7:hover .divider {
  height: 0;
  background-color: transparent;
}

/* counter 7 end -------------------------*/
/* counter 8 start ---------------------*/
.counter-area-8 {
  position: relative;
  padding: 115px 0 80px 0;
  max-width: 1760px;
  margin: auto;
  border-radius: 0 0 20px 20px;
  margin-top: -35px;
  z-index: 1;
}

/* counter 8 end ---------------------*/
/*------------------- 4.00. Blog  -------------------*/
/* Blog Card ---------------------------------- */
.blog-card {
  box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
  border-radius: 10px;
  background-color: var(--white-color);
}

.blog-card .box-title {
  margin-bottom: 22px;
}

.blog-card .box-title a {
  background-image: -webkit-linear-gradient(right, var(--theme-color), var(--theme-color));
  background-image: linear-gradient(to left, var(--theme-color), var(--theme-color));
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 0 2px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.blog-card .box-title a:hover {
  background-size: 100% 2px;
}

.blog-card .blog-img {
  overflow: hidden;
  box-shadow: 0px 10px 15px rgba(23, 27, 42, 0.06);
  border-radius: 10px 10px 0px 0px;
}

.blog-card .blog-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.blog-card .blog-meta {
  margin-bottom: 20px;
}

.blog-card .blog-meta span > i,
.blog-card .blog-meta a > i {
  color: inherit;
}

.blog-card .blog-content {
  padding: 30px 40px;
}

.blog-card .blog-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid var(--th-border-color);
  padding-top: 25px;
}

.blog-card .author {
  color: var(--body-color);
}

.blog-card .author img {
  margin-right: 5px;
}

.blog-card .author:hover {
  color: var(--theme-color);
}

.blog-card .blog-text {
  margin-top: -0.5em;
  margin-bottom: 22px;
}

.blog-card .line-btn {
  margin-bottom: 0;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--body-color);
}

.blog-card .line-btn:before {
  background-color: var(--body-color);
}

.blog-card .line-btn:hover {
  color: var(--theme-color);
}

.blog-card .line-btn:hover:before {
  background-color: var(--theme-color);
}

.blog-card:hover .blog-img img {
  -webkit-transform: scale(1.08);
      -ms-transform: scale(1.08);
          transform: scale(1.08);
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .blog-card .blog-content {
    padding: 30px 35px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .blog-card .blog-content {
    padding: 30px 30px;
  }
  .blog-card .box-title {
    font-size: 22px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .blog-card .blog-content {
    padding: 30px 40px;
  }
  .blog-card .box-title {
    font-size: 24px;
  }
}

@media (max-width: 410px) {
  .blog-card .blog-content {
    padding: 30px 30px;
  }
  .blog-card .box-title {
    font-size: 22px;
  }
}

@media (max-width: 350px) {
  .blog-card .blog-content {
    padding: 30px 20px;
  }
  .blog-card .box-title {
    font-size: 20px;
  }
}

/* blog box --------------------------  */
.blog-box {
  position: relative;
}

.blog-box .blog-img {
  position: relative;
  border-radius: 20px;
  margin-bottom: 35px;
  overflow: hidden;
}

.blog-box .blog-img img {
  width: 100%;
  border-radius: 20px;
  -webkit-transform: 0.6s ease-in-out;
      -ms-transform: 0.6s ease-in-out;
          transform: 0.6s ease-in-out;
}

.blog-box .blog-meta {
  margin-bottom: 25px;
}

.blog-box .blog-meta a {
  font-size: 16px;
  color: #8D929C;
}

.blog-box .blog-meta a i {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.blog-box .box-title {
  color: var(--white-color);
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.02em;
  margin-bottom: 25px;
}

.blog-box .line-btn {
  text-transform: capitalize;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.02em;
}

.blog-box .line-btn:before {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.blog-box:hover .blog-img img {
  -webkit-transform: scale(1.08);
      -ms-transform: scale(1.08);
          transform: scale(1.08);
}

/*blog box start ----------------------------- */
.blog-box2 {
  background-color: var(--white-color);
  box-shadow: 0px 5px 15px rgba(0, 31, 96, 0.06);
  padding: 30px;
  border-radius: 30px;
}

.blog-box2.style2 .blog-img {
  margin-bottom: 30px;
}

.blog-box2 .blog-img {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.blog-box2 .blog-img img {
  border-radius: 15px;
  -webkit-transform: 0.4s ease-in-out;
      -ms-transform: 0.4s ease-in-out;
          transform: 0.4s ease-in-out;
}

.blog-box2 .blog-tag {
  position: relative;
  padding: 4.5px 20.5px;
  font-family: var(--body-font);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: var(--theme-color);
  border: 1px solid var(--white-color);
  color: var(--white-color);
  border-radius: 15px;
  max-width: 142px;
  display: block;
  margin: -15px auto auto auto;
  margin-bottom: 28px;
  z-index: 2;
}

.blog-box2 .box-title {
  margin-bottom: 14px;
}

.blog-box2 .blog-img img {
  width: 100%;
}

.blog-box2 .blog-meta {
  margin: -0.3rem 0 18px 0;
}

.blog-box2 .blog-meta span,
.blog-box2 .blog-meta a {
  font-size: 16px;
}

.blog-box2 .line-btn {
  text-transform: capitalize;
}

.blog-box2:hover .blog-img img {
  -webkit-transform: scale(1.08);
      -ms-transform: scale(1.08);
          transform: scale(1.08);
}

/* blog-box2 style3 start ------------------------------*/
.blog-box2.style3 {
  padding: 40px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .blog-box2.style3 {
    padding: 30px;
  }
}

.blog-box2.style3 .blog-meta {
  background-color: var(--theme-color);
  padding: 12px 24px 12px 43px;
  margin: -50px 0 38px -22px;
  -webkit-clip-path: path("M22 1.01855L294.979 0.0401371C298.301 0.02823 301 2.71799 301 6.0401V52.0186H55.6471H0L22 27.0186V1.01855Z");
          clip-path: path("M22 1.01855L294.979 0.0401371C298.301 0.02823 301 2.71799 301 6.0401V52.0186H55.6471H0L22 27.0186V1.01855Z");
  border-radius: 0px 0px 6px 0px;
  /* Extra small devices */
}

@media (max-width: 480px) {
  .blog-box2.style3 .blog-meta {
    margin: 30px 0 38px -22px;
    padding: 12px 12px 12px 30px;
  }
}

@media (max-width: 375px) {
  .blog-box2.style3 .blog-meta {
    -webkit-clip-path: none;
            clip-path: none;
    padding: 12px 11px 11px 11px;
    margin-bottom: 25px;
    border-radius: 6px;
  }
}

.blog-box2.style3 .blog-meta a {
  color: var(--white-color);
  /* Extra small devices */
}

@media (max-width: 375px) {
  .blog-box2.style3 .blog-meta a {
    font-size: 14px;
  }
}

.blog-box2.style3 .blog-meta a i {
  color: var(--white-color);
}

/*------------------- 4.00. Brand  -------------------*/
/* Brand 1 ---------------------------------- */
.brand-sec1 {
  position: relative;
  z-index: 4;
  border-radius: 10px;
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  /* Small devices */
}

.brand-sec1:after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--title-color);
  border-radius: inherit;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 70px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 70px 50%, 0 11px);
          clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 70px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 70px 50%, 0 11px);
  z-index: -1;
  /* Extra large devices */
  /* Medium Large devices */
}

@media (max-width: 1500px) {
  .brand-sec1:after {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 40px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 40px 50%, 0 11px);
            clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 40px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 40px 50%, 0 11px);
  }
}

@media (max-width: 1299px) {
  .brand-sec1:after {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 30px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 30px 50%, 0 11px);
            clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 30px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 30px 50%, 0 11px);
  }
}

@media (max-width: 767px) {
  .brand-sec1 {
    margin-left: 12px;
    margin-right: 12px;
  }
  .brand-sec1:after {
    -webkit-clip-path: none;
            clip-path: none;
  }
}

.brand-sec1 .slick-arrow {
  visibility: visible;
  opacity: 1;
}

/* Small devices */
@media (max-width: 767px) {
  .brand-box.py-20 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Brand 1.1 ---------------------------------- */
.brand-box1-1 {
  border-radius: 20px;
  border: 1px solid #EDF0F4;
  background: -webkit-linear-gradient(top, #EDF0F4 0%, rgba(237, 240, 244, 0) 100%);
  background: linear-gradient(180deg, #EDF0F4 0%, rgba(237, 240, 244, 0) 100%);
  display: grid;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 90px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.brand-box1-1 img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}

.brand-box1-1:hover {
  -webkit-filter: none;
          filter: none;
}

.brand-box1-1:hover img {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.brand-title {
  letter-spacing: -0.4px;
  font-weight: 600;
}

/* Brand 4 ---------------------------------- */
.brand-slider4 {
  padding-bottom: 60px;
  border-bottom: 1px solid #D3D3E7;
}

.brand-slider5 {
  padding-top: 120px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.brand-slider6 {
  padding-bottom: 80px;
}

.brand-item {
  display: grid;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  text-align: center;
  min-height: 40px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  /* Large devices */
}

@media (max-width: 1199px) {
  .brand-item {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.brand-item.style2 a .gray {
  -webkit-filter: brightness(140%) grayscale(100%);
          filter: brightness(140%) grayscale(100%);
  mix-blend-mode: normal;
}

.brand-item.style3 {
  border-radius: 20px;
  border: 1px solid #EDF0F4;
  background: -webkit-linear-gradient(top, #EDF0F4 0%, rgba(237, 240, 244, 0) 100%);
  background: linear-gradient(180deg, #EDF0F4 0%, rgba(237, 240, 244, 0) 100%);
  display: grid;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 90px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.brand-item a {
  overflow: hidden;
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.brand-item a .original {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  visibility: hidden;
}

.brand-item a .gray {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
}

.brand-item a:hover .original {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  opacity: 3;
  visibility: visible;
  -webkit-transform: translateY(0%);
      -ms-transform: translateY(0%);
          transform: translateY(0%);
}

.brand-item a:hover .gray {
  display: block;
  visibility: hidden;
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
}

/*brand 6 start --------------------------------*/
.brand-sec6 {
  padding: 81px 30px;
  border-radius: 30px;
}

/*brand 6 end --------------------------------*/
/*brand 7 start --------------------------------*/
/*brand 7 end --------------------------------*/
/*brand 8 start --------------------------------*/
.brand-slider8-area {
  position: absolute;
  left: 0;
  bottom: 0px;
  background-color: #EFF1F9;
  padding: 30px 80px;
  max-width: 1163px;
  border-radius: 0 50px 50px 0px;
  z-index: 2;
  /* Large devices */
}

@media (max-width: 1199px) {
  .brand-slider8-area {
    max-width: 100%;
  }
}

.brand-slider8 {
  max-width: 1003px;
}

/*brand 8 end --------------------------------*/
/* brand 9 start --------------------------------*/
.brand-sec8 {
  padding: 80px 0 30px 0;
}

.brand-box.style4 {
  background-color: var(--white-color);
  border: 1px solid #EDF0F4;
  border-radius: 20px;
  padding: 30px 40px;
  width: 240px;
  height: 90px;
  display: block;
  margin: auto;
}

/* brand 9 end --------------------------------*/
/* brand 10 start -------------------*/
.brand-title9 span {
  display: inline-block;
  position: relative;
  color: var(--theme-color);
}

.brand-title9 span:after {
  content: "";
  height: 15px;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='215' height='19' viewBox='0 0 215 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 16.9999C37.6667 5.9999 129.8 -9.4001 213 16.9999' stroke='%23407360' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: repeat-x;
  position: absolute;
  bottom: -7px;
  left: 0;
  -webkit-animation: titleFill 3s linear infinite;
          animation: titleFill 3s linear infinite;
}

/* brand 10 end -------------------*/
/*------------------- 4.00. Simple Sections  -------------------*/
.checklist ul {
  padding-left: 0;
  list-style: none;
  text-align: left;
  margin-bottom: 0;
}

.checklist li {
  color: var(--title-color);
  font-weight: 500;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.checklist li > i {
  color: var(--theme-color);
}

.checklist li:not(:last-child) {
  margin-bottom: 10px;
}

.checklist.style7 ul li {
  color: var(--body-color);
}

.checklist.style7 ul li:not(:last-child) {
  margin-bottom: 2px;
}

.checklist.style2 li {
  font-size: 18px;
  font-weight: 700;
  color: var(--title-color);
  font-family: var(--title-font);
  gap: 15px;
}

.checklist.style2 li:not(:last-child) {
  margin-bottom: 26px;
}

.checklist.style2 li > i {
  font-size: 20px;
  border-radius: 50%;
  border: 1px dashed var(--theme-color);
  padding: 9px;
}

.checklist.style3 li:not(:last-child) {
  margin-bottom: 20px;
}

.checklist.style3 li > i {
  font-size: 25px;
}

.checklist.style4 li {
  color: #141D38;
}

.checklist.style4 li:not(:last-child) {
  margin-bottom: 20px;
}

.checklist.style4 li > i {
  font-size: 25px;
}

.checklist.style4 li img {
  max-width: 26px;
}

.checklist.style5 li {
  color: var(--body-color);
  font-family: var(--body-font);
  font-weight: 400;
}

.checklist.style5 li:not(:last-child) {
  margin-bottom: 13px;
}

.checklist.style5 li > i {
  font-size: 22px;
}

.checklist.style6 li {
  font-size: 18px;
  font-weight: 700;
  color: var(--title-color);
  font-family: var(--title-font);
  gap: 15px;
  -webkit-box-align: start;
  -webkit-align-items: start;
      -ms-flex-align: start;
          align-items: start;
}

.checklist.style6 li p {
  font-weight: 400;
  font-size: 16px;
}

.checklist.style6 li:not(:last-child) {
  margin-bottom: 30px;
}

.checklist.style6 li > i {
  font-size: 14px;
  border-radius: 50%;
  color: var(--white-color);
  background: var(--theme-color);
  padding: 8px;
  margin-top: 1px;
}

.checklist.style8 li {
  gap: 15px;
  color: var(--body-color);
}

.checklist.style8 li i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  font-size: 24px;
  background-color: #EFF1F9;
  text-align: center;
}

.checklist.style9 li {
  gap: 15px;
  font-family: var(--body-font);
  font-weight: 500;
  font-size: 18px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .checklist.style9 li {
    font-size: 16px;
  }
}

.checklist.style9 li:not(:last-child) {
  margin-bottom: 30px;
}

.checklist.style9 li i {
  font-size: 30px;
}

.checklist.list-two-column ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 30px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .checklist.list-two-column ul {
    gap: 0 15px;
  }
}

@media (max-width: 520px) {
  .checklist.list-two-column ul {
    grid-template-columns: repeat(1, 1fr);
  }
}

.checklist.list-two-column ul li {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* Medium Large devices */
}

.checklist.list-two-column ul li img {
  max-width: 24px;
}

@media (max-width: 1299px) {
  .checklist.list-two-column ul li {
    font-size: 18px;
    line-height: 28px;
  }
}

.checklist.mb-40 {
  /* Large devices */
}

@media (max-width: 1199px) {
  .checklist.mb-40 {
    margin-bottom: 32px;
  }
}

.checklist.mb-45 {
  /* Large devices */
}

@media (max-width: 1199px) {
  .checklist.mb-45 {
    margin-bottom: 35px;
  }
}

.two-column:has(.checklist.style6) {
  gap: 30px;
}

.bg-img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}

.bg-img img {
  width: 100%;
  height: 100%;
}

.th-video {
  position: relative;
  border-radius: 5px;
}

.th-video img {
  border-radius: inherit;
}

.th-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.th-video .play-btn > i {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.icon-box {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 10px;
}

.btn-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px 30px;
}

.video-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--body-color);
  text-transform: uppercase;
  line-height: 1.6;
}

.video-btn .play-btn {
  --icon-size: 50px;
  margin-right: 15px;
}

.video-btn .play-btn > i {
  background-color: var(--theme-color);
  color: var(--white-color);
  padding-right: 0.1em;
}

.video-btn .play-btn:before {
  background-color: var(--theme-color);
}

.video-btn .small-text {
  font-size: 10px;
  text-decoration: underline;
  display: block;
}

.video-btn .big-text {
  font-size: 14px;
  font-weight: 500;
}

/* Large devices */
@media (max-width: 1199px) {
  p.mb-40 {
    margin-bottom: 35px;
  }
  p.mb-45 {
    margin-bottom: 38px;
  }
}

/* th anim start----------------------- */
.th-anim {
  position: relative;
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  visibility: hidden;
  overflow: hidden;
}

.th-anim img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-transform-origin: left;
      -ms-transform-origin: left;
          transform-origin: left;
}

/* th anim end ----------------------- */
/* custom-mouse-pointer-end */
.cursor2 {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--theme-color);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 999999;
  -webkit-transform: translate(calc(-50% + 15px), -50%);
      -ms-transform: translate(calc(-50% + 15px), -50%);
          transform: translate(calc(-50% + 15px), -50%);
}

.cursor2.hover {
  display: none;
}

.cursor {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--theme-color);
  opacity: .3;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 999999;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
}

.cursor.hover {
  display: none;
}

/*------------------- 4.00. Why -------------------*/
/* Why 1 ---------------------------------- */
.appointment-tabs.nav-tabs {
  border-bottom: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 24px;
}

.appointment-tabs.nav-tabs .nav-item.show .nav-link,
.appointment-tabs.nav-tabs .nav-link.active {
  color: var(--white-color);
  background-color: transparent;
}

.appointment-tabs .nav-link {
  position: relative;
  padding: 34px 60px;
  font-size: 24px;
  font-family: var(--title-font);
  font-weight: 500;
  color: var(--white-color);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  border: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50px;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  z-index: 2;
  -webkit-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
  /* Small devices */
  /* Extra small devices */
}

.appointment-tabs .nav-link:hover {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.appointment-tabs .nav-link:hover:after {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
}

.appointment-tabs .nav-link:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.appointment-tabs .nav-link:after {
  content: "";
  position: absolute;
  inset: 1px;
  border-radius: 50px;
  background: #16181D;
  z-index: -1;
  -webkit-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}

@media (max-width: 767px) {
  .appointment-tabs .nav-link {
    font-size: 20px;
    padding: 20px 60px;
  }
}

@media (max-width: 375px) {
  .appointment-tabs .nav-link {
    font-size: 18px;
  }
}

.prompts-tabs {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.img-box2 {
  text-align: center;
  margin-left: 85px;
  position: relative;
  z-index: 2;
}

.img-box2:before, .img-box2:after {
  content: '';
  position: absolute;
  background-color: var(--theme-color);
  width: 100%;
  height: 100%;
  -webkit-animation: morph 10s ease-in-out infinite;
          animation: morph 10s ease-in-out infinite;
  z-index: -1;
}

.img-box2:before {
  top: -20px;
  left: -40px;
  opacity: 0.5;
  -webkit-animation-delay: 0.2;
          animation-delay: 0.2;
}

.img-box2:after {
  right: -20px;
  bottom: -40px;
  background-color: var(--smoke-color2);
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
  z-index: -2;
}

.img-box2 .img1 img {
  -webkit-animation: morph 10s ease-in-out infinite;
          animation: morph 10s ease-in-out infinite;
  width: 100%;
  max-width: 760px;
}

.two-column {
  display: grid;
  grid-template-columns: auto auto;
  max-width: 700px;
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .img-box2 {
    margin-left: 0;
  }
  .img-box2:before {
    left: -20px;
  }
  .img-box2:after {
    right: -12px;
  }
}

@media (max-width: 450px) {
  .two-column {
    grid-template-columns: auto;
    gap: 18px;
  }
  .two-column.list-center {
    max-width: 230px;
  }
  .checklist.style2 li:not(:last-child) {
    margin-bottom: 18px;
  }
}

/* Why 2 ---------------------------------- */
.feature-circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 14px;
  max-width: 310px;
}

.feature-circle-wrap {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 34px;
}

.feature-circle_title {
  font-size: 20px;
  margin-bottom: 7px;
}

.feature-circle_text {
  margin-bottom: 0px;
  font-size: 14px;
}

.feature-circle .progressbar {
  position: relative;
}

.feature-circle .circle-num {
  font-size: 24px;
  font-weight: 700;
  color: var(--title-color);
  font-family: var(--title-font);
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin: 15px;
  background-color: var(--white-color);
  border-radius: 999px;
  text-align: center;
}

@media (min-width: 1300px) {
  .img-box5 {
    margin-left: 56px;
    margin-right: -77px;
  }
}

.img-box5 {
  position: relative;
  z-index: 2;
  margin-bottom: -46px;
  margin-top: -60px;
}

.img-box5 .year-counter {
  position: absolute;
  top: 20px;
  left: 40%;
  border-color: var(--smoke-color2);
  box-shadow: none;
  height: 155px;
  width: 155px;
  padding: 32px 0;
}

.img-box5 .year-counter_number {
  font-size: 36px;
}

.animate-shape1 {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.animate-shape1 svg {
  width: 100%;
  height: 100%;
}

.why-sec-v2 {
  overflow-x: hidden;
  margin-top: -440px;
  padding-top: 420px;
}

/* Large devices */
@media (max-width: 1199px) {
  .img-box5 {
    max-width: 760px;
    margin-bottom: -20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .img-box5 .animate-shape1 {
    max-width: 610px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .feature-circle-wrap {
    grid-template-columns: auto;
    gap: 20px;
  }
}

.img-box7,
.img-box8 {
  position: relative;
  margin-left: 56px;
}

.img-box7 .shape1,
.img-box8 .shape1 {
  position: absolute;
  top: 0;
  left: 40px;
  -webkit-animation: jumpReverseAni 7s linear infinite;
          animation: jumpReverseAni 7s linear infinite;
}

.img-box7 .shape2,
.img-box8 .shape2 {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-animation: moving 8s linear infinite;
          animation: moving 8s linear infinite;
}

.img-box8 .shape1 {
  left: 0;
}

.img-box8 .shape2 {
  -webkit-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite;
}

.img-box8 .color-animate {
  top: -200px;
  max-width: 400px;
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .img-box7,
  .img-box8 {
    margin-left: 0;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .img-box7,
  .img-box8 {
    text-align: center;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .img-box7 .shape1 {
    max-width: 50px;
  }
  .img-box7 .shape2 {
    max-width: 70px;
  }
  .img-box8 .shape1 {
    max-width: 100px;
  }
  .img-box8 .shape2 {
    max-width: 100px;
  }
}

/* Why 5 start ------------------------*/
.skill-circle {
  /* Extra small devices */
}

.skill-circle-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 60px;
  /* Large devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .skill-circle-wrap {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .skill-circle-wrap {
    gap: 10px;
  }
}

@media (max-width: 575px) {
  .skill-circle-wrap {
    display: block;
  }
}

@media (max-width: 575px) {
  .skill-circle {
    margin-bottom: 30px;
  }
}

.skill-circle .box-text {
  font-family: var(--body-font);
  color: var(--title-color);
  margin-bottom: 0px;
  position: absolute;
  bottom: 40px;
  left: 25px;
  text-align: center;
}

.skill-circle_text {
  margin-bottom: 0px;
  font-size: 14px;
}

.skill-circle .progressbar {
  position: relative;
  max-height: 176px;
}

.skill-circle .circle-num {
  font-family: var(--title-font);
  font-size: 54px;
  font-weight: 700;
  color: var(--title-color);
  font-family: var(--title-font);
  position: absolute;
  top: -20px;
  left: 0;
  width: 176px;
  height: 176px;
  line-height: 176px;
  border-radius: 50%;
  margin: 8px;
  text-align: center;
}

/* Why 5 end  ------------------------*/
/* Choose 2 ---------------------------------- */
.choose-tabs-wrapper {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1E2127;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  box-shadow: 0px 16px 48px 0px rgba(18, 20, 26, 0.64);
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1199px) {
  .choose-tabs-wrapper {
    gap: 30px;
  }
}

@media (max-width: 991px) {
  .choose-tabs-wrapper {
    display: block;
  }
}

.choose-tabs-tabs {
  /* Medium devices */
}

.choose-tabs-tabs.nav-tabs {
  border-bottom: none;
  padding: 60px;
  display: block;
  /* Large devices */
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .choose-tabs-tabs.nav-tabs {
    padding: 60px 40px;
  }
}

@media (max-width: 991px) {
  .choose-tabs-tabs.nav-tabs {
    padding: 60px 40px 40px 40px;
    margin-bottom: 0;
  }
}

@media (max-width: 375px) {
  .choose-tabs-tabs.nav-tabs {
    max-width: 100%;
    padding: 60px 20px 20px 20px;
  }
}

.choose-tabs-tabs.nav-tabs .nav-link:focus,
.choose-tabs-tabs.nav-tabs .nav-link:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.choose-tabs-tabs.nav-tabs .nav-item.show .nav-link,
.choose-tabs-tabs.nav-tabs .nav-link.active {
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.choose-tabs-tabs .nav-link {
  position: relative;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1D2128;
  padding: 18px 40px;
  font-family: var(--title-font);
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  min-width: 330px;
  color: var(--white-color);
  text-align: left;
  display: block;
  box-shadow: none;
  /* Medium devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .choose-tabs-tabs .nav-link {
    min-width: 100%;
  }
}

@media (max-width: 575px) {
  .choose-tabs-tabs .nav-link {
    padding: 14px 20px;
  }
}

@media (max-width: 375px) {
  .choose-tabs-tabs .nav-link {
    font-size: 16px;
  }
}

.choose-tabs-tabs .nav-link:not(:last-child) {
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .choose-tabs-tabs {
    margin-bottom: 30px;
  }
}

.choose-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  padding: 60px;
  /* Large devices */
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .choose-wrapper {
    display: block;
    padding: 60px 40px 40px 0;
    margin: 0;
    border: none;
  }
}

@media (max-width: 991px) {
  .choose-wrapper {
    padding: 30px 40px 60px 40px;
  }
}

@media (max-width: 375px) {
  .choose-wrapper {
    padding: 30px 20px 60px 20px;
  }
}

.choose-wrapper .choose-image {
  min-width: 276px;
  height: 300px;
  border-radius: 30px;
  /* Large devices */
}

.choose-wrapper .choose-image img {
  width: 100%;
  border-radius: 30px;
}

@media (max-width: 1199px) {
  .choose-wrapper .choose-image {
    margin-top: 30px;
    min-width: 100%;
    width: 100%;
    height: 100%;
  }
}

.choose-content .sec-title {
  font-weight: 500;
  letter-spacing: normal;
}

.choose-content .box-text {
  font-size: 14px;
}

.choose-content .checklist {
  margin-bottom: 35px;
}

.choose-content .checklist li {
  color: var(--body-color);
  font-size: 14px;
  line-height: 16px;
}

.choose-content .checklist li:after {
  color: var(--body-color);
}

/* choose area  6 start ------------------------------------- */
.choose-image2 {
  position: relative;
  text-align: right;
  margin-right: 95px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .choose-image2 {
    margin: 0 0 40px 0;
  }
}

.choose-image2 .img1 {
  min-width: 561px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .choose-image2 .img1 {
    min-width: 100%;
  }
}

.choose-image2 .img1 img {
  width: 100%;
}

.choose-image2 .video-box4 {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* Large devices */
}

@media (max-width: 1199px) {
  .choose-image2 .video-box4 {
    left: 50%;
  }
}

.choose-image2 .video-box4 .play-btn:after, .choose-image2 .video-box4 .play-btn:before {
  background-color: var(--theme-color);
}

.choose-image2 .video-box4 .play-btn > i {
  --icon-size: 167px;
  --icon-font-size: 1.9em;
  background-color: var(--theme-color);
  color: var(--white-color);
  /* Extra small devices */
}

@media (max-width: 575px) {
  .choose-image2 .video-box4 .play-btn > i {
    --icon-size: 114px;
  }
}

.choose-text {
  color: #A1A6B0;
  font-size: 16px;
}

/* choose area  6 end ------------------------------------- */
/* Appointment 3 ---------------------------------- */
.appointment-area {
  padding: 100px;
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .appointment-area {
    padding: 80px;
    margin-top: 80px;
  }
}

@media (max-width: 767px) {
  .appointment-area {
    padding: 80px 40px;
  }
}

@media (max-width: 575px) {
  .appointment-area {
    padding: 80px 20px;
  }
}

.appointment-area .sub-title:after {
  background-color: var(--white-color);
}

.feature-wrapper.style3 {
  margin-bottom: 60px;
}

.feature-wrapper.style3 .feature-icon {
  width: 75px;
  height: 75px;
  line-height: 75px;
  background-color: #1E2127;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.feature-wrapper.style3 .feature-icon:hover {
  background-color: var(--theme-color);
}

.feature-wrapper.style3 .header-info_label {
  font-size: 16px;
  font-family: var(--body-font);
  color: var(--body-color);
  font-weight: 500;
  letter-spacing: -0.32px;
}

.feature-wrapper.style3 .header-info_link {
  font-family: var(--body-font);
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.48px;
}

.work-area {
  margin-bottom: 40px;
}

.work-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  /* Small devices */
}

.work-item:first-child {
  max-width: 290px;
  /* Medium Large devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .work-item:first-child {
    max-width: 250px;
  }
}

@media (max-width: 767px) {
  .work-item:first-child {
    max-width: 100%;
  }
}

.work-item:first-child:before {
  right: -15px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .work-item:first-child:before {
    right: -10px;
  }
}

@media (max-width: 767px) {
  .work-item {
    margin-bottom: 20px;
  }
}

.work-item:before {
  content: url("data:image/svg+xml,%3Csvg width='20' height='37' viewBox='0 0 20 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 18.5L0 0L10.6446 18.5L0 37L20 18.5Z' fill='%233E66F3'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: 0px;
  right: -40px;
  font-family: var(--icon-font);
  width: 20px;
  height: 37px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  color: var(--theme-color);
  font-weight: 900;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  /* Large devices */
}

@media (max-width: 1199px) {
  .work-item:before {
    display: none;
  }
}

.work-item:last-child:before {
  display: none;
}

.work-item_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* Small devices */
}

@media (max-width: 767px) {
  .work-item_wrapper {
    display: block;
  }
}

.work-item_number {
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  display: block;
  background-color: #1E2127;
  color: var(--white-color);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.work-item .work-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #C7C7C7;
}

/* Why 8 ---------------------------------- */
.why-sec-4 {
  /* Large devices */
}

@media (max-width: 1199px) {
  .why-sec-4 {
    background: transparent !important;
  }
}

.why-feature-wrap-4-1 {
  margin-top: 40px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .why-feature-wrap-4-1 {
    margin-top: 0;
  }
}

.why-feature-wrap-4-2 {
  margin-top: 100px;
  margin-left: 55px;
  /* Medium Large devices */
  /* Medium Large devices */
  /* Medium devices */
}

@media (max-width: 1399px) {
  .why-feature-wrap-4-2 {
    margin-left: 45px;
  }
}

@media (max-width: 1299px) {
  .why-feature-wrap-4-2 {
    margin-top: 40px;
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .why-feature-wrap-4-2 {
    margin-bottom: 40px;
  }
}

.why-thumb-4 {
  text-align: center;
}

/*------------------- 4.00. Faq -------------------*/
/* Faq 1 ---------------------------------- */
.accordion-card {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  box-shadow: 0px 5px 15px rgba(42, 77, 113, 0.04);
  border-radius: 5px;
  overflow: hidden;
}

.accordion-card:not(:last-child) {
  margin-bottom: 24px;
}

.accordion-card .accordion-button {
  font-size: 18px;
  font-weight: 700;
  font-family: var(--title-font);
  border: 0;
  color: var(--title-color);
  background-color: var(--white-color);
  border-radius: 0;
  padding: 12px 45px 12px 30px;
  min-height: 56px;
  gap: 10px;
  margin-bottom: 0;
  text-align: left;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}

.accordion-card .accordion-button:after {
  content: "\2b";
  height: 100%;
  width: auto;
  line-height: 1;
  background-color: transparent;
  font-family: var(--icon-font);
  color: var(--title-color);
  font-weight: 700;
  font-size: 1em;
  display: grid;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: 30px;
  -webkit-clip-path: polygon(20px 0%, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(20px 0%, 100% 0, 100% 100%, 0% 100%);
  padding-left: 10px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.accordion-card .accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.accordion-card .accordion-button:not(.collapsed) {
  color: var(--white-color);
  background-color: var(--theme-color);
  box-shadow: none;
  border-radius: 0;
}

.accordion-card .accordion-button:not(.collapsed):after {
  content: '\f068';
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
  color: var(--white-color);
}

.accordion-card .accordion-collapse {
  border: none;
}

.accordion-card .accordion-body {
  border-radius: 0;
  background-color: var(--white-color);
  border: none;
  padding: 23px 30px 30px 30px;
}

.accordion-card .faq-text {
  margin-bottom: -0.48em;
}

.accordion-card .faq-img {
  height: 100%;
}

.accordion-card .faq-img img {
  height: 100%;
  object-fit: cover;
}

.accordion-card.style2 {
  box-shadow: none;
  border: 1px solid var(--smoke-color2);
  box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
}

.accordion-card.style2:not(:last-child) {
  margin-bottom: 24px;
}

.accordion-card.style2 .accordion-button {
  background-color: var(--white-color);
  box-shadow: none;
}

.accordion-card.style2 .accordion-button:not(.collapsed) {
  color: var(--white-color);
  border-bottom: none;
  background-color: var(--theme-color);
}

.accordion-card.style3 {
  border-radius: 20px;
  box-shadow: 0px 5px 15px rgba(42, 77, 113, 0.04);
}

.accordion-card.style3:not(:last-child) {
  margin-bottom: 30px;
}

.accordion-card.style3 .accordion-button {
  background-color: var(--white-color);
  box-shadow: none;
}

.accordion-card.style3 .accordion-button:not(.collapsed) {
  color: var(--title-color);
  background-color: var(--white-color);
  box-shadow: none;
}

.accordion-card.style3 .accordion-button:not(.collapsed):after {
  color: var(--theme-color);
}

.accordion-card.style3 .accordion-body {
  padding: 0 30px 30px 30px;
}

.accordion-card.style4 {
  position: relative;
  box-shadow: none;
  margin-bottom: 0;
  z-index: 2;
}

.accordion-card.style4 .accordion-button {
  background-color: transparent;
  padding: 40px 38px 40px 0px;
  font-size: 20px;
  font-weight: 500;
  color: var(--white-color);
}

.accordion-card.style4 .accordion-button.collapsed {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.accordion-card.style4 .accordion-button:after {
  width: 36px;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  top: 30px;
  right: 0px;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-clip-path: none;
          clip-path: none;
  padding: 0;
}

.accordion-card.style4 .accordion-button:before {
  content: url("data:image/svg+xml,%3Csvg width='36' height='40' viewBox='0 0 36 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='35' height='39' rx='9.5' stroke='url(%23paint0_linear_434_637)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_434_637' x1='18' y1='0' x2='18' y2='40' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2302CE80'/%3E%3Cstop offset='1' stop-color='%237BD706'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  position: absolute;
  top: 30px;
  right: 0px;
  width: 36px;
  height: 40px;
  z-index: -2;
}

.accordion-card.style4 .accordion-body {
  background-color: transparent;
  padding: 0 0 40px 0;
}

.accordion-card.style4 .faq-text {
  color: #8D929C;
}

.accordion-card.style4 .accordion-collapse {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.accordion-card.style5 .accordion-button {
  min-height: 55px;
  font-weight: 500;
}

.accordion-card.style5 .accordion-button:after {
  content: "\f107";
}

.accordion-card.style5 .accordion-button:not(.collapsed):after {
  content: '\f106';
}

.accordion-card.style5 .accordion-body {
  padding: 20px 30px 30px 30px;
}

.accordion-card.style6 {
  background-color: transparent;
  box-shadow: none;
  overflow: visible;
}

.accordion-card.style6:first-child .accordion-button:before {
  background-color: transparent;
}

.accordion-card.style6:last-child .accordion-button:before {
  background-color: transparent;
}

.accordion-card.style6 .accordion-button {
  background-color: transparent;
  padding: 0;
  color: var(--white-color);
  min-height: auto;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 45px;
  margin-top: -0.2rem;
}

.accordion-card.style6 .accordion-button:before {
  content: "";
  position: absolute;
  bottom: 11px;
  left: -34px;
  width: 50px;
  height: 1px;
  background-color: var(--theme-color);
  /* Extra small devices */
}

@media (max-width: 375px) {
  .accordion-card.style6 .accordion-button:before {
    display: none;
  }
}

.accordion-card.style6 .accordion-button:after {
  color: var(--white-color);
  left: 0px;
  top: 5px;
  right: unset;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  background-color: var(--theme-color);
  border-radius: 50%;
  -webkit-clip-path: none;
          clip-path: none;
  background-image: none;
  padding: 0;
  font-weight: 400;
}

.accordion-card.style6 .accordion-header {
  margin-bottom: 6px;
}

.accordion-card.style6 .accordion-body {
  background-color: transparent;
  padding: 0 0 0 45px;
}

.accordion-card.style7 {
  box-shadow: none;
  border: 1px solid #E3E7F0;
  border-radius: 10px;
  box-shadow: none;
}

.accordion-card.style7:not(:last-child) {
  margin-bottom: 24px;
}

.accordion-card.style7 .accordion-button {
  background-color: #FBFCFF;
  box-shadow: none;
  font-weight: 500;
}

.accordion-card.style7 .accordion-button:not(.collapsed) {
  color: var(--white-color);
  border-bottom: none;
  background-color: var(--theme-color);
}

.accordion-area.style5 {
  margin-right: 93px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .accordion-area.style5 {
    margin-right: 0;
  }
}

.accordion-area.style6 {
  position: relative;
  margin-left: 40px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .accordion-area.style6 {
    margin: 0 0 80px 0;
  }
}

.accordion-area.style6:before {
  content: "";
  position: absolute;
  left: -35px;
  top: 15px;
  width: 50px;
  height: 85%;
  border: 1px solid var(--theme-color);
  border-right: 1px solid transparent;
  border-radius: 20px 0px 0px 20px;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .accordion-area.style6:before {
    display: none;
  }
}

.accordion-area .faq-text {
  color: #A1A6B0;
}

.accordion-area.grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 0 24px;
}

.accordion-area.grid .accordion-card {
  width: calc(50% - 12px);
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}

@media (min-width: 1300px) {
  .faq-img {
    margin-left: 56px;
    margin-right: -48px;
  }
}

.faq-img {
  text-align: center;
  position: relative;
  display: inline-block;
}

.faq-img .img-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  top: 0;
}

.faq-img .img-shape img {
  height: 100%;
  width: 100%;
  max-width: n;
}

.faq-img .img-shape .mask-icon {
  -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
}

.faq-img img {
  width: 100%;
  max-width: 700px;
}

/* Extra small devices */
@media (max-width: 575px) {
  .accordion-card .accordion-button {
    font-size: 16px;
  }
}

.faq-widget {
  background-color: var(--smoke-color);
  padding: 40px;
  margin-bottom: 40px;
  border-radius: 5px;
  text-align: center;
}

.faq-widget .title {
  margin-top: -0.23em;
}

.faq-form-wrap {
  margin-top: 70px;
}

.faq-form-wrap .form-text {
  max-width: 500px;
  margin-bottom: 25px;
}

/* Medium devices */
@media (max-width: 991px) {
  .faq-form-wrap {
    margin-top: 45px;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .faq-widget {
    padding: 40px 20px;
  }
}

.faq-img4 {
  position: relative;
  z-index: 2;
  width: 634px;
  height: 724.57px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .faq-img4 {
    margin-bottom: 50px;
  }
}

.faq-img4 .img1 {
  position: absolute;
  top: 0px;
  right: 0;
  width: 317px;
  height: 362.286px;
  border-radius: 1000px 1000px 0px 0px;
  /* Medium Large devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .faq-img4 .img1 {
    right: 30px;
  }
}

@media (max-width: 767px) {
  .faq-img4 .img1 {
    right: 60px;
  }
}

@media (max-width: 575px) {
  .faq-img4 .img1 {
    right: unset;
    left: 0;
  }
}

.faq-img4 .img1 img {
  border-radius: 1000px 1000px 0px 0px;
}

.faq-img4 .img2 {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 317px;
  height: 362.286px;
  border-radius: 0 0 1000px 1000px;
}

.faq-img4 .img2 img {
  border-radius: 0 0 1000px 1000px;
}

.faq-img4 .faq-client-box {
  position: absolute;
  left: 20%;
  top: 40%;
  padding: 20px 30px;
  border-radius: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 14px;
  background-color: var(--white-color);
  text-decoration: underline;
  box-shadow: 0px 15px 38px 0px rgba(0, 0, 0, 0.06);
  z-index: 3;
  -webkit-transform: translate(-50% -50%);
      -ms-transform: translate(-50% -50%);
          transform: translate(-50% -50%);
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 575px) {
  .faq-img4 .faq-client-box {
    left: 0;
  }
}

@media (max-width: 375px) {
  .faq-img4 .faq-client-box {
    display: block;
    text-align: center;
    padding: 10px 40px;
  }
}

.faq-img4 .faq-client-box .cilent-box_title {
  min-width: 170px;
  font-size: 18px;
  font-weight: 600;
  color: var(--title-color);
}

.faq-img4 .faq-client-box .client-thumb-group {
  min-width: 112px;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .faq-img4 .faq-client-box .client-thumb-group {
    margin-bottom: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.faq-img4 .faq-client-box .client-thumb-group .thumb:not(:first-child) {
  margin-left: -35px;
}

.faq-img4 .faq-shape {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 372px;
  height: 372px;
  background-color: rgba(50, 86, 217, 0.07);
  border-radius: 50%;
  z-index: 2;
  -webkit-transform: translate(-50% -50%);
      -ms-transform: translate(-50% -50%);
          transform: translate(-50% -50%);
}

.faq-img4 .faq-shape:after, .faq-img4 .faq-shape:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(50, 86, 217, 0.15);
  z-index: -2;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.faq-img4 .faq-shape:after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

/* faq 5 start -------------------------*/
.faq-img5 {
  position: relative;
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .faq-img5 {
    margin-bottom: 300px;
  }
}

@media (max-width: 575px) {
  .faq-img5 {
    margin-bottom: 250px;
  }
}

@media (max-width: 480px) {
  .faq-img5 {
    margin-bottom: 200px;
  }
}

@media (max-width: 480px) {
  .faq-img5 {
    margin-bottom: 50px;
  }
}

.faq-img5 img {
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
}

.faq-img5 .img2 {
  position: absolute;
  right: 30px;
  bottom: -28%;
  /* Small devices */
}

@media (max-width: 767px) {
  .faq-img5 .img2 {
    display: none;
  }
}

.faq-img5 .img3 {
  position: absolute;
  left: -10%;
  bottom: -42%;
}

@media (max-width: 480px) {
  .faq-img5 .img3 {
    display: none;
  }
}

.faq-img5 .faq-shape5 {
  position: absolute;
  bottom: -48%;
  right: 1%;
  width: 610px;
  height: 367px;
  border-radius: 20px;
  border: 2px solid var(--theme-color);
  z-index: -1;
}

@media (max-width: 480px) {
  .faq-img5 .faq-shape5 {
    display: none;
  }
}

/* faq 5 end -------------------------*/
/* faq 6 start ----------------------------*/
.faq-img6 {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-clip-path: polygon(42% 0%, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(42% 0%, 100% 0, 100% 100%, 0% 100%);
  z-index: 3;
  /* Large devices */
}

@media (max-width: 1199px) {
  .faq-img6 {
    display: none;
  }
}

/* faq 6 end ----------------------------*/
/* faq 7 start ----------------------------*/
.faq-img7 {
  position: relative;
  /* Large devices */
}

@media (max-width: 1199px) {
  .faq-img7 {
    margin-bottom: 100px;
  }
}

.faq-img7 .img1 {
  border: 1px solid var(--theme-color);
  padding: 25px;
  padding-bottom: 70px;
  border-radius: 20px;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .faq-img7 .img1 {
    padding-bottom: 25px;
  }
}

.faq-img7 .img2 {
  position: absolute;
  left: 5%;
  bottom: -15%;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .faq-img7 .img2 {
    display: none;
  }
}

.faq-img7 .img2 img {
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 10px;
}

.faq-img7 .img3 {
  position: absolute;
  right: 20%;
  bottom: -5%;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .faq-img7 .img3 {
    display: none;
  }
}

.faq-img7 .img3 img {
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 10px;
}

/* faq 7 end ----------------------------*/
/* faq 8 start ----------------------------*/
.accordion-card.style2.th-radius1 {
  border-radius: 10px;
}

.faq-img8 {
  position: relative;
  text-align: right;
  z-index: 3;
  /* Large devices */
}

@media (max-width: 1199px) {
  .faq-img8 {
    text-align: center;
    margin-top: 50px;
  }
}

.faq-img8 .img1 {
  /* Large devices */
}

@media (max-width: 1199px) {
  .faq-img8 .img1 {
    padding: 20px 0;
  }
}

.faq-img8 img {
  border-radius: 20px;
}

.faq-img8 .img2 {
  position: absolute;
  bottom: -135px;
  left: 60px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .faq-img8 .img2 {
    bottom: 0;
  }
}

.faq-img8 .img2 img {
  border-radius: 20px;
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
}

.faq-img8 .faq8-shape {
  position: absolute;
  bottom: -135px;
  right: -50px;
  width: 610px;
  height: 367px;
  border: 2px solid #3E66F3;
  border-radius: 20px;
  z-index: -1;
  /* Extra large devices */
  /* Medium Large devices */
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1500px) {
  .faq-img8 .faq8-shape {
    right: 0;
  }
}

@media (max-width: 1299px) {
  .faq-img8 .faq8-shape {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .faq-img8 .faq8-shape {
    height: 100%;
    bottom: 0;
  }
}

@media (max-width: 767px) {
  .faq-img8 .faq8-shape {
    border: none;
  }
}

/* faq 8 end ----------------------------*/
/*------------------- 4.00. CTA -------------------*/
.img-half {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
}

.img-half img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-half .img-left {
  left: 0;
}

.th-video2:after {
  content: "";
  height: 140px;
  width: 140px;
  background-color: #080E1C;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 70px);
  right: -70px;
}

.th-video2 .play-btn {
  position: absolute;
  right: -40px;
  top: calc(50% - 40px);
}

.th-video2 .play-btn > i {
  --icon-size: 80px;
}

.th-video2 .play-btn:before, .th-video2 .play-btn:after {
  background-color: var(--theme-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .img-half {
    position: relative;
    width: 100%;
    height: auto;
  }
  .th-video2:after {
    right: calc(50% - 70px);
    top: calc(100% - 70px);
  }
  .th-video2 .play-btn {
    position: absolute;
    top: calc(100% - 40px);
    right: calc(50% - 40px);
  }
}

.cta-box {
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--smoke-color);
}

.cta-box_img {
  height: 100%;
}

.cta-box_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cta-box_content {
  padding: 70px 70px 70px 55px;
  position: relative;
}

.cta-box_icon {
  position: absolute;
  right: 70px;
  bottom: 70px;
}

/* Medium devices */
@media (max-width: 991px) {
  .cta-box {
    text-align: center;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .cta-box_content {
    padding: 70px 20px;
  }
  .cta-box_icon {
    bottom: 20px;
    right: 20px;
  }
}

/*CTA Area 4***********************/
.cta-area4 {
  padding: 0 20px 91px 20px;
  border-radius: 30px;
}

.cta-area4 .cta-title {
  border-radius: 100px;
  border: 1px solid #3E66F3;
  background: var(--white-color);
  padding: 6px 49px;
  margin: 0px 0 49px 0;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: inline-block;
}

.cta-area4 .cta-title.sub-title:before {
  top: 8px;
  left: 25px;
}

.cta-area4 .cta-title.sub-title:after {
  top: 8px;
  right: 25px;
}

.cta-area4 .cta-text {
  font-size: 18px;
  font-weight: 500;
  max-width: 460px;
  display: block;
  margin: auto;
}

.cta-area4 .counter-title {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.96px;
  margin-bottom: 0;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .cta-area4 .counter-title {
    font-size: 25px;
  }
}

.cta-area4 .title-area .sec-title {
  margin-bottom: 26px;
}

.cta-area4 .cta-desc {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
}

.cta-area4 .th-btn {
  padding: 17px 46.5px;
}

/*CTA Area 5***********************/
.cta-area5 {
  padding: 173.5px 0;
  background-attachment: fixed;
  background-position: right;
  position: relative;
  /* Large devices */
  /* Medium devices */
}

.cta-area5:after {
  content: '';
  position: absolute;
  background: -webkit-linear-gradient(315deg, rgba(104, 77, 244, 0) -24.45%, var(--theme-color) 165.8%);
  background: linear-gradient(135deg, rgba(104, 77, 244, 0) -24.45%, var(--theme-color) 165.8%);
  -webkit-backdrop-filter: blur(10.5px);
          backdrop-filter: blur(10.5px);
  left: 0;
  top: 0;
  width: 60%;
  height: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
}

@media (max-width: 1199px) {
  .cta-area5:after {
    width: 100%;
    -webkit-clip-path: none;
            clip-path: none;
  }
}

@media (max-width: 991px) {
  .cta-area5 {
    padding: 120px 0;
  }
}

.cta-title-area .sec-title {
  font-size: 60px;
  line-height: 64px;
  letter-spacing: -1.2px;
  margin-bottom: 30px;
  /* Medium Large devices */
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .cta-title-area .sec-title {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .cta-title-area .sec-title {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 575px) {
  .cta-title-area .sec-title {
    font-size: 30px;
    line-height: 40px;
  }
}

.cta-title-area .sec-text {
  font-size: 18px;
  color: var(--white-color);
  font-weight: 600;
  margin-bottom: 0;
}

/*CTA Area 6***********************/
.cta-sec6 {
  padding: 80px 100px;
  border-radius: 30px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .cta-sec6 {
    padding: 80px 50px;
  }
}

.cta-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /* Medium devices */
}

@media (max-width: 991px) {
  .cta-content {
    display: block;
  }
}

.cta-content .cta-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 25px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  padding-right: 80px;
  margin-right: 80px;
  min-width: 440px;
  /* Medium Large devices */
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .cta-content .cta-wrapper {
    padding-right: 40px;
    margin-right: 40px;
    min-width: 360px;
  }
}

@media (max-width: 991px) {
  .cta-content .cta-wrapper {
    border: none;
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 375px) {
  .cta-content .cta-wrapper {
    display: block;
  }
}

.cta-content .cta-wrapper .cta-icon {
  width: 90px;
  height: 90px;
  line-height: 90px;
  border-radius: 90px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.22);
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(11px);
          backdrop-filter: blur(11px);
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  /* Extra small devices */
}

.cta-content .cta-wrapper .cta-icon:hover {
  background-color: var(--title-color);
}

@media (max-width: 375px) {
  .cta-content .cta-wrapper .cta-icon {
    margin-bottom: 20px;
  }
}

.cta-content .cta-wrapper .header-info_link {
  font-size: 30px;
  font-weight: 600;
  color: var(--white-color);
  letter-spacing: -0.3px;
  margin-bottom: 0;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .cta-content .cta-wrapper .header-info_link {
    font-size: 24px;
  }
}

.cta-content .cta-wrapper .header-info_link a {
  color: inherit;
}

.cta-content .title-area {
  margin-right: 64px;
  /* Medium Large devices */
  /* Medium devices */
}

@media (max-width: 1299px) {
  .cta-content .title-area {
    margin-right: 20px;
  }
}

@media (max-width: 991px) {
  .cta-content .title-area {
    margin: 30px 0 30px 0 !important;
  }
}

.cta-content .cta-group {
  min-width: 160px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  /* Medium devices */
}

@media (max-width: 991px) {
  .cta-content .cta-group {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin: 0;
  }
}

.cta-sec7 {
  max-width: 1860px;
  margin: auto;
  border-radius: 200px 200px 0 0;
  /* Medium devices */
}

@media (max-width: 991px) {
  .cta-sec7 {
    border-radius: 40px 40px 0 0;
  }
}

.cta-sec8 {
  border-radius: 30px;
  padding: 80px 0;
  text-align: center;
}

.cta-sec8 .sec-text {
  margin: 40px auto 33px;
}

.cta-sec8 .btn-wrap {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 32px;
}

/* cta 9 start ---------------------------- */
.cta-area9 {
  position: relative;
  padding: 120px;
  border-radius: 60px;
  background-color: #F9F9F9;
  max-width: 1620px;
  display: block;
  margin: auto;
  border-top: 2px solid rgba(62, 102, 243, 0.4);
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1299px) {
  .cta-area9 {
    padding: 120px 80px;
  }
}

@media (max-width: 1199px) {
  .cta-area9 {
    padding: 120px 40px;
  }
  .cta-area9 .container {
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .cta-area9 {
    padding: 80px 20px 80px 20px;
  }
}

.cta-feature_img {
  /* Medium devices */
}

@media (max-width: 991px) {
  .cta-feature_img {
    display: none;
  }
}

.cta-title-area2 {
  max-width: 543px;
  display: block;
  margin: auto;
  /* Medium devices */
}

@media (max-width: 991px) {
  .cta-title-area2 {
    max-width: 100%;
  }
}

.feature-area2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .feature-area2 {
    gap: 15px;
  }
}

@media (max-width: 540px) {
  .feature-area2 {
    display: block;
  }
}

.feature-area2 .th-btn {
  padding: 17px 44.5px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .feature-area2 .th-btn {
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }
}

.feature-wrapper.style2 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 15px;
  margin-bottom: 0;
}

.feature-wrapper.style2 .feature-icon {
  border-color: var(--theme-color);
}

.feature-wrapper.style2 .feature-icon:hover {
  background-color: var(--theme-color);
}

.feature-wrapper.style2 .feature-icon:hover i {
  color: var(--white-color);
}

.feature-wrapper.style2 .feature-icon i {
  color: var(--theme-color);
}

.feature-wrapper.style2 .header-info_link {
  color: var(--theme-color);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.36px;
  margin-bottom: -0.5rem;
}

.feature-wrapper.style2 .header-info_link a {
  color: inherit;
}

.feature-wrapper.style2 .header-info_label {
  color: var(--body-color);
}

/* cta 9 end ---------------------------- */
/* cta 10 start ---------------------*/
.cta-area10 {
  border-radius: 30px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .cta-area10 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.cta-area10 .sec-title {
  font-size: 54px;
  /* Medium Large devices */
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .cta-area10 .sec-title {
    font-size: 44px;
  }
}

@media (max-width: 1199px) {
  .cta-area10 .sec-title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .cta-area10 .sec-title {
    font-size: 30px;
  }
}

.cta-area10 .cta-text {
  font-size: 18px;
  max-width: 690px;
  display: block;
  margin: auto auto 30px auto;
}

/* cta 10 end ---------------------*/
.download4-thumb {
  position: relative;
}

.download4-thumb .img1 img {
  -webkit-filter: drop-shadow(1px 14px 70px rgba(62, 102, 243, 0.15));
          filter: drop-shadow(1px 14px 70px rgba(62, 102, 243, 0.15));
}

/*------------------- 4.00. Service -------------------*/
/* Global Style ---------------------------------- */
.shape-icon {
  position: relative;
  z-index: 2;
  width: 90px;
  height: 85px;
  line-height: 85px;
  text-align: center;
}

.shape-icon:before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--smoke-color2);
  -webkit-clip-path: path("M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z");
          clip-path: path("M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z");
  z-index: -1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.shape-icon .dots:before, .shape-icon .dots:after {
  content: '';
  position: absolute;
  background-color: var(--theme-color);
  height: 24px;
  width: 23px;
  border-radius: 50%;
}

.shape-icon .dots:before {
  top: 0;
  right: 9px;
}

.shape-icon .dots:after {
  height: 12px;
  width: 11px;
  bottom: 0;
  left: 27px;
}

/* Service Featured ---------------------------------- */
.service-featured {
  text-align: center;
  position: relative;
  z-index: 2;
}

.service-featured:before {
  content: '';
  height: 180px;
  width: 100%;
  background-color: var(--smoke-color);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 10px;
  z-index: -1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-featured .shape-icon {
  margin: 0 auto 30px auto;
}

.service-featured .icon-btn {
  border-radius: 99px;
  background-color: var(--theme-color);
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.5);
  border: none;
  color: var(--white-color);
  position: absolute;
  bottom: -28px;
  left: calc(50% - 28px);
}

.service-featured .icon-btn:hover {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.service-featured_content {
  background-color: var(--white-color);
  max-width: 312px;
  box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 15px 28px 15px;
  margin-bottom: 28px;
}

.service-featured_text {
  max-width: 255px;
  margin: 0 auto 31px auto;
}

/* Service Card ---------------------------------- */
.service-card {
  padding: 40px;
  position: relative;
  z-index: 2;
  box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
  background-color: var(--white-color);
  border-radius: 10px;
  overflow: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-card:before, .service-card:after {
  content: '';
  position: absolute;
  height: 110px;
  width: 110px;
  background-color: var(--theme-color);
  opacity: 0.6;
  border-radius: 50%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: -1;
}

.service-card:before {
  bottom: -73px;
  right: -28px;
}

.service-card:after {
  right: -73px;
  bottom: -28px;
}

.service-card .shape-icon {
  margin-bottom: 30px;
}

.service-card .box-title a:hover {
  color: var(--smoke-color2);
}

.service-card .bg-shape {
  position: absolute;
  bottom: -200px;
  left: 0;
  width: 100%;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  pointer-events: none;
}

.service-card .bg-shape img {
  width: 100%;
}

.service-card_number {
  position: absolute;
  top: 25px;
  right: 40px;
  font-size: 100px;
  line-height: 1;
  font-weight: bold;
  color: var(--smoke-color2);
  opacity: 0.3;
}

.service-card_text {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  margin-bottom: 22px;
}

.service-card .th-btn {
  background-color: var(--smoke-color2);
  color: var(--title-color);
  padding: 12.5px 20px;
  box-shadow: none;
}

.service-card .th-btn:before, .service-card .th-btn:after {
  background-color: var(--smoke-color2);
}

.service-card:hover {
  width: 100%;
}

.service-card:hover:before, .service-card:hover:after {
  opacity: 1;
  height: 120%;
  width: 120%;
  border-radius: 0;
}

.service-card:hover .shape-icon:before {
  background-color: var(--white-color);
}

.service-card:hover .bg-shape {
  bottom: 0;
  opacity: 1;
}

.service-card:hover .box-title {
  color: var(--white-color);
}

.service-card:hover .th-btn {
  background-color: var(--white-color);
}

.service-card:hover .service-card_text {
  color: var(--white-color);
}

.service-sec {
  background-size: auto;
  background-position: top center;
  background-color: var(--smoke-color);
}

/* Extra small devices */
@media (max-width: 375px) {
  .service-card {
    padding: 40px 30px;
  }
}

/* Service Box ---------------------------------- */
.service-box {
  position: relative;
  box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
  background-color: var(--white-color);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-box .bg-shape {
  position: absolute;
  bottom: -200px;
  left: 0;
  width: 100%;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  pointer-events: none;
}

.service-box .bg-shape img {
  width: 100%;
}

.service-box_img {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.service-box_img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-box .box-title {
  margin-bottom: 12px;
}

.service-box .box-title a:hover {
  color: var(--smoke-color2);
}

.service-box_text {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  max-width: 230px;
  margin: 0 auto 10px auto;
}

.service-box_icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: var(--white-color);
  margin: -40px auto 0 auto;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: 50%;
}

.service-box_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-box_content {
  position: relative;
  padding: 0 10px 25px 10px;
}

.service-box:hover {
  background-color: var(--theme-color);
}

.service-box:hover .bg-shape {
  bottom: 0;
  opacity: 1;
}

.service-box:hover .box-title {
  color: var(--white-color);
}

.service-box:hover .link-btn {
  color: var(--white-color);
}

.service-box:hover .link-btn:before {
  background-color: var(--white-color);
  width: 100%;
}

.service-box:hover .link-btn:hover:before {
  width: 70px;
}

.service-box:hover .service-box_img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.service-box:hover .service-box_icon {
  background-color: var(--theme-color);
}

.service-box:hover .service-box_icon img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.service-box:hover .service-box_text {
  color: var(--white-color);
}

/* Service Grid ---------------------------------- */
.service-grid {
  position: relative;
  margin-top: -40px;
}

.service-grid .bg-shape {
  position: absolute;
  bottom: -200px;
  left: 0;
  width: 100%;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  pointer-events: none;
}

.service-grid .bg-shape img {
  width: 100%;
}

.service-grid_content {
  box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
  background-color: var(--white-color);
  border-radius: 10px;
  text-align: center;
  padding: 70px 15px 40px 15px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  overflow: hidden;
}

.service-grid_icon {
  height: 80px;
  width: 80px;
  line-height: 80px;
  background-color: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 2;
  margin: 0 auto 0 auto;
  -webkit-transform: translateY(40px);
      -ms-transform: translateY(40px);
          transform: translateY(40px);
}

.service-grid_icon img {
  position: relative;
  z-index: 2;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-grid_icon:after, .service-grid_icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--theme-color);
  z-index: -2;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.service-grid_icon:after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.service-grid .box-title {
  margin-bottom: 15px;
}

.service-grid .box-title a:hover {
  color: var(--smoke-color2);
}

.service-grid_text {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  max-width: 230px;
  margin: 0 auto 22px auto;
}

.service-grid .th-btn {
  padding: 15.5px 18px;
}

.service-grid:hover .bg-shape {
  bottom: 0;
  opacity: 1;
}

.service-grid:hover .box-title {
  color: var(--white-color);
}

.service-grid:hover .th-btn {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.service-grid:hover .th-btn:hover {
  color: var(--white-color);
}

.service-grid:hover .service-grid_content {
  background-color: var(--theme-color);
}

.service-grid:hover .service-grid_icon {
  background-color: var(--white-color);
}

.service-grid:hover .service-grid_icon img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-filter: none;
          filter: none;
}

.service-grid:hover .service-grid_icon:before, .service-grid:hover .service-grid_icon:after {
  background-color: var(--white-color);
}

.service-grid:hover .service-grid_text {
  color: var(--white-color);
}

/* Service 3D ---------------------------------- */
.service-3d {
  text-align: center;
  background: var(--white-color);
  border-radius: 10px;
  padding: 40px 12px;
}

.service-3d_text {
  max-width: 315px;
  margin: 0 auto 22px auto;
}

.service-3d_icon {
  margin-bottom: 30px;
}

.service-3d_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-3d .th-btn {
  padding: 15.5px 18px;
  background-color: var(--smoke-color2);
  color: var(--title-color);
  box-shadow: none;
}

.service-3d .th-btn:before, .service-3d .th-btn:after {
  background-color: var(--smoke-color2);
}

.service-3d .th-btn:hover {
  color: var(--white-color);
}

.service-3d .th-btn:hover:before, .service-3d .th-btn:hover:after {
  background-color: var(--theme-color);
}

.service-3d:hover .service-3d_icon img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/* Service Card 6---------------------------------- */
.service-area6 {
  background: -webkit-linear-gradient(top, rgba(237, 240, 244, 0) 0%, rgba(237, 240, 244, 0.85) 100%);
  background: linear-gradient(180deg, rgba(237, 240, 244, 0) 0%, rgba(237, 240, 244, 0.85) 100%);
  overflow: hidden;
}

.service-item {
  border-radius: 30px;
  border: 1px solid var(--white-color);
  padding: 40px 30px;
  background: -webkit-linear-gradient(top, #FFF 77.78%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(180deg, #FFF 77.78%, rgba(255, 255, 255, 0) 100%);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.service-item_icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: block;
  margin-bottom: 28px;
  background-color: #F3F5FC;
  border-radius: 50%;
}

.service-item_text {
  font-size: 16px;
  margin-bottom: 15px;
}

.service-item .box-title {
  letter-spacing: -0.48px;
  margin-bottom: 10px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .service-item .box-title {
    font-size: 20px;
  }
}

.service-item img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.service-item:hover .service-item_icon img {
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
}

/* Service Card 7---------------------------------- */
.service-area7 {
  position: relative;
  background-color: #EFF1F9;
  border-radius: 30px;
  overflow: hidden;
  /* Extra large devices */
}

@media (max-width: 1500px) {
  .service-area7 {
    border-radius: 0;
  }
}

.service-grid7 {
  position: relative;
  background: var(--white-color);
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #E3E7F0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .service-grid7 {
    padding: 25px;
  }
}

.service-grid7_icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #E3E7F0;
  background-color: var(--white-color);
  box-shadow: drop-shadow(0px 26px 65px rgba(232, 234, 238, 0.55));
  border-radius: 50%;
  z-index: 9;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-grid7_icon img {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-grid7_text {
  margin-bottom: 15px;
}

.service-grid7 .box-title {
  font-weight: 600;
  margin: 25px 0 5px 0;
}

.service-grid7 .icon-btn {
  border-radius: 99px;
  background-color: var(--white-color);
  border: 1px solid var(--th-border-color);
  color: var(--title-color);
  position: relative;
  z-index: 3;
  display: block;
  text-align: center;
  margin: auto;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.service-grid7 .icon-btn:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white-color);
}

.service-grid7 .line-btn {
  font-family: var(--body-font);
}

.service-grid7:hover .service-grid7_img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.service-grid7:hover .service-grid7_icon {
  border: 1px solid var(--theme-color);
}

.service-grid7:hover .service-grid7_icon:before {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}

.service-grid7:hover .service-grid7_icon img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/* Service Box 7---------------------------------- */
.service-box7 {
  text-align: center;
}

.service-box7_thumb {
  border-radius: 20px;
  background: #EFF1F9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: end;
      -ms-flex-align: end;
          align-items: end;
  margin-bottom: 50px;
}

.service-box7_thumb img {
  margin: 20px 20px 0 20px;
  border-radius: 10px 10px 0 0;
  width: -webkit-fill-available;
}

.service-box7_content {
  margin: 0 30px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .service-box7_content {
    margin: 0;
  }
}

.service-box7_text {
  margin-bottom: -0.4em;
}

/* Service Details ---------------------------------- */
.page-title {
  margin-top: -0.22em;
  font-size: 40px;
  margin-bottom: 20px;
}

.page-img {
  margin-bottom: 40px;
  border-radius: 5px;
  overflow: hidden;
}

.page-single {
  margin-bottom: 30px;
}

.service-feature {
  background-color: var(--white-color);
  padding: 30px 25px 30px 30px;
  border: 1px solid var(--th-border-color);
  box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}

.service-feature-wrap {
  display: grid;
  grid-template-columns: auto auto;
  gap: 25px;
}

.service-feature_icon {
  background: var(--theme-color);
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
  border-radius: 5px;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
}

.service-feature_title {
  font-size: 20px;
  margin-bottom: 12px;
}

.service-feature_text {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .page-title {
    font-size: 38px;
  }
}

/* Medium Large devices */
@media (max-width: 1299px) {
  .service-feature {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    gap: 20px;
    padding: 30px 10px;
  }
  .service-feature_text {
    margin-bottom: -0.5em;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .page-title {
    font-size: 32px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .page-title {
    font-size: 28px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .page-title {
    font-size: 24px;
  }
  .service-feature-wrap {
    grid-template-columns: auto;
  }
}

/* Service Card ---------------------------------- */
.service-card2 {
  border-radius: 20px;
  background: #16181D;
  padding: 40px;
  min-width: 327px;
  text-align: center;
  background-position: bottom center;
  background-size: auto;
  background-repeat: no-repeat;
  /* Medium Large devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .service-card2 {
    min-width: 300px;
    padding: 30px;
    background-size: contain;
  }
}

@media (max-width: 767px) {
  .service-card2 {
    padding: 30px 20px;
    min-width: 100%;
  }
}

@media (max-width: 575px) {
  .service-card2 {
    padding: 30px;
  }
}

.service-card2.style1 {
  text-align: left;
  max-width: 678px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .service-card2.style1 {
    max-width: 100%;
    background-position: right center;
  }
}

.service-card2.style1 .service-card2_content {
  max-width: 260px;
}

.service-card2.style1 .service-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: var(--white-color);
  margin-bottom: 28px;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .service-card2.style1 .service-title {
    font-size: 25px;
    line-height: 35px;
  }
}

.service-card2.style1 .ser-shape {
  position: absolute;
  top: 50px;
  right: 15px;
}

.service-card2.style1 .cilent-box_counter {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--white-color);
  max-width: 200px;
}

.service-card2.style1 .counter-number {
  font-weight: 500;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.service-card2_wrap {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .service-card2_wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.service-card2_wrap.style1 {
  /* Large devices */
}

@media (max-width: 1199px) {
  .service-card2_wrap.style1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.service-card2_wrap .service-shape {
  /* Medium Large devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .service-card2_wrap .service-shape {
    left: 26% !important;
  }
}

@media (max-width: 575px) {
  .service-card2_wrap .service-shape {
    display: none;
  }
}

.service-card2_img {
  min-width: 100%;
  border-radius: 26px;
}

.service-card2_img img {
  width: 100%;
  border-radius: 26px;
}

.service-card2_content {
  position: relative;
}

.service-card2_icon {
  position: relative;
  margin-bottom: 40px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-card2_icon:before {
  content: "";
  position: absolute;
  left: 25%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: rgba(97, 255, 24, 0.1);
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .service-card2_icon:before {
    left: 30%;
  }
}

@media (max-width: 575px) {
  .service-card2_icon:before {
    left: 40%;
  }
}

.service-card2_icon img {
  display: block;
  position: relative;
  z-index: 9;
  margin: auto;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.service-card2 .box-title {
  color: var(--white-color);
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 15px;
  /* Small devices */
}

@media (max-width: 767px) {
  .service-card2 .box-title {
    font-size: 22px;
    line-height: 34px;
  }
}

.service-card2_text {
  color: #848994;
  line-height: 26px;
  margin-bottom: -0.4rem;
}

.service-card2 .th-btn {
  padding: 16px 35px;
  color: var(--title-color);
}

.service-card2:hover .service-card2_icon img {
  -webkit-transform: scale(-1) rotate(180deg);
      -ms-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}

/* service 10 start -------------------*/
.service-area4 {
  background-color: var(--white-color);
  margin: 0 100px;
}

@media (max-width: 1699px) {
  .service-area4 {
    margin: 0;
  }
}

.service-box2 {
  position: relative;
  padding: 30px 30px 10px 30px;
  z-index: 2;
  overflow: hidden;
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}

.service-box2_shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 148px;
  height: 148px;
  background-position: right center;
  background-size: auto;
  background-repeat: no-repeat;
  z-index: 9;
}

.service-box2:before, .service-box2:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #F3F2EA;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: -1;
  -webkit-clip-path: path("M307 0H20C8.9543 0 0 8.9543 0 20V230C0 241.046 8.95431 250 20 250H251C262.046 250 271 241.046 271 230V224C271 207.431 284.431 194 301 194H307C318.046 194 327 185.046 327 174V20C327 8.9543 318.046 0 307 0Z");
          clip-path: path("M307 0H20C8.9543 0 0 8.9543 0 20V230C0 241.046 8.95431 250 20 250H251C262.046 250 271 241.046 271 230V224C271 207.431 284.431 194 301 194H307C318.046 194 327 185.046 327 174V20C327 8.9543 318.046 0 307 0Z");
  /* Large devices */
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 1399px) {
  .service-box2:before, .service-box2:after {
    -webkit-clip-path: path("M295 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.9543 250 20 250H241.055C252.101 250 261.055 241.046 261.055 230V224C261.055 207.431 274.487 194 291.055 194H295C306.046 194 315 185.046 315 174V20C315 8.9543 306.046 0 295 0Z");
            clip-path: path("M295 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.9543 250 20 250H241.055C252.101 250 261.055 241.046 261.055 230V224C261.055 207.431 274.487 194 291.055 194H295C306.046 194 315 185.046 315 174V20C315 8.9543 306.046 0 295 0Z");
  }
}

@media (max-width: 1299px) {
  .service-box2:before, .service-box2:after {
    -webkit-clip-path: path("M230 0H20C8.95431 0 0 8.95431 0 20V250C0 261.046 8.95431 270 20 270H187.187C198.232 270 207.187 261.046 207.187 250V235.208C207.187 221.021 218.687 209.52 232.875 209.52C242.333 209.52 250 201.853 250 192.395V20C250 8.95431 241.046 0 230 0Z");
            clip-path: path("M230 0H20C8.95431 0 0 8.95431 0 20V250C0 261.046 8.95431 270 20 270H187.187C198.232 270 207.187 261.046 207.187 250V235.208C207.187 221.021 218.687 209.52 232.875 209.52C242.333 209.52 250 201.853 250 192.395V20C250 8.95431 241.046 0 230 0Z");
  }
}

@media (max-width: 1199px) {
  .service-box2:before, .service-box2:after {
    -webkit-clip-path: path("M430 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.95432 250 20 250H352.936C363.981 250 372.936 241.046 372.936 230V224C372.936 207.431 386.367 194 402.936 194H430C441.046 194 450 185.046 450 174V20C450 8.9543 441.046 0 430 0Z");
            clip-path: path("M430 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.95432 250 20 250H352.936C363.981 250 372.936 241.046 372.936 230V224C372.936 207.431 386.367 194 402.936 194H430C441.046 194 450 185.046 450 174V20C450 8.9543 441.046 0 430 0Z");
  }
}

@media (max-width: 991px) {
  .service-box2:before, .service-box2:after {
    -webkit-clip-path: path("M307 0H20C8.9543 0 0 8.9543 0 20V230C0 241.046 8.95431 250 20 250H251C262.046 250 271 241.046 271 230V224C271 207.431 284.431 194 301 194H307C318.046 194 327 185.046 327 174V20C327 8.9543 318.046 0 307 0Z");
            clip-path: path("M307 0H20C8.9543 0 0 8.9543 0 20V230C0 241.046 8.95431 250 20 250H251C262.046 250 271 241.046 271 230V224C271 207.431 284.431 194 301 194H307C318.046 194 327 185.046 327 174V20C327 8.9543 318.046 0 307 0Z");
  }
}

@media (max-width: 767px) {
  .service-box2:before, .service-box2:after {
    -webkit-clip-path: path("M430 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.95432 250 20 250H352.936C363.981 250 372.936 241.046 372.936 230V224C372.936 207.431 386.367 194 402.936 194H430C441.046 194 450 185.046 450 174V20C450 8.9543 441.046 0 430 0Z");
            clip-path: path("M430 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.95432 250 20 250H352.936C363.981 250 372.936 241.046 372.936 230V224C372.936 207.431 386.367 194 402.936 194H430C441.046 194 450 185.046 450 174V20C450 8.9543 441.046 0 430 0Z");
  }
}

@media (max-width: 480px) {
  .service-box2:before, .service-box2:after {
    -webkit-clip-path: path("M307 0H20C8.9543 0 0 8.9543 0 20V230C0 241.046 8.95431 250 20 250H251C262.046 250 271 241.046 271 230V224C271 207.431 284.431 194 301 194H307C318.046 194 327 185.046 327 174V20C327 8.9543 318.046 0 307 0Z");
            clip-path: path("M307 0H20C8.9543 0 0 8.9543 0 20V230C0 241.046 8.95431 250 20 250H251C262.046 250 271 241.046 271 230V224C271 207.431 284.431 194 301 194H307C318.046 194 327 185.046 327 174V20C327 8.9543 318.046 0 307 0Z");
  }
}

@media (max-width: 320px) {
  .service-box2:before, .service-box2:after {
    -webkit-clip-path: path("M270 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.9543 250 20 250H220.336C231.382 250 240.336 241.046 240.336 230V223.798C240.336 207.341 253.677 194 270.135 194C281.106 194 290 185.106 290 174.135V20C290 8.9543 281.046 0 270 0Z");
            clip-path: path("M270 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.9543 250 20 250H220.336C231.382 250 240.336 241.046 240.336 230V223.798C240.336 207.341 253.677 194 270.135 194C281.106 194 290 185.106 290 174.135V20C290 8.9543 281.046 0 270 0Z");
  }
}

.service-box2:before {
  width: 100%;
}

.service-box2:after {
  width: 0;
  background-color: var(--theme-color);
}

.service-box2_icon {
  margin-bottom: 25px;
}

.service-box2_text {
  max-width: 233px;
}

.service-box2 .box-title {
  font-size: 20px;
}

.service-box2 img {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.service-box2 .icon-btn {
  width: var(--btn-size, 46px);
  height: var(--btn-size, 46px);
  line-height: var(--btn-size, 46px);
  font-size: var(--btn-font-size, 16px);
  background-color: #F3F2EA;
  color: var(--theme-color);
  text-align: center;
  border-radius: 50px;
  border: none;
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
  position: absolute;
  right: 0;
  bottom: 5px;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
}

@media (max-width: 1399px) {
  .service-box2 .icon-btn {
    bottom: 0;
  }
}

@media (max-width: 1299px) {
  .service-box2 .icon-btn {
    bottom: 20px;
  }
}

@media (max-width: 1199px) {
  .service-box2 .icon-btn {
    bottom: 5px;
    right: 20px;
  }
}

@media (max-width: 991px) {
  .service-box2 .icon-btn {
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 767px) {
  .service-box2 .icon-btn {
    right: 80px;
  }
}

@media (max-width: 375px) {
  .service-box2 .icon-btn {
    right: 0;
  }
}

.service-box2:hover:after {
  width: 100%;
}

.service-box2:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.service-box2:hover .box-title {
  color: var(--white-color);
}

.service-box2:hover .icon-btn {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.service-box2:hover .service-box2_icon img {
  -webkit-transform: scale(-1) rotate(180deg);
      -ms-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}

.service-box2:hover .service-box2_text {
  color: var(--white-color);
}

/* service 10 end -------------------*/
/* Service 11 start ------------------*/
.service-area5 {
  position: relative;
}

.service-box3 {
  position: relative;
  background-color: #EFF1F9;
  border-radius: 30px;
  padding: 30px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .service-box3 {
    padding: 30px 20px;
  }
}

.service-box3_icon {
  margin-bottom: 30px;
}

.service-box3_icon img {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.service-box3_img {
  position: relative;
  border-radius: 30px;
  max-width: 678px;
}

.service-box3_img img {
  width: 100%;
  border-radius: 30px;
}

.service-box3_img .service-shape {
  position: absolute;
  bottom: -94px;
  left: -71px;
  z-index: -1;
  width: 131px;
  height: 134px;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: auto;
          mask-size: auto;
  background-color: #EFF1F9;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1399px) {
  .service-box3_img .service-shape {
    bottom: -99px;
    left: -73px;
  }
}

@media (max-width: 1299px) {
  .service-box3_img .service-shape {
    bottom: -136px;
    left: -72px;
  }
}

@media (max-width: 1199px) {
  .service-box3_img .service-shape {
    display: none;
  }
}

.service-box3_img .service-shape2 {
  position: absolute;
  bottom: -80px;
  right: -66px;
  z-index: -1;
  width: 122px;
  height: 124px;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: auto;
          mask-size: auto;
  background-color: #EFF1F9;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1399px) {
  .service-box3_img .service-shape2 {
    bottom: -88px;
    right: -66px;
  }
}

@media (max-width: 1299px) {
  .service-box3_img .service-shape2 {
    bottom: -122px;
    right: -66px;
  }
}

@media (max-width: 1199px) {
  .service-box3_img .service-shape2 {
    display: none;
  }
}

.service-box3 .box-title {
  font-weight: 600;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .service-box3 .box-title {
    font-size: 20px;
  }
}

.service-box3 .line-btn {
  font-size: 16px;
  text-transform: capitalize;
}

.service-box3:hover .service-box3_icon img {
  -webkit-transform: scale(-1) rotate(180deg);
      -ms-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}

.service-btn {
  min-width: 100%;
  min-height: 317px;
  background-color: var(--theme-color);
  border-radius: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
  font-size: 20px;
}

.service-btn-area {
  position: relative;
  z-index: 2;
}

.service-btn-area .ser-shape {
  position: absolute;
  top: -7%;
  left: -10%;
  z-index: 2;
  -webkit-transform: translate(50%, 50%);
      -ms-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
  z-index: 0;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .service-btn-area .ser-shape {
    top: -7%;
    left: -20%;
  }
}

@media (max-width: 1199px) {
  .service-btn-area .ser-shape {
    left: 20px;
  }
}

@media (max-width: 991px) {
  .service-btn-area .ser-shape {
    left: -20px;
  }
}

@media (max-width: 375px) {
  .service-btn-area .ser-shape {
    left: -30px;
  }
}

/* service 11 end -------------------*/
/* Service 12 start ------------------------ */
.service-item2 {
  position: relative;
  padding: 50px 40px 60px 40px;
  background-color: var(--theme-color);
  box-shadow: 0px 0px 15px #E9E9E9;
  border-radius: 5px 5px 50px 5px;
  margin-bottom: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: 2;
  /* Medium Large devices */
}

@media (max-width: 1399px) {
  .service-item2 {
    padding: 40px 30px;
  }
}

@media (max-width: 1299px) {
  .service-item2 {
    padding: 50px 20px;
  }
}

.service-item2:hover:before {
  height: 0;
}

.service-item2:before, .service-item2:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.service-item2:before {
  background-color: var(--white-color);
}

.service-item2:after {
  border-top: 6px solid var(--theme-color);
}

.service-item2 .box-title {
  font-weight: 600;
  margin-right: 40px;
  /* Medium Large devices */
}

@media (max-width: 1399px) {
  .service-item2 .box-title {
    margin-right: 20px;
  }
}

@media (max-width: 1299px) {
  .service-item2 .box-title {
    margin-right: 0;
  }
}

.service-item2 .box-title a {
  color: inherit;
}

.service-item2_text {
  margin-bottom: 25px;
}

.service-item2:hover .service-item2_text {
  color: var(--white-color);
}

.service-item2:hover .box-title {
  color: var(--white-color);
}

.service-item2:hover .line-btn {
  color: var(--white-color);
}

.service-item2:hover .line-btn:before {
  background-color: var(--white-color);
}

.service-item2 .line-btn {
  font-size: 16px;
  color: var(--theme-color);
  text-transform: capitalize;
}

.service-item2 .line-btn:before {
  background-color: var(--theme-color);
}

/* Service 12 end ------------------------ */
/* Service 13 start --------------------------*/
.service_card2 {
  position: relative;
  background-color: #EFF1F9;
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  z-index: 2;
}

@media (max-width: 1399px) {
  .service_card2 {
    padding: 30px 20px;
  }
}

.service_card2:before, .service_card2:after {
  content: "";
  position: absolute;
  border-radius: inherit;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
}

.service_card2:before {
  width: 100%;
  height: 100%;
  background-color: #EFF1F9;
  top: 0;
  left: 0;
  z-index: -1;
}

.service_card2:after {
  inset: 0;
  background: rgba(62, 101, 243, 0.8);
  z-index: -2;
}

.service_card2 .box-icon {
  position: relative;
  width: 90px;
  height: 90px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--white-color);
  border: 5px solid #7D9AFF;
  display: block;
  margin: auto auto 22px auto;
  z-index: 2;
}

.service_card2 .box-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  border-radius: 50%;
  background-color: var(--theme-color);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transition: all 0.5s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  transition: all 0.5s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.service_card2 .box-icon img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.service_card2 .box-title {
  font-size: 22px;
  font-weight: 600;
}

.service_card2 .box-title a {
  color: inherit;
}

.service_card2_text {
  margin-bottom: 12px;
}

.service_card2 .line-btn {
  text-transform: capitalize;
}

.service_card2:hover:before {
  width: 0;
}

.service_card2:hover .box-title {
  color: var(--white-color);
}

.service_card2:hover .box-icon {
  border: 5px solid var(--white-color);
}

.service_card2:hover .box-icon:before {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}

.service_card2:hover .box-icon img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.service_card2:hover .service_card2_text {
  color: var(--white-color);
}

.service_card2:hover .line-btn {
  color: var(--white-color);
}

.service_card2:hover .line-btn:before {
  background-color: var(--white-color);
}

/* Service 13 end --------------------------*/
/* Service 14 start ------------------------------*/
.service_card3 {
  background-color: transparent;
  border-radius: 6px;
  padding: 40px;
  border: 1px solid #E3E7F0;
  text-align: center;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .service_card3 {
    padding: 30px 20px;
  }
}

.service_card3 .box-icon {
  background-color: #EFF1F9;
  border-radius: 6px;
  padding: 20px;
  display: inline-block;
  margin-bottom: 30px;
}

.service_card3 .box-title {
  font-weight: 600;
  margin: 0 40px 13px 40px;
  /* Medium Large devices */
}

@media (max-width: 1399px) {
  .service_card3 .box-title {
    margin: 0 20px 13px 20px;
  }
}

@media (max-width: 1299px) {
  .service_card3 .box-title {
    margin: 0 0 13px 0;
    font-size: 20px;
  }
}

.service_card3 .line-btn {
  text-transform: capitalize;
}

/* Service 14 end ------------------------------*/
/* Service 15 start ------------------------------*/
.service_card4 {
  text-align: center;
  background-color: transparent;
  border-radius: 20px;
  padding: 40px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .service_card4 {
    padding: 30px;
  }
}

.service_card4 .box-icon {
  margin-bottom: 30px;
}

.service_card4 .box-icon img {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.service_card4 .box-title {
  /* Large devices */
}

@media (max-width: 1199px) {
  .service_card4 .box-title {
    font-size: 20px;
  }
}

.service_card4 .line-btn {
  text-transform: capitalize;
}

.service_card4_text {
  margin-bottom: 15px;
}

.service_card4:hover {
  background-color: #EFF1F9;
}

.service_card4:hover .box-icon img {
  -webkit-transform: scale(-1) rotate(180deg);
      -ms-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}

/* Service 15 end ------------------------------*/
/* Service 16 start ------------------------------*/
.service-sec16 {
  background-color: #EFF1F9;
}

.service_card5 {
  position: relative;
  background-color: var(--white-color);
  border-radius: 30px;
  padding: 40px 0 0 40px;
  min-height: 311px;
  overflow: hidden;
  /* Large devices */
}

@media (max-width: 1199px) {
  .service_card5 {
    padding: 30px 0 0 30px;
  }
}

.service_card5_content {
  padding-right: 40px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .service_card5_content {
    padding-right: 30px;
  }
}

.service_card5_wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.service_card5 .line-btn {
  text-transform: capitalize;
  font-size: 16px;
  min-width: 104px;
}

.service_card5 .line-btn:before {
  display: none;
}

/* Service 16 end ------------------------------*/
/* Service 17 start ------------------------------*/
.service_card6 {
  position: relative;
  background-color: var(--white-color);
  border-radius: 20px;
  padding: 30px 20px;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 2;
  /* Medium Large devices */
}

.service_card6:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: var(--title-color);
  border-radius: 20px;
  z-index: -1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@media (max-width: 1299px) {
  .service_card6 {
    padding: 30px 20px;
  }
}

.service_card6 .box-icon {
  background-color: #EFF1F9;
  border-radius: 50%;
  padding: 20px;
  display: inline-block;
  margin-bottom: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.service_card6 .box-icon img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.service_card6 .box-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 7px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .service_card6 .box-title {
    font-size: 18px;
  }
}

.service_card6_text {
  margin-bottom: 10px;
}

.service_card6 .line-btn {
  text-transform: capitalize;
}

.service_card6:hover:before {
  height: 100%;
}

.service_card6:hover .box-icon {
  background-color: var(--theme-color);
}

.service_card6:hover .box-icon img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  -webkit-transform: scale(-1) rotate(180deg);
      -ms-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}

.service_card6:hover .box-title {
  color: var(--white-color);
}

.service_card6:hover .line-btn {
  color: var(--theme-color);
}

.service_card6:hover .line-btn:before {
  background-color: var(--theme-color);
}

.service_card6:hover .service_card6_text {
  color: var(--white-color);
}

/* Service 14 end ------------------------------*/
.body-shape-content {
  position: absolute;
  top: 51%;
  left: -3%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (max-width: 1699px) {
  .body-shape-content {
    display: none;
  }
}

.body-shape-content.style2 {
  left: unset;
  right: -5%;
}

.body-shape-content.style2 .shepe-text {
  color: #EFF1F9;
}

.body-shape-content.style3 {
  left: unset;
  right: 1%;
}

.body-shape-content.style3 .shepe-text {
  color: #EFF1F9;
}

.body-shape-content.style4 .shepe-text {
  color: #3E66F3;
  opacity: 0.1;
}

.shepe-text {
  font-weight: 700;
  font-size: 84px;
  line-height: 101px;
  color: rgba(239, 241, 249, 0.15);
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

/*------------------- 4.00. Pricing -------------------*/
/* Pricing 1 ---------------------------------- */
.checklist li.unavailable > i {
  color: var(--body-color);
}

.price-card {
  --space-x: 40px;
  --space-y: 40px;
  background-color: var(--smoke-color);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.price-card_top {
  background-color: var(--title-color);
  position: relative;
  z-index: 2;
  padding: var(--space-y) 0;
  overflow: hidden;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.price-card_top:before, .price-card_top:after {
  content: '';
  position: absolute;
  height: 110px;
  width: 110px;
  background-color: var(--theme-color);
  opacity: 0.6;
  border-radius: 50%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: -1;
}

.price-card_top:before {
  top: -73px;
  left: -28px;
}

.price-card_top:after {
  left: -73px;
  top: -28px;
}

.price-card_title {
  font-size: 30px;
  margin-top: -0.3em;
  margin-bottom: 4px;
  color: var(--white-color);
  padding: 0 var(--space-x);
  position: relative;
  z-index: 3;
}

.price-card_text {
  color: var(--white-color);
  padding: 0 var(--space-x) 28px var(--space-x);
  margin-bottom: 35px;
  border-bottom: 2px solid;
  -webkit-border-image: -webkit-linear-gradient(right, var(--title-color) 27%, var(--theme-color) 100%);
          border-image: linear-gradient(to left, var(--title-color) 27%, var(--theme-color) 100%);
  border-image-slice: 1;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.price-card_price {
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  margin: -0.09em 0;
  color: var(--white-color);
  padding: 0 var(--space-x);
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.price-card_price .duration {
  font-size: 16px;
  font-weight: 400;
  color: var(--white-color);
  margin-bottom: 0;
  position: relative;
  top: -1px;
  left: -4px;
}

.price-card .particle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 190px;
  height: 165px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-clip-path: path("M182 165L0.5 164L11 111L51 55.5L110 13L182 0V165Z");
          clip-path: path("M182 165L0.5 164L11 111L51 55.5L110 13L182 0V165Z");
}

.price-card_content {
  padding: var(--space-y) var(--space-x);
}

.price-card .checklist {
  margin: -5px 0 32px 0;
}

.price-card .th-btn {
  background-color: var(--title-color);
  box-shadow: none;
}

.price-card .th-btn:before, .price-card .th-btn:after {
  background-color: var(--theme-color);
}

.price-card:hover .particle {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.price-card:hover .price-card_top:before, .price-card:hover .price-card_top:after {
  opacity: 1;
  height: 120%;
  width: 120%;
  border-radius: 0;
  background-color: var(--theme-color);
}

.price-card:hover .price-card_text {
  -webkit-border-image: -webkit-linear-gradient(right, var(--theme-color) 27%, var(--white-color) 100%);
          border-image: linear-gradient(to left, var(--theme-color) 27%, var(--white-color) 100%);
  border-image-slice: 1;
}

/* Extra small devices */
@media (max-width: 375px) {
  .price-card {
    --space-x: 30px;
    --space-y: 30px;
  }
  .price-card_text {
    font-size: 14px;
  }
  .price-card_title, .price-card_price {
    font-size: 26px;
  }
}

@media (max-width: 350px) {
  .price-card {
    --space-x: 20px;
    --space-y: 40px;
  }
}

/* Pricing 2 ---------------------------------- */
.available-list {
  padding-left: 30px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .available-list {
    padding: 0;
  }
}

.available-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.available-list li {
  position: relative;
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  padding-left: 30px;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: var(--body-color);
}

.available-list li:after {
  content: "\f058";
  font-family: var(--icon-font);
  font-weight: 600;
  color: var(--theme-color);
  font-size: 1.1em;
  position: absolute;
  top: 1px;
  left: 0;
}

.available-list li.unavailable:after {
  content: "\f058";
  right: 4px;
  color: var(--body-color);
  font-weight: 400;
}

.available-list li img {
  max-width: 18px;
  margin-right: 10px;
}

.available-list li:last-child {
  margin-bottom: 0;
}

.available-list li.unavailable {
  font-weight: 400;
  color: var(--body-color);
}

.available-list li.unavailable img {
  opacity: 0.2;
}

.price-box {
  position: relative;
  border-radius: 15px;
  border: 1px solid #E3EEFE;
  background: var(--white-color);
  padding: 50px;
  margin-top: 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 1;
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .price-box {
    padding: 40px;
  }
}

@media (max-width: 575px) {
  .price-box {
    padding: 30px;
  }
}

.price-box .offer-tag {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
  background: transparent;
  border-radius: 0;
  padding: 0;
  z-index: -1;
}

.price-box .offer-tag .tag {
  text-align: center;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -31px;
  top: 25px;
  width: 210px;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--body-font);
  letter-spacing: -0.408px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
}

.price-box.active {
  border: 1px solid var(--theme-color);
  -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
          transform: translateY(-30px);
}

.price-box.active .offer-tag {
  opacity: 1;
  visibility: visible;
}

.price-box .box-title {
  font-weight: 600;
  letter-spacing: -0.48px;
}

.price-box_text {
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #D9D9D9;
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .price-box_text {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .price-box_text {
    font-size: 14px;
  }
}

.price-box_price {
  font-family: var(--title-font);
  color: var(--title-color);
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.96px;
  margin-bottom: -0.2rem;
}

.price-box .available-list {
  padding: 0;
  margin-bottom: 35px;
}

.price-box .available-list li:after {
  content: "\f00c";
  color: var(--body-color);
}

.price-box:hover .price-box_icon img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.price-box.style3 {
  background-color: #16181D;
  border-radius: 20px;
  border: none;
  padding: 50px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .price-box.style3 {
    padding: 40px;
  }
}

.price-box.style3 .offer-tag .tag {
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.price-box.style3.active {
  border: 1px solid var(--theme-color);
  -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
          transform: translateY(-30px);
}

.price-box.style3.active .offer-tag {
  opacity: 1;
  visibility: visible;
}

.price-box.style3 .price-box_text {
  color: #8D929C;
  font-weight: 400;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.price-box.style3 .price-box_price {
  font-weight: 600;
  font-size: 40px;
  color: var(--white-color);
  margin-bottom: 10px;
}

.price-box.style3 .price-box_text2 {
  margin-bottom: 32px;
}

.price-box.style3 .duration {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  font-family: var(--body-font);
  color: #8D929C;
  letter-spacing: -0.408px;
  margin-left: 10px;
}

.price-box.style3 .box-title {
  font-weight: 600;
  font-size: 30px;
  color: var(--white-color);
  margin-bottom: 7px;
}

.price-box.style3 .available-list li:after {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: block;
  text-align: center;
  border-radius: 30px;
  color: var(--title-color);
  font-size: 10px;
}

.price-box.style4 {
  background-color: #16181D;
  border-radius: 20px;
  border: none;
  padding: 50px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .price-box.style4 {
    padding: 40px;
  }
}

.price-box.style4 .offer-tag .tag {
  background: var(--theme-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.price-box.style4.active {
  border: 1px solid var(--theme-color);
  -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
          transform: translateY(-30px);
}

.price-box.style4.active .offer-tag {
  opacity: 1;
  visibility: visible;
}

.price-box.style4 .price-box_text {
  color: #8D929C;
  font-weight: 400;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.price-box.style4 .price-box_price {
  font-weight: 600;
  font-size: 40px;
  color: var(--white-color);
  margin-bottom: 10px;
}

.price-box.style4 .price-box_text2 {
  margin-bottom: 32px;
}

.price-box.style4 .duration {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  font-family: var(--body-font);
  color: #8D929C;
  letter-spacing: -0.408px;
  margin-left: 10px;
}

.price-box.style4 .box-title {
  font-weight: 600;
  font-size: 30px;
  color: var(--white-color);
  margin-bottom: 7px;
}

.price-box.style4 .available-list li:after {
  background: var(--theme-color);
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: block;
  text-align: center;
  border-radius: 30px;
  color: var(--title-color);
  font-size: 10px;
}

.price-box.style4 .th-btn {
  text-transform: capitalize;
  font-size: 16px;
  font-family: var(--title-font);
}

.price-box.style4 .th-btn:before, .price-box.style4 .th-btn:after {
  display: block;
  background-color: var(--white-color);
}

.price-box.style4 .th-btn:hover {
  color: var(--title-color);
}

.price-box.style7 {
  background-color: var(--white-color);
  border: 1px solid #E3E7F0;
  border-radius: 30px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .price-box.style7 {
    padding: 30px;
  }
}

.price-box.style7 .box-title {
  font-size: 36px;
}

.price-box.style7 .available-list ul {
  display: grid;
  grid-template-columns: auto auto auto;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .price-box.style7 .available-list ul {
    grid-template-columns: auto auto;
  }
}

@media (max-width: 480px) {
  .price-box.style7 .available-list ul {
    grid-template-columns: auto;
  }
}

.price-box.style7 .available-list ul li {
  padding: 0;
  font-size: 18px;
  color: var(--body-color);
}

.price-box.style7 .available-list ul li i {
  font-size: 18px;
  color: var(--theme-color);
  margin-right: 10px;
}

.price-box.style7 .available-list ul li:after {
  display: none;
}

.price-box.style7 .price-box_text {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--body-color);
  border-bottom: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

@media (max-width: 480px) {
  .price-box.style7 .price-box_text {
    display: block;
  }
}

.price-box.style7 .price-box_text span {
  background: rgba(64, 115, 96, 0.2);
  border-radius: 30px;
  padding: 4px 15px;
  color: var(--theme-color);
  font-weight: 500;
}

.price-box.style7 .price-box_price {
  font-size: 36px;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .price-box.style7 .price-box_price {
    margin: 10px 0;
  }
}

.price-box.style7 .price-box_content {
  border-top: 1px solid #E3E7F0;
  margin-top: 30px;
  padding-top: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 480px) {
  .price-box.style7 .price-box_content {
    display: block;
  }
}

.price-box.style7:hover {
  background-color: var(--theme-color);
}

.price-box.style7:hover .box-title {
  color: var(--white-color);
}

.price-box.style7:hover .available-list ul li {
  color: var(--white-color);
}

.price-box.style7:hover .available-list ul li i {
  color: var(--white-color);
}

.price-box.style7:hover .price-box_text {
  color: var(--white-color);
}

.price-box.style7:hover .price-box_text span {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--white-color);
}

.price-box.style7:hover .price-box_price {
  color: var(--white-color);
}

.price-box.style7:hover .th-btn {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.price-box.style7:hover .th-btn:hover {
  color: var(--white-color);
}

/* Pricing 4 ---------------------------------- */
.pricing-tabs {
  margin-top: 31px;
  margin-bottom: 60px;
  /* Extra small devices */
}

.pricing-tabs .discount-tag {
  color: var(--theme-color);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--body-font);
  position: absolute;
  bottom: -3px;
  -webkit-transform: translate(-35px, 100%);
      -ms-transform: translate(-35px, 100%);
          transform: translate(-35px, 100%);
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: end;
  -webkit-align-items: end;
      -ms-flex-align: end;
          align-items: end;
  gap: 10px;
}

@media (max-width: 575px) {
  .pricing-tabs {
    margin-bottom: 0px;
  }
  .pricing-tabs .discount-tag {
    display: none;
  }
}

.pricing-tabs.style3 {
  margin-bottom: 0;
}

.pricing-tabs.style3 .switch-area .switch {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 28px;
  height: 28px;
}

.pricing-tabs.style3 .switch-area .check:checked ~ .switch {
  right: 4px;
  left: 53.5%;
}

.pricing-tabs.style3 .switch-area .toggle {
  width: 70px;
  height: 36px;
  background: -webkit-linear-gradient(top, #02CE80 0%, #7BD706 100%);
  background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
  border-radius: 30px;
}

.pricing-tabs.style3 .switch-area .toggler {
  color: var(--white-color);
  font-family: var(--title-font);
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
}

.pricing-tabs.style4 .switch-area .switch {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 28px;
  height: 28px;
}

.pricing-tabs.style4 .switch-area .check:checked ~ .switch {
  right: 4px;
  left: 53.5%;
}

.pricing-tabs.style4 .switch-area .toggle {
  width: 70px;
  height: 36px;
}

.pricing-tabs.style4 .toggler {
  color: var(--white-color);
  font-weight: 400;
}

.pricing-tabs.style4 .toggler.toggler--is-active {
  color: var(--white-color);
}

.switch-area {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.switch-area .toggler {
  -webkit-transition: .2s;
  transition: .2s;
  font-weight: 600;
  font-size: 20px;
  font-family: var(--body-font);
  color: var(--title-color);
  background: transparent;
  margin-bottom: -0.4em;
  cursor: pointer;
}

.switch-area .toggler.toggler--is-active {
  color: var(--theme-color);
}

.switch-area .toggle {
  position: relative;
  width: 60px;
  height: 30px;
  border-radius: 100px;
  background-color: var(--theme-color);
  overflow: hidden;
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
}

.switch-area .check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
  visibility: visible;
}

.switch-area .check:checked ~ .switch {
  right: 2px;
  left: 57.5%;
  -webkit-transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-delay: .08s, 0s;
          transition-delay: .08s, 0s;
}

.switch-area .switch {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 57.5%;
  background-color: var(--white-color);
  border-radius: 36px;
  z-index: 1;
  -webkit-transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-delay: 0s, .08s;
          transition-delay: 0s, .08s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.wrapper-full.hide {
  display: none;
}

.price-box.style2 {
  background: #EFF1F9;
  border: 0;
  z-index: 1;
}

.price-box.style2 .offer-tag {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
  background: transparent;
  border-radius: 0;
  padding: 0;
  z-index: -1;
}

.price-box.style2 .offer-tag .tag {
  text-align: center;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -31px;
  top: 25px;
  width: 210px;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--body-font);
  letter-spacing: -0.408px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
}

.price-box.style2 .price-title-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}

.price-box.style2 .price-title-wrap img {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
}

.price-box.style2 .price-title-wrap .subtitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--theme-color);
  letter-spacing: -0.408px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.price-box.style2 .price-title-wrap .box-title {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 600;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .price-box.style2 .price-title-wrap .box-title {
    font-size: 24px;
  }
}

.price-box.style2 .price-box_text {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
  margin-top: 11px;
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 30px;
}

.price-box.style2 .price-box_price {
  font-size: 40px;
  border-top: 2px solid rgba(28, 28, 37, 0.1);
  padding-top: 28px;
  padding-bottom: 7px;
}

.price-box.style2 .price-box_price .duration {
  font-weight: 400;
  font-size: 16px;
  font-family: var(--body-font);
  color: var(--body-color);
  letter-spacing: normal;
  margin-left: 7px;
}

.price-box.style2 .available-list li {
  padding-left: 0;
}

.price-box.style2 .available-list li:after {
  display: none;
}

.price-box.style2 .available-list li i {
  color: var(--theme-color);
  margin-right: 10px;
}

.price-box.style2 .available-list li.unavailable i {
  color: var(--body-color);
}

/* price area start -------------------------*/
.price-area3 {
  background-color: #0E0E0E;
  margin: 0 100px;
}

@media (max-width: 1699px) {
  .price-area3 {
    margin: 0;
  }
}

/* price */
.price-area6 {
  position: relative;
  max-width: 1760px;
  display: block;
  margin: auto;
  border-radius: 30px;
  z-index: 3;
}

.pricing-tabs.style6 {
  margin-bottom: 30px;
  /* Small devices */
}

@media (max-width: 767px) {
  .pricing-tabs.style6 {
    text-align: center;
  }
}

.pricing-tabs.style6 .switch-area {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  padding: 15.5px 8.5px;
  gap: 0;
}

.pricing-tabs.style6 .switch-area .toggle {
  opacity: 0;
  display: none;
}

.pricing-tabs.style6 .switch-area .toggler {
  background-color: transparent;
  border-radius: 30px;
  padding: 5px 16.7px;
  font-weight: 600;
  font-size: 16px;
  color: var(--white-color);
}

.pricing-tabs.style6 .switch-area .toggler.toggler--is-active {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.price-box.style5 {
  background: #1C1C25;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .price-box.style5 {
    padding: 30px;
  }
}

.price-box.style5 .price-title-area {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 30px;
  margin-bottom: 30px;
  /* Medium Large devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .price-box.style5 .price-title-area {
    display: block;
  }
  .price-box.style5 .price-title-area img {
    margin-top: 20px;
  }
}

.price-box.style5 .price-title-area img {
  margin-right: 20px;
}

@media (max-width: 375px) {
  .price-box.style5 .price-title-area img {
    max-width: 40px;
  }
}

.price-box.style5 .subtitle {
  color: var(--theme-color);
  margin-bottom: 0;
}

.price-box.style5 .box-title {
  font-weight: 600;
  font-size: 30px;
  color: var(--white-color);
  margin-bottom: 7px;
}

.price-box.style5 .th-btn {
  text-transform: capitalize;
}

.price-box.style5 .price-box_price {
  font-weight: 600;
  font-size: 40px;
  color: var(--white-color);
  margin-bottom: 10px;
}

.price-box.style5 .price-box_text2 {
  color: var(--white-color);
  margin-bottom: 32px;
}

.price-box.style5 .price-box_text {
  color: var(--white-color);
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.price-box.style5 .duration {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  font-family: var(--body-font);
  color: var(--theme-color);
  letter-spacing: -0.408px;
  margin-left: 10px;
}

.price-box.style5 .available-list li {
  color: var(--white-color);
  padding: 0;
}

.price-box.style5 .available-list li:after {
  display: none;
}

.price-box.style5 .available-list li i {
  color: var(--theme-color);
  margin-right: 10px;
}

.price-box.style5 .available-list li.unavailable i {
  color: var(--white-color);
}

.price-box.style5:hover {
  background: var(--white-color);
  border-color: var(--white-color);
}

.price-box.style5:hover .price-title-area {
  border-bottom: 2px solid #E3E7F0;
}

.price-box.style5:hover .box-title {
  color: var(--title-color);
}

.price-box.style5:hover .price-box_text {
  color: var(--title-color);
}

.price-box.style5:hover .price-box_text2 {
  color: var(--body-color);
}

.price-box.style5:hover .price-box_price {
  color: var(--title-color);
}

.price-box.style5:hover .available-list li {
  color: var(--body-color);
}

.price-box.style5:hover .available-list li.unavailable i {
  color: var(--body-color);
}

/* pricing box -----------------------------*/
.price-box.style6 {
  background-color: #EFF1F9;
  border: 1px solid #E3E7F0;
  padding: 0 40px 40px 40px;
  -webkit-mask-size: auto;
          mask-size: auto;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  margin: 0;
}

@media (max-width: 1399px) {
  .price-box.style6 {
    -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
  }
}

.price-box.style6 .price-title-wrap {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 60px 40px;
  display: block;
  margin: 5px auto 45px auto;
  background: var(--theme-color);
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1299px) {
  .price-box.style6 .price-title-wrap {
    width: 150px;
    height: 150px;
    padding: 40px 40px;
  }
}

@media (max-width: 1199px) {
  .price-box.style6 .price-title-wrap {
    width: 200px;
    height: 200px;
    padding: 60px 40px;
  }
}

@media (max-width: 991px) {
  .price-box.style6 .price-title-wrap {
    margin: auto auto 45px auto;
  }
}

.price-box.style6 .price-box_price {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: var(--white-color);
  display: block;
  text-align: center;
}

.price-box.style6 .price-box_text {
  text-align: center;
  padding-bottom: 26px;
  margin-bottom: 17px;
}

.price-box.style6 .duration {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white-color);
  display: block;
}

.price-box.style6 .box-title {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 5px;
  text-align: center;
}

.price-box.style6 .available-list {
  padding-left: 76px;
  margin-bottom: 30px;
  /* Medium Large devices */
  /* Small devices */
  /* Extra small devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .price-box.style6 .available-list {
    padding-left: 40px;
  }
}

@media (max-width: 767px) {
  .price-box.style6 .available-list {
    padding-left: 76px;
  }
}

@media (max-width: 575px) {
  .price-box.style6 .available-list {
    padding-left: 50px;
  }
}

@media (max-width: 375px) {
  .price-box.style6 .available-list {
    padding-left: 0px;
  }
}

.price-box.style6 .available-list li {
  font-family: var(--body-font);
}

.price-box.style6 .available-list li:after {
  font-weight: 400;
  font-size: 16px;
}

.price-box.style6 .th-btn {
  max-width: 258px;
  display: block;
  margin: auto;
  text-transform: capitalize;
}

.price-box.style6 .offer-feature {
  text-align: center;
  display: block;
}

.price-box.style6 .offer-feature .feature {
  background-color: var(--theme-color);
  border-radius: 4px;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: var(--white-color);
  padding: 5px 20px;
  display: inline-block;
  margin-bottom: 40px;
}

/*------------------- 4.00. Gallery -------------------*/
/* Gallery Card ---------------------------------- */
.gallery-card .gallery-img {
  position: relative;
  z-index: 2;
  border-radius: 10px;
  overflow: hidden;
}

.gallery-card .gallery-img:before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--black-color2);
  opacity: 0.8;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  z-index: 1;
}

.gallery-card .gallery-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.gallery-card .gallery-img .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
      -ms-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
}

.gallery-card .gallery-img .play-btn > i {
  font-size: 24px;
}

.gallery-card:hover .gallery-img:before {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}

.gallery-card:hover .gallery-img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.gallery-card:hover .gallery-img .play-btn {
  -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

/* Gallery box start---------------------------------- */
.gallery-row {
  --bs-gutter-y: 1.9rem;
}

.gallery-box {
  --box-space: 30px;
  position: relative;
  text-align: center;
  overflow: hidden;
}

.gallery-box .gallery-img {
  overflow: hidden;
}

.gallery-box .gallery-img img {
  width: 100%;
  height: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.gallery-box .gallery-content {
  position: absolute;
  top: var(--box-space);
  left: var(--box-space);
  width: calc(100% - var(--box-space)*2);
  height: calc(100% - var(--box-space)*2);
  z-index: 2;
  background: rgba(1, 15, 52, 0.77);
  -webkit-backdrop-filter: blur(10.6px);
          backdrop-filter: blur(10.6px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.gallery-box .box-title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

.gallery-box .box-title a:not(:hover) {
  color: var(--white-color);
}

.gallery-box .gallery-subtitle {
  color: var(--theme-color);
  margin-bottom: 8px;
}

.gallery-box .icon-btn {
  width: var(--btn-size, 46px);
  height: var(--btn-size, 46px);
  line-height: var(--btn-size, 46px);
  border-radius: 50%;
  border: 2px solid var(--white-color);
  color: var(--white-color);
  font-size: 20px;
}

.gallery-box .icon-btn:hover {
  background: var(--white-color);
  color: var(--title-color);
}

.gallery-box:hover .gallery-img img {
  -webkit-transform: scale(1.2) rotate(-5deg);
      -ms-transform: scale(1.2) rotate(-5deg);
          transform: scale(1.2) rotate(-5deg);
}

.gallery-box:hover .gallery-content {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

/* Gallery box end---------------------------------- */
/*------------------- 4.00. Project -------------------*/
/* Project Card ---------------------------------- */
.project-card {
  --space: 30px;
  position: relative;
  overflow: hidden;
}

.project-card .project-img {
  overflow: hidden;
  border-radius: 10px;
  z-index: 2;
}

.project-card .project-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.project-card .project-img:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: -webkit-linear-gradient(top, rgba(0, 0, 40, 0) 0%, rgba(0, 0, 40, 0.140476) 43.1%, #000028 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 40, 0) 0%, rgba(0, 0, 40, 0.140476) 43.1%, #000028 100%);
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: 1;
  border-radius: inherit;
}

.project-card .project-content-wrap {
  position: absolute;
  left: var(--space);
  bottom: -30px;
  width: 100%;
  max-width: calc(100% - var(--space)*2 - 28px);
  z-index: 3;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.project-card .project-content {
  background-color: var(--theme-color);
  background-size: auto;
  background-position: right center;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  padding: 26px 30px;
}

.project-card .box-title {
  color: var(--white-color);
  margin-bottom: 2px;
}

.project-card .box-title a:hover {
  color: var(--smoke-color2);
}

.project-card .project-subtitle {
  margin-bottom: -0.5em;
  color: var(--white-color);
}

.project-card .icon-btn {
  position: absolute;
  right: -28px;
  top: calc(50% - 28px);
  background-color: var(--white-color);
  border: none;
  color: var(--theme-color);
  font-size: 24px;
}

.project-card .icon-btn:hover {
  background-color: var(--title-color);
}

.project-card:hover .project-img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.project-card:hover .project-img:before {
  height: 100%;
  opacity: 1;
}

.project-card:hover .project-content-wrap {
  bottom: var(--space);
  opacity: 1;
}

/* Extra small devices */
@media (max-width: 375px) {
  .project-card {
    --space: 15px;
  }
}

@media (max-width: 360px) {
  .project-card .box-title {
    font-size: 22px;
  }
  .project-card .project-content {
    padding: 26px 20px;
  }
}

/* Project Card 2 start---------------------------------- */
.project-card2 {
  --space: 30px;
  position: relative;
  overflow: hidden;
}

.project-card2 .project-img {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  z-index: 2;
}

.project-card2 .project-img img {
  width: 100%;
  border-radius: 20px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.project-card2 .project-img:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: -webkit-linear-gradient(top, rgba(0, 0, 40, 0) 0%, rgba(0, 0, 40, 0.140476) 43.1%, #000028 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 40, 0) 0%, rgba(0, 0, 40, 0.140476) 43.1%, #000028 100%);
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  z-index: 1;
  border-radius: inherit;
}

.project-card2 .project-content-wrap {
  position: absolute;
  left: var(--space);
  bottom: -30px;
  width: 100%;
  max-width: 474px;
  z-index: 3;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.project-card2 .project-content {
  background-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: auto;
          mask-size: auto;
  background-color: var(--white-color);
  background-size: auto;
  background-position: center center;
  position: relative;
  z-index: 2;
  padding: 90px 30px 30px 30px;
}

.project-card2 .box-title {
  color: var(--title-color);
  margin-bottom: 0px;
}

.project-card2 .box-title a {
  color: inherit;
}

.project-card2 .box-title:hover {
  color: var(--theme-color);
}

.project-card2 .project-subtitle {
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--theme-color);
}

.project-card2 .icon-btn {
  position: absolute;
  right: 20px;
  top: 25px;
  border: none;
  --btn-size: auto;
  color: var(--theme-color);
  font-size: 20px;
}

.project-card2:hover .project-img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.project-card2:hover .project-img:before {
  height: 100%;
  opacity: 1;
}

.project-card2:hover .project-content-wrap {
  bottom: var(--space);
  opacity: 1;
}

/* Extra small devices */
@media (max-width: 375px) {
  .project-card2 {
    --space: 15px;
  }
}

@media (max-width: 360px) {
  .project-card2 .box-title {
    font-size: 22px;
  }
  .project-card2 .project-content {
    padding: 26px 20px;
  }
}

/* Project Card 2 end---------------------------------- */
/* Project Grid ---------------------------------- */
.project-grid {
  background-color: var(--white-color);
  box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
  border-radius: 10px;
}

.project-grid_img {
  position: relative;
  z-index: 2;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.project-grid_img:before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--black-color2);
  opacity: 0.8;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  z-index: 1;
}

.project-grid_img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.project-grid_img .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
      -ms-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
}

.project-grid_img .play-btn > i {
  font-size: 24px;
}

.project-grid_text {
  margin-bottom: -0.5em;
}

.project-grid_content {
  padding: 40px;
}

.project-grid:hover .project-grid_img:before {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
}

.project-grid:hover .project-grid_img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

.project-grid:hover .project-grid_img .play-btn {
  -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

/* Project Box ---------------------------------- */
.project-box {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.project-box.style2 {
  border-radius: 20px;
}

.project-box.style2 .project-img {
  border-radius: 20px;
  margin-bottom: 30px;
}

.project-box.style2 .project-img img {
  border-radius: 20px;
}

.project-box.style2 .project-box_desc {
  font-size: 14px;
  margin-bottom: 7px;
}

.project-box.style2 .box-title {
  font-weight: 600;
}

.project-box .project-img {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.project-box .project-img img {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.project-box .project-content {
  background-color: var(--white-color);
  padding: 22px 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}

.project-box .box-title {
  margin-bottom: 3px;
}

.project-box .project-subtitle {
  margin-bottom: -0.5em;
  color: var(--theme-color);
}

.project-box .icon-btn {
  background-color: var(--theme-color);
  border: none;
  color: var(--white-color);
  font-size: 24px;
  border-radius: 8px;
}

.project-box .icon-btn:hover {
  background-color: var(--title-color);
}

.project-box:hover .project-img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

@media (max-width: 360px) {
  .project-box .box-title {
    font-size: 22px;
  }
  .project-box .project-content {
    padding: 26px 20px;
  }
}

/* Project Box 4---------------------------------- */
.project-box4 {
  position: relative;
  --space: 40px;
  border-radius: 35px;
  margin-bottom: 0px;
  overflow: hidden;
}

.project-box4 .project-content {
  position: absolute;
  left: var(--space);
  bottom: -30px;
  width: calc(100% - var(--space)*2);
  z-index: 3;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.project-box4 .box-title {
  color: var(--white-color);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.project-box4 .box-title a {
  color: inherit;
}

.project-box4 .box-title:hover {
  color: var(--white-color);
}

.project-box4 .project-tags {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
}

.project-box4 .project-tags a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
  background-color: var(--white-color);
  color: var(--title-color);
  border-radius: 100px;
  padding: 4px 20px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.project-box4 .project-tags a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.project-box4 .project-img {
  overflow: hidden;
  position: relative;
  z-index: 2;
  border-radius: 30px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.project-box4 .project-img:before, .project-box4 .project-img:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.project-box4 .project-img:before {
  background: var(--theme-color);
  mix-blend-mode: multiply;
  z-index: 4;
}

.project-box4 .project-img:after {
  background: rgba(64, 102, 242, 0.92);
  z-index: 3;
}

.project-box4 .project-img img {
  width: 100%;
  border-radius: 20px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.project-box4:hover .project-img img {
  -webkit-filter: grayscale(50%);
          filter: grayscale(50%);
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.project-box4:hover .project-img:before {
  opacity: 1;
  visibility: visible;
}

.project-box4:hover .project-content {
  bottom: var(--space);
  opacity: 1;
  visibility: visible;
}

.project-slider4 {
  margin-left: 40px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .project-slider4 {
    margin: 50px 0 0 0;
  }
}

.project-btn {
  /* Small devices */
}

@media (max-width: 767px) {
  .project-btn {
    text-align: center;
  }
}

/* Project 5---------------------------------- */
.project-area-5 {
  background: #1C1C25;
}

.project-card5 {
  position: relative;
  border-radius: 20px;
  border: 1px solid #2E3039;
  background-color: #1C1C25;
  padding: 20px;
  overflow: hidden;
}

.project-card5 .project-img {
  overflow: hidden;
  border-radius: 20px;
  z-index: 2;
}

.project-card5 .project-img img {
  width: 100%;
  border-radius: 20px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.project-card5 .project-content {
  padding: 30px 20px 20px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .project-card5 .project-content {
    padding: 30px 0px 20px;
  }
}

.project-card5 .box-title {
  font-size: 22px;
  color: var(--white-color);
  margin-bottom: 14px;
  letter-spacing: -0.22px;
}

.project-card5_desc {
  margin-bottom: 22px;
}

.project-card5 .line-btn {
  font-family: var(--body-font);
}

.project-card5 .line-btn:hover {
  color: var(--white-color);
}

.project-card5 .line-btn:hover:before {
  background-color: var(--white-color);
}

.project-card5:hover .project-img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}

@media (max-width: 360px) {
  .project-card5 .box-title {
    font-size: 22px;
  }
  .project-card5 .project-content {
    padding: 26px 20px;
  }
}

/*project-card3 start --------------------------*/
.project-card3 {
  text-align: center;
}

.project-card3 .project-img {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.project-card3 .project-img:after {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--theme-color);
  mix-blend-mode: normal;
  opacity: 0;
  border-radius: 20px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-transform: scale(0.3);
      -ms-transform: scale(0.3);
          transform: scale(0.3);
}

.project-card3 .project-img img {
  width: 100%;
  border-radius: 20px;
}

.project-card3 .shape {
  position: absolute;
  inset: 30px;
  border: 1px solid var(--white-color);
  z-index: 2;
  border-radius: 12px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-transform: scaleY(0);
      -ms-transform: scaleY(0);
          transform: scaleY(0);
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .project-card3 .shape {
    inset: 20px;
  }
}

@media (max-width: 375px) {
  .project-card3 .shape {
    inset: 10px;
  }
}

.project-card3 .project-content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  mix-blend-mode: normal;
  border-radius: 12px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  -webkit-transform: translateY(40px);
      -ms-transform: translateY(40px);
          transform: translateY(40px);
}

.project-card3 .icon-btn {
  background-color: var(--white-color);
  border-radius: 50%;
  border: none;
  color: var(--theme-color);
  font-size: 18px;
  margin-bottom: 15px;
}

.project-card3 .icon-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.project-card3 .project-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--white-color);
  margin-bottom: 0;
}

.project-card3 .box-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: var(--white-color);
  margin-bottom: 0;
}

.project-card3 .box-title a {
  color: inherit;
}

.project-card3:hover .project-img:after {
  opacity: 0.89;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.project-card3:hover .shape {
  -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
          transform: scaleY(1);
}

.project-card3:hover .project-content {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

/*project-card3 end --------------------------*/
/* Project Details ---------------------------------- */
.project-inner-box {
  background-color: var(--smoke-color);
  border-radius: 5px;
  padding: 30px;
  /* Extra small devices */
}

@media (max-width: 375px) {
  .project-inner-box {
    padding: 20px;
  }
}

/*------------------- 4.00. Skill -------------------*/
.skill-feature:not(:last-child) {
  margin-bottom: 25px;
}

.skill-feature_title,
.skill-feature .progress-value {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: -0.2em;
  font-family: var(--title-font);
  color: var(--title-color);
}

.skill-feature .progress {
  position: relative;
  height: 10px;
  background-color: var(--white-color);
  overflow: visible;
  border-radius: 100px;
}

.skill-feature .progress-bar {
  background-color: var(--theme-color);
  height: 4px;
  margin: 3px;
  border-radius: inherit;
  position: relative;
  overflow: visible;
}

.skill-feature .progress-value {
  position: absolute;
  top: -34px;
  right: 0;
}

.skill-feature.style2:not(:last-child) {
  margin-bottom: 32px;
}

.skill-feature.style2 .progress {
  background-color: var(--smoke-color2);
}

@media (min-width: 1430px) {
  .video-box1 {
    margin-right: -105px;
  }
}

@media (min-width: 1300px) {
  .video-box1 {
    margin-left: 30px;
  }
}

.video-box1 {
  position: relative;
  text-align: center;
}

.video-box1 img {
  width: 100%;
  max-width: 650px;
}

.video-box1 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* video box2 start ---------------------------------*/
.video-box2 {
  position: relative;
  text-align: center;
  margin-top: 35px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .video-box2 {
    margin-bottom: 80px;
  }
}

.video-box2 img {
  width: 100%;
  border-radius: 30px;
}

.video-box2 .video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 0;
  width: 100px;
  height: 100px;
  line-height: 102px;
  -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  border-radius: 50%;
  background: transparent;
  margin: 0 auto;
  display: inline-block;
  font-size: 24px;
  text-align: center;
  color: var(--white-color);
  /* Small devices */
}

@media (max-width: 767px) {
  .video-box2 .video-play-btn {
    width: 50px;
    height: 50px;
    line-height: 52px;
    font-size: 20px;
  }
}

.video-box2 .video-play-btn i {
  color: var(--white-color);
}

.video-box2 .video-play-btn:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.3;
  transition: 0.3;
  border-radius: 50%;
  border: 2px solid var(--white-color);
  -webkit-animation: ripple 2s linear infinite;
          animation: ripple 2s linear infinite;
}

.video-box2 .video-play-btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.3;
  transition: 0.3;
  border-radius: 50%;
  border: 2px solid var(--white-color);
  -webkit-animation: ripple 2s linear 1s infinite;
          animation: ripple 2s linear 1s infinite;
}

/* video box2 end ---------------------------------*/
/*------------------- 4.00. Marquee -------------------*/
/* Marquee 1---------------------------------- */
.marquee-area {
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  padding: 35px 0;
}

.marquee-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 9;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-right: -75%;
  /* Large devices */
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 1199px) {
  .marquee-wrapper {
    margin-right: -90%;
  }
}

@media (max-width: 991px) {
  .marquee-wrapper {
    margin-right: -100%;
  }
}

@media (max-width: 767px) {
  .marquee-wrapper {
    margin-right: -140%;
    margin-top: 20%;
  }
}

@media (max-width: 530px) {
  .marquee-wrapper {
    margin-right: -190%;
    margin-top: 20%;
  }
}

@media (max-width: 330px) {
  .marquee-wrapper {
    margin-right: -210%;
    margin-top: 50%;
  }
}

.marquee-wrapper.style2 {
  margin-right: 0;
  margin-left: -70%;
  -webkit-transform: rotate(-120deg);
      -ms-transform: rotate(-120deg);
          transform: rotate(-120deg);
  z-index: -1;
}

.marquee-wrapper.style2 .marquee {
  --gap: 10px;
}

.marquee-wrapper.style2 .marquee .marquee-group .text {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(92, 127, 255, 0.15);
  box-shadow: 0px 2px 15px rgba(41, 83, 233, 0.15);
  border-radius: 100px;
  color: var(--theme-color);
  padding: 8px 30px;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.marquee-content_wrapper .marquee .marquee-group .item {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: var(--white-color);
}

.marquee-content_wrapper .marquee .marquee-group .item img {
  margin: 0 29px;
}

.marquee {
  position: relative;
  --duration: 120s;
  --gap: 0px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  gap: var(--gap);
}

.marquee:first-child {
  --duration: 100s;
}

.marquee:last-child {
  --duration: 80s;
}

.marquee.marquee--reverse .marquee-group {
  animation-direction: reverse;
}

.marquee .marquee-group {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
          align-items: center;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  -webkit-animation: scroll var(--duration) linear infinite;
          animation: scroll var(--duration) linear infinite;
}

.marquee .marquee-group .text {
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  border-radius: 100px;
  color: var(--white-color);
  padding: 6px 30px;
  margin: 8px 5px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid rgba(92, 127, 255, 0.62);
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.marquee .marquee-group .text:hover {
  background-color: var(--white-color);
  color: var(--theme-color);
}

@media (prefers-reduced-motion: reduce) {
  .marquee-wrapper .marquee .marquee-group {
    -webkit-animation-play-state: play;
    animation-play-state: play;
  }
}

/*------------------- 4.00. Process -------------------*/
.process-card {
  position: relative;
  box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
  border-radius: 10px;
  background-color: var(--white-color);
  text-align: center;
  padding: 30px 20px;
  max-width: 230px;
  margin-left: auto;
  margin-right: auto;
}

.process-card-area {
  position: relative;
}

.process-card-area .process-line {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.process-card-area .position-top {
  top: 42px;
  bottom: unset;
}

.process-card-wrap {
  padding: 30px 0 0 30px;
}

.process-card-wrap:has(.pulse) {
  padding: 0 12px;
}

.process-card-wrap:has(.pulse):nth-child(even) {
  padding-top: 60px;
}

.process-card:has(.pulse) {
  margin-top: 52px;
}

.process-card .box-title {
  font-weight: 600;
  margin-bottom: 14px;
}

.process-card_number,
.process-card .pulse {
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: -30px;
  left: -30px;
  z-index: 2;
  font-size: 36px;
  font-weight: 700;
  color: var(--white-color);
}

.process-card_number:after, .process-card_number:before,
.process-card .pulse:after,
.process-card .pulse:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--theme-color);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.process-card_number:after,
.process-card .pulse:after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.process-card .pulse {
  width: 16px;
  height: 16px;
  position: absolute;
  top: -52px;
  left: calc(50% - 8px);
}

.process-card .pulse:before, .process-card .pulse:after {
  -webkit-animation-name: ripple2;
          animation-name: ripple2;
}

.process-card_icon {
  margin-bottom: 24px;
}

.process-card_text {
  margin-bottom: -0.53em;
  font-size: 14px;
}

/* Large devices */
@media (max-width: 1199px) {
  .process-card-area .process-line {
    display: none;
  }
  .process-card-wrap:has(.pulse):nth-child(even) {
    padding-top: 30px;
  }
  .process-card:has(.pulse) {
    margin-top: 8px;
  }
  .process-card .pulse {
    top: -8px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .process-card-wrap:has(.pulse):nth-child(even) {
    padding-top: 0;
  }
}

/*process area 3*********************/
.process-area-3 .sec-text {
  max-width: 430px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .process-area-3 .sec-text {
    margin-left: auto;
    margin-right: auto;
  }
}

.process-card-area3 {
  position: relative;
}

.process-card-area3 .process-line {
  margin: -268px -25px 0;
  position: absolute;
  z-index: -1;
  /* Medium Large devices */
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1399px) {
  .process-card-area3 .process-line {
    max-width: 1100px;
  }
}

@media (max-width: 1299px) {
  .process-card-area3 .process-line {
    max-width: 900px;
    margin: -208px -25px 0;
  }
}

@media (max-width: 1199px) {
  .process-card-area3 .process-line {
    display: none;
  }
}

.process-card-area3 .process-card-wrap {
  padding-top: 0;
  padding-left: 80px;
  position: relative;
  z-index: 2;
  /* Medium Large devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .process-card-area3 .process-card-wrap {
    padding-left: 20px;
  }
}

@media (max-width: 575px) {
  .process-card-area3 .process-card-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.process-card-area3 .process-card-wrap:first-child {
  padding-top: 40px;
  /* Medium Large devices */
  /* Medium Large devices */
}

@media (max-width: 1399px) {
  .process-card-area3 .process-card-wrap:first-child {
    padding-top: 30px;
  }
}

@media (max-width: 1299px) {
  .process-card-area3 .process-card-wrap:first-child {
    padding-top: 0px;
  }
}

.process-card-area3 .process-card-wrap:nth-child(2) {
  margin-top: -40px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .process-card-area3 .process-card-wrap:nth-child(2) {
    margin-top: 40px;
  }
}

.process-card-area3 .process-card-wrap:nth-child(3) {
  margin-top: -280px;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1299px) {
  .process-card-area3 .process-card-wrap:nth-child(3) {
    margin-top: -240px;
  }
}

@media (max-width: 1199px) {
  .process-card-area3 .process-card-wrap:nth-child(3) {
    margin-top: 40px;
  }
}

.process-card.style3 {
  text-align: start;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  max-width: 326px;
  background: transparent;
  margin-bottom: -0.3em;
}

.process-card.style3 .process-card_icon {
  height: 64px;
  width: 64px;
  line-height: 64px;
  text-align: center;
  border-radius: 20px;
  background: var(--white-color);
  margin-bottom: 30px;
}

.process-card.style3 .process-card_icon img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.process-card.style3 .process-card_number {
  background: transparent;
  width: auto;
  height: auto;
  left: auto;
  right: 0;
  color: var(--title-color);
  opacity: 0.06;
  font-size: 230px;
  font-weight: 800;
  line-height: 1;
  top: -50px;
}

.process-card.style3 .process-card_number:after, .process-card.style3 .process-card_number:before {
  display: none;
}

.process-card.style3 .process-card_text {
  font-size: 16px;
  margin-bottom: 13px;
}

.process-card.style3:hover .process-card_icon img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/*process-item start ----------------------------------*/
.process-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 60px;
  z-index: 2;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .process-item {
    gap: 20px;
  }
}

.process-item:not(:last-child) {
  margin-bottom: 55px;
}

.process-item_number {
  position: relative;
  color: var(--white-color);
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  padding: 5px 15px;
  background: -webkit-linear-gradient(top, var(--theme-color) 0%, var(--theme-color2) 100%);
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  z-index: 2;
}

.process-item_number:after {
  content: url("data:image/svg+xml,%3Csvg width='36' height='40' viewBox='0 0 36 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='35' height='39' rx='12.5' stroke='url(%23paint0_linear_418_444)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_418_444' x1='18' y1='0' x2='18' y2='40' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2302CE80'/%3E%3Cstop offset='1' stop-color='%237BD706'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  position: absolute;
  inset: 0px;
  z-index: -1;
}

.process-item_number:before {
  content: url("data:image/svg+xml,%3Csvg width='1' height='100' viewBox='0 0 1 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.5' y1='2.18557e-08' x2='0.499996' y2='100' stroke='url(%23paint0_linear_418_455)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_418_455' x1='0' y1='0' x2='3.57637e-06' y2='100' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2302CE80' stop-opacity='0'/%3E%3Cstop offset='0.475' stop-color='%231BD067'/%3E%3Cstop offset='1' stop-color='%237BD706' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  position: absolute;
  width: 2px;
  height: 100px;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
      -ms-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}

.process-item_number:before:last-child {
  display: none;
}

.process-item .box-title {
  font-size: 22px;
  color: var(--white-color);
  font-weight: 500;
}

.process-item_content {
  max-width: 232px;
}

.process-item_text {
  color: #8D929C;
}

.process-item_text:last-child {
  margin-bottom: -0.3rem;
}

.process-item:last-child .process-item_number:before {
  display: none;
}

.process-image {
  border-radius: 20px;
}

.process-image img {
  border-radius: 20px;
}

.checklist-wrapper {
  position: relative;
  min-width: 645px;
  min-height: 300px;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: 25px;
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1199px) {
  .checklist-wrapper {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .checklist-wrapper {
    background: none;
    min-width: 100%;
    min-height: 100%;
  }
}

@media (max-width: 352px) {
  .checklist-wrapper {
    background-image: none !important;
  }
}

.checklist.style5 ul li {
  position: relative;
  font-family: var(--title-font);
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: var(--white-color);
  padding: 31px 35px 34px 75px;
  margin-bottom: 0px;
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 991px) {
  .checklist.style5 ul li {
    padding: 33px 29px 40px 75px;
  }
}

@media (max-width: 767px) {
  .checklist.style5 ul li {
    padding: 30px 29px 35px 75px;
  }
}

@media (max-width: 352px) {
  .checklist.style5 ul li {
    padding: 15px 20px 15px 40px;
    font-size: 18px;
  }
}

.checklist.style5 ul li:before {
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_417_424)'%3E%3Cpath d='M6.75 9.00002L10.044 13.611C10.1796 13.8009 10.3569 13.9571 10.5623 14.0677C10.7677 14.1783 10.9958 14.2403 11.2289 14.249C11.462 14.2577 11.694 14.2128 11.9071 14.1178C12.1202 14.0228 12.3086 13.8802 12.458 13.701L23.25 0.749023' stroke='url(%23paint0_linear_417_424)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21.75 10.5V20.25C21.75 21.0456 21.4339 21.8087 20.8713 22.3713C20.3087 22.9339 19.5456 23.25 18.75 23.25H3.75C2.95435 23.25 2.19129 22.9339 1.62868 22.3713C1.06607 21.8087 0.75 21.0456 0.75 20.25V5.25C0.75 4.45435 1.06607 3.69129 1.62868 3.12868C2.19129 2.56607 2.95435 2.25 3.75 2.25H16.5' stroke='url(%23paint1_linear_417_424)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_417_424' x1='15' y1='0.749023' x2='15' y2='14.2501' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2302CE80'/%3E%3Cstop offset='1' stop-color='%237BD706'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_417_424' x1='11.25' y1='2.25' x2='11.25' y2='23.25' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2302CE80'/%3E%3Cstop offset='1' stop-color='%237BD706'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_417_424'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  position: absolute;
  top: 30px;
  left: 30px;
}

@media (max-width: 352px) {
  .checklist.style5 ul li:before {
    top: 10px;
    left: 0;
  }
}

.checklist.style5 ul li:not(:last-child) {
  margin-bottom: 22px;
}

@media (max-width: 352px) {
  .checklist.style5 ul li:not(:last-child) {
    margin-bottom: 0;
  }
}

/*process-item end ----------------------------------*/
/*process start ----------------------------------*/
.process-tabs-wrapper {
  position: relative;
  border-radius: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-filter: drop-shadow(0px 16px 48px rgba(18, 20, 26, 0.06));
          filter: drop-shadow(0px 16px 48px rgba(18, 20, 26, 0.06));
  z-index: 3;
  overflow: hidden;
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 990px) {
  .process-tabs-wrapper {
    display: block;
  }
}

.process-tabs-wrapper:before {
  content: "";
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: cover;
          mask-size: cover;
  background-size: cover;
  background-position: center center;
  -webkit-mask-image: url(../img/shape/process-shape.png);
          mask-image: url(../img/shape/process-shape.png);
  background-color: var(--white-color);
  z-index: -1;
  overflow: hidden;
  /* Medium Large devices */
}

@media (max-width: 1199px) {
  .process-tabs-wrapper {
    gap: 30px;
  }
}

.process-tabs-wrapper .process-image {
  min-width: 344px;
  border-radius: 30px;
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1199px) {
  .process-tabs-wrapper .process-image {
    min-width: 230px;
  }
}

@media (max-width: 767px) {
  .process-tabs-wrapper .process-image {
    min-width: 100%;
    margin-top: 40px;
  }
}

.process-tabs-wrapper .box-text {
  font-size: 14px;
  margin-bottom: 0;
}

.process-tabs-wrapper .checklist li {
  font-size: 14px;
}

.process-tabs-wrapper .checklist li:not(:last-child) {
  margin-bottom: 5px;
}

.process-tabs-wrapper .th-btn {
  margin-top: 30px;
}

.process-tabs-tabs {
  /* Medium devices */
}

.process-tabs-tabs.nav-tabs {
  border-bottom: none;
  padding: 60px 40px 60px 60px;
  display: block;
  /* Medium Large devices */
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .process-tabs-tabs.nav-tabs {
    padding: 40px 40px 40px 40px;
  }
}

@media (max-width: 991px) {
  .process-tabs-tabs.nav-tabs {
    padding: 40px 20px 40px 40px;
    margin-bottom: 0;
  }
}

@media (max-width: 375px) {
  .process-tabs-tabs.nav-tabs {
    max-width: 100%;
    padding: 60px 20px 20px 20px;
  }
}

.process-tabs-tabs.nav-tabs .nav-link:focus,
.process-tabs-tabs.nav-tabs .nav-link:hover {
  color: var(--title-color);
  background-color: transparent;
  border-color: transparent;
}

.process-tabs-tabs.nav-tabs .nav-item.show .nav-link,
.process-tabs-tabs.nav-tabs .nav-link.active {
  color: var(--white-color);
  background-color: transparent;
  border-color: transparent;
}

.process-tabs-tabs.nav-tabs .nav-item.show .nav-link .step,
.process-tabs-tabs.nav-tabs .nav-link.active .step {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.process-tabs-tabs.nav-tabs .nav-item.show .nav-link .title,
.process-tabs-tabs.nav-tabs .nav-link.active .title {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.process-tabs-tabs .nav-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.process-tabs-tabs .nav-link:not(:last-child) {
  margin-bottom: 18px;
}

@media (max-width: 520px) {
  .process-tabs-tabs .nav-link {
    display: block;
  }
}

.process-tabs-tabs .nav-link .step {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--title-color);
  background-color: var(--white-color);
  border: 1px solid #E3E7F0;
  border-radius: 100px;
  display: block;
  padding: 19.5px 28px;
  min-width: 124px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .process-tabs-tabs .nav-link .step {
    font-size: 14px;
    padding: 16px 20px;
  }
}

@media (max-width: 520px) {
  .process-tabs-tabs .nav-link .step {
    margin-bottom: 20px;
  }
}

@media (max-width: 375px) {
  .process-tabs-tabs .nav-link .step {
    min-width: 100%;
    padding: 14px 30px;
  }
}

.process-tabs-tabs .nav-link .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--title-color);
  background-color: var(--white-color);
  border: 1px solid #E3E7F0;
  border-radius: 100px;
  display: block;
  padding: 14px 28px;
  min-width: 300px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  /* Medium Large devices */
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1299px) {
  .process-tabs-tabs .nav-link .title {
    min-width: 200px;
  }
}

@media (max-width: 1199px) {
  .process-tabs-tabs .nav-link .title {
    font-size: 16px;
    padding: 14px 20px;
    min-width: 150px;
  }
}

@media (max-width: 990px) {
  .process-tabs-tabs .nav-link .title {
    min-width: 300px;
  }
}

@media (max-width: 375px) {
  .process-tabs-tabs .nav-link .title {
    min-width: 100%;
    padding: 14px 30px;
  }
}

@media (max-width: 991px) {
  .process-tabs-tabs {
    margin-bottom: 30px;
  }
}

.process-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  border-left: 1px solid #E3E7F0;
  padding: 60px 60px 60px 40px;
  z-index: 2;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 1299px) {
  .process-wrapper {
    padding: 40px 40px 40px 40px;
  }
}

@media (max-width: 1199px) {
  .process-wrapper {
    padding: 60px 40px 40px 0;
    margin: 0;
    border: none;
  }
}

@media (max-width: 991px) {
  .process-wrapper {
    padding: 40px 40px 40px 0;
  }
}

@media (max-width: 990px) {
  .process-wrapper {
    padding: 0px 40px 40px 60px;
  }
}

@media (max-width: 767px) {
  .process-wrapper {
    display: block;
    padding: 0 40px 40px 40px;
  }
}

.process-wrapper:before {
  content: "";
  position: absolute;
  top: 60px;
  left: -2px;
  width: 9px;
  height: 35px;
  background-color: var(--white-color);
  z-index: 1;
}

.process-area5 {
  position: relative;
  padding: 400px 0 240px 0;
  -webkit-clip-path: path("M0 0L270 128.559H1920V1192L1650 1068.58H0V0Z");
          clip-path: path("M0 0L270 128.559H1920V1192L1650 1068.58H0V0Z");
  /* Large devices */
}

@media (min-width: 1930px) {
  .process-area5 {
    -webkit-clip-path: path("M0 0L468.703 128.559H3333V1192L2864.3 1068.58H0V0Z");
            clip-path: path("M0 0L468.703 128.559H3333V1192L2864.3 1068.58H0V0Z");
  }
}

@media (max-width: 990px) {
  .process-area5 {
    -webkit-clip-path: none;
            clip-path: none;
    padding: 515px 0 80px 0;
    margin-bottom: 80px;
  }
}

@media (max-width: 1199px) {
  .process-area5 .container {
    max-width: 100%;
  }
}

/*process end ----------------------------------*/
/* process area start ----------------------*/
.process-area4 {
  max-width: 1760px;
  display: block;
  margin: auto;
}

.process-card2 {
  text-align: center;
}

.process-card2-wrap {
  position: relative;
}

.process-card2-wrap:after {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='154' height='31' viewBox='0 0 154 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.01514 21.3442C22.6268 8.46921 79.5758 -10.9353 134.479 14.4469' stroke='white' stroke-width='3' stroke-dasharray='5 5'/%3E%3Cpath d='M134.055 13.9293L134.078 14.2636L133.777 14.4113L124.484 18.9803L146.959 21.7007L133.365 3.59837L134.055 13.9293Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  height: 30px;
  width: 153px;
  position: absolute;
  top: 75px;
  right: -75px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .process-card2-wrap:after {
    display: none;
  }
}

.process-card2-wrap:last-child:after {
  display: none;
}

.process-card2_icon {
  width: 134px;
  height: 134px;
  line-height: 134px;
  background-color: #EFF1F9;
  text-align: center;
  border: 5px solid rgba(28, 28, 37, 0.2);
  border-radius: 100px;
  margin: 0 auto 25px auto;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  /* Large devices */
}

@media (max-width: 1199px) {
  .process-card2_icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

.process-card2_icon img {
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}

.process-card2 .box-title {
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 8px;
}

.process-card2_text {
  max-width: 285px;
  color: var(--white-color);
  margin: 0 auto -0.5em auto;
}

.process-card2_number {
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 60px;
  line-height: 36px;
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #466EF9 100%);
  background: linear-gradient(180deg, #FFFFFF 0%, #466EF9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.process-card2:hover .process-card2_icon img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/* process area end ----------------------*/
/* process area start --------------------*/
.process-area6 {
  background-color: #16181D;
  -webkit-mask-repeat: repeat-x;
          mask-repeat: repeat-x;
  -webkit-mask-size: cover;
          mask-size: cover;
  /* Medium devices */
}

@media (max-width: 991px) {
  .process-area6 {
    -webkit-mask-image: none !important;
            mask-image: none !important;
  }
}

.process-shape {
  background-repeat: repeat-x;
  width: 100%;
  height: 100%;
}

.process-card3 {
  position: relative;
}

.process-card3_icon {
  position: relative;
  margin-bottom: 30px;
}

.process-card3_icon:before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: rgba(64, 115, 96, 0.3);
  border-radius: 999px;
  z-index: -1;
}

.process-card3_icon img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.process-card3 .box-title {
  color: var(--white-color);
  font-size: 30px;
  font-weight: 600;
}

.process-card3_text {
  color: #A1A6B0;
}

.process-card3:hover .process-card3_icon img {
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
}

/*------------------- 4.00. Particle -------------------*/
.particle-1 {
  max-width: 440px;
  height: 390px;
}

.particle-2 {
  max-width: 800px;
  height: 790px;
  aspect-ratio: 16 / 14;
}

.particle-2.small {
  max-width: 515px;
  height: 490px;
}

.particle-3 {
  max-width: 600px;
  height: 470px;
}

.box-particle {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 180px;
  height: 100px;
  z-index: -1;
}

.price-particle {
  width: 190px;
  height: 165px;
}

.body-particle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

/*------------------- 4.00. Feature -------------------*/
/* Feature Card ---------------------------------- */
.feature-card {
  text-align: center;
  border: 2px solid #D9E0F3;
  border-radius: 10px;
  padding: 40px 12px;
}

.feature-card .shape-icon {
  width: 120px;
  height: 115px;
  line-height: 115px;
  margin: 0 auto 30px auto;
  -webkit-filter: drop-shadow(0px 10px 30px rgba(8, 14, 28, 0.06));
          filter: drop-shadow(0px 10px 30px rgba(8, 14, 28, 0.06));
}

.feature-card .shape-icon:before {
  background-color: var(--white-color);
  -webkit-clip-path: path("M6.35418 25.7699C-3.10248 43.0115 -1.601 65.2669 8.18807 82.317C21.8699 106.149 51.9755 119.386 78.1565 111.915C104.338 104.443 123.425 76.1006 119.484 48.8163C112.307 -0.867846 31.1988 -19.5262 6.35418 25.7699Z");
          clip-path: path("M6.35418 25.7699C-3.10248 43.0115 -1.601 65.2669 8.18807 82.317C21.8699 106.149 51.9755 119.386 78.1565 111.915C104.338 104.443 123.425 76.1006 119.484 48.8163C112.307 -0.867846 31.1988 -19.5262 6.35418 25.7699Z");
}

.feature-card_text {
  max-width: 315px;
  margin: 0 auto -0.5em auto;
}

/* Appijntment 3 ---------------------------------- */
.feature-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 25px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .feature-wrapper {
    margin-bottom: 30px;
  }
}

.feature-wrapper.style4 {
  gap: 15px;
}

.feature-wrapper .feature-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  color: var(--white-color);
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--white-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.feature-wrapper .feature-icon:hover {
  background-color: var(--white-color);
}

.feature-wrapper .feature-icon:hover i {
  color: var(--theme-color);
}

.feature-wrapper .feature-icon i {
  color: var(--white-color);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.feature-wrapper .header-info_link {
  font-family: var(--title-font);
  font-size: 18px;
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: -0.3rem;
}

.feature-wrapper .header-info_link a {
  color: inherit;
}

.feature-wrapper .header-info_label {
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 500;
  color: var(--white-color);
  margin-bottom: 0;
}

.feature-wrapper.style3 {
  margin-bottom: 60px;
}

.feature-wrapper.style3 .feature-icon {
  width: 75px;
  height: 75px;
  line-height: 75px;
  background-color: #1E2127;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.feature-wrapper.style3 .feature-icon:hover {
  background-color: var(--theme-color);
}

.feature-wrapper.style3 .header-info_label {
  font-size: 16px;
  font-family: var(--body-font);
  color: var(--body-color);
  font-weight: 500;
  letter-spacing: -0.32px;
}

.feature-wrapper.style3 .header-info_link {
  font-family: var(--body-font);
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.48px;
}

/* Feature 2 ---------------------------------- */
.choose-feature {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 35px;
  max-width: 410px;
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1199px) {
  .choose-feature {
    gap: 25px;
  }
}

@media (max-width: 767px) {
  .choose-feature {
    display: block;
    max-width: 100%;
    margin: auto;
  }
}

.choose-feature .box-icon {
  position: relative;
  z-index: 2;
  min-width: 50px;
}

.choose-feature .box-icon:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #E8ECF6;
  border-radius: 999px;
  z-index: -1;
  /* Small devices */
}

@media (max-width: 767px) {
  .choose-feature .box-icon:before {
    top: 15px;
    left: 50%;
  }
}

.choose-feature .box-icon img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-left: 10px;
}

.choose-feature .box-title {
  margin-bottom: 7px;
  font-size: 20px;
  font-weight: 700;
}

.choose-feature_text {
  margin-bottom: -0.3rem;
}

.choose-feature:hover .box-icon img {
  -webkit-transform: scale(-1) rotate(180deg);
      -ms-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}

/* Small devices */
@media (max-width: 767px) {
  .choose-feature {
    text-align: center;
    padding: 0px 20px;
  }
  .choose-feature .box-icon {
    position: static;
    margin: 0 auto 20px auto;
    padding-top: 0;
    padding-left: 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .choose-feature {
    max-width: 100%;
    width: 100%;
  }
  .choose-feature .box-text {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Feature 7 ---------------------------------- */
.feature-wrap7 {
  position: relative;
}

.feature-wrap7 .feature-bg-line {
  position: absolute;
  left: 50%;
  top: 60px;
  bottom: 0;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  /* Large devices */
}

.feature-wrap7 .feature-bg-line img {
  height: calc(100% + 20px);
}

@media (max-width: 1199px) {
  .feature-wrap7 .feature-bg-line {
    display: none;
  }
}

.feature-wrap7 .feature-content {
  position: relative;
}

.feature-wrap7 .feature-content:after, .feature-wrap7 .feature-content:before {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 16px;
  display: inline-block;
  background: var(--white-color);
  left: 0;
  top: 50%;
  -webkit-transform: translate(-94px, -50%);
      -ms-transform: translate(-94px, -50%);
          transform: translate(-94px, -50%);
  /* Medium Large devices */
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1399px) {
  .feature-wrap7 .feature-content:after, .feature-wrap7 .feature-content:before {
    -webkit-transform: translate(-91px, -50%);
        -ms-transform: translate(-91px, -50%);
            transform: translate(-91px, -50%);
  }
}

@media (max-width: 1299px) {
  .feature-wrap7 .feature-content:after, .feature-wrap7 .feature-content:before {
    -webkit-transform: translate(-84px, -50%);
        -ms-transform: translate(-84px, -50%);
            transform: translate(-84px, -50%);
  }
}

@media (max-width: 1199px) {
  .feature-wrap7 .feature-content:after, .feature-wrap7 .feature-content:before {
    display: none;
  }
}

.feature-wrap7 .feature-content:after {
  background: var(--theme-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 20px;
}

.feature-wrap7 .feature-content.left-content:after, .feature-wrap7 .feature-content.left-content:before {
  left: auto;
  right: 0;
  -webkit-transform: translate(94px, -50%);
      -ms-transform: translate(94px, -50%);
          transform: translate(94px, -50%);
  /* Medium Large devices */
}

@media (max-width: 1399px) {
  .feature-wrap7 .feature-content.left-content:after, .feature-wrap7 .feature-content.left-content:before {
    -webkit-transform: translate(93px, -50%);
        -ms-transform: translate(93px, -50%);
            transform: translate(93px, -50%);
  }
}

.feature-wrap7 .feature-content.left-content:after {
  margin-left: 0;
  margin-right: 20px;
}

.feature-wrap7 .feature-content .two-column {
  /* Extra small devices */
}

@media (max-width: 575px) {
  .feature-wrap7 .feature-content .two-column {
    gap: 10px;
  }
}

/* Feature 8 ---------------------------------- */
.feature-card-wrap2:nth-of-type(even) .feature-card.style2:after {
  left: 0;
  right: auto;
  top: 0;
  -webkit-transform: translate(calc(-100% + 170px), calc(-100% + 160px));
      -ms-transform: translate(calc(-100% + 170px), calc(-100% + 160px));
          transform: translate(calc(-100% + 170px), calc(-100% + 160px));
}

.feature-card.style2 {
  border-radius: 20px;
  box-shadow: 0px 20px 30px rgba(187, 192, 207, 0.15);
  background: var(--white-color);
  border: 0;
  text-align: start;
  padding: 40px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .feature-card.style2 {
    padding: 30px;
  }
}

.feature-card.style2:after {
  content: '';
  position: absolute;
  height: 380px;
  width: 380px;
  border: 50px solid #EFF1F9;
  border-radius: 50%;
  right: 0;
  top: 0;
  z-index: -1;
  -webkit-transform: translate(calc(100% - 170px), calc(-100% + 160px));
      -ms-transform: translate(calc(100% - 170px), calc(-100% + 160px));
          transform: translate(calc(100% - 170px), calc(-100% + 160px));
}

.feature-card.style2 .feature-card-icon {
  margin-bottom: 38px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .feature-card.style2 .feature-card-icon {
    margin-bottom: 28px;
  }
}

.feature-card.style2 .feature-card-icon img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.feature-card.style2 .box-title {
  font-weight: 600;
}

.feature-card.style2 .feature-card_text {
  margin-left: 0;
}

.feature-card.style2:hover .feature-card-icon img {
  -webkit-transform: scale(-1) rotate(180deg);
      -ms-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}

/* feature ----------------------*/
.feature-area3 {
  background-color: var(--white-color);
  border-radius: 50px;
  padding: 80px 0;
  max-width: 1720px;
  display: block;
  margin: auto;
  /* Large devices */
}

@media (max-width: 1199px) {
  .feature-area3 {
    max-width: 100%;
    border-radius: 25px;
  }
}

.choose-checklist.list-two-column ul {
  gap: 0 24px;
  /* Small devices */
}

@media (max-width: 767px) {
  .choose-checklist.list-two-column ul {
    grid-template-columns: repeat(1, 1fr);
  }
}

.choose-checklist.list-two-column li {
  font-family: var(--title-font);
  background-color: var(--white-color);
  padding: 20px 30px;
  font-size: 18px !important;
  line-height: 28px !important;
  border-radius: 20px;
  margin-bottom: 24px;
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .choose-checklist.list-two-column li {
    padding: 18px 20px;
  }
}

.choose-checklist.list-two-column li:not(:last-child) {
  margin-bottom: 24px;
}

.choose-checklist.list-two-column li > i {
  font-size: 24px;
}

.choose-image {
  min-width: 632px;
  margin-left: 65px;
  /* Medium Large devices */
  /* Large devices */
}

@media (max-width: 1299px) {
  .choose-image {
    min-width: 100%;
  }
}

@media (max-width: 1199px) {
  .choose-image {
    margin-top: 30px;
    margin-left: 0;
  }
}

.choose-feature2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  max-width: 330px;
  /* Small devices */
}

@media (max-width: 767px) {
  .choose-feature2 {
    display: block;
    max-width: 100%;
    margin: auto;
    text-align: center;
  }
}

.choose-feature2-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 991px) {
  .choose-feature2-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

@media (max-width: 767px) {
  .choose-feature2-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.choose-feature2-wrap .divider {
  background: #F3F2EA;
  width: 4px;
  height: 154px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .choose-feature2-wrap .divider {
    display: none;
  }
}

.choose-feature2-wrap .divider:last-of-type {
  display: none;
}

.choose-feature2 .box-icon {
  position: relative;
  z-index: 2;
  min-width: 50px;
  /* Small devices */
}

@media (max-width: 767px) {
  .choose-feature2 .box-icon {
    margin-bottom: 20px;
  }
}

.choose-feature2 .box-icon img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.choose-feature2 .box-title {
  margin-bottom: 15px;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  /* Medium devices */
}

@media (max-width: 991px) {
  .choose-feature2 .box-title {
    font-size: 25px;
    line-height: 35px;
  }
}

.choose-feature2_text {
  margin-bottom: -0.3rem;
}

.choose-feature2:hover .box-icon img {
  -webkit-transform: scale(-1) rotate(180deg);
      -ms-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}

/* feature 8 ----------------------- */
.feature-wrap8.style2 {
  border-top: 1px solid #E3E7F0;
  margin-top: 50px;
}

.feature-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #E3E7F0;
  margin-top: 30px;
  padding-top: 40px;
}

@media (max-width: 480px) {
  .feature-wrap {
    display: block;
  }
  .feature-wrap .btn-wrap {
    margin-bottom: 15px;
  }
}

.two-column.style3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.about-client-box.style2 {
  padding: 0;
  gap: 15px;
}

.about-client-box.style2:before {
  display: none;
}

.about-client-box.style2 .about_review {
  margin-bottom: 3px;
}

.about-client-box.style2 .about_review i {
  font-size: 16px;
  color: #FFBE11;
  margin-right: 5px;
}

.about-client-box.style2 .cilent-box_counter {
  font-size: 14px;
  font-family: var(--body-font);
}

.about-client-box.style2 .client-thumb-group .thumb img {
  height: 51px;
}

.feature-thumb8 {
  text-align: right;
}

.feature-thumb8_wrapp {
  position: relative;
  /* Large devices */
}

@media (max-width: 1199px) {
  .feature-thumb8_wrapp {
    margin-top: 40px;
  }
}

.feature-thumb8_wrapp img {
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 25px;
}

.feature-thumb8-1 {
  text-align: right;
  /* Small devices */
}

@media (max-width: 767px) {
  .feature-thumb8-1 {
    text-align: left;
  }
}

.feature-thumb8-2 {
  position: absolute;
  left: 6%;
  bottom: 0;
  /* Small devices */
}

@media (max-width: 767px) {
  .feature-thumb8-2 {
    display: none;
  }
}

.feature-thumb10_wrapp {
  position: relative;
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1199px) {
  .feature-thumb10_wrapp {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .feature-thumb10_wrapp {
    margin-top: 40px;
  }
}

@media (max-width: 480px) {
  .feature-thumb10_wrapp {
    display: none;
  }
}

.feature-thumb10_wrapp img {
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 20px;
}

.feature-thumb10-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  position: absolute;
  right: 26%;
  top: 10%;
  /* Small devices */
}

@media (max-width: 767px) {
  .feature-thumb10-2 {
    display: none;
  }
}

.feature-thumb10-2 img {
  border-radius: 6px;
}

.feature-thumb10-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  position: absolute;
  right: 11%;
  bottom: 3%;
}

.feature-thumb10-3 img {
  border-radius: 12px;
}

.feature-thumb11-1 {
  text-align: right;
  /* Small devices */
}

@media (max-width: 767px) {
  .feature-thumb11-1 {
    text-align: left;
  }
}

.feature-thumb11-2 {
  position: absolute;
  left: 6%;
  bottom: 0;
  /* Small devices */
}

@media (max-width: 767px) {
  .feature-thumb11-2 {
    display: none;
  }
}

.feature-thumb11-2 img {
  border-radius: 12px;
}

.two-column.style3 {
  padding-right: 55px;
  margin-right: 16px;
}

@media (max-width: 480px) {
  .two-column.style3 {
    display: block;
  }
  .two-column.style3 .checklist {
    margin-bottom: 10px;
  }
}

.two-column.style3 .divider {
  background: #E3E7F0;
  width: 1px;
  height: 52px;
}

@media (max-width: 480px) {
  .two-column.style3 .divider {
    display: none;
  }
}

.left-content2 {
  border-right: 1px solid #E3E7F0;
  padding-right: 60px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .left-content2 {
    border-right: 0;
    padding-right: 0;
  }
}

.left-content3 {
  border-left: 1px solid #E3E7F0;
  padding-left: 80px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .left-content3 {
    border-left: 0;
    padding-left: 0;
  }
}

.feature-line {
  margin-top: 50px;
  margin-bottom: 0;
}

/* choose area start ---------------------------- */
.choose-image4 {
  /* Large devices */
}

@media (max-width: 1199px) {
  .choose-image4 {
    margin-bottom: 50px;
  }
}

.choose-image4 .img1 {
  position: relative;
  width: 100%;
  border-radius: 100px 20px 150px 20px;
  z-index: 2;
}

.choose-image4 .img1 img {
  width: 100%;
  border-radius: 100px 20px 150px 20px;
}

.choose-image4 .img1:before {
  content: "";
  position: absolute;
  inset: 0;
  right: -25px;
  background-color: #EFF1F9;
  border-radius: 100px 20px 100px 20px;
  z-index: -1;
}

.choose-feature3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  max-width: 510px;
}

.choose-feature3:not(:last-child) {
  margin-bottom: 20px;
}

.choose-feature3_icon {
  min-width: 56px;
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background-color: #E5EBFF;
  border-radius: 50%;
}

.choose-feature3 .box-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.choose-feature3_text {
  line-height: 26px;
  margin-bottom: -0.3rem;
}

/* choose area end ---------------------------- */
/* feature area start --------------------------------*/
.feature-wrapp {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  /* Extra small devices */
}

@media (max-width: 575px) {
  .feature-wrapp {
    grid-template-columns: repeat(1, 1fr);
  }
}

.feature-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  background-color: var(--white-color);
  border: 1px solid #E3E7F0;
  border-radius: 6px;
  padding: 20px;
}

.feature-item_icon {
  min-width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  border-radius: 50%;
  background: -webkit-linear-gradient(45deg, #3E66F3 0%, #8BA5FF 100%);
  background: linear-gradient(45deg, #3E66F3 0%, #8BA5FF 100%);
  border-radius: 6px;
}

.feature-item .box-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: -0.3rem;
}

.feature-image7 {
  margin: 0 -80px 0 50px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .feature-image7 {
    margin: 50px 0 0 0;
  }
}

.feature-image7 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-wrap.style2 {
  border-top: 1px solid #E3E7F0;
  margin-top: 40px;
  padding-top: 30px;
}

/* feature */
.checklist.style10 ul li {
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: var(--title-color);
  /* Medium Large devices */
}

@media (max-width: 1299px) {
  .checklist.style10 ul li {
    font-size: 18px;
    line-height: 28px;
  }
}

.checklist.style10 ul li:not(:last-child) {
  margin-bottom: 20px;
}

.checklist.style10 ul li img {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 10px;
  background: #EFF1F9;
  border: 1.5px solid #DFDFDF;
  border-radius: 14px;
}

.feature-box {
  position: relative;
  z-index: 8;
  /* Large devices */
}

@media (max-width: 1199px) {
  .feature-box {
    margin-top: 50px;
  }
}

.feature-box .img1 {
  text-align: right;
  /* Large devices */
}

@media (max-width: 1199px) {
  .feature-box .img1 {
    text-align: center;
  }
}

.feature-content11 {
  position: absolute;
  top: 22.8%;
  left: -23%;
  z-index: -1;
  /* Large devices */
  /* Small devices */
}

@media (max-width: 1199px) {
  .feature-content11 {
    left: -10%;
  }
}

@media (max-width: 767px) {
  .feature-content11 {
    display: none;
  }
}

.feature-text {
  background-color: var(--theme-color);
  font-weight: 700;
  font-size: 94px;
  line-height: 104px;
  color: rgba(255, 255, 255, 0.1);
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  border-radius: 40px;
  min-width: 550px;
  height: 299px;
  margin: 0;
  text-align: center;
  padding-top: 50px;
}

@media (max-width: 1399px) {
  .feature-text {
    padding-top: 30px;
  }
}

/* feature area start ----------------------*/
.feature-thumb12_wrapp {
  position: relative;
}

.feature-thumb12_wrapp .feature-thumb12-2 {
  position: absolute;
  left: -40px;
  bottom: 40px;
}

.feature-thumb12_wrapp .feature-thumb12-2 img {
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 20px;
}

.feature-thumb12_wrapp .feature-thumb12-4 {
  position: absolute;
  left: -80px;
  bottom: 40px;
}

.feature-thumb12_wrapp .feature-thumb12-4 img {
  box-shadow: 10px 10px 50px 10px rgba(0, 31, 96, 0.2);
  border-radius: 20px;
}

/* feature area end ----------------------*/
/* software area start ------------------------- */
.software-area {
  position: relative;
  background-color: var(--white-color);
  border: 1px solid #E3E7F0;
  border-radius: 30px;
  overflow: hidden;
}

.software-content {
  padding: 120px 0 120px 120px;
  /* Medium Large devices */
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1299px) {
  .software-content {
    padding: 120px 0 120px 40px;
  }
}

@media (max-width: 1199px) {
  .software-content {
    padding-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .software-content {
    padding: 80px 0 80px 40px;
  }
}

.soft-image {
  height: 510px;
  margin-left: -23%;
  /* Large devices */
}

@media (max-width: 1199px) {
  .soft-image {
    margin-left: 0;
  }
}

.soft-image img {
  height: 100%;
  object-fit: cover;
}

/* software area end ------------------------- */
/*------------------- 4.00. Screenshot -------------------*/
.screenshot-sec-1 {
  background-position: bottom;
  padding-bottom: 160px;
  /* Medium devices */
  /* Extra small devices */
}

@media (max-width: 991px) {
  .screenshot-sec-1 {
    padding-bottom: 100px;
  }
}

@media (max-width: 575px) {
  .screenshot-sec-1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.screen-slider1 .swiper-pagination-bullets {
  margin-bottom: 0;
  margin-top: 90px;
  height: 14px;
  /* Large devices */
  /* Extra small devices */
}

@media (max-width: 1199px) {
  .screen-slider1 .swiper-pagination-bullets {
    margin-top: 50px;
  }
}

@media (max-width: 575px) {
  .screen-slider1 .swiper-pagination-bullets {
    display: none;
  }
}

.screen-slider1 .swiper-pagination-bullets .swiper-pagination-bullet {
  --swiper-pagination-bullet-size: 14px;
  --swiper-pagination-bullet-horizontal-gap: 10px;
  background: rgba(255, 255, 255, 0.5);
}

.screen-slider1 .swiper-pagination-bullets .swiper-pagination-bullet:before {
  background: var(--white-color);
}

.screetshot-thumb img {
  width: 100%;
}

/*------------------- 4.00. Color Scheme -------------------*/
/* color scheme ---------------------------------- */
.color-scheme-wrap {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 99;
  background: var(--white-color);
  padding: 20px 30px;
  border-radius: 10px 0 0 10px;
  display: inline-block;
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 0px 4px 0px rgba(51, 51, 51, 0.2);
}

.color-scheme-wrap .switchIcon {
  position: absolute;
  left: 0;
  top: 10px;
  border: 0;
  background: var(--theme-color);
  color: var(--white-color);
  height: 45px;
  width: 45px;
  border-radius: 5px 0 0 5px;
  -webkit-transform: translate(-100%, 0);
      -ms-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}

.color-scheme-wrap .color-scheme-wrap-title {
  font-size: 22px;
  border-bottom: 1px solid var(--th-border-color);
  padding-bottom: 6px;
  color: var(--title-color);
  margin-bottom: 30px;
}

.color-scheme-wrap .color-scheme-wrap-title i {
  font-size: 18px;
  margin-right: 3px;
}

.color-scheme-wrap .color-switch-btns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  margin-bottom: 25px;
}

.color-scheme-wrap .color-switch-btns button {
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 34px;
  color: var(--theme-color);
  text-align: left;
}

.color-scheme-wrap.active {
  -webkit-transform: translate(100%, 0);
      -ms-transform: translate(100%, 0);
          transform: translate(100%, 0);
}

/*=================================
    05. Spacing
==================================*/
/*-- Padding Left And Right --*/
.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.px-35 {
  padding-right: 35px;
  padding-left: 35px;
}

.px-40 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-45 {
  padding-right: 45px;
  padding-left: 45px;
}

.px-50 {
  padding-right: 50px;
  padding-left: 50px;
}

/*-- Padding Top And Bottom --*/
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.fs-20 {
  font-size: 20px;
}

/*-- margin Left And Right --*/
.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mx-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}

.mx-35 {
  margin-right: 35px;
  margin-left: 35px;
}

.mx-40 {
  margin-right: 40px;
  margin-left: 40px;
}

.mx-45 {
  margin-right: 45px;
  margin-left: 45px;
}

.mx-50 {
  margin-right: 50px;
  margin-left: 50px;
}

/*-- margin Top And Bottom --*/
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

/*-- margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

/*-- margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

/*-- margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

/*-- margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-n1 {
  margin-top: -.25rem;
}

.mt-n2 {
  margin-top: -.6rem;
}

.mt-n3 {
  margin-top: -0.8rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -3rem;
}

.mb-n1 {
  margin-bottom: -.25rem;
}

.mb-n2 {
  margin-bottom: -.6rem;
}

.mb-n3 {
  margin-bottom: -0.8rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -3rem;
}

.mb-38 {
  margin-bottom: 38px;
}

.space,
.space-top {
  padding-top: var(--section-space);
}

.space,
.space-bottom {
  padding-bottom: var(--section-space);
}

.space-extra,
.space-extra-top {
  padding-top: calc(var(--section-space) - 30px);
}

.space-extra,
.space-extra-bottom {
  padding-bottom: calc(var(--section-space) - 30px);
}

.space-extra2,
.space-extra2-top {
  padding-top: calc(var(--section-space) - 40px);
}

.space-extra2,
.space-extra2-bottom {
  padding-bottom: calc(var(--section-space) - 40px);
}

/* Medium devices */
@media (max-width: 991px) {
  .space,
  .space-top {
    padding-top: var(--section-space-mobile);
  }
  .space,
  .space-bottom {
    padding-bottom: var(--section-space-mobile);
  }
  .space-extra,
  .space-extra-top {
    padding-top: calc(var(--section-space-mobile) - 30px);
  }
  .space-extra,
  .space-extra-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }
  .space-top-md-none {
    padding-top: 0;
  }
  .space-extra2,
  .space-extra2-top {
    padding-top: 70px;
  }
  .space-extra2,
  .space-extra2-bottom {
    padding-bottom: 70px;
  }
}

