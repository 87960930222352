.widget_nav_menu,
.widget_meta,
.widget_pages,
.widget_archive,
.widget_categories {
    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 0 0;
    }

    > ul,
    .menu {
        margin: 0 0 -10px 0;
    }

    a {
        display: block;
        background-color: $white-color;
        margin: 0 0 10px 0;
        padding: 16px 45px 19px 25px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.313;
        color: $body-color;
        border-radius: 4px;

        &::before {
            content: "\f061";
            position: absolute;
            right: 25px;
            top: 17px;
            font-family: $icon-font;
            font-weight: 600;
            color: $theme-color;
            transition: all ease 0.4s;
        }
    }

    li {
        display: block;
        position: relative;

        &:last-child {
            a {
                border-bottom: none;
            }
        }

        > span {
            color: $white-color;
            background-color: #272A34;
            font-weight: 600;
            text-align: center;
            position: absolute;
            right: 24px;
            top: 15px;
            transition: all ease 0.4s;
            pointer-events: none;
            min-width: 20px;
            text-align: right;
        }
    }
    a {
        &:hover {
            color: $white-color;
            background-color: $theme-color;
            &:before {
                color: $white-color;
            }
            ~ span {
                color: $white-color;
                background-color: $theme-color;
            }
        }
    }

    .children {
        margin-left: 10px;
    }
}

.widget_nav_menu,
.widget_meta,
.widget_pages {
    a {
        padding-right: 20px;
    }
}

.widget_nav_menu {
    .sub-menu {
        margin-left: 10px;
    }
}

.wp-block-archives {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;

    a:not(:hover) {
        color: inherit;
    }
}

.th-blog ul.wp-block-archives li {
    margin: 5px 0;
}
