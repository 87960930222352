.breadcumb-menu {
    max-width: 100%;
    padding: 0;
    margin: 30px 0 -0.5em 0;
    list-style-type: none;
    position: relative;
    li {
        display: inline-block;
        margin-right: 3px;
        padding-right: 5px;
        list-style: none;
        position: relative;

        &:after {
            content: "\f324";
            position: relative;
            margin-left: 10px;
            font-weight: 500;
            font-size: 15px;
            color: $white-color;
            font-family: $icon-font;
        }

        &:last-child {
            padding-right: 0;
            margin-right: 0;

            &:after {
                display: none;
            }
        }

        // &:last-child {
        //   &,
        //   a,
        //   span {
        //     color: $theme-color;
        //   }
        // }
    }

    li,
    a,
    span {
        white-space: normal;
        color: inherit;
        word-break: break-word;
        font-weight: 400;
        font-size: 18px;
        color: $white-color;
    }
}

.breadcumb-title {
    color: $white-color;
    margin: -0.22em 0 -0.14em 0;
	line-height: 1.1;
}

.breadcumb-wrapper {
    background-color: $title-color;
    padding: 160px 0;
    overflow: hidden;
    text-align: center;
}

@include lg {
    .breadcumb-wrapper {
        padding: 140px 0;
    }
	.breadcumb-menu {
        margin: 20px 0 -0.5em 0;
        li,
        a,
        span {
            font-size: 16px;
        }
    }
}

@include md {
    .breadcumb-wrapper {
        padding: 120px 0;
    }
}

@include sm {
    .breadcumb-wrapper {
        padding: 100px 0;
    }
}

@include xs {
	.breadcumb-title {
		font-size: 34px;
	}
}