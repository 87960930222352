/*-- margin Left And Right --*/
@for $i from 1 through $space-count {
  .mx-#{5 * $i} {
    margin-right: 5px *$i;
    margin-left: 5px *$i;
  }
}

/*-- margin Top And Bottom --*/
@for $i from 1 through $space-count {
  .my-#{5 * $i} {
    margin-top: 5px *$i;
    margin-bottom: 5px *$i;
  }
}

/*-- margin Top --*/
@for $i from 1 through $space-count {
  .mt-#{5 * $i} {
    margin-top: 5px *$i;
  }
}

/*-- margin Bottom --*/
@for $i from 1 through $space-count {
  .mb-#{5 * $i} {
    margin-bottom: 5px *$i;
  }
}

/*-- margin Left --*/
@for $i from 1 through $space-count {
  .ml-#{5 * $i} {
    margin-left: 5px *$i;
  }
}

/*-- margin Right --*/
@for $i from 1 through $space-count {
  .mr-#{5 * $i} {
    margin-right: 5px *$i;
  }
}

.mb-60 {
  margin-bottom: 60px;
}


.mt-n1 {
  margin-top: -.25rem;
}

.mt-n2 {
  margin-top: -.6rem;
}

.mt-n3 {
  margin-top: -0.8rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -3rem;
}

.mb-n1 {
  margin-bottom: -.25rem;
}

.mb-n2 {
  margin-bottom: -.6rem;
}

.mb-n3 {
  margin-bottom: -0.8rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -3rem;
}

.mb-38 {
  margin-bottom: 38px;
}