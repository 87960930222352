/* Testimonial Card ---------------------------------- */
.testi-card {
    background-color: $white-color;
    border-radius: 10px;
    padding: 40px;
    position: relative;

    &-area {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    &-slide {
        background-color: $white-color;
        border-radius: 10px;
        padding: 40px;
        position: relative;
    }

    &-tab {
        position: relative;
        z-index: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 5px 0;
        width: 56px;

        &:after {
            content: '';
            height: 100%;
            width: 3px;
            position: absolute;
            top: 0;
            left: 26.5px;
            background-color: $theme-color;
            z-index: -1;
        }

        .tab-btn {
            cursor: pointer;
            width: 56px;
            border-radius: 99px;

            img {
                border-radius: inherit;
            }
        }

        .indicator {
            position: absolute;
            top: calc(var(--pos-y) - 5px);
            left: calc(var(--pos-x) - 5px);
            width: calc(var(--width-set) + 10px);
            height: calc(var(--height-set) + 10px);
            border: 3px solid $theme-color;
            transition: 0.4s ease-in-out;
            border-radius: 50%;
        }
    }

    &_quote {
        position: absolute;
        right: 40px;
        bottom: 40px;
    }

    &_profile {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &_avater {
        border-radius: 99px;
        overflow: hidden;
    }

    &_name {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    &_desig {
        margin-bottom: 0;
        display: block;
        color: $theme-color;
    }

    &_text {
        margin-bottom: 22px;
    }

    &_review {
        color: $theme-color;
        font-size: 14px;
        margin-top: -0.4em;
        margin-bottom: 17px;

        i {
            margin-right: 3px;
        }
    }
}

@include lg {
    .testi-card-area {
        max-width: 700px;
    }
}

@include sm {
    .testi-card {
        &-area {
            display: block;
        }

        &-tab {
            flex-direction: row;
            width: 100%;
            max-width: 300px;
            margin: 30px auto 0 auto;

            &:after {
                height: 3px;
                top: 26.5px;
                left: 0;
                width: 100%;
            }

            .tab-btn {
                flex: 56px;
                width: auto;
                max-width: 56px;
            }
        }
    }
}

@include vxs {
    .testi-card {
        padding: 40px 20px;

        &_name {
            font-size: 20px;
        }

        &_avater {
            max-width: 60px;
        }
    }
}


/* Testimonial Box ---------------------------------- */
.testi-box {
    position: relative;
    display: flex;
    background-color: $white-color;
    box-shadow: 0px 6px 15px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    gap: 30px;
    padding: 40px;

    &_img {
        min-width: 180px;
        text-align: center;

        >img {
            border-radius: 10px;
            width: 100%;
        }
    }

    &_quote {
        height: 56px;
        width: 56px;
        line-height: 52px;
        background-color: $theme-color;
        border-radius: 50%;
        text-align: center;
        position: relative;
        z-index: 2;
        margin: -28px auto 0 auto;

        img {
            position: relative;
            z-index: 1;
        }

        &:after,
        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background-color: $theme-color;
            @extend .ripple-animation;
            z-index: -1;
            border-radius: 50%;
            transition: all ease 0.4s;
        }

        &:after {
            animation-delay: 2s;
        }
    }

    &_avater {
        margin-bottom: 12px;

        img {
            border-radius: 999px;
        }
    }

    .box-title {
        margin-bottom: 4px;
    }

    &_desig {
        font-size: 14px;
        display: block;
        margin-bottom: -0.5em;
    }

    &_text {
        margin-top: -0.45em;
        padding-bottom: 23px;
        margin-bottom: 30px;
        border-bottom: 1px solid $smoke-color2;
    }

    &_review {
        color: $theme-color;
        font-size: 14px;
        position: absolute;
        bottom: 55px;
        right: 40px;

        i {
            margin-right: 3px;
        }
    }
}

@include lg {
    .testi-box {
        max-width: 760px;
        margin-left: auto;
        margin-right: auto;

        &_review {
            bottom: 75px;
        }
    }
}

@include sm {
    .testi-box {
        padding: 20px;
        gap: 20px;

        &_img {
            min-width: 140px;
        }

        &_review {
            bottom: 30px;
        }

        &_text {
            padding-bottom: 15px;
            margin-bottom: 25px;
        }
    }
}

@include xs {
    .testi-box {
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        text-align: center;
        padding-bottom: 30px;

        &_img {
            min-width: 120px;
            max-width: 120px;
            margin-top: -60px;

            >img {
                border-radius: 50%;
            }
        }

        &_review {
            position: static;
            margin-bottom: 10px;
            margin-top: -0.4em;
        }
    }
}


/* Testimonial Grid ---------------------------------- */
.testi-grid {
    position: relative;
    background-color: $white-color;
    box-shadow: 0px 6px 15px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    padding: 120px 40px 40px 40px;
    margin-top: 50px;

    &_img {
        background-color: $black-color2;
        ;
        width: 120px;
        text-align: center;
        border-radius: 10px;
        border: 10px solid $black-color2;
        position: absolute;
        top: -50px;
        left: 40px;

        >img {
            border-radius: 10px;
            width: 100%;
        }
    }

    &_quote {
        height: 36px;
        width: 36px;
        line-height: 36px;
        background-color: $theme-color;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        bottom: -18px;
        left: calc(50% - 18px);
        z-index: 2;
        margin: -28px auto 0 auto;

        img {
            position: relative;
            z-index: 1;
        }

        &:after,
        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background-color: $theme-color;
            @extend .ripple-animation;
            z-index: -1;
            border-radius: 50%;
            transition: all ease 0.4s;
        }

        &:after {
            animation-delay: 2s;
        }
    }

    .box-title {
        margin-bottom: 4px;
    }

    &_desig {
        font-size: 14px;
        display: block;
        margin-bottom: -0.5em;
    }

    &_text {
        margin-top: -0.45em;
        margin-bottom: 25px;
    }

    &_review {
        color: $theme-color;
        font-size: 14px;
        position: absolute;
        top: 35px;
        right: 40px;

        i {
            margin-right: 3px;
        }
    }
}

/* Testimonial 1 ---------------------------------- */
.testi-block {
    &-area {
        background-color: $white-color;
        padding: 100px 95px 100px 100px;
        max-width: 660px;
        border-radius: 20px;
        position: relative;
        margin-top: 100px;
        margin-bottom: 100px;
    }

    &-quote {
        position: absolute;
        bottom: 70px;
        right: 100px;
        animation: jumpAni 7s linear infinite;
    }

    &-slide {
        .slick-dots {
            text-align: left;
        }
    }

    &_profile {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &_avater {
        border-radius: 99px;
        overflow: hidden;
    }

    .box-title {
        margin-top: 0;
        margin-bottom: 5px;
    }

    &_desig {
        margin-bottom: 0;
        display: block;
        color: $theme-color;
    }

    &_text {
        font-size: 20px;
        margin-top: -0.5em;
        margin-bottom: 35px;
    }
}

@media (min-width: 1500px) {
    .testi-block {
        &-area {
            margin-left: -100px;
        }
    }
}

@include xl {
    .testi-block {
        &-area {
            margin-top: 50px;
            margin-bottom: 50px;
            padding: 50px;
        }

        &-quote {
            right: 50px;
            bottom: 30px;
        }
    }
}

@include sm {
    .testi-block_text {
        font-size: 18px;
        margin-top: -0.45em;
        margin-bottom: 30px;
    }
}


@include xs {
    .testi-block {
        &-area {
            margin-top: 80px;
            margin-bottom: 80px;
            padding: 50px 20px;
            border-radius: 10px;
        }

        &-slide {
            .slick-dots {
                margin-top: 25px;
            }
        }

        &-quote {
            right: 10px;
        }

        &_text {
            font-size: 16px;
            margin-bottom: 25px;
        }

        .box-title {
            font-size: 22px;
        }

        &_profile {
            gap: 15px;
        }

        &_avater {
            max-width: 70px;
        }

    }
}

@include vxs {
    .testi-block {
        &-quote {
            display: none;
        }
    }
}

/* Testimonial Box 5 ---------------------------------- */
.testi-box5 {
    position: relative;
    padding: 40px;
    background-color: $white-color;
    border-radius: 15px;
    box-shadow: 0px 14px 16px 0px rgba(0, 0, 0, 0.04);

    @include xl {
        padding: 30px;
    }

    &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-bottom: 0px;

        @include xl {
            gap: 15px;
        }

        @include md {
            display: block;
        }

        @include vxs {
            display: block;

        }
    }

    &_author {
        margin-bottom: 15px;
    }

    &_image {
        margin-bottom: 20px;
    }

    .testi-box5 {
        &_img {
            min-width: 60px;

            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }

        &_name {
            margin-bottom: 0px;
        }

        &_text {
            border-bottom: 1px solid #EAEAEA;
            padding-bottom: 30px;
            margin-bottom: 40px;

            @include md {
                padding-bottom: 20px;
                margin-bottom: 20px;
            }

        }

        &_desig {
            font-size: 14px;
            color: $theme-color;

            @include vxs {
                margin-bottom: 40px;
            }
        }

        &_info {
            margin-bottom: -0.3rem;
        }

    }

    .testi-quote {
        @include md {
            position: absolute;
            right: 30px;
            bottom: 30px;
        }
    }

    .box-title {
        font-size: 20px;
        letter-spacing: -0.4px;
        font-weight: 500;
        margin-bottom: -0.3rem;

        @include xl {
            font-size: 20px;
        }
    }
}

@include sm {
    .testi-box5 {
        &_quote {
            display: none;
        }
    }
}

/* Testimonial 7 ---------------------------------- */
.testi-grid2 {
    text-align: center;

    &-area {
        position: relative;
        background-color: $white-color;
        padding: 80px 120px;
        border-radius: 20px;
        border: 1px solid #D8DFED;
        text-align: center;
        z-index: 2;

        &.style2 {
            background-color: transparent;
            border: 1px solid #C5C5C5;
        }

        @include xxl {
            padding: 80px;
        }

        @include xl {
            padding: 80px 60px;
        }

        @include md {
            padding: 80px 30px;
        }

        @include xs {
            padding: 80px 20px;
        }

        .testi-indicator {
            position: absolute;
            width: 100%;
            right: 0;
            bottom: 160px;

            @include xs {
                position: initial;
                margin-bottom: 30px;
            }
        }

        .slider-arrow {
            top: 66%;
            left: 0;
            border: 1px solid $theme-color;
            opacity: 1;
            visibility: visible;
            transform: scale(1);

            &.slider-next {
                right: 0;
                left: auto;
            }

            @include xxl {
                display: block !important;
            }

            @include sm {
                display: none;
            }
        }

        .testi-line {
            position: absolute;
            left: 120px;
            z-index: -1;
            bottom: 196px;
            width: 80%;
            height: 1px;
            background-color: $theme-color;

            @include xl {
                left: 60px;
                width: 85%;
            }

            @include sm {
                display: none;
            }
        }
    }

    &-thumb {
        position: relative;
        max-width: 410px;
        padding: 7px;
        z-index: 2;

        @include xs {
            max-width: 100%;
        }

        .box-img {
            background-color: $white-color;
            border: 1px solid $theme-color;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            line-height: 50px;
            text-align: center;
            padding: 5px;
            overflow: hidden;
            transition: 0.4s ease-in-out;
            cursor: pointer;

            @include xs {
                margin: auto;
            }

            @include vxs {
                width: 50px;
                height: 50px;
                line-height: 30px;
                padding: 8px;
            }
        }

        .swiper-slide-thumb-active {
            opacity: 1;

            .box-img {
                transform: scale(1.20);
                border-width: 3px;
                line-height: 40px;
                border-color: $theme-color;

                @include vxs {
                    line-height: 25px;
                }
            }
        }
    }
}

.testi-card2 {
    text-align: center;
    position: relative;

    &_profile {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        z-index: 3;
        gap: 20px;
    }


    &_avater {
        width: 60px;
        min-width: 60px;
        border-radius: 50%;

        img {
            border-radius: inherit;
        }
    }

    &_text {
        font-size: 22px;
        color: $title-color;
        letter-spacing: -0.22px;
        margin-bottom: 140px;

        @include md {
            font-size: 18px;
        }

        @include xs {
            font-size: 16px;
            margin-bottom: 20px;
        }

    }

    &_desig {
        font-family: $title-font;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
    }

    .box-title {
        font-weight: 700;
        letter-spacing: -0.48px;
        margin-bottom: -0.2rem;
    }
}

/* testimonial box start ------------------------*/
.testiSlider8 {
    .swiper-slide {
        width: calc(678px) !important;

        @include sm {
            width: 100% !important;
        }
    }
}

.testi-box6 {
    background: #2D2F34;
    border-radius: 20px;
    padding: 40px;

    @include xs {
        padding: 30px;
    }

    &_image {
        margin-bottom: 35px;
    }

    &_text {
        font-size: 18px;
        line-height: 28px;
        color: #8D929C;
        max-width: 598px;
        margin-bottom: 35px;

        @include md {
            max-width: 100%;
        }
    }

    &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 400px) {
            display: block;
        }
    }

    &_profile {
        display: flex;
        align-items: center;
        gap: 20px;

        @media(max-width: 400px) {
            margin-bottom: 20px;
        }
    }

    &_author {
        min-width: 60px;

        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
    }

    .box-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $white-color;
        margin-bottom: 0;
    }

    &_desig {
        font-weight: 500;
        font-size: 14px;
        font-family: $body-font;
        background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;


    }

}

/* testimonial box end ------------------------*/

/* testimonoal 9 start-----------------------------*/
.testi-grid3 {
    text-align: center;

    &-area {
        position: relative;
        background-color: $white-color;
        padding: 80px 120px;
        border-radius: 20px;
        border: 1px solid #D8DFED;
        text-align: center;
        z-index: 2;

        &.style2 {
            background-color: transparent;
            border: 1px solid #C5C5C5;
        }

        @include xxl {
            padding: 80px;
        }

        @include xl {
            padding: 80px 60px;
        }

        @include md {
            padding: 80px 30px;
        }

        @include xs {
            padding: 80px 20px;
        }

        .testi-indicator {
            position: absolute;
            width: 100%;
            right: 0;
            bottom: 160px;

            @include xs {
                position: initial;
                margin-bottom: 30px;
            }
        }

        .slider-arrow {
            top: 66%;
            left: 0;
            border: 1px solid $theme-color;
            opacity: 1;
            visibility: visible;
            transform: scale(1);

            &.slider-next {
                right: 0;
                left: auto;
            }

            @include xxl {
                display: block;
            }

            @include sm {
                display: none;
            }
        }

        .testi-line {
            position: absolute;
            left: 120px;
            z-index: -1;
            bottom: 196px;
            width: 80%;
            height: 1px;
            background-color: $theme-color;

            @include xl {
                left: 60px;
                width: 85%;
            }

            @include sm {
                display: none;
            }
        }
    }

    &-thumb {
        position: relative;
        max-width: 410px;
        padding: 7px;
        z-index: 2;

        @include xs {
            max-width: 100%;
        }

        .box-img {
            background-color: $white-color;
            border: 1px solid $theme-color;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            line-height: 50px;
            text-align: center;
            padding: 5px;
            overflow: hidden;
            transition: 0.4s ease-in-out;
            cursor: pointer;

            @include xs {
                margin: auto;
            }

            @include vxs {
                width: 50px;
                height: 50px;
                line-height: 30px;
                padding: 8px;
            }
        }

        .swiper-slide-thumb-active {
            opacity: 1;

            .box-img {
                transform: scale(1.20);
                border-width: 3px;
                line-height: 40px;
                border-color: $theme-color;

                @include vxs {
                    line-height: 25px;
                }
            }
        }
    }
}

/* testimonoal 9 end -----------------------------*/

/* testimonial 10 start ------------------------------*/
.testi-box7 {
    background-color: $white-color;
    border: 1px solid #E3E7F0;
    border-radius: 10px;
    padding: 60px;
    transition: all 0.4s ease-in-out;

    @include lg {
        padding: 40px;
    }

    @include xs {
        padding: 30px;
    }

    &_text {
        font-size: 20px;
        color: #656A74;
        margin-top: -0.5rem;
        border-bottom: 1px solid #E3E7F0;
        margin-bottom: 40px;
        padding-bottom: 30px;
    }

    &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 430px) {
            display: block;
        }
    }

    &_profile {
        display: flex;
        align-items: center;
        gap: 20px;

        @media(max-width: 430px) {
            margin-bottom: 20px;
        }
    }

    &_author {
        min-width: 60px;

        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
    }

    .box-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;

        @include vxs {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &_desig {
        font-weight: 400;
        font-size: 14px;
        font-family: $body-font;
        color: $theme-color;


    }

    &_review {
        i {
            color: #F9B317;
        }
    }

    &:hover {
        border: 1px solid transparent;
        box-shadow: 7px 10px 15px 6px rgba(0, 31, 96, 0.07);

    }
}

.testi-grid4 {
    &-area {
        position: relative;
        margin-bottom: 125px;

        @include xs {
            margin-bottom: 0;
        }

        .testi-indicator {
            position: absolute;
            width: 100%;
            right: 0;
            bottom: -100px;


            @include xs {
                position: initial;
                margin-top: 30px;
            }
        }

        .slider-arrow {
            top: 114%;
            left: 20%;
            border: 1px solid $theme-color;
            opacity: 1;
            visibility: visible;
            transform: scale(1);

            @include lg {
                top: 116%;
            }

            &.slider-next {
                right: 20%;
                left: auto;

                @include xl {
                    right: 10%;
                }
            }


            @include xxl {
                display: block;
            }

            @include xl {
                left: 10%;
            }

            @include sm {
                display: none;
            }
        }

        .testi-line {
            position: absolute;
            left: 22%;
            z-index: -1;
            bottom: -65px;
            width: 56%;
            height: 1px;
            background-color: $theme-color;

            @include xl {
                left: 14%;
                width: 75%;
            }

            @include sm {
                display: none;
            }
        }
    }
}

/* testimonial 9 start ----------------------- */
.testimonial-area9 {
    padding: 220px 0;
    clip-path: path("M0 0L270 100.815H1920V825L1650 724.185H0V0Z");

    @media(min-width: 1930px) {
        clip-path: path("M0 0L468.703 100.815H3333V825L2864.3 724.185H0V0Z");
    }

    @include xl {
        padding-bottom: 200px;
    }

    @include lg {
        padding-bottom: 240px;
    }

    @include md {
        padding: 80px 0;
        clip-path: none;
    }
}

.testi-tabs {
    justify-content: center;
    gap: 50px;
    background-color: $white-color;
    border-radius: 20px;
    margin-bottom: 40px;
    border: none;
    overflow: hidden;


    @include lg {
        gap: 8px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    @include sm {
        display: block;
    }

    .nav-item.show .nav-link,
    .nav-link.active {
        color: $title-color;
        background-color: transparent;
        border-color: none;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);
            opacity: 0.1;

        }

    }

    .nav-link {
        &.active {
            .box-img {
                &:before {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .nav-link {
        position: relative;
        padding: 10px 59px;
        border: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        gap: 26px;
        font-family: $title-font;
        background-color: transparent;
        font-weight: 600;
        font-size: 24px;
        color: $title-color;
        margin-bottom: 0px;

        @include xl {
            padding: 10px 30px;
        }

        @include md {
            gap: 10px;
            padding: 10px 20px;
            font-size: 18px;
        }

        @include sm {
            width: 100%;
            justify-content: center;
        }


        .box-img {
            position: relative;
            background-color: var(--white-color);
            border: 3px solid $theme-color;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            line-height: 50px;
            text-align: center;
            transition: 0.4s ease-in-out;
            cursor: pointer;


            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 16px;
                height: 16px;
                border-radius: 50px;
                background-color: $white-color;
                border: 3px solid $theme-color;
                transition: all 0.4s ease-in-out;
                opacity: 0;
                visibility: hidden;
            }
        }

        .title {}

        .desig {
            font-family: $body-font;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $body-color;
            display: block;
            margin-top: 5px;
        }
    }

}

.testi-box5 {
    &.style2 {
        .testi-box5 {
            &_wrapper {
                margin-bottom: 30px;
            }

            &_image {
                margin-bottom: 0;
            }

            &_text {
                border: none;
                margin-bottom: -0.4rem;
                padding-bottom: 0;
            }
        }
    }
}

/* testimonial 9 end ----------------------- */

/* restimonial 10 start */
.testi-area10 {
    background-color: #EFF1F9;
    border-radius: 20px 20px 0 0;
    max-width: 1760px;
    margin: auto;
    margin-bottom: -30px;
    padding-bottom: 150px !important;

    @include md {
        padding-bottom: 110px !important;
    }
}

.testi-grid2 {
    &-area {
        &.style3 {
            background-color: transparent;
            border: none;
            padding: 0;
            max-width: 1080px;
            margin: auto;

            .testi-indicator {
                position: absolute;
                width: 100%;
                right: 0;
                bottom: 80px;

                @include xs {
                    position: initial;
                    margin-bottom: 30px;
                }
            }

            .testi-line {
                left: 50px;
                bottom: 112px;
                width: 92%;
            }
        }
    }
}

/* testi-card3 start ------------------------------------ */
.testi-area11 {
    position: relative;
    z-index: 2;
    border-radius: 30px;
    overflow: hidden;

    @include lg {
        padding: 120px 0;
    }

    @include md {
        padding: 80px 0;
    }
}

.shape-mockup.testi-shape {
    min-width: 973px;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    border-radius: 30px 0 0 30px;

    @include lg {
        display: none;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px 0 0 30px;
    }

}

.testiSlide8 {
    position: relative;

    .icon-box {
        position: absolute;
        bottom: 10px;
        right: 0;

        @include xs {
            display: none !important;
        }
    }

    .slider-pagination {
        position: absolute;
        bottom: 0;
        left: 45%;

        @include sm {
            display: none !important;
        }

    }

    .swiper-pagination-bullet-active {
        background: $white-color;
    }

    .swiper-pagination-bullets {
        .swiper-pagination-bullet {
            &:before {
                border: 1px solid $white-color;
                background: $white-color;
            }
        }
    }
}

.testi-image11 {
    min-width: 758px;
    margin-left: -100px;

    @include xl {
        min-width: 100%;
    }

    @include lg {
        display: none;
    }
}

.testi-card3 {
    &_text {
        color: $white-color;
        margin-bottom: 50px;


    }

    &_profile {
        display: flex;
        gap: 10px;
    }

    .box-title {
        color: $white-color;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0;
    }

    &_desig {
        color: $white-color;
        font-size: 14px;
        font-weight: 500;
    }

    .testi-quote {
        position: absolute;
        top: 0;
        right: 0;
    }
}

/* testi-card3 end ------------------------------------ */

/* testi area 14 start ----------------------------*/
.testi-area14 {
    background-color: #EFF1F9;
}

.testi-box3 {
    position: relative;
    display: flex;
    background-color: $white-color;
    box-shadow: 0px 6px 30px rgba(213, 222, 255, 0.16);
    border-radius: 30px;
    gap: 30px;
    padding: 50px;

    @include xl {
        padding: 40px;
        gap: 20px;
    }

    &_img {
        min-width: 223px;
        text-align: center;

        >img {
            border-radius: 15px;
            width: 100%;
        }
    }

    &_avater {

        img {
            border-radius: 999px;
        }
    }

    .box-title {
        margin-bottom: 2px;
    }

    &_desig {
        font-size: 14px;
        display: block;
        margin-bottom: -0.5em;
    }

    &_text {
        line-height: 26px;
        margin-bottom: 28px;
    }

    &_review {
        color: #F9B317;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 15px;

        i {
            margin-right: 3px;
        }
    }
}

@include lg {
    .testi-box3 {
        max-width: 760px;
        margin-left: auto;
        margin-right: auto;

        &_review {
            bottom: 75px;
        }
    }
}


@include sm {
    .testi-box3 {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 30px;

        &_img {
            min-width: 120px;
            max-width: 120px;
        }
    }
}

/* testi 12 start ---------------------*/
.testi-area12 {
    position: relative;
    border-radius: 30px;
    z-index: 2;

    &:before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 30px;
        background: rgba(28, 28, 37, 0.9);
        z-index: -1;
    }

}

.testi-grid3-area {
    &.style4 {
        background-color: transparent;
        border: none;
        padding: 0;

        .testi-line {
            left: 50px;
            bottom: 114px;
            width: 90%;
            background-color: rgba(255, 255, 255, 0.2);

            @include md {
                display: none !important;
            }
        }

        .slider-arrow {
            @include xxl {
                display: block !important;
            }

            @include lg {
                top: 59%;
            }

            @include md {
                display: none !important;
            }
        }
    }

    .testi-grid2-thumb {
        .swiper-wrapper {
            justify-content: center;
        }
    }

    .testi-indicator {
        position: absolute;
        width: 100%;
        right: 0;
        bottom: 80px;
    }
}

.testi-card4 {
    text-align: center;
    position: relative;

    &_text {
        font-size: 22px;
        color: $white-color;
        letter-spacing: -0.22px;
        margin-bottom: 140px;

        @include xl {
            font-size: 18px;
        }
    }

    &_profile {
        justify-content: center;
    }

    .box-title {
        color: $white-color;
        font-size: 24px;
        margin-bottom: 0;
    }

    &_desig {
        font-size: 16px;
        color: $body-color;
    }
}

.testi-image {
    margin-left: -20%;

    @media(max-width: 1699px) {
        margin-left: 0;
    }

    @include lg {
        text-align: center;
    }

    img {
        border-radius: 20px;
    }
}