.error-content {
    text-align: center;
}
.error-img {
	text-align: center;
	margin-bottom: 45px;
}
.error-title {
    margin-bottom: 20px;
}
.error-text {
    margin-bottom: 35px;
	max-width: 650px;
	margin-left: auto;
	margin-right: auto;
}

@include md {
	.error-title {
		margin-bottom: 15px;
	}
	.error-img {
		margin-bottom: 30px;
	}
	.error-text {
		margin-bottom: 25px;
	}
}

@include sm {
	.error-title {
		margin-bottom: 10px;
	}
}
