/* Team global ---------------------------------- */
.th-team {
    position: relative;

    .team-img {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            transition: 0.4s ease-in-out;
        }
    }

    .team-desig {
        font-size: 14px;
        display: block;
        margin-bottom: -0.5em;
        transition: 0.4s ease-in-out;
        color: $theme-color;
    }

    .team-social {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 20px;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        transition: 0.4s ease-in-out;
        z-index: 4;

        a {
            color: $white-color;

            &:hover {
                color: $theme-color;
            }
        }
    }

    .th-social {
        a {
            --icon-size: 36px;
            background-color: $white-color;
            color: $theme-color;

            &:hover {
                background-color: $theme-color;
                color: $white-color;
            }
        }
    }

    .box-title {
        margin-bottom: 3px;
    }

    &:hover {
        .team-img {
            img {
                transform: scale(1.08);
            }
        }

        .team-social {
            visibility: visible;
            opacity: 1;
        }
    }
}

/* Team Card ---------------------------------- */
.team-card {
    text-align: center;

    .team-content {
        box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
        border-radius: 10px;
        background-color: $white-color;
        padding: 26px 10px;
        transition: 0.4s ease-in-out;
        position: relative;
        z-index: 2;
        max-width: 248px;
        margin: -50px auto 0 auto;
    }

    .team-img {
        border-radius: 10px;
    }

    .team-social {
        position: absolute;
        top: unset;
        bottom: 100%;
        left: 18px;
        height: 0px;
        padding-top: 15px;
        max-width: calc(100% - 36px);
        background-color: $white-color;
        clip-path: path("M105.5 0C58.2259 0 17.7021 28.902 0.634674 70H210.365C193.298 28.902 152.774 0 105.5 0Z");
        z-index: -1;

        a {
            color: $title-color;
        }
    }

    &:hover {
        .team-content {
            background-color: $theme-color;
            box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
        }

        .box-title {
            color: $white-color;

            a {
                &:hover {
                    color: $white-color;
                }
            }
        }

        .team-desig {
            color: $white-color;
        }

        .team-social {
            height: 70px;
        }
    }
}

.team-sec {
    position: relative;
    background-color: $smoke-color;
    background-size: auto;
    background-position: bottom left;
    overflow: hidden;
}

@include xs {
    .team-card {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Team Box ---------------------------------- */
.team-box {
    position: relative;
    background-color: $white-color;
    border-radius: 10px;
    text-align: center;
    transition: 0.4s ease-in-out;

    .team-img {
        border-radius: 10px 10px 0 0;
    }

    .th-social {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transform: scaleX(0);
        transition: 0.4s ease-in-out;

        a {
            --icon-size: 36px;
            color: $theme-color;
            background-color: $white-color;
            border: none;

            &:hover {
                background-color: $theme-color;
                color: $white-color;
            }

            &:nth-child(2) {
                margin-right: 30px;
            }

            &:nth-child(3) {
                margin-left: 30px;
            }
        }
    }

    .team-content {
        padding: 26px 10px;
        position: relative;
        z-index: 2;
    }

    .team-social {
        display: block;
        top: unset;
        bottom: 25px;
        text-align: center;
        width: 100%;
        visibility: visible;
        opacity: 1;

        .play-btn {
            cursor: pointer;

            >i {
                --icon-size: 36px;
                background-color: $theme-color;
                color: $white-color;
                font-size: 20px;
            }

            &:before,
            &:after {
                background-color: $theme-color;
            }

            &:hover {
                >i {
                    transform: rotate(45deg);
                }
            }
        }

        &:hover {
            .th-social {
                visibility: visible;
                opacity: 1;
                transform: scaleX(1);
            }
        }
    }

    &:hover {
        background-color: $theme-color;

        .box-title {
            color: $white-color;

            a {
                &:hover {
                    color: $white-color;
                }
            }
        }

        .team-desig {
            color: $white-color;
        }
    }
}

@include xs {
    .team-box {
        max-width: 340px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Team Grid ---------------------------------- */
.team-grid {
    position: relative;
    z-index: 2;
    background-color: $white-color;
    box-shadow: 0px 4px 15px rgba(8, 14, 28, 0.1);
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    transition: 0.4s ease-in-out;

    .team-img {
        border-radius: 10px;
        margin-bottom: 25px;
    }

    .th-social {
        position: absolute;
        bottom: 42px;
        right: 0;
        max-width: 36px;
        opacity: 0;
        transform: scaleY(0);
        transform-origin: bottom;
        transition: 0.4s ease-in-out;

        a {
            margin-bottom: 6px;
        }
    }

    .team-social {
        display: block;
        top: unset;
        bottom: 82px;
        right: 48px;
        left: unset;
        visibility: visible;
        opacity: 1;
        width: 36px;

        .play-btn {
            cursor: pointer;

            >i {
                --icon-size: 36px;
                background-color: $theme-color;
                color: $white-color;
                font-size: 20px;
            }

            &:before,
            &:after {
                background-color: $theme-color;
            }

            &:hover {
                >i {
                    transform: rotate(45deg);
                }
            }
        }

        &:hover {
            .th-social {
                visibility: visible;
                opacity: 1;
                transform: scaleX(1);
            }
        }
    }

    .team-desig {
        margin-bottom: 0;
    }

    &:hover {
        background-color: $theme-color;

        .box-title {
            color: $white-color;

            a {
                &:hover {
                    color: $white-color;
                }
            }
        }

        .team-desig {
            color: $white-color;
        }

        .play-btn {
            >i {
                background-color: $white-color;
                color: $theme-color;
            }

            &:before,
            &:after {
                background-color: $white-color;
            }
        }
    }
}


/* Team Featured ---------------------------------- */
.team-featured {
    &-img {
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    padding-left: 36px;

    .team-title {
        margin-bottom: 5px;
        margin-top: -0.32em;
    }

    .team-desig {
        color: $theme-color;
        margin-bottom: 20px;
    }

    .team-bio {
        margin-bottom: 23px;
    }
}

.team-contact {
    &-wrap {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;
        padding: 30px 0;
        margin-bottom: 30px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: $smoke-color2;
    }

    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    background-color: $smoke-color;
    border-radius: 5px;

    .icon-btn {
        --btn-size: 50px;
        border-color: $theme-color;
        background-color: $theme-color;
        color: $white-color;
    }

    &_label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    &_link {
        color: $body-color;
        font-family: $body-font;
        font-size: 14px;
        display: inline-block;
    }

    a:hover {
        color: $theme-color;
    }
}

@include xl {
    .team-featured {
        padding-left: 0;
    }
}

@include sm {
    .team-contact {
        &-wrap {
            grid-template-columns: auto auto;
        }
    }
}

@include xs {
    .team-contact {
        &-wrap {
            grid-template-columns: auto;
        }
    }
}

/* Team Details ---------------------------------- */
.about-card {
    background-color: $white-color;
    box-shadow: 0px 0px 50px rgba(8, 14, 28, 0.1);
    border-radius: 10px;
    padding: 40px;
    display: flex;
    align-items: center;
    gap: 40px;

    &_img {
        border-radius: 10px;
        overflow: hidden;
        flex: 490px;

        img {
            width: 100%;
            border-radius: inherit;
        }
    }

    &_box {
        flex: 610px;
    }

    &_title {
        margin-top: -0.32em;
        margin-bottom: 7px;
    }

    &_desig {
        color: $theme-color;
        margin-bottom: 0;
    }

    &_top {
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &_text {
        margin-bottom: 30px;
    }
}

.team-info-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 40px;

    .contact-feature {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
    }
}

.border-title {
    border-bottom: 1px solid $smoke-color2;
    margin-bottom: 30px;
    padding-bottom: 20px;
}

.experience-box {
    &-wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        box-shadow: 0px 13px 21px rgba(0, 0, 0, 0.03);
    }

    padding: 30px 30px 30px 65px;
    border: 1px solid #F1F1F1;
    overflow: hidden;
    position: relative;
    margin-bottom: -1px;
    margin-right: -1px;

    &_num {
        font-size: 12px;
        @include equal-size(60px);
        line-height: 65px;
        background-color: $theme-color;
        color: $white-color;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        top: -13px;
        left: -13px;

        &::first-letter {
            font-size: 18px;
            font-weight: 600;
        }
    }

    &_title {
        font-family: $title-font;
        font-size: 18px;
        margin-bottom: 4px;
        margin-top: -0.2em;
    }

    &_text {
        font-size: 14px;
        margin-bottom: -0.2em;
    }
}

@include lg {
    .border-title {
        margin-bottom: 24px;
        padding-bottom: 15px;
    }

    .about-card {
        flex-direction: column;
        align-items: stretch;

        &_img {
            flex: 100%;

            img {
                width: 100%;
            }
        }

        &_box {
            flex: 100%;
        }
    }
}

@include sm {
    .experience-box {
        padding: 30px 30px 30px 50px;
    }

    .about-card {
        padding: 20px;
    }
}

@include xs {
    .experience-box {
        &-wrap {
            grid-template-columns: repeat(1, 1fr);
        }

        padding: 30px 30px 30px 65px;
    }

    .team-info-wrap {
        grid-template-columns: auto;
    }
}



/* Team Card ---------------------------------- */
.team-card2 {
    position: relative;
    text-align: center;
    --space: 74px;

    @include xl {
        --space: 50px;
    }

    @include lg {
        --space: 30px;
    }

    .team-img {
        position: relative;
        border-radius: 15px;
        margin-bottom: 15px;

        img {
            border-radius: 15px;
            transition: 1.3s all ease;
        }

    }

    .team-desig {
        color: $body-color;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: -0.3rem;
    }

    .box-title {
        margin-bottom: 6px;
        margin-bottom: -0.3rem;
    }

    .box-content {
        background-color: $white-color;
        border-radius: 15px;
        padding: 28px;
    }

    .social-links {
        position: absolute;
        left: var(--space);
        bottom: -30px;
        width: auto;
        z-index: 3;
        opacity: 0;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        background-color: $white-color;
        padding: 9px 25px;
        border-radius: 100px;

        a {
            color: $body-color;
            margin: 0px 18px 0px 0px;

            &:hover {
                color: $theme-color;
            }
        }
    }

    &:hover {
        .team-img {
            img {
                transform: scale(1.3);
            }

            &:before {
                opacity: 1;
                visibility: visible;
            }
        }

        .social-links {
            bottom: 30px;
            opacity: 1;
            visibility: visible;
        }
    }
}

/* Team Card start---------------------------------- */
.team-area6 {
    background-color: #EFF1F9;
    overflow: hidden;
}

.team-card3 {
    position: relative;
    text-align: center;
    background-color: $white-color;
    border-radius: 30px;
    padding: 20px;

    &.style2 {
        box-shadow: 1px 5px 10px 5px transparent;

        &:hover {
            box-shadow: 1px 5px 10px 5px rgba(0, 31, 96, 0.04);
        }

    }

    --space: 74px;

    @include xl {
        --space: 50px;
    }

    @include lg {
        --space: 30px;
    }

    .team-img {
        position: relative;
        border-radius: 15px;

        img {
            border-radius: 15px;
            transition: 1.3s all ease;
        }

    }

    .team-desig {
        color: $body-color;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: -0.3rem;
    }

    .box-title {
        margin-bottom: 6px;
        margin-bottom: -0.3rem;
    }

    .box-content {
        background-color: $white-color;
        border-radius: 15px;
        padding: 0px 30px 10px 30px;
    }

    .social-links {
        position: relative;
        max-width: 179px;
        display: block;
        margin: -20px auto 0px auto;
        z-index: 3;
        transition: all 0.6s ease-in-out;
        background-color: #EFF1F9;
        padding: 9.5px 20px;
        border-radius: 100px;
        transform: scaleX(0);

        a {
            color: $body-color;
            margin: 0px 14px 0px 0px;

            &:hover {
                color: $theme-color;
            }
        }
    }

    &:hover {

        .team-img {
            img {
                transform: scale(1.3);
            }

            &:before {
                opacity: 1;
                visibility: visible;
            }
        }

        .social-links {
            transform: scaleX(1);
            margin: -20px auto 30px auto;
        }

    }
}

/* Team Card end ---------------------------------- */
/* Team box2 end ---------------------------------- */
.team-box2 {
    position: relative;
    overflow: hidden;
    border-radius: 100px 100px 200px 200px;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 140px;
        width: 100%;
        height: 100%;
        background: #3E66F3;
        border-radius: 100px 100px 200px 200px;
        z-index: -1;
    }


    .team-img-wrap {
        position: relative;
        transition: 0.4s;
    }

    .team-img {
        overflow: hidden;
        position: relative;
        border-radius: 20px;
        background: #EFF1F9;
        border-radius: 200px;
        z-index: 2;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background: rgba(28, 28, 37, 0.8);
            border-radius: 200px;
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            transition: 0.4s ease-in-out;

        }


        img {
            width: 100%;
            border-radius: 20px;
            transition: 0.4s ease-in-out;
        }
    }

    .th-social {
        position: absolute;
        bottom: 45%;
        left: 50%;
        transform: translate(-50%, 0);
        transition: 0.4s;
        width: max-content;
        z-index: 3;

        a {
            opacity: 0;
            transform: translateY(20px);
            transition: 0.3s;

            &:nth-child(1) {
                transition-delay: 0s;
            }

            &:nth-child(2) {
                transition-delay: 0.1s;
            }

            &:nth-child(3) {
                transition-delay: 0.2s;
            }

            &:nth-child(4) {
                transition-delay: 0.3s;
            }
        }
    }

    .box-title {
        color: $white-color;
        font-weight: 500;
        margin-bottom: 0px;

        a {
            color: inherit;
        }
    }

    .team-desig {
        color: $white-color;
        display: block;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: -0.3em;
    }

    .team-content {
        padding: 20px 30px 30px 30px;
        text-align: center;
    }

    &:hover {
        .team-img-wrap {
            border-color: $theme-color;
        }

        .team-img {
            &:before {
                opacity: 1;
                visibility: visible;

            }

            img {
                transform: scale(1.08);
            }
        }

        .th-social a {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

/* Team box2 end ---------------------------------- */

/* Team box3 start -------------------------------*/
.team-box3 {
    position: relative;
    background-color: $theme-color;
    border-radius: 30px;
    transition: all 1s ease-in-out;
    overflow: hidden;

    .team-img {
        border-radius: 30px;
    }

    .team-content {
        padding: 30px;
    }

    .box-title {
        color: $white-color;
        font-weight: 600;
        margin-bottom: 0;
    }

    .team-desig {
        color: $white-color;
    }



    .th-social {
        border-top: 1px solid rgba(255, 255, 255, 0.21);
        margin-top: 18px;
        padding-top: 18px;
        transform: translateY(50px);
        height: 0;
        opacity: 0;
        margin-bottom: -2.2em;
        transition: all 0.6s ease-in-out;

        a {
            &:hover {
                background-color: $title-color;
            }
        }
    }

    &:hover {

        .th-social {
            opacity: 1;
            height: 100%;
            margin-bottom: 0;
            transform: translateY(0px);

        }
    }
}

.slider-arrow {
    &.style2 {
        display: block !important;

        @include lg {
            display: none !important;
        }
    }
}



/* Team box3 end -------------------------------*/

/* Team box4 Start ---------------------------------- */
.team-box4 {
    position: relative;
    overflow: hidden;
    background-color: #EFF1F9; 
    border-radius: 20px;


    .team-img-wrap {
        position: relative;
        transition: 0.4s;
    }

    .team-img {
        overflow: hidden;
        position: relative;
        border-radius: 20px;
        background: #EFF1F9;
        border-radius: 20px;
        z-index: 2;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background: rgba(28, 28, 37, 0.8);
            border-radius: 20px;
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            transition: 0.4s ease-in-out;

        }


        img {
            width: 100%;
            border-radius: 20px;
            transition: 0.4s ease-in-out;
        }
    }

    .th-social {
        position: absolute;
        bottom: 45%;
        left: 50%;
        transform: translate(-50%, 0);
        transition: 0.4s;
        width: max-content;
        z-index: 3;

        a {
            opacity: 0;
            transform: translateY(20px);
            transition: 0.3s;

            &:nth-child(1) {
                transition-delay: 0s;
            }

            &:nth-child(2) {
                transition-delay: 0.1s;
            }

            &:nth-child(3) {
                transition-delay: 0.2s;
            }

            &:nth-child(4) {
                transition-delay: 0.3s;
            }
        }
    }

    .box-title {
        margin-bottom: 0px;

        a {
            color: inherit;
        }
    }

    .team-desig {
        color: $body-color;
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: -0.3em;
    }

    .team-content {
        padding: 20px 30px 30px 30px;
        text-align: center;
    }

    &:hover {
        .team-img-wrap {
            border-color: $theme-color;
        }

        .team-img {
            &:before {
                opacity: 1;
                visibility: visible;

            }
            img {
                transform: scale(1.08);
            }
        }

        .th-social a {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

/* Team box4 end ---------------------------------- */