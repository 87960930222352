@each $bgcolorMap,
$value in $bgcolorMap {
    .bg-#{$bgcolorMap} {
        background-color: #{$value} !important;
    }
}

.gradient-body {
    background-image: linear-gradient(100.62deg, rgba(249, 215, 175, 0.3) 0%, rgba(214, 202, 245, 0.3) 24.03%, rgba(198, 241, 255, 0.3) 45.73%, rgba(248, 242, 222, 0.3) 69.05%, rgba(212, 179, 253, 0.3) 100.44%);
}

.gr-bg1 {
    background-image: linear-gradient(80deg, rgba(249, 215, 175, 0.3) 0%, rgba(214, 202, 245, 0.3) 23.81%, rgba(198, 241, 255, 0.3) 45.3%, rgba(248, 242, 222, 0.3) 68.4%, rgba(212, 179, 253, 0.3) 99.5%);
}

.gr-bg2 {
    background-image: linear-gradient(100.62deg, rgba(249, 215, 175, 0.3) 0%, rgba(214, 202, 245, 0.3) 24.03%, rgba(198, 241, 255, 0.3) 45.73%, rgba(248, 242, 222, 0.3) 69.05%, rgba(212, 179, 253, 0.3) 100.44%);
}

.gr-bg3 {
    // background-image: linear-gradient(110.59deg, rgba(205, 219, 253, 0.4) 0%, rgba(229, 233, 255, 0.7) 100%);
    background-image: linear-gradient(110.59deg, rgba(236, 207, 254, 0.4) 0%, rgba(255, 221, 221, 0.5) 46.79%, rgba(247, 255, 229, 0.7) 100%);
    backdrop-filter: blur(10px);
}

.gr-bg4 {
    background-image: linear-gradient(105.44deg, rgba(255, 229, 133, 0.4) 0%, rgba(189, 255, 199, 0.4) 48.48%, rgba(223, 109, 223, 0.4) 100%);
    backdrop-filter: blur(15px);
}

.gr-bg5 {
    background: linear-gradient(180deg, rgba(237, 240, 244, 0.00) 0%, rgba(237, 240, 244, 0.85) 100%);
}

.gr-bg6 {
    background: linear-gradient(180deg, #02CE80 0%, #7BD706 100%);

}

.gray-body {
    background-color: #F8FAFF;
}

.smoke-body {
    background-color: #F3F2EA; 
}

.background-image,
[data-bg-src] {
    @include background-content(no-repeat, cover, center center);
}

.bg-fluid {
    @include background-content(no-repeat, 100% 100%, center center);
}

.bg-auto {
    background-size: auto auto;
}

.bg-top-center {
    background-size: auto;
    background-position: top center;
}
.bg-bottom-center {
    background-size: auto;
    background-position: bottom center; 
}

.bg-repeat {
    background-size: auto;
    background-repeat: repeat;
}

.bg-bottom-right {
    background-size: auto;
    background-position: bottom right;
}

.bg-top-right {
    background-size: auto;
    background-position: top right;
}