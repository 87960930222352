.footer-widget {
    margin-bottom: 50px;

    &,
    .widget {
        padding: 0;
        border: none;
        padding-bottom: 0;
        background-color: transparent;
        box-shadow: none;
    }

    .form-group {
        >i {
            color: $theme-color;
            top: 18px;
        }
    }

    .sidebar-gallery {
        max-width: 287px;
    }

    .widget_title {
        max-width: 270px;
        color: $white-color;
        font-weight: 600;
        text-transform: uppercase;
        margin: -0.12em 0 35px 0;
        padding: 0 0 17px 0;

        &:before {
            content: '';
            height: 2px;
            width: 100px;
            background-color: $theme-color;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &:after {
            content: '';
            height: 10px;
            width: 10px;
            background-color: $white-color;
            position: absolute;
            bottom: -4px;
            left: 96px;
            border-radius: 99px;
            border: 2px solid $theme-color;
        }

        &.style2 {
            color: $white-color;

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 61px;
                height: 2px;
                background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);

            }

            &:after {
                width: 16px;
                border: 2px solid $black-color;
                height: 10px;
                background: transparent; 
                bottom: -3px;
                left: 40px;
                animation: footerLine 7s linear infinite;
            }
        }
    }

    &.widget_meta,
    &.widget_pages,
    &.widget_archive,
    &.widget_categories,
    &.widget_nav_menu {
        ul {
            margin-top: -5px;
        }

        .menu,
        >ul {
            margin-bottom: -5px;
        }

        a {
            font-size: 16px;
            font-weight: 400;
            padding: 0 0 0 20px;
            margin-bottom: 21px;
            font-family: $body-font;
            display: block;
            max-width: 100%;
            width: max-content;
            padding-right: 0;
            background-color: transparent;
            border-bottom: none;
            position: relative;

            &:before {
                content: "\f054";
                font-weight: 600;
                left: 0;
                top: 2px;
                transform: translateY(0);
                font-size: 0.9em;
                background-color: transparent;
                border: none;
                color: inherit;
            }

            &:hover {
                background-color: transparent;
                color: $theme-color;

                &:before {
                    color: $theme-color;
                }
            }
        }

        li {
            >span {
                @include equal-size(auto);
                position: relative;
                background-color: transparent;
                color: $body-color;
                line-height: 1;
            }

            &:last-child {
                a {
                    margin-bottom: 0;
                }
            }
        }
    }

    .recent-post {
        max-width: 315px;
        margin-top: -0.3em;
        margin-bottom: 28px;

        .post-title {
            color: $white-color;
            font-weight: 500;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }

        .recent-post-meta {
            a {
                font-weight: 400;
                line-height: 1.2;
            }

            i {
                color: $theme-color;
            }

            a:hover {
                i {
                    color: $theme-color;
                }
            }
        }
    }
}

.th-widget-about {
    .about-logo {
        margin-bottom: 35px;
        margin-top: -8px;
    }

    .about-text {
        margin-bottom: 25px;
        margin-top: -0.5em;
    }
}

.footer-text {
    margin-top: -0.46em;
    margin-bottom: 25px;
}

.social-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .social-title {
        font-size: 20px;
        color: $white-color;
        font-weight: 600;
        margin-right: 20px;
        margin-bottom: 0;
    }
}

.icon-group {
    a {
        color: $white-color;
        font-size: 18px;
        margin-right: 17px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.newsletter-widget {
    max-width: 290px;

    .form-control {
        background-color: $white-color !important;
        border: 1px solid $border-color;
    }
}

.newsletter-form {
    position: relative;
    max-width: 465px;
    display: flex;
    align-items: center;
    gap: 10px;

    .th-btn {
        min-width: 145px;
        padding: 21px 20px;
    }
}

@include lg {
    .footer-widget {

        &.widget_meta,
        &.widget_pages,
        &.widget_archive,
        &.widget_categories,
        &.widget_nav_menu {
            a {
                margin-bottom: 16px;
            }

        }
    }
}

@include sm {
    .footer-widget {
        .widget_title {
            margin-bottom: 35px;
        }
    }

    .th-widget-about .about-text {
        margin-bottom: 20px;
        ;
    }

    .social-box.mb-30 {
        margin-bottom: 25px;
    }
}