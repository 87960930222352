.widget {
    padding: var(--widget-padding-y, 40px) var(--widget-padding-x, 40px);
    background-color: $smoke-color;
    margin-bottom: 40px;
    position: relative;
    border-radius: 5px;

    &[data-overlay]:before {
        z-index: -1;
    }
    .th-social {
        a {
            box-shadow: 0px 2px 14px rgba(4, 6, 66, 0.1);
        }
    }
}
.widget_title {
    position: relative;
    font-size: 24px;
    font-weight: 700;
    font-family: $title-font;
    line-height: 1em;
    margin: -0.12em 0 28px 0;
}

.widget {
    .search-form {
        position: relative;
        display: flex;

        input {
            flex: 1;
            border-radius: 4px 0 0 4px;
            padding-right: 25px;
        }

        button {
            border: none;
            font-size: 16px;
            background-color: $theme-color;
            color: $white-color;
            display: inline-block;
            padding: 0;
            width: 56px;
            text-align: center;
            border-radius: 0 4px 4px 0;

            &:hover {
                background-color: $title-color;
            }
        }
    }
}

.wp-block-tag-cloud,
.tagcloud {
    a {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        font-family: $body-font;
        text-transform: capitalize;
        line-height: 1;
        padding: 13px 19px;
        margin-right: 5px;
        margin-bottom: 10px;
        color: $body-color;
        background-color: $white-color;
        border-radius: 4px;

        &:hover {
            background-color: $theme-color;
            color: $white-color !important;
            border-color: $theme-color;
        }
    }
}
.wp-block-tag-cloud,
.tagcloud {
    margin-right: -10px;
    margin-bottom: -10px;
}


.sidebar-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 320px;
    .gallery-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        color: $white-color;
        visibility: hidden;
        opacity: 0;
        transform: translate(-50%, 20px);
        &:hover {
            color: $theme-color;
        }
    }
    .gallery-thumb {
        overflow: hidden;
        position: relative;
        border-radius: 5px;
        &:before {
            content: '';
            height: calc(100% - 14px);
            width: calc(100% - 14px);
            background-color: $title-color;
            opacity: 0.8;
            position: absolute;
            top: 7px;
            left: 7px;
            border: 1px solid $white-color;
            transform: scaleX(0);
            border-radius: inherit;
            transition: 0.4s ease-in-out;
        }
        img {
            width: 100%;
        }
        &:hover {
            &:before {
                transform: scaleX(1);
            }
            .gallery-btn {
                visibility: visible;
                opacity: 1;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.recent-post {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    .media-img {
        margin-right: 15px;
        width: 80px;
        overflow: hidden;
        border-radius: 5px;

        img {
            width: 100%;
            transition: 0.4s ease-in-out;
        }
    }

    .post-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 8px 0;
        font-family: $title-font;
        text-transform: capitalize;
    }

    .recent-post-meta {
        a {
            text-transform: capitalize;
            font-size: 14px;
            color: $body-color;
            font-family: $body-font;

            i {
                margin-right: 6px;
                color: $theme-color;
            }
            &:hover {
                color: $theme-color;
            }
        }
    }
    &:hover {
        .media-img {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.sidebar-area {
    margin-bottom: -10px;
    ul.wp-block-latest-posts {
        margin-bottom: 0;

        li {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    select,
    input {
        background-color: $white-color;
    }

    .widget .wp-block-search {
        margin-bottom: 0;
    }

    .wp-block-group__inner-container h2 {
        font-size: 20px;
        line-height: 1em;
        margin-bottom: 20px;
        margin-top: -0.07em;
    }

    ol.wp-block-latest-comments {
        padding: 0;
        margin: 0;

        li {
            line-height: 1.5;
            margin: 0 0 20px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}

// Download Widget --------------------
.download-widget-wrap {
    .th-btn {
        width: 100%;
        &:first-child {
            margin-bottom: 20px;
        }
        &.style2 {
            color: $body-color;
            &:before,
            &:after {
                background-color: $theme-color;
            }
            &:hover {
                color: $white-color;
                border-color: $theme-color;
            }
        }
    }
}

// Banner Widget --------------------
.widget_banner {
    background-color: $title-color;
    position: relative;
    .shape1 {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .widget_title {
        color: $white-color;
    }
    &:has(.widget-map) {
        padding: 0;
        line-height: 0px;
    }
}
.widget-map {
    iframe {
        min-height: 280px;
    }
}
.widget-banner {
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media (max-width: 360px) {
        height: 280px;
    }
    .text {
        display: block;
        margin-top: -0.4em;
        margin-bottom: 15px;
        color: $white-color;
        text-transform: uppercase;
        font-weight: 500;
    }
    .title {
        color: $white-color;
        margin-bottom: 35px;
    }
}

@include xl {
    .widget-banner .title {
        font-size: 34px;
    }
}

// Widget Info ---------------------
.project-info-list {
    .contact-feature {
        padding: 17px;
        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }
}

// Price Filter --------------
.price_slider_wrapper {
	margin-top: -0.5em;
    .price_label {
        span {
            display: inline-block;
            color: $body-color;
        }
    }

    .ui-slider {
        height: 6px;
        position: relative;
        width: 100%;
        background-color: $theme-color;
        border: none;
        margin-top: 15px;
        margin-bottom: 25px;
        cursor: pointer;
        border-radius: 0;
    }

    .ui-slider-range {
        border: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 1;
        display: block;
        background-color: $title-color;
    }

    .ui-slider-handle {
        width: 16px;
        height: 16px;
        line-height: 16px;
        border-radius: 50%;
        text-align: center;
        padding: 0;
        cursor: pointer;
        position: absolute;
        margin-top: -5px;
        z-index: 2;
        background-color: $white-color;
        border: 3px solid $title-color;
        transform: translateX(-1px);

        &:focus {
            outline: none;
            box-shadow: 1.5px 2.598px 10px 0px rgba(0, 0, 0, 0.15);
        }

        // &:before {
        //     content: "";
        //     position: absolute;
        //     background-color: #fff;
        //     top: 50%;
        //     left: 50%;
        //     width: 12px;
        //     height: 12px;
        //     border-radius: 50%;
        //     transform: translate(-50%, -50%);
        // }
        &:last-child {
            transform: translateX(-9px);
        }
    }
    button,
    .button {
        background-color: $theme-color;
        color: $white-color;
        font-weight: 500;
        line-height: 1.6;
        text-transform: capitalize;
        text-align: center;
        border: none;
        display: inline-block;
        overflow: hidden;
        position: relative;
        z-index: 2;
        padding: 7px 20px;
        min-width: 100px;
        font-size: 14px;
        border-radius: 5px;
        transition: 0.4s ease-in;
        &:hover {
            background-color: $title-color;
        }
    }
}

.widget_shopping_cart {
    .widget_title {
        margin-bottom: 30px;
        border-bottom: none;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
        }
    }

    .mini_cart_item {
        position: relative;
        padding: 30px 30px 30px 90px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 0;
        text-align: left;

        &:first-child {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .cart_list {
        a:not(.remove) {
            display: block;
            color: $body-color;
            font-size: 16px;
            font-weight: 500;
            font-family: $title-font;
            font-weight: 600;
            color: $title-color;

            &:hover {
                color: $theme-color;
            }
        }

        a.remove {
            position: absolute;
            top: 50%;
            left: 95%;
            transform: translateY(-50%);
            color: $body-color;

            &:hover {
                color: $theme-color;
            }
        }

        img {
            width: 75px;
            height: 75px;
            position: absolute;
            left: 0;
            top: 18px;
        }
    }

    .quantity {
        display: inline-flex;
        white-space: nowrap;
        vertical-align: top;
        margin-right: 20px;
        font-size: 14px;
        font-weight: 500;
    }

    .total {
        margin-top: 20px;
        font-size: 18px;
        color: $title-color;
        font-family: $body-font;
        strong {
            font-family: $title-font;
        }
    }

    .amount {
        padding-left: 5px;
    }
}

.sidebar-area {
    .widget_shopping_cart .th-btn {
        padding: 8px 22px;
        font-size: 14px;
    }
}

@media (min-width: 1200px) {
    .sidebar-shop {
        .widget {
            padding: 30px;
        }
    }
}

@media (min-width: 992px) {
    .sidebar-shop {
        .widget {
            margin-bottom: 24px;
        }
    }
}

@include lg {
    .widget {
        --widget-padding-y: 30px;
        --widget-padding-x: 30px;
    }
    .widget_title {
        font-size: 22px;
        margin: -0.12em 0 24px 0;
    }
    .author-widget-wrap .name {
        font-size: 22px;
    }
}

@include md {
    .sidebar-area {
        padding-top: 30px;
    }
    .widget {
        --widget-padding-y: 40px;
        --widget-padding-x: 40px;
    }

    .wp-block-tag-cloud a,
    .tagcloud a {
        padding: 10.5px 18px;
    }
}

@include sm {
    .widget {
        padding: 35px 20px;
    }
}

@media (max-width: 330px) {
    .recent-post {
        .post-title {
            font-size: 14px;
            line-height: 24px;
        }
        .recent-post-meta a {
            font-size: 12px;
        }
    }
}