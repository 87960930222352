.th-header {
    position: relative;
    z-index: 41;
}

.header-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.sticky-wrapper {
    transition: 0.4s ease-in-out;

    &.sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-color: $white-color;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
        animation: stickyAni 0.4s ease-in-out;
    }
}

@keyframes stickyAni {
    0% {
        transform: translate3d(0, -40px, 0) scaleY(0.8);
        opacity: 0.7;
    }

    100% {
        transform: translate3d(0, 0, 0) scaleY(1);
        opacity: 1;
    }
}

.main-menu {
    a {
        display: block;
        position: relative;
        font-weight: 600;
        font-size: 14px;
        color: $title-color;
        font-family: $title-font;
        text-transform: uppercase;

        &:hover {
            color: $theme-color;
        }
    }

    >ul {
        >li {
            margin: 0 14px;

            >a {
                padding: 47px 0;

                &:hover {
                    color: $theme-color;
                }
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            position: relative;

            &.menu-item-has-children {
                >a {
                    &:after {
                        content: "\f078";
                        position: relative;
                        font-family: $icon-font;
                        margin-left: 4px;
                        font-weight: 600;
                        top: 0;
                        font-size: 1em;
                    }
                }
            }

            &:last-child {
                margin-right: 0 !important;
            }

            &:first-child {
                margin-left: 0 !important;
            }

            &:hover {
                >ul.sub-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: scaleY(1);
                    z-index: 9;
                }

                ul.mega-menu {
                    visibility: visible;
                    opacity: 1;
                    z-index: 9;
                    transform: scaleY(1) translateX(-50%);
                }
            }
        }
    }

    ul.sub-menu,
    ul.mega-menu {
        position: absolute;
        text-align: left;
        top: 100%;
        left: 0;
        background-color: $white-color;
        visibility: hidden;
        min-width: 190px;
        width: max-content;
        padding: 7px;
        left: -14px;
        opacity: 0;
        z-index: -1;
        border: 0;
        box-shadow: 0px 4px 15px rgba(1, 15, 28, 0.06);
        border-radius: 0;
        transform: scaleY(0);
        transform-origin: top center;
        transition: all 0.4s ease 0s;

        a {
            font-size: 16px;
            line-height: 30px;
        }
    }

    ul.sub-menu {
        padding: 18px 20px 18px 18px;
        left: -27px;

        // &:before {
        //   content: "";
        //   position: absolute;
        //   left: 34.5px;
        //   top: 30px;
        //   width: 1px;
        //   background-color: #ededed;
        //   height: calc(100% - 60px);
        // }

        li {
            display: block;
            margin: 0 0;
            padding: 0px 9px;

            &.menu-item-has-children {
                >a:after {
                    content: "\f105";
                    float: right;
                    top: 1px;
                }
            }

            a {
                position: relative;
                padding-left: 23px;
                text-transform: capitalize;

                &:before {
                    content: "\f649";
                    position: absolute;
                    top: 8px;
                    left: 0;
                    font-family: $icon-font;
                    width: 11px;
                    height: 11px;
                    text-align: center;
                    border-radius: 50%;
                    display: inline-block;
                    font-size: 0.9em;
                    line-height: 1;
                    color: $theme-color;
                    font-weight: 500;
                    // background-color: $white-color;
                    // box-shadow: inset 0px 2px 4px 0px rgba(#ad8858, 0.4);
                }
            }

            ul.sub-menu {
                left: 100%;
                right: auto;
                top: 0;
                margin: 0 0;
                margin-left: 20px;

                li {
                    ul {
                        left: 100%;
                        right: auto;
                    }
                }
            }
        }
    }

    .mega-menu-wrap {
        position: static;
    }

    ul.mega-menu {
        display: flex;
        justify-content: space-between;
        text-align: left;
        width: 100%;
        max-width: var(--main-container);
        padding: 20px 15px 23px 15px;
        left: 50%;
        transform: scaleY(0) translateX(-50%);

        li {
            display: block;
            width: 100%;
            padding: 0 15px;

            li {
                padding: 2px 0;
            }

            a {
                display: inline-block;
                text-transform: capitalize;
            }
        }

        >li {
            >a {
                display: block;
                padding: 0;
                padding-bottom: 15px;
                margin-bottom: 10px;
                text-transform: capitalize;
                letter-spacing: 1px;
                font-weight: 700;
                color: $title-color;
                border-color: $theme-color;

                &::after,
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 15px;
                    height: 1px;
                    background-color: $theme-color;
                }

                &::after {
                    width: calc(100% - 20px);
                    left: 20px;
                }

                &:hover {
                    padding-left: 0;
                }
            }
        }
    }
}

.main-menu {
    &.style2 {
        >ul>li {
            margin: 0 6px;

            >a {
                padding: 2px 16px;
                border-radius: 100px;
                background-color: transparent;
                transition: all 0.4s ease-in-out;

                &:hover {
                    background-color: rgba(64, 102, 242, 0.1);
                }
            }
        }

        ul.sub-menu {
            padding: 18px 20px 18px 18px;
            left: -27px;
            margin-top: 15px;
        }
    }
}

.header-button {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    .icon-btn {
        position: relative;

        &:hover {
            color: $white-color;
            background-color: $theme-color;
            border-color: $theme-color;

            .badge {
                background-color: $white-color;
                color: $theme-color;
            }
        }
    }
}

.header-links {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        display: inline-block;
        position: relative;
        font-size: 16px;
        font-weight: 400;

        &:not(:last-child) {
            margin: 0 26px 0 0;
        }

        >i {
            margin-right: 6px;
        }
    }

    li,
    span,
    p,
    a {
        color: $body-color;
    }

    a:hover {
        color: $theme-color;
    }

    i {
        color: $body-color;
    }

    b,
    strong {
        font-weight: 600;
    }
}

.header-social {
    .social-title {
        font-weight: 500;
        font-size: 16px;
        display: inline-block;
        margin: 0 15px 0 0;
        color: $body-color;
    }

    a {
        font-size: 16px;
        display: inline-block;
        color: $body-color;
        margin: 0 15px 0 0;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: $theme-color;
        }
    }
}

.header-logo {
    padding-top: 15px;
    padding-bottom: 15px;

    @include xs {
        max-width: 150px;

        .icon-masking .mask-icon {
            mask-size: cover;
        }
    }
}

@include lg {
    .main-menu>ul>li>a {
        padding: 37px 0;
    }
}

/* Header 1 ---------------------------------- */
.header-layout1 {
    position: relative;

    .header-top {
        padding: 17px 0;
        background-color: $theme-color;
        --body-color: #fff;

        a {
            &:hover {
                color: $title-color;
            }
        }
    }

    .menu-area {
        position: relative;
        z-index: 2;
        background-color: #080E1C;
    }

    .main-menu {
        margin-left: 60px;

        >ul {
            >li {
                >a {
                    &:not(:hover) {
                        color: $white-color;
                    }
                }
            }
        }

    }

    .menu-area {
        position: relative;
    }

    .header-logo {
        position: relative;
        z-index: 2;
    }

    .header-button {
        .icon-btn {
            color: $white-color;
        }
    }

    .logo-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 32.5%;
        height: 100%;

        &:before,
        &:after {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $theme-color;
            clip-path: polygon(0 0, calc(100% - 80px) 0%, 100% 100%, 0% 100%);
            z-index: -2;
        }

        &:after {
            background-image: url('../img/theme-img/logo_bg.svg');
            background-color: $white-color;
            background-repeat: repeat;
            width: calc(100% - 20px);
            clip-path: polygon(0 0, calc(100% - 60px) 0%, 100% 100%, 0% 100%);
            z-index: -1;
        }
    }
}

@include hd {
    .header-layout1 {
        .logo-bg {
            width: 37%;
        }
    }
}

@include xxl {
    .header-layout1 {
        .logo-bg {
            width: 27%;
        }
    }
}

@include ml {
    .header-layout1 {
        .header-button {
            .th-btn {
                display: none;
            }
        }
    }
}

@include lg {
    .header-layout1 {
        .logo-bg {
            &:before {
                clip-path: polygon(0 0, calc(100% - 50px) 0%, 100% 100%, 0% 100%);
            }

            &:after {
                clip-path: polygon(0 0, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
            }
        }

        .main-menu {
            margin-left: 30px;
        }

        .header-top {
            padding: 12px 0;
        }
    }
}

@include md {
    .header-layout1 {
        .logo-bg {
            width: 56%;
        }

        .header-logo {
            img {
                max-height: 80px;
            }
        }
    }
}

@include vxs {
    .header-layout1 {
        .logo-bg {
            width: 60%;
        }
    }
}

@media (max-width: 320px) {
    .header-layout1 .logo-bg {
        width: 64%;
    }
}

/* Header 2 ---------------------------------- */
.header-layout2 {
    .icon-btn {
        background-color: $white-color;
        color: $title-color;
        border: 1px solid #ddd;
        border-radius: 50%;
        line-height: 54px;

        &:hover {
            background-color: $theme-color;
            color: $white-color;
        }
    }

    .main-menu>ul>li>a {
        color: $title-color;
        font-weight: 600;

        &:hover {
            color: $theme-color;
        }
    }

    ul.sub-menu {
        background-color: #E1EAFF;
    }

    .header-top {
        padding: 17px 0;
        background-color: $theme-color;
        --body-color: #fff;

        a {
            &:hover {
                color: $title-color;
            }
        }
    }

    .menu-area {
        background-color: #E1EAFF;
    }
}

@include lg {
    .header-layout2 {
        .header-button {
            .th-btn {
                display: none;
            }
        }

        .header-top {
            padding: 12px 0;
        }
    }
}

/* Header 3 ---------------------------------- */
.header-layout3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    --main-container: 1700px;

    .header-button {
        .icon-btn:not(:hover) {
            border-color: #bdbdbd;
        }
    }

    .main-menu>ul>li>a {
        font-weight: 600;
    }
}

@include ml {
    .header-layout3 {
        .header-button {
            .th-btn {
                display: none;
            }
        }
    }
}

/* Header 4 ---------------------------------- */
.header-layout4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .sticky-wrapper {
        &.sticky {
            .menu-area {
                background-color: $title-color;
            }
        }
    }

    .menu-area {
        background: rgba(0, 0, 0, 0.29);
        backdrop-filter: blur(7.5px);
        border-bottom: 1px solid rgba(0, 0, 0, 0.36);
        padding: 15px 0;
    }

    .main-menu>ul>li>a {
        color: var(--white-color) !important;
        font-size: 16px;
        font-weight: 600;

        &:after {
            color: var(--white-color) !important;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            color: $white-color !important;

            &:after {
                color: $white-color !important;
            }
        }
    }

    .main-menu ul li.menu-item-has-children>a:after {
        content: "\2b";
        font-size: 12px;
        transition: 0.3s ease-in-out;
        transform: rotate(0deg);
    }

    .main-menu ul li.menu-item-has-children>a:hover:after {
        content: "\f068";
        transform: rotate(180deg);
    }

    .header-button {
        .th-btn {
            padding: 17px 42.5px;
            font-weight: 700;
        }

        @include lg {
            .th-btn {
                display: none;
            }
        }
    }
}

/* Header 5 ---------------------------------- */
.header-layout5 {
    position: relative;

    .social-links {

        .social-title {
            font-weight: 600;
            font-size: 16px;
            display: inline-block;
            margin: 0 10px 0 0;
            letter-spacing: -0.32px;
            font-family: $title-font;
            color: $title-color;

            @media(max-width: 1599px) {
                display: none;
            }
        }

        a {
            font-size: 14px;
            display: inline-block;
            color: $title-color;
            margin: 0 15px 0 0;
            transition: all 0.4s ease;


            &:last-child {
                margin-right: 0 !important;
            }

            &:hover {
                color: $theme-color;

                i {
                    animation: slideTop 0.5s;
                }
            }
        }
    }

    .header-top {
        background-color: transparent;
        border-bottom: 1px solid #D9D9D9;
        padding: 12px 0;

        .header-links {
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
            }

            li {
                display: inline-block;
                position: relative;
                font-family: $title-font;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: -0.32px;

                &:not(:last-child) {
                    margin: 0 40px 0 0;

                    // @media(max-width: 1699px) {
                    //     margin: 0 14px 0 0;
                    // }

                    &:after {
                        content: '';
                        height: 15px;
                        width: 1px;
                        background-color: #CAD4F1;
                        position: absolute;
                        top: 5px;
                        right: -20px
                    }
                }

                >i {
                    color: $theme-color;
                    margin-right: 8px;
                }
            }
        }
    }

    .main-menu ul li.menu-item-has-children>a:after {
        content: "\2b";
        font-size: 12px;
        transition: 0.3s ease-in-out;
        transform: rotate(0deg);
    }

    .main-menu ul li.menu-item-has-children>a:hover:after {
        content: "\f068";
        transform: rotate(180deg);
    }

    .menu-area {
        padding: 18px 0;
    }

    .main-menu {
        margin-right: 150px;

        @include ml {
            margin-right: 0;
        }
    }

    .header-button {
        .th-btn {
            padding: 18px 38px;
        }
    }
}


/* Header 6 ---------------------------------- */
.header-layout6 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .menu-area {
        padding: 17px 0;

        @include xs {
            padding: 10px 0;
        }
    }
}

/* Header layout 7 start  ---------------------------------- */
.header-layout7 {

    .sticky-wrapper {
        &.sticky {
            .menu-area {
                background-color: $title-color;
            }
        }
    }

    .menu-area {
        background: rgba(0, 0, 0, 0.29);
        backdrop-filter: blur(7.5px);
        border-bottom: 1px solid rgba(0, 0, 0, 0.36);
        padding: 15px 0;
    }

    .main-menu.style2>ul>li {
        margin: 0 4px;
    }

    .main-menu>ul>li>a {
        color: $white-color !important;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
            border-radius: 100px;
            opacity: 0;
            transition: all 1s ease-in-out;
        }

        &:after {
            color: var(--white-color) !important;
        }

        &:hover {
            &:before {
                opacity: 0.1;
            }

            color: $theme-color !important;
            background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;



            &:after {
                color: $theme-color !important;
                background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
    }

    .main-menu ul li.menu-item-has-children>a:after {
        content: "\2b";
        font-size: 12px;
        transition: 0.3s ease-in-out;
        transform: rotate(0deg);
    }

    .main-menu ul li.menu-item-has-children>a:hover:after {
        content: "\f068";
        transform: rotate(180deg);
    }

    .header-button {
        .th-btn {
            padding: 17px 40px;
        }

        @include lg {
            .th-btn {
                display: none;
            }
        }
    }
}

@media (min-width: 1400px) {
    .header-layout7 .th-container {
        --main-container: 1720px;
    }
}

/* header layout 7 end -----------------------------*/

/* header-layout8 start ------------------- */
.header-layout8 {
    .sticky-wrapper {
        &.sticky {
            .menu-area {
                padding: 15px 0;
            }
        }
    }

    .menu-area {
        padding: 40px 0;

        @include sm {
            padding: 25px 0;
        }
    }

    .main-menu a {
        display: block;
        position: relative;
        font-size: 16px;
        text-transform: capitalize;
    }

    .main-menu.style2>ul>li {
        margin: 0 0px;
    }

    .th-btn {
        border-radius: 100px;
        padding: 18px 42px;

        @include lg {
            display: none;
        }
    }

}

/* header-layout8 end ------------------- */

/* header-layout9 start ------------------- */
.header-layout9 {
    &.header-absolute {
        top: 120px;
    }

    .sticky-wrapper {
        &.sticky {
            .menu-area {
                background-color: $title-color;
            }
        }
    }

    .menu-area {
        max-width: 1500px;
        display: block;
        margin: auto;
        background: rgba(22, 24, 29, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        border-radius: 50px;
        padding: 16px 45px;

    }

    .main-menu.style2>ul>li {
        margin: 0 4px;
    }

    .main-menu>ul>li>a {
        color: $white-color !important;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
            border-radius: 100px;
            opacity: 0;
            transition: all 1s ease-in-out;
        }

        &:after {
            color: var(--white-color) !important;
        }

        &:hover {
            &:before {
                opacity: 0.1;
            }

            color: $theme-color !important;
            background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;



            &:after {
                color: $theme-color !important;
                background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
    }

    .main-menu ul li.menu-item-has-children>a:after {
        content: "\2b";
        font-size: 12px;
        transition: 0.3s ease-in-out;
        transform: rotate(0deg);
    }

    .main-menu ul li.menu-item-has-children>a:hover:after {
        content: "\f068";
        transform: rotate(180deg);
    }

    .header-button {
        .th-btn {
            padding: 17px 40px;
        }

        @include lg {
            .th-btn {
                display: none;
            }
        }
    }
}

/* header-layout9 end ------------------- */
/* header-layout10 start ------------------- */
.header-layout10 {
    position: relative;


    .social-links {
        .social-title {
            font-weight: 400;
            font-size: 16px;
            display: inline-block;
            margin: 0 10px 0 0;
            letter-spacing: -0.32px;
            font-family: $title-font;
            color: $white-color;

            @media(max-width: 1599px) {
                display: none;
            }
        }

        a {
            font-size: 14px;
            display: inline-block;
            color: $white-color;
            margin: 0 15px 0 0;
            transition: all 0.4s ease;


            &:last-child {
                margin-right: 0 !important;
            }

            &:hover {
                color: $title-color;

                i {
                    animation: slideTop 0.5s;
                }
            }
        }
    }

    .header-top {
        background-color: $theme-color;
        padding: 12px 0;
        --body-color: #fff;

        .header-links {
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
            }

            li {
                font-family: $title-font;

                &:not(:last-child) {
                    margin: 0 40px 0 0;

                    &:after {
                        content: '';
                        height: 15px;
                        width: 1px;
                        background-color: #CAD4F1;
                        position: absolute;
                        top: 5px;
                        right: -20px
                    }
                }

                a {
                    &:hover {
                        color: $title-color;
                    }
                }

                >i {
                    margin-right: 8px;
                }
            }
        }
    }

    .main-menu ul li.menu-item-has-children>a:after {
        content: "\2b";
        font-size: 12px;
        transition: 0.3s ease-in-out;
        transform: rotate(0deg);
    }

    .main-menu ul li.menu-item-has-children>a:hover:after {
        content: "\f068";
        transform: rotate(180deg);
    }

    .menu-area {
        padding: 18px 0;
    }

    .main-menu {
        a {
            font-size: 16px;
            text-transform: capitalize;
        }
    }

    .main-menu.style2>ul>li {
        margin: 0 2px;
    }


    .header-button {
        .th-btn {
            padding: 18px 38px;
        }
    }
}

/* header-layout10 end ------------------- */
/* header layout 11 start ----------------*/
.header-layout11 {

    .sticky-wrapper {
        &.sticky {
            background-color: var(--title-color);
        }
    }

    .menu-area {
        padding: 15px 0;
    }

    .main-menu>ul>li>a {
        color: var(--white-color);
        text-transform: capitalize;

        &:hover {
            background: rgba(62, 101, 243, 0.1);
            color: $theme-color;

        }

    }

    .main-menu.style2>ul>li {
        margin: 0 0px;
    }
}

/* header layout 11 end ----------------*/
/* header layout 13 start ----------------*/
.header-layout12 {
    .main-menu.style2>ul>li>a {
        text-transform: capitalize;
        font-size: 16px;
    }

    .menu-area {
        background-color: #EFF1F9;
        padding: 16.5px 0;
    }

    .th-btn {
        text-transform: capitalize;
        font-weight: 400;
    }

}

/* header layout 13 end ----------------*/

/* header layout 14 end ----------------*/
.header-layout13 {
    .menu-area {
        padding: 16.5px 0;
    }
}

/* header layout 14 end ----------------*/

/* header layout 15 start ----------------*/
.header-layout15 {
    .header-top {
        padding: 17px 0;
        background-color: $theme-color;
        --body-color: #fff;
    }

    .header-links {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            font-family: $title-font;

            &:not(:last-child) {
                margin: 0 40px 0 0;

                &:after {
                    content: '';
                    height: 15px;
                    width: 1px;
                    background-color: #CAD4F1;
                    position: absolute;
                    top: 5px;
                    right: -20px
                }
            }

            a {
                &:hover {
                    color: $title-color;
                }
            }

            >i {
                margin-right: 8px;
            }
        }
    }

    .social-links {
        .social-title {
            font-weight: 400;
            font-size: 16px;
            display: inline-block;
            margin: 0 10px 0 0;
            letter-spacing: -0.32px;
            font-family: $title-font;
            color: $white-color;

            @media(max-width: 1599px) {
                display: none;
            }
        }

        a {
            font-size: 14px;
            display: inline-block;
            color: $white-color;
            margin: 0 15px 0 0;
            transition: all 0.4s ease;


            &:last-child {
                margin-right: 0 !important;
            }

            &:hover {
                color: $title-color;

                i {
                    animation: slideTop 0.5s;
                }
            }
        }
    }

    .menu-area {
        background-color: #EFF1F9;
        padding: 16.5px 0;
    }

    .th-btn {
        text-transform: capitalize;
        font-weight: 400;
    }
}

/* header layout 16 start -------------------*/
.header-layout16 {

    .menu-area {
        padding: 16px 0;
    }
    .main-menu>ul>li>a { 
        font-size: 16px;
        text-transform: capitalize; 
    }

}

/* header layout 16 end -------------------*/

/* header layout 17 start -------------------*/
.header-layout17 {
    background-color: #EFF1F9;

    .menu-area {
        padding: 16px 0;
        border-bottom: 1px solid rgba(101, 106, 116, 0.2);
    }

    .main-menu>ul>li>a { 
        font-size: 16px;
        text-transform: capitalize; 
    }
}

/* header layout 17 end -------------------*/