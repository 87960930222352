.sec-title {
    margin-bottom: calc(var(--section-title-space) - 10px);
    margin-top: -0.32em;
    text-transform: capitalize;

    &.sec-title2 {
        span {
            display: inline-block;
            position: relative;
            color: $theme-color;

            &:after {
                content: "";
                height: 15px;
                width: 100%;
                background-image: url("data:image/svg+xml,%3Csvg width='215' height='19' viewBox='0 0 215 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 16.9999C37.6667 5.9999 129.8 -9.4001 213 16.9999' stroke='%23407360' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                background-size: cover;
                background-repeat: repeat-x;
                position: absolute;
                bottom: -7px;
                left: 0;
                animation: titleFill 3s linear infinite;
            }
        }
    }
}

.sub-title {
    position: relative;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: $theme-color;
    margin-bottom: 30px;
    text-transform: uppercase;
    line-height: 24px;
    margin-top: -0.34em;

    &:has(img) {
        margin-top: 0;
    }

    &.sub-title2 {
        position: relative;
        font-family: $body-font;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 25px;

        &.style1 {
            &::before {
                margin-left: -4.5rem;
                display: none;

                @include xs {
                    margin-left: -4.5rem;
                }

                @include vxs {
                    display: none;
                }
            }
        }

        &::before,
        &:after {
            content: "";
            position: absolute;
            top: 48%;
            height: 2px;
            width: 40px;

            @include xs {
                width: 30px;
            }

            @media(max-width:450px) {
                display: none;
            }
        }

        &::before {
            background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
            margin-left: -3.2rem;
            opacity: 0.5;

            @include xs {
                margin-left: -2.6rem;
            }
        }

        &::after {
            background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
            margin-left: 10px;
            opacity: 0.5;
        }
    }

    &.sub-title3 {
        position: relative;
        font-family: $title-font;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        color: $theme-color;
        margin-bottom: 25px;

        &.style1 {
            &::before {
                margin-left: -4.5rem;
                display: none;

                @include xs {
                    margin-left: -4.5rem;
                }

                @include vxs {
                    display: none;
                }
            }
        }

        &::before,
        &:after {
            content: "";
            position: absolute;
            top: 48%;
            height: 2px;
            width: 40px;

            @include xs {
                width: 30px;
            }

            @media(max-width:450px) {
                display: none;
            }
        }

        &::before {
            background-color: $theme-color;
            margin-left: -3.2rem;

            @include xs {
                margin-left: -2.6rem;
            }
        }

        &::after {
            background-color: $theme-color;
            margin-left: 10px;
        }
    }

    &.sub-title4 {
        position: relative;
        display: inline-block;
        padding: 0 20px;

        &:before,
        &:after {
            position: absolute;
            content: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='1.14673' width='9' height='9' rx='0.5' fill='white' stroke='%233E66F3'/%3E%3Crect x='3' y='3.64673' width='4' height='4' rx='2' fill='%233E66F3'/%3E%3C/svg%3E%0A");
            position: absolute;
            top: 0px;
            left: 0px;
        }

        &:after {
            left: unset;
            right: 0px;
        }

        &.style1 {
            padding-right: 0;

            &:after {
                display: none;
            }
        }
    }
}

.box-title {
    font-size: 24px;
    line-height: 1.417;
    font-weight: 700;
    margin-top: -0.32em;

    a {
        color: inherit;

        &:hover {
            color: $theme-color;
        }
    }
}

.sec-text {
    max-width: 720px;
}

.title-area {
    margin-bottom: calc(var(--section-title-space) - 10px);
    position: relative;
    z-index: 2;

    &.style2 {
        margin-top: -8px;
    }

    .sec-title {
        margin-bottom: 15px;
    }

    &.mb-0 {
        .sec-title {
            margin-bottom: -0.41em;
        }
    }

    .th-btn {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

hr.title-line {
    margin-top: 0;
    background-color: $border-color;
    opacity: 1;
}

.sec-btn,
.title-line {
    margin-bottom: var(--section-title-space);
}

.shadow-title {
    font-family: $title-font;
    font-size: 74px;
    font-weight: 900;
    line-height: 1;
    background-image: linear-gradient(180deg, rgba(226, 232, 250, 0.7) 0%, rgba(226, 232, 250, 0) 88.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: -0.55em 0 -0.45em -0.25em;

    &.color2 {
        background-image: linear-gradient(180deg, #232C47 0%, rgba(20, 29, 56, 0) 91.15%);
    }

    &.color3 {
        background-image: linear-gradient(180deg, #E0E0E0 0%, rgba(220, 214, 214, 0) 93.75%);
    }
}


@include lg {
    .sub-title {
        margin-bottom: 20px;
    }

    .shadow-title {
        font-size: 64px;
    }

    .title-area,
    .sec-title {
        --section-title-space: 60px;

        &.mb-45 {
            margin-bottom: 36px;
        }

        &.mb-50 {
            margin-bottom: 40px;
        }
    }

    .sec-btn,
    .title-line {
        --section-title-space: 55px;
    }
}

@include md {
    .shadow-title {
        font-size: 60px;
    }

    .title-area,
    .sec-title {
        --section-title-space: 50px;

        &.mb-45 {
            margin-bottom: 35px;
        }
    }

    .sec-btn,
    .title-line {
        --section-title-space: 50px;
    }
}

@include xs {
    .shadow-title {
        font-size: 52px;
    }
}

@include vxs {
    .shadow-title {
        font-size: 40px;
    }
}