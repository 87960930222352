/* Global Style ---------------------------------- */
.shape-icon {
    position: relative;
    z-index: 2;
    width: 90px;
    height: 85px;
    line-height: 85px;
    text-align: center;

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: $smoke-color2;
        clip-path: path("M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z");
        z-index: -1;
        transition: 0.4s ease-in-out;
    }

    .dots {

        &:before,
        &:after {
            content: '';
            position: absolute;
            background-color: $theme-color;
            height: 24px;
            width: 23px;
            border-radius: 50%;
        }

        &:before {
            top: 0;
            right: 9px;
        }

        &:after {
            height: 12px;
            width: 11px;
            bottom: 0;
            left: 27px;
        }
    }

}

/* Service Featured ---------------------------------- */
.service-featured {
    text-align: center;
    position: relative;
    z-index: 2;

    &:before {
        content: '';
        height: 180px;
        width: 100%;
        background-color: $smoke-color;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border-radius: 10px;
        z-index: -1;
        transition: 0.4s ease-in-out;
    }

    .shape-icon {
        margin: 0 auto 30px auto;
    }

    .icon-btn {
        border-radius: 99px;
        background-color: $theme-color;
        box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.5);
        border: none;
        color: $white-color;
        position: absolute;
        bottom: -28px;
        left: calc(50% - 28px);

        &:hover {
            background-color: $white-color;
            color: $theme-color;
        }
    }

    &_content {
        background-color: $white-color;
        max-width: 312px;
        box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 15px 28px 15px;
        margin-bottom: 28px;
    }

    &_text {
        max-width: 255px;
        margin: 0 auto 31px auto;
    }
}

/* Service Card ---------------------------------- */
.service-card {
    padding: 40px;
    position: relative;
    z-index: 2;
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
    background-color: $white-color;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;

    &:before,
    &:after {
        content: '';
        position: absolute;
        height: 110px;
        width: 110px;
        background-color: $theme-color;
        opacity: 0.6;
        border-radius: 50%;
        transition: 0.4s ease-in-out;
        z-index: -1;
    }

    &:before {
        bottom: -73px;
        right: -28px;
    }

    &:after {
        right: -73px;
        bottom: -28px;
    }

    .shape-icon {
        margin-bottom: 30px;
    }

    .box-title {
        a {
            &:hover {
                color: $smoke-color2;
            }
        }
    }

    .bg-shape {
        position: absolute;
        bottom: -200px;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: 0.4s ease-in-out;
        pointer-events: none;

        img {
            width: 100%;
        }
    }

    &_number {
        position: absolute;
        top: 25px;
        right: 40px;
        font-size: 100px;
        line-height: 1;
        font-weight: bold;
        color: $smoke-color2;
        opacity: 0.3;
    }

    &_text {
        transition: 0.4s ease-in-out;
        margin-bottom: 22px;
    }

    .th-btn {
        background-color: $smoke-color2;
        color: $title-color;
        padding: 12.5px 20px;
        box-shadow: none;

        &:before,
        &:after {
            background-color: $smoke-color2;
        }
    }

    &:hover {
        width: 100%;

        &:before,
        &:after {
            opacity: 1;
            height: 120%;
            width: 120%;
            border-radius: 0;
        }

        .shape-icon {
            &:before {
                background-color: $white-color;
            }
        }

        .bg-shape {
            bottom: 0;
            opacity: 1;
        }

        .box-title {
            color: $white-color;
        }

        .th-btn {
            background-color: $white-color;
        }

        .service-card {
            &_text {
                color: $white-color;
            }
        }
    }
}

.service-sec {
    background-size: auto;
    background-position: top center;
    background-color: $smoke-color;
}

@include vxs {
    .service-card {
        padding: 40px 30px;
    }
}

/* Service Box ---------------------------------- */
.service-box {
    position: relative;
    box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
    background-color: $white-color;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    transition: 0.4s ease-in-out;

    .bg-shape {
        position: absolute;
        bottom: -200px;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: 0.4s ease-in-out;
        pointer-events: none;

        img {
            width: 100%;
        }
    }

    &_img {
        border-radius: 10px 10px 0 0;
        overflow: hidden;

        img {
            width: 100%;
            transition: 0.4s ease-in-out;
        }
    }

    .box-title {
        margin-bottom: 12px;

        a:hover {
            color: $smoke-color2;
        }
    }

    &_text {
        transition: 0.4s ease-in-out;
        max-width: 230px;
        margin: 0 auto 10px auto;
    }

    &_icon {
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        background-color: $white-color;
        margin: -40px auto 0 auto;
        transition: 0.4s ease-in-out;
        border-radius: 50%;

        img {
            transition: 0.4s ease-in-out;
        }
    }

    &_content {
        position: relative;
        padding: 0 10px 25px 10px;
    }

    &:hover {
        background-color: $theme-color;

        .bg-shape {
            bottom: 0;
            opacity: 1;
        }

        .box-title {
            color: $white-color;
        }

        .link-btn {
            color: $white-color;

            &:before {
                background-color: $white-color;
                width: 100%;
            }

            &:hover {
                &:before {
                    width: 70px;
                }
            }
        }

        .service-box {
            &_img {
                img {
                    transform: scale(1.1);
                }
            }

            &_icon {
                background-color: $theme-color;

                img {
                    transform: rotateY(180deg);
                    filter: brightness(0) invert(1);
                }
            }

            &_text {
                color: $white-color;
            }
        }
    }
}

/* Service Grid ---------------------------------- */
.service-grid {
    position: relative;
    margin-top: -40px;

    .bg-shape {
        position: absolute;
        bottom: -200px;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: 0.4s ease-in-out;
        pointer-events: none;

        img {
            width: 100%;
        }
    }

    &_content {
        box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
        background-color: $white-color;
        border-radius: 10px;
        text-align: center;
        padding: 70px 15px 40px 15px;
        transition: 0.4s ease-in-out;
        overflow: hidden;
    }

    &_icon {
        height: 80px;
        width: 80px;
        line-height: 80px;
        background-color: $theme-color;
        border-radius: 50%;
        text-align: center;
        position: relative;
        z-index: 2;
        margin: 0 auto 0 auto;
        transform: translateY(40px);

        img {
            position: relative;
            z-index: 2;
            filter: brightness(0) invert(1);
            transition: 0.4s ease-in-out;
        }

        &:after,
        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background-color: $theme-color;
            @extend .ripple-animation;
            z-index: -2;
            border-radius: 50%;
            transition: all ease 0.4s;
        }

        &:after {
            animation-delay: 2s;
        }

    }

    .box-title {
        margin-bottom: 15px;

        a:hover {
            color: $smoke-color2;
        }
    }

    &_text {
        transition: 0.4s ease-in-out;
        max-width: 230px;
        margin: 0 auto 22px auto;
    }

    .th-btn {
        padding: 15.5px 18px;
    }

    &:hover {
        .bg-shape {
            bottom: 0;
            opacity: 1;
        }

        .box-title {
            color: $white-color;
        }

        .th-btn {
            background-color: $white-color;
            color: $theme-color;

            &:hover {
                color: $white-color;
            }
        }

        .service-grid {
            &_content {
                background-color: $theme-color;
            }

            &_icon {
                background-color: $white-color;

                img {
                    transform: rotateY(180deg);
                    filter: none;
                }

                &:before,
                &:after {
                    background-color: $white-color;
                }
            }

            &_text {
                color: $white-color;
            }
        }
    }
}

/* Service 3D ---------------------------------- */
.service-3d {
    text-align: center;
    background: $white-color;
    border-radius: 10px;
    padding: 40px 12px;

    &_text {
        max-width: 315px;
        margin: 0 auto 22px auto;
    }

    &_icon {
        margin-bottom: 30px;

        img {
            transition: 0.4s ease-in-out;
        }
    }

    .th-btn {
        padding: 15.5px 18px;
        background-color: $smoke-color2;
        color: $title-color;
        box-shadow: none;

        &:before,
        &:after {
            background-color: $smoke-color2;
        }

        &:hover {
            color: $white-color;

            &:before,
            &:after {
                background-color: $theme-color;
            }
        }
    }

    &:hover {
        .service-3d {
            &_icon {
                img {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

/* Service Card 6---------------------------------- */
.service-area6 {
    background: linear-gradient(180deg, rgba(237, 240, 244, 0.00) 0%, rgba(237, 240, 244, 0.85) 100%);
    overflow: hidden;
}

.service-item {
    border-radius: 30px;
    border: 1px solid $white-color;
    padding: 40px 30px;
    background: linear-gradient(180deg, #FFF 77.78%, rgba(255, 255, 255, 0.00) 100%);
    transition: all 0.4s ease-in-out;

    &_icon {
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        display: block;
        margin-bottom: 28px;
        background-color: #F3F5FC;
        border-radius: 50%;
    }

    &_text {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .box-title {
        letter-spacing: -0.48px;
        margin-bottom: 10px;

        @include xl {
            font-size: 20px;
        }
    }

    img {
        transition: all 0.4s ease-in-out;
    }

    &:hover {
        .service-item {
            &_icon {
                img {
                    transform: rotateY(360deg);
                }
            }
        }
    }
}

/* Service Card 7---------------------------------- */
.service-area7 {
    position: relative;
    background-color: #EFF1F9;
    border-radius: 30px;
    overflow: hidden;

    @include xxl {
        border-radius: 0;
    }
}

.service-grid7 {
    position: relative;
    background: $white-color;
    padding: 40px;
    border-radius: 20px;
    border: 1px solid #E3E7F0;
    transition: 0.4s ease-in-out;

    @include xl {
        padding: 25px;
    }

    &_icon {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px solid #E3E7F0;
        background-color: $white-color;
        box-shadow: drop-shadow(0px 26px 65px rgba(232, 234, 238, 0.55));
        border-radius: 50%;
        z-index: 9;
        transition: 0.4s ease-in-out;


        img {
            transition: 0.4s ease-in-out;
        }
    }

    &_text {
        margin-bottom: 15px;
    }

    .box-title {
        font-weight: 600;
        margin: 25px 0 5px 0;
    }

    .icon-btn {
        border-radius: 99px;
        background-color: $white-color;
        border: 1px solid $border-color;
        color: $title-color;
        position: relative;
        z-index: 3;
        display: block;
        text-align: center;
        margin: auto;
        transition: all 0.4s ease-in-out;

        &:hover {
            background-color: $theme-color;
            border-color: $theme-color;
            color: $white-color;
        }
    }

    .line-btn {
        font-family: $body-font;
    }

    &:hover {
        .service-grid7 {
            &_img {
                img {
                    transform: scale(1.1);
                }
            }

            &_icon {
                border: 1px solid $theme-color;

                &:before {
                    transform: scaleX(1.0);
                }

                img {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

/* Service Box 7---------------------------------- */
.service-box7 {
    text-align: center;

    &_thumb {
        border-radius: 20px;
        background: #EFF1F9;
        display: flex;
        align-items: end;
        margin-bottom: 50px;

        img {
            margin: 20px 20px 0 20px;
            border-radius: 10px 10px 0 0;
            width: -webkit-fill-available;
        }
    }

    &_content {
        margin: 0 30px;

        @include md {
            margin: 0;
        }
    }

    &_text {
        margin-bottom: -0.4em;
    }
}

/* Service Details ---------------------------------- */
.page-title {
    margin-top: -0.22em;
    font-size: 40px;
    margin-bottom: 20px;
}

.page-img {
    margin-bottom: 40px;
    border-radius: 5px;
    overflow: hidden;
}

.page-single {
    margin-bottom: 30px;
}

.service-feature {
    &-wrap {
        display: grid;
        grid-template-columns: auto auto;
        gap: 25px;
    }

    background-color: $white-color;
    padding: 30px 25px 30px 30px;
    border: 1px solid $border-color;
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 15px;

    &_icon {
        background: $theme-color;
        box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
        border-radius: 5px;
        @include equal-size-lineHeight(90px);
        text-align: center;
    }

    &_title {
        font-size: 20px;
        margin-bottom: 12px;
    }

    &_text {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
    }
}

@include xl {
    .page-title {
        font-size: 38px;
    }
}

@include xl {
    .service-feature {
        flex-direction: column;
        text-align: center;
        gap: 20px;
        padding: 30px 10px;

        &_text {
            margin-bottom: -0.5em;
            max-width: 260px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@include lg {
    .page-title {
        font-size: 32px;
    }
}

@include sm {
    .page-title {
        font-size: 28px;
    }
}

@include xs {
    .page-title {
        font-size: 24px;
    }

    .service-feature-wrap {
        grid-template-columns: auto;
    }
}


/* Service Card ---------------------------------- */
.service-card2 {
    border-radius: 20px;
    background: #16181D;
    padding: 40px;
    min-width: 327px;
    text-align: center;
    background-position: bottom center;
    background-size: auto;
    background-repeat: no-repeat;

    @include xl {
        min-width: 300px;
        padding: 30px;
        background-size: contain;
    }

    @include sm {
        padding: 30px 20px;
        min-width: 100%;
    }

    @include xs {
        padding: 30px;
    }

    &.style1 {
        text-align: left;
        max-width: 678px;

        @include lg {
            max-width: 100%;
            background-position: right center;
        }

        .service-card2 {
            &_content {
                max-width: 260px;
            }

        }

        .service-title {
            font-weight: 600;
            font-size: 36px;
            line-height: 46px;
            color: $white-color;
            margin-bottom: 28px;

            @include vxs {
                font-size: 25px;
                line-height: 35px;
            }

        }

        .ser-shape {
            position: absolute;
            top: 50px;
            right: 15px;
        }

        .cilent-box_counter {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: $white-color;
            max-width: 200px;
        }

        .counter-number {
            font-weight: 500;
            background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }

    &_wrap {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        @include xs {
            grid-template-columns: repeat(1, 1fr);
        }

        &.style1 {
            @include lg {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .service-shape {

            @include xl {
                left: 26% !important;
            }

            @include xs {
                display: none;
            }
        }
    }



    &_img {
        min-width: 100%;
        border-radius: 26px;


        img {
            width: 100%;
            border-radius: 26px;
        }
    }

    &_content {
        position: relative;
    }

    &_icon {
        position: relative;
        margin-bottom: 40px;
        transition: 0.4s ease-in-out;

        &:before {
            content: "";
            position: absolute;
            left: 25%;
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 50%;
            background: rgba(97, 255, 24, 0.1);

            @include lg {
                left: 30%;
            }

            @include xs {
                left: 40%;
            }

        }

        img {
            display: block;
            position: relative;
            z-index: 9;
            margin: auto;
            transition: all 0.4s ease-in-out;
        }
    }

    .box-title {
        color: $white-color;
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 15px;

        @include sm {
            font-size: 22px;
            line-height: 34px;
        }
    }

    &_text {
        color: #848994;
        line-height: 26px;
        margin-bottom: -0.4rem;
    }

    .th-btn {
        padding: 16px 35px;
        color: $title-color;
    }

    &:hover {
        .service-card2 {
            &_icon {
                img {
                    transform: scale(-1) rotate(180deg);
                }
            }
        }
    }
}

/* service 10 start -------------------*/
.service-area4 {
    background-color: $white-color;
    margin: 0 100px;

    @media(max-width: 1699px) {
        margin: 0;
    }
}

.service-box2 {
    position: relative;
    padding: 30px 30px 10px 30px;
    z-index: 2;
    overflow: hidden;
    transition: 0.6s ease-in-out;

    &_shape {
        position: absolute;
        top: 0;
        right: 0;
        width: 148px;
        height: 148px;
        background-position: right center;
        background-size: auto;
        background-repeat: no-repeat;
        z-index: 9;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        background-color: #F3F2EA;
        transition: 0.4s ease-in-out;
        z-index: -1;
        clip-path: path("M307 0H20C8.9543 0 0 8.9543 0 20V230C0 241.046 8.95431 250 20 250H251C262.046 250 271 241.046 271 230V224C271 207.431 284.431 194 301 194H307C318.046 194 327 185.046 327 174V20C327 8.9543 318.046 0 307 0Z");

        @media(max-width: 1399px) {
            clip-path: path("M295 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.9543 250 20 250H241.055C252.101 250 261.055 241.046 261.055 230V224C261.055 207.431 274.487 194 291.055 194H295C306.046 194 315 185.046 315 174V20C315 8.9543 306.046 0 295 0Z");
        }

        @media(max-width: 1299px) {
            clip-path: path("M230 0H20C8.95431 0 0 8.95431 0 20V250C0 261.046 8.95431 270 20 270H187.187C198.232 270 207.187 261.046 207.187 250V235.208C207.187 221.021 218.687 209.52 232.875 209.52C242.333 209.52 250 201.853 250 192.395V20C250 8.95431 241.046 0 230 0Z");
        }

        @include lg {
            clip-path: path("M430 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.95432 250 20 250H352.936C363.981 250 372.936 241.046 372.936 230V224C372.936 207.431 386.367 194 402.936 194H430C441.046 194 450 185.046 450 174V20C450 8.9543 441.046 0 430 0Z");
        }

        @include md {
            clip-path: path("M307 0H20C8.9543 0 0 8.9543 0 20V230C0 241.046 8.95431 250 20 250H251C262.046 250 271 241.046 271 230V224C271 207.431 284.431 194 301 194H307C318.046 194 327 185.046 327 174V20C327 8.9543 318.046 0 307 0Z");
        }

        @include sm {
            clip-path: path("M430 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.95432 250 20 250H352.936C363.981 250 372.936 241.046 372.936 230V224C372.936 207.431 386.367 194 402.936 194H430C441.046 194 450 185.046 450 174V20C450 8.9543 441.046 0 430 0Z");
        }

        @media(max-width: 480px) {
            clip-path: path("M307 0H20C8.9543 0 0 8.9543 0 20V230C0 241.046 8.95431 250 20 250H251C262.046 250 271 241.046 271 230V224C271 207.431 284.431 194 301 194H307C318.046 194 327 185.046 327 174V20C327 8.9543 318.046 0 307 0Z");
        }

        @media(max-width: 320px) {
            clip-path: path("M270 0H20C8.95431 0 0 8.9543 0 20V230C0 241.046 8.9543 250 20 250H220.336C231.382 250 240.336 241.046 240.336 230V223.798C240.336 207.341 253.677 194 270.135 194C281.106 194 290 185.106 290 174.135V20C290 8.9543 281.046 0 270 0Z");
        }
    }

    &:before {
        width: 100%;
    }

    &:after {
        width: 0;
        background-color: $theme-color;
    }

    &_icon {
        margin-bottom: 25px;
    }

    &_text {
        max-width: 233px;
    }

    .box-title {
        font-size: 20px;
    }

    img {
        transition: all 0.6s ease-in-out;
    }

    .icon-btn {
        width: var(--btn-size, 46px);
        height: var(--btn-size, 46px);
        line-height: var(--btn-size, 46px);
        font-size: var(--btn-font-size, 16px);
        background-color: #F3F2EA;
        color: $theme-color;
        text-align: center;
        border-radius: 50px;
        border: none;
        transition: 0.6s ease-in-out;
        position: absolute;
        right: 0;
        bottom: 5px;

        @media(max-width: 1399px) {
            bottom: 0;
        }

        @include xl {
            bottom: 20px;
        }

        @include lg {
            bottom: 5px;
            right: 20px;
        }

        @include md {
            bottom: 0;
            right: 0;
        }

        @include sm {
            right: 80px;
        }

        @include vxs {
            right: 0;
        }

    }

    &:hover {
        &:after {
            width: 100%;
        }

        img {
            filter: brightness(0) invert(1);
        }

        .box-title {
            color: $white-color;
        }

        .icon-btn {
            background-color: $theme-color;
            color: $white-color;
        }

        .service-box2 {
            &_icon {
                img {
                    transform: scale(-1) rotate(180deg);
                }
            }

            &_text {
                color: $white-color;
            }
        }
    }
}

/* service 10 end -------------------*/
/* Service 11 start ------------------*/
.service-area5 {
    position: relative;

}

.service-box3 {
    position: relative;
    background-color: #EFF1F9;
    border-radius: 30px;
    padding: 30px;

    @include xl {
        padding: 30px 20px;
    }


    &_icon {
        margin-bottom: 30px;

        img {
            transition: all 0.6s ease-in-out;
        }

    }

    &_img {
        position: relative;
        border-radius: 30px;
        max-width: 678px;

        img {
            width: 100%;
            border-radius: 30px;
        }

        .service-shape {
            position: absolute;
            bottom: -94px;
            left: -71px;
            z-index: -1;
            width: 131px;
            height: 134px;
            background-repeat: no-repeat;
            background-size: auto;
            mask-repeat: no-repeat;
            mask-size: auto;
            background-color: #EFF1F9;

            @media(max-width: 1399px) {
                bottom: -99px;
                left: -73px;
            }

            @include xl {
                bottom: -136px;
                left: -72px;
            }

            @include lg {
                display: none;
            }
        }

        .service-shape2 {
            position: absolute;
            bottom: -80px;
            right: -66px;
            z-index: -1;
            width: 122px;
            height: 124px;
            background-repeat: no-repeat;
            background-size: auto;
            mask-repeat: no-repeat;
            mask-size: auto;
            background-color: #EFF1F9;

            @media(max-width: 1399px) {
                bottom: -88px;
                right: -66px;
            }

            @include xl {
                bottom: -122px;
                right: -66px;
            }

            @include lg {
                display: none;
            }
        }
    }

    .box-title {
        font-weight: 600;

        @include xl {
            font-size: 20px;
        }
    }

    .line-btn {
        font-size: 16px;
        text-transform: capitalize;
    }

    &:hover {
        .service-box3 {
            &_icon {
                img {
                    transform: scale(-1) rotate(180deg);
                }
            }
        }


    }

}

.service-btn {
    min-width: 100%;
    min-height: 317px;
    background-color: $theme-color;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;




}

.service-btn-area {
    position: relative;
    z-index: 2;

    .ser-shape {
        position: absolute;
        top: -7%;
        left: -10%;
        z-index: 2;
        transform: translate(50%, 50%);
        z-index: 0;

        @include xl {
            top: -7%;
            left: -20%;
        }

        @include lg {
            left: 20px;
        }

        @include md {
            left: -20px;
        }

        @include vxs {
            left: -30px;
        }
    }
}


/* service 11 end -------------------*/

/* Service 12 start ------------------------ */
.service-item2 {
    position: relative;
    padding: 50px 40px 60px 40px;
    background-color: $theme-color;
    box-shadow: 0px 0px 15px #E9E9E9;
    border-radius: 5px 5px 50px 5px;
    margin-bottom: 0;
    transition: 0.4s ease-in-out;
    z-index: 2;

    @media(max-width: 1399px) {
        padding: 40px 30px;
    }

    @include xl {
        padding: 50px 20px;
    }

    &:hover {
        &:before {
            height: 0;
        }
    }

    &:before,
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: inherit;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: 0.4s ease-in-out;

    }

    &:before {
        background-color: $white-color;
    }

    &:after {
        border-top: 6px solid $theme-color;
    }

    .box-title {
        font-weight: 600;
        margin-right: 40px;

        @media(max-width: 1399px) {
            margin-right: 20px;
        }

        @include xl {
            margin-right: 0;
        }

        a {
            color: inherit;
        }
    }

    &_text {
        margin-bottom: 25px;
    }

    &:hover {
        .service-item2 {
            &_text {
                color: $white-color;
            }
        }

        .box-title {
            color: $white-color;
        }

        .line-btn {
            color: $white-color;

            &:before {
                background-color: $white-color;
            }
        }
    }

    .line-btn {
        font-size: 16px;
        color: $theme-color;
        text-transform: capitalize;

        &:before {
            background-color: $theme-color;
        }
    }
}

/* Service 12 end ------------------------ */

/* Service 13 start --------------------------*/
.service_card2 {
    position: relative;
    background-color: #EFF1F9;
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    z-index: 2;

    @media(max-width: 1399px) {
        padding: 30px 20px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        border-radius: inherit;
        transition: 0.6s ease;
    }

    &:before {
        width: 100%;
        height: 100%;
        background-color: #EFF1F9;
        top: 0;
        left: 0;
        z-index: -1;

    }

    &:after {
        inset: 0;
        background: rgba(62, 101, 243, 0.8);
        z-index: -2;
    }

    .box-icon {
        position: relative;
        width: 90px;
        height: 90px;
        line-height: 80px;
        text-align: center;
        border-radius: 50%;
        background-color: $white-color;
        border: 5px solid #7D9AFF;
        display: block;
        margin: auto auto 22px auto;
        z-index: 2;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            transform: scale(0);
            border-radius: 50%;
            background-color: var(--theme-color);
            transform-origin: center;
            transform-style: preserve-3d;
            transition: all 0.5s cubic-bezier(0.62, 0.21, 0.45, 1.52);
            z-index: -1;
        }

        img {
            transition: all 0.4s ease-in-out;
        }
    }

    .box-title {
        font-size: 22px;
        font-weight: 600;

        a {
            color: inherit;
        }
    }

    &_text {
        margin-bottom: 12px;
    }

    .line-btn {
        text-transform: capitalize;
    }

    &:hover {
        &:before {
            width: 0;
        }

        .box-title {
            color: $white-color;
        }

        .box-icon {
            border: 5px solid $white-color;

            &:before {
                transform: scaleX(1);
            }

            img {
                filter: brightness(0) invert(1);
                transform: rotateY(180deg);
            }
        }

        .service_card2 {
            &_text {
                color: $white-color;
            }
        }

        .line-btn {
            color: $white-color;

            &:before {
                background-color: $white-color;
            }
        }
    }
}

/* Service 13 end --------------------------*/

/* Service 14 start ------------------------------*/
.service_card3 {
    background-color: transparent;
    border-radius: 6px;
    padding: 40px;
    border: 1px solid #E3E7F0;
    text-align: center;

    @include xl {
        padding: 30px 20px;
    }

    .box-icon {
        background-color: #EFF1F9;
        border-radius: 6px;
        padding: 20px;
        display: inline-block;
        margin-bottom: 30px;
    }

    .box-title {
        font-weight: 600;
        margin: 0 40px 13px 40px;

        @media(max-width: 1399px) {
            margin: 0 20px 13px 20px;
        }

        @include xl {
            margin: 0 0 13px 0;
            font-size: 20px;
        }
    }

    .line-btn {
        text-transform: capitalize;
    }
}

/* Service 14 end ------------------------------*/

/* Service 15 start ------------------------------*/
.service_card4 {
    text-align: center;
    background-color: transparent;
    border-radius: 20px;
    padding: 40px;
    transition: all 1s ease;

    @include xl {
        padding: 30px;
    }

    .box-icon {
        margin-bottom: 30px;

        img {
            transition: all 0.6s ease-in-out;
        }
    }

    .box-title {
        @include lg {
            font-size: 20px;
        }
    }

    .line-btn {
        text-transform: capitalize;
    }

    &_text {
        margin-bottom: 15px;
    }

    &:hover {
        background-color: #EFF1F9;

        .box-icon {
            img {
                transform: scale(-1) rotate(180deg);
            }
        }
    }

}

/* Service 15 end ------------------------------*/
/* Service 16 start ------------------------------*/
.service-sec16 {
    background-color: #EFF1F9;
}

.service_card5 {
    position: relative;
    background-color: $white-color;
    border-radius: 30px;
    padding: 40px 0 0 40px;
    min-height: 311px;
    overflow: hidden;

    @include lg {
        padding: 30px 0 0 30px;
    }

    &_content {
        padding-right: 40px;

        @include lg {
            padding-right: 30px;
        }
    }

    &_wrapp {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .line-btn {
        text-transform: capitalize;
        font-size: 16px;
        min-width: 104px;

        &:before {
            display: none;
        }
    }
}

/* Service 16 end ------------------------------*/

/* Service 17 start ------------------------------*/
.service_card6 {
    position: relative;
    background-color: $white-color;
    border-radius: 20px;
    padding: 30px 20px;
    text-align: center;
    transition: all 0.4s ease-in-out;
    z-index: 2;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        background-color: $title-color;
        border-radius: 20px;
        z-index: -1;
        transition: all 0.4s ease-in-out;

    }

    @include xl {
        padding: 30px 20px;
    }

    .box-icon {
        background-color: #EFF1F9;
        border-radius: 50%;
        padding: 20px;
        display: inline-block;
        margin-bottom: 20px;
        transition: all 0.4s ease-in-out;

        img {
            transition: all 0.4s ease-in-out;
        }
    }

    .box-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 7px;

        @include xl {
            font-size: 18px;
        }
    }

    &_text {
        margin-bottom: 10px;
    }

    .line-btn {
        text-transform: capitalize;
    }

    &:hover {
        &:before {
            height: 100%;
        }

        .box-icon {
            background-color: $theme-color;

            img {
                filter: brightness(0) invert(1);
                transform: scale(-1) rotate(180deg);
            }
        }

        .box-title {
            color: $white-color;
        }

        .line-btn {
            color: $theme-color;

            &:before {
                background-color: $theme-color;
            }
        }

        .service_card6 {
            &_text {
                color: $white-color;
            }
        }
    }
}

/* Service 14 end ------------------------------*/
.body-shape-content {
    position: absolute;
    top: 51%;
    left: -3%;
    transform: translateY(-50%);

    @media(max-width: 1699px) { 
        display: none;
    }

    &.style2 {
        left: unset;
        right: -5%;

        .shepe-text {
            color: #EFF1F9;
        }
    }

    &.style3 {
        left: unset;
        right: 1%;

        .shepe-text {
            color: #EFF1F9;
        }
    }

    &.style4 {
        .shepe-text {
            color: #3E66F3;
            opacity: 0.1;

        }
    }
}

.shepe-text {
    font-weight: 700;
    font-size: 84px;
    line-height: 101px;
    color: rgba(239, 241, 249, 0.15);
    transform: rotate(-90deg);

}