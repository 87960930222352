/* Gallery Card ---------------------------------- */
.gallery-card {
    .gallery-img {
        position: relative;
        z-index: 2;
        border-radius: 10px;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            inset: 0;
            background-color: $black-color2;
            opacity: 0.8;
            transition: 0.4s ease-in-out;
            transform: scaleX(0);
            z-index: 1;
        }
        img {
            width: 100%;
            transition: 0.4s ease-in-out;
        }
        .play-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            > i {
                font-size: 24px;
            }
        }
    }
    &:hover {
        .gallery-img {
            &:before {
                transform: scaleX(1);
            }
            img {
                transform: scale(1.1);
            }
            .play-btn {
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }
}
/* Gallery box start---------------------------------- */
.gallery-row {
    --bs-gutter-y: 1.9rem;
}
.gallery-box {
    --box-space: 30px;   
    position: relative;
    text-align: center;
    overflow: hidden;

    .gallery-img {
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            transition: 0.4s ease-in-out;
        }
    }

    .gallery-content {
        position: absolute;
        top: var(--box-space);
        left: var(--box-space);
        width: calc(100% - var(--box-space)*2);
        height: calc(100% - var(--box-space)*2);
        z-index: 2;
        background: rgba(1, 15, 52, 0.77);
        backdrop-filter: blur(10.600000381469727px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        visibility: hidden;
        opacity: 0;
        transform: scale(0);
        transition: 0.4s ease-in-out;
    }

    .box-title {
        font-size: 20px;
        line-height: 30px; 
        margin-bottom: 20px;

        a:not(:hover) {
            color: $white-color;
        }
    }

    .gallery-subtitle {   
        color: $theme-color;
        margin-bottom: 8px;
    }

    .icon-btn {
        width: var(--btn-size, 46px);
        height: var(--btn-size, 46px);
        line-height: var(--btn-size, 46px); 
        border-radius: 50%;
        border: 2px solid $white-color;
        color: $white-color;
        font-size: 20px;

        &:hover {
            background: $white-color;
            color: $title-color;
        }
    }

    &:hover {
        .gallery-img {
            img {
                transform: scale(1.2) rotate(-5deg);
            }
        }

        .gallery-content {
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        }
    } 
}
/* Gallery box end---------------------------------- */
