/* Blockquote ---------------------*/
blockquote,
.wp-block-quote {
    display: block;
    position: relative;
    overflow: hidden;
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    font-family: $body-font;
    color: $title-color;
    background-color: var(--quote-bg, #f5f5f5);
    padding: 50px 40px 38px 165px;
    margin: 35px 0;
    line-height: 1.667;
    border: none !important;
    border-radius: 5px;

    p,
    pre {
        font-family: inherit;
        color: inherit;
        font-weight: inherit;
        width: 100%;
        position: relative;
        z-index: 3;
        margin-top: -0.5em;
        margin-bottom: 0;
        a {
            color: inherit;
        }
    }

    &:before {
        content: "\f10e";
        font-family: $icon-font;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 60px;
        font-weight: 900;
        line-height: 1;
        color: $white-color;
        background-color: $theme-color;
        width: 125px;
        height: 100%;
        text-align: center;
        display: grid;
        align-items: center;
    }

    cite {
        display: inline-block;
        font-size: 18px !important;
        font-family: $title-font;
        position: relative;
        padding-left: 45px;
        line-height: 1;
        font-weight: 600;
        margin-top: 17px;
        font-style: normal;
        color: $theme-color;
        white-space: nowrap;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 7px;
            width: 30px;
            height: 2px;
            border-top: 2px solid $theme-color;
        }
    }
    &.style-left-icon,
    &.is-large:not(.is-style-plain),
    &.is-style-large:not(.is-style-plain),
    &.has-text-align-right {
        padding: 50px 40px 38px 165px;
    }
    &.style-left-icon {
        font-size: 18px;
        color: $body-color;
        font-weight: 400;
        line-height: 1.556;
        background-color: $smoke-color;
        padding-left: 160px;
        cite {
            color: $title-color;
            &:before {
                background-color: $title-color;
                top: 8px;
            }
        }
    }
    &.is-large,
    &.is-style-large {
        p {
            margin-bottom: -0.4em;
        }
        cite {
            &:before {
                top: unset;
                bottom: 10px;
            }
        }
    }
    &.has-text-align-right {
        &:before {
            content: "\f10d";
            right: unset;
            left: 0;
        }
    }
}
.wp-block-pullquote {
    color: $body-color;
    padding: 0;
}

blockquote.has-very-dark-gray-color {
    color: $body-color !important;
}

.wp-block-column {
    blockquote,
    .wp-block-quote {
        &:before {
            width: 100%;
            height: 60px;
            font-size: 30px;
        }
        padding: 100px 15px 30px 15px;
        &.style-left-icon,
        &.is-large:not(.is-style-plain),
        &.is-style-large:not(.is-style-plain),
        &.has-text-align-right {
            padding: 100px 15px 30px 15px;
        }
    }
}

@include lg {
    blockquote,
    .wp-block-quote {
        font-size: 16px;
        padding: 40px 20px 30px 120px;
        &:before {
            width: 100px;
            font-size: 52px;
        }
        cite {
            margin-top: 23px;
        }
        &.style-left-icon,
        &.is-large:not(.is-style-plain),
        &.is-style-large:not(.is-style-plain),
        &.has-text-align-right {
            padding: 40px 20px 30px 120px;
        }
    }
}

@include sm {
    blockquote,
    .wp-block-quote {
        padding: 100px 20px 30px 20px;

        &:before {
            height: 60px;
            width: 100%;
            font-size: 2.5rem;
        }
        &.style-left-icon,
        &.is-large:not(.is-style-plain),
        &.is-style-large:not(.is-style-plain),
        &.has-text-align-right {
            padding: 100px 20px 30px 20px;
        }
    }
    .wp-block-pullquote.is-style-solid-color blockquote {
        max-width: 90%;
    }
}

.blog-meta {
    display: block;
    span,
    a {
        display: inline-block;
        font-size: 14px;
        color: $body-color;
        font-family: $body-font;
        position: relative;
        margin-right: 20px;
        i {
            margin-right: 6px;
            color: $theme-color;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .author {
        img {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin-right: 6px;
        }
    }
    a:hover {
        color: $theme-color;
    }
}


@include lg {
    .blog-meta {
        span,
        a {
            margin-right: 6px;
            padding-right: 15px;
        }
    }
}

.blog-audio,
.blog-img,
.blog-video {
    img {
        transition: 0.4s ease-in-out;
    }
}

.blog-title {
    a {
        color: inherit;

        &:hover {
            color: $theme-color;
        }
    }
}

.th-blog {
    margin-bottom: 30px;
}

.blog-inner-title {
    margin-top: -0.25em;
    margin-bottom: 25px;
    i {
        color: $theme-color;
        margin-right: 4px;
    }
}

@media (min-width: 1300px) {
    .page-single,
    .blog-single,
    .as-comments-wrap,
    .as-comment-form {
        margin-right: 16px;
    }
}

.blog-single {
    position: relative;
    margin-bottom: var(--blog-space-y, 40px);
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
    border-radius: 5px;
    overflow: hidden;
    .blog-title {
        margin-bottom: 15px;
        font-size: 36px;
        line-height: 1.4;
        font-weight: 600;
    }
    .blog-text {
        margin-bottom: 27px;
    }
    .social-links {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: inline-block;
        li {
            display: inline-block;
            margin-right: 3px;
            &:last-child {
                margin-right: 0;
            }
        }
        a {
            display: inline-block;
            @include equal-size(40px);
            line-height: 40px;
            background-color: $smoke-color;
            font-size: 14px;
            color: $title-color;
            text-align: center;
            border-radius: 5px;
            &:hover {
                color: $white-color;
                background-color: $theme-color;
            }
        }
    }
    .wp-block-tag-cloud, 
    .tagcloud  {
        a {
            background-color: $smoke-color;
            color: $title-color;
            &:hover {
                background-color: $theme-color;
            }
        }
    }
    .blog-meta {
        margin: -0.35em 0 10px 0;
    }
    .blog-content {
        margin: 0 0 0 0;
        padding: 40px;
        position: relative;
    }
    .blog-audio {
        line-height: 1;
    }
    .blog-audio,
    .blog-img,
    .blog-video {
        position: relative;
        overflow: hidden;
        background-color: $smoke-color;
    }
    .blog-img {
        .slick-arrow {
            --pos-x: 30px;
            --icon-size: 45px;
            border: none;
            background-color: $white-color;
            color: $theme-color;
            border-radius: 5px;
            box-shadow: none;

            &:hover {
                background-color: $theme-color;
                color: $white-color;
            }
        }
        .play-btn {
            --icon-size: 60px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2);
        }
    }
    .line-btn {
        display: block;
        max-width: fit-content;
        margin-bottom: -1px;
    }
    &:hover {
        .blog-img {
            .slick-arrow {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.share-links-title {
    font-size: 20px;
    color: $title-color;
    font-family: $title-font;
    font-weight: 700;
    margin: 0 15px 0 0;
    display: inline-block;
}

.share-links {
    margin: 0 var(--blog-space-x, 40px) var(--blog-space-y, 40px)
        var(--blog-space-x, 40px);
    border-top: 1px solid $border-color;
    padding: 30px 0 0 0;

    > .row {
        align-items: center;
        --bs-gutter-y: 20px;
    }

    .wp-block-tag-cloud,
    .tagcloud {
        display: inline-block;
    }
}

.blog-details {
    .blog-single {
        background-color: transparent;
    }
}

@include ml {
    .blog-single {
        .blog-content {
            padding: 40px 20px;
        }

        .blog-title {
            font-size: 28px;
        }
    }
    .share-links {
        --blog-space-x: 20px;
    }
}


@include md {
    .blog-single .blog-content {
        padding: 40px;
    }
    .blog-details .blog-single {
        --blog-space-x: 20px;
        --blog-space-y: 40px;
    }
    .share-links {
        --blog-space-x: 40px;
    }
}

@include sm {
    .share-links {
        --blog-space-x: 20px;
    }

    .blog-details .blog-single {
        --blog-space-x: 20px;
        --blog-space-y: 20px;
    }

    .blog-single {
        .blog-content {
            padding: 30px 20px;
        }

        .blog-title {
            font-size: 24px;
            line-height: 1.3;
        }

        .blog-text {
            margin-bottom: 22px;
        }
        .blog-bottom {
            padding-top: 15px;
        }

        .share-links-title {
            font-size: 18px;
            display: block;
            margin: 0 0 10px 0;
        }
    }
}