.particle-1 {
    max-width: 440px;
    height: 390px;
}
.particle-2 {
    max-width: 800px;
    height: 790px;
    aspect-ratio: 16 / 14;
    &.small {
        max-width: 515px;
        height: 490px;
    }
}

.particle-3 {
    max-width: 600px;
    height: 470px;
}

.box-particle {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 180px;
    height: 100px;
    z-index: -1;
}
.price-particle {
    width: 190px;
    height: 165px;
}

.body-particle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
}