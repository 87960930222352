.skill-feature {
    &:not(:last-child) {
        margin-bottom: 25px;
    }

    &_title,
    .progress-value {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: -0.2em;
        font-family: $title-font;
        color: $title-color;
    }

    .progress {
        position: relative;
        height: 10px;
        background-color: $white-color;
        overflow: visible;
        border-radius: 100px;
    }

    .progress-bar {
        background-color: $theme-color;
        height: 4px;
        margin: 3px;
        border-radius: inherit;
        position: relative;
        overflow: visible;
    }

    .progress-value {
        position: absolute;
        top: -34px;
        right: 0;
    }

    &.style2 {
        &:not(:last-child) {
            margin-bottom: 32px;
        }

        .progress {
            background-color: $smoke-color2;
        }
    }
}

@media (min-width: 1430px) {
    .video-box1 {
        margin-right: -105px;
    }
}

@media (min-width: 1300px) {
    .video-box1 {
        margin-left: 30px;
    }
}

.video-box1 {
    position: relative;
    text-align: center;

    img {
        width: 100%;
        max-width: 650px;
    }

    .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

/* video box2 start ---------------------------------*/
.video-box2 {
    position: relative;
    text-align: center; 
    margin-top: 35px; 
    @include md {
        margin-bottom: 80px;
    }

    img {
        width: 100%;
        border-radius: 30px;
    }

    // .play-btn {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    // }
    .video-play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0;
        width: 100px;
        height: 100px;
        line-height: 102px;
        transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        border-radius: 50%;
        background: transparent;
        margin: 0 auto;
        display: inline-block;
        font-size: 24px;
        text-align: center;
        color: $white-color;
        @include sm{
            width: 50px;
            height: 50px;
            line-height: 52px;
            font-size: 20px;
        }
    
        i {
            // margin-left: 5px; 
            color: $white-color;
        }
    
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            transition: 0.3;
            border-radius: 50%;
            border: 2px solid $white-color;
            animation: ripple 2s linear infinite;
        }
    
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            transition: 0.3;
            border-radius: 50%;
            border: 2px solid $white-color;
            animation: ripple 2s linear 1s infinite;
        }
    }
}

/* video box2 end ---------------------------------*/