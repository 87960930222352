/* Blog Card ---------------------------------- */
.blog-card {
    box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    background-color: $white-color;

    .box-title {
        margin-bottom: 22px;

        a {
            background-image: linear-gradient(to left, $theme-color, $theme-color);
            background-repeat: no-repeat;
            background-position: bottom left;
            background-size: 0 2px;
            transition: 0.5s ease-in-out;

            &:hover {
                background-size: 100% 2px;
            }
        }
    }

    .blog-img {
        overflow: hidden;
        box-shadow: 0px 10px 15px rgba(23, 27, 42, 0.06);
        border-radius: 10px 10px 0px 0px;

        img {
            width: 100%;
            transition: 0.4s ease-in-out;
        }
    }

    .blog-meta {
        margin-bottom: 20px;

        span,
        a {
            >i {
                color: inherit;
            }
        }
    }

    .blog-content {
        padding: 30px 40px;
    }

    .blog-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $border-color;
        padding-top: 25px;
    }

    .author {
        color: $body-color;

        img {
            margin-right: 5px;
        }

        &:hover {
            color: $theme-color;
        }
    }

    .blog-text {
        margin-top: -0.5em;
        margin-bottom: 22px;
    }

    .line-btn {
        margin-bottom: 0;
        display: block;
        width: fit-content;
        color: $body-color;

        &:before {
            background-color: $body-color;
        }

        &:hover {
            color: $theme-color;

            &:before {
                background-color: $theme-color;
            }
        }
    }

    &:hover {
        .blog-img {
            img {
                transform: scale(1.08);
            }
        }
    }
}

@include xl {
    .blog-card .blog-content {
        padding: 30px 35px;
    }
}

@include md {
    .blog-card {
        .blog-content {
            padding: 30px 30px;
        }

        .box-title {
            font-size: 22px;
        }
    }
}

@include sm {
    .blog-card {
        .blog-content {
            padding: 30px 40px;
        }

        .box-title {
            font-size: 24px;
        }
    }
}

@media (max-width: 410px) {
    .blog-card {
        .blog-content {
            padding: 30px 30px;
        }

        .box-title {
            font-size: 22px;
        }
    }
}

@media (max-width: 350px) {
    .blog-card {
        .blog-content {
            padding: 30px 20px;
        }

        .box-title {
            font-size: 20px;
        }
    }
}

/* blog box --------------------------  */
.blog-box {
    position: relative;

    .blog-img {
        position: relative;
        border-radius: 20px;
        margin-bottom: 35px;
        overflow: hidden;

        img {
            width: 100%;
            border-radius: 20px;
            transform: 0.6s ease-in-out;
        }
    }

    .blog-meta {
        margin-bottom: 25px;

        a {
            font-size: 16px;
            color: #8D929C;

            i {
                background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
    }

    .box-title {
        color: $white-color;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.02em;
        margin-bottom: 25px;
    }

    .line-btn {
        text-transform: capitalize;
        background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.02em;

        &:before {
            background: linear-gradient(180deg, $theme-color 0%, $theme-color2 100%);
        }
    }

    &:hover {
        .blog-img {
            img {
                transform: scale(1.08);
            }
        }
    }
}

/*blog box start ----------------------------- */
.blog-box2 {
    background-color: $white-color;
    box-shadow: 0px 5px 15px rgba(0, 31, 96, 0.06);
    padding: 30px;
    border-radius: 30px;

    &.style2 {
        .blog-img {
            margin-bottom: 30px;
        }
    }

    .blog-img {
        position: relative;
        overflow: hidden;
        border-radius: 15px;

        img {
            border-radius: 15px;
            transform: 0.4s ease-in-out;
        }
    }

    .blog-tag {
        position: relative;
        padding: 4.5px 20.5px;
        font-family: $body-font;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        background-color: $theme-color;
        border: 1px solid $white-color;
        color: $white-color;
        border-radius: 15px;
        max-width: 142px;
        display: block;
        margin: -15px auto auto auto;
        margin-bottom: 28px;
        z-index: 2;
    }

    .box-title {
        margin-bottom: 14px;

    }

    .blog-img {
        img {
            width: 100%;
        }
    }

    .blog-text {}

    .blog-meta {
        margin: -0.3rem 0 18px 0;

        span,
        a {
            font-size: 16px;
        }
    }

    .line-btn {
        text-transform: capitalize;
    }

    &:hover {
        .blog-img {
            img {
                transform: scale(1.08);
            }
        }
    }
}

/* blog-box2 style3 start ------------------------------*/
.blog-box2 {
    &.style3 {
        padding: 40px;

        @include xs {
            padding: 30px;
        }

        .blog-meta {
            background-color: $theme-color;
            padding: 12px 24px 12px 43px;
            margin: -50px 0 38px -22px;
            clip-path: path("M22 1.01855L294.979 0.0401371C298.301 0.02823 301 2.71799 301 6.0401V52.0186H55.6471H0L22 27.0186V1.01855Z");
            border-radius: 0px 0px 6px 0px;

            @media(max-width: 480px) {
                margin: 30px 0 38px -22px;
                padding: 12px 12px 12px 30px;
            }

            @include vxs {
                clip-path: none;
                padding: 12px 11px 11px 11px; 
                margin-bottom: 25px;
                border-radius: 6px;
            }


            a {
                color: $white-color;

                @include vxs {
                    font-size: 14px;
                }

                i {
                    color: $white-color;
                }
            }
        }
    }
}